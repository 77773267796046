import React from 'react';
import APICall from '../../Common/APICall.js';
import styled from 'styled-components';
import Store from '../../Common/Store.js';
// import {CSLTable} from '@mcc-regtech/core-library';
import CSLTable from '../../Components/Common/CSLTable';
import DatePicker from "react-date-picker";
import moment from "moment";
import { BiCommentDetail } from "react-icons/bi";
import ClipLoader from 'react-spinners/ClipLoader';
import TaskDetailsLogModal from "./TaskDetailsLogModal";
import ActivityCompletedModal from './ActivityCompletedModal';

const MRButton = styled.button`
  box-sizing: border-box;
  background-color: #01B0A2;
  color: #ffffff;
  border: 1px solid #37ada7;
  width: 100px;
  font-size: 13px;
  height: 25px;
  border-radius: 4px;
  margin-right: 3px;
`;
const InactiveOverlay = styled.div`
  display: block;
  box-sizing: border-box;
  height: 1178px;
  width: 100%;
  background-color: #ffffff;
  position: absolute;
  left: -10%;
  top: -80px;
  z-index: 1000;
  opacity: 0.8;
`;
const Dropdownlist = styled.select`
  height: 20px;
  border-radius: 4px;
  width: 150px;
  margin-left: 30px;
`;


const DummyOverlay = styled.div`
  display: table;
  box-sizing: border-box;
  height: calc(100vh - 65px);
  width: calc(100vw - 300px);
  background-color: #ffffff;
  position: fixed;
  left: 300px;
  top: 65px;
  z-index: 1000;
  opacity: 0.6;
`;
const Source_Map = { finpromo: 'Financial Promotion' }
const Activity_Shown = {
    COMP_FINPROMO_REGISTERED: 1,
    COMP_FINPROMO_REVISION: 1,
    COMP_FINPROMO_WITHDRAWN: 1,
    COMP_FINPROMO_CONFIRMED: 1,
    COMP_FINPROMO_EXPIRED: 1
};
const Activity_Text = {
    'COMP_FINPROMO_REGISTERED': 'New FP Submitted',
    'COMP_FINPROMO_REVISION': 'FP sent for Revision',
    'COMP_FINPROMO_WITHDRAWN': 'FP Withdrawn',
    'COMP_FINPROMO_CONFIRMED': 'FP Confirmed',
    'COMP_FINPROMO_EXPIRED': 'FP Expired'
};

class FinancialPromotionLog extends React.Component {
    state = {
        ready: false,
        view_count: 0,
        cur_view: 'activitylog',
        screen: { changelog: 0, activitylog: 1, emaillog: 0 },
        module: {},
        contact_name: '',
        menu_obj: '',
        activitylog_data: {},
        contact_list: {},
        gc_companies: {},
        client_name: '',
        emaillog: {},
        data_change_log: {},
        staff_list: {},

    }

    componentDidMount = () => {
        let module_id = 'finpromo';
        //console.log("componentDidMount_Auditlogs ====>", module_id)
        let postData = { command: "getauditlog2", module: module_id };
        let api = new APICall();
        api.command(postData, this.processDataSet);
    }

    processDataSet = (result) => {
        this.setState({
            ready: true,
            contact_name: result.loggedin_contact.contact_name,
            activitylog_data: result.my_tasks,
            contact_list: result.contact_list,
            gc_companies: result.gc_companies,
            client_name: result.clientname,
            data_change_log: result.data_change_log,
            staff_list: result.staff_list
        });
    }
    refreshCallBack = (cur_view) => {
        this.setState({ cur_view: cur_view })
    }

    render() {
        let module_id = 'finpromo';
        return (

            <div style={{ width: "100%", position: "relative" }}>
                {(() => {
                    if (JSON.stringify(this.state.activitylog_data) === '{}') {
                        return (<div>
                            <DummyOverlay>
                                <div
                                    style={{ display: 'table-cell', verticalAlign: 'middle', textAlign: 'center' }}
                                >
                                    <ClipLoader size={150} color={'#123abc'} loading={true} />
                                </div>
                            </DummyOverlay>
                        </div>)
                    }
                    return (
                        <ActivityLog changelog_data={this.state.data_change_log}
                            activitylog_data={this.state.activitylog_data}
                            contact_list={this.state.contact_list}
                            gc_companies={this.state.gc_companies}
                            client_name={this.state.client_name}
                            module_id={module_id} />
                    );
                })()}
            </div>
        );
    }
}

export default FinancialPromotionLog;


class ActivityLog extends React.Component {
    state = {
        activitylog_data: {},
        client_name: '',
        modal_view: null,
        modal_data: {},
        ddlmodule_val: 0,
        ddldate_val: 'anydate',
        startDate: new Date(),
        endDate: new Date(),
        tableData: '',
        module_id: 'finpromo',
        showmodal: null,
        selectedTask: []
    }

    componentDidMount = () => {
        this.setState({
            activitylog_data: this.props.activitylog_data,
            client_name: this.props.client_name,
            //module_id: this.props.module_id
        })

    }
    closeModal = (event) => {
        this.setState({
            showmodal: null
        })
    }
    calculateDateInTimestamp = function (inputDate) {
        var date_input = new Date(inputDate);
        var date_output = date_input.getDate() + '/' + (date_input.getMonth() + 1) + '/' + date_input.getFullYear()
        return date_output;
    }
    getGroupCompanies = (company_id) => {
        var group_company_id = parseInt(company_id) % 100000;
        let group_company_name = '';
        let gc_companies = this.props.gc_companies;
        let grp = {};
        if (gc_companies.length > 0) {
            for (var k = 0; k < gc_companies.length; k++) {
                grp[gc_companies[k].id] = gc_companies[k].company_name;
            }
        }
        group_company_name = grp[group_company_id]
        return group_company_name;
    }
    openActivityModal = (task_id, module_id, client_name, changed_date) => (event) => {
        event.preventDefault();
        var task_data = {};
        for (let i = 0; i < this.state.activitylog_data.length; i++) {
            task_data[this.state.activitylog_data[i].id] = this.state.activitylog_data[i];
        }
        var modal_data = {
            module_name: module_id,
            client_name: client_name,
            changed_date: changed_date,
            activitylog_data: task_data[task_id]
        }
        //console.log("uu", modal_data);
        this.setState({ modal_view: 'activitymodal', modal_data: modal_data })
    }

    getDaysInMonth = (year, month) => {
        return new Date(year, month, 0).getDate();
    }
    showModal = (data) => {
        if (data != null) {
            let dataelem = data;
            this.setState({ showmodal: 'taskdetailsmodal', selectedTask: dataelem });
        }
    }
    addMonths = (input, months) => {
        months = isNaN(months) ? parseInt(-100) : months;
        const date = new Date(input)
        date.setDate(1)
        date.setMonth(date.getMonth() + months);
        date.setDate(Math.min(input.getDate(), this.getDaysInMonth(date.getFullYear(), date.getMonth() + 1)));
        return date;
    }
    processAcceptanceQueueData = () => {
        let followup_tasks = [];
        let temptaskid = 0;
        let taskData = this.state.activitylog_data;
        var task_action_arr = [];
        var task_action = {};
        for (let i = 0; i < taskData.length; i++) {
            var taskjson = JSON.parse(taskData[i].task_json);
            //store and pass followup information
            if (taskjson.task_data.hasOwnProperty('task_type')) {
                if (taskjson.task_data.task_type === 'COMP_FPR_FOLLOWUP') {
                    followup_tasks.push(taskjson);
                }
                Store.updateStore('followuptasks_from_log', followup_tasks);
            }
            var action_data = taskjson.action_data;
            //console.log("Action Data", action_data);
            for (let j = 0; j < action_data.length; j++) {
                if (action_data[j].action in Activity_Shown) {
                    if (temptaskid == taskData[i].id)
                        continue;
                    let lane_parts = action_data[j].action.split('_')
                    let lane_end = lane_parts[lane_parts.length - 1]

                    task_action.module_id = taskData[i].module_id;
                    task_action.fpr_id = taskData[i].alt_ref_id;
                    task_action.activity_type = action_data[j].action;
                    task_action.client_id = taskData[i].company_id; //.unix(date).toDate()
                    task_action.last_action_time = moment.unix(taskData[i].last_action_time).format("DD/MM/YYYY");
                    if (lane_end == 'CREATE') {
                        task_action.changed_by = taskData[i].contact_id;
                    } else {
                        task_action.changed_by = action_data[j].performed_by;
                    }
                    task_action.changed_date = this.calculateDateInTimestamp(action_data[j].actiondate);
                    task_action.date_time_stamp = action_data[j].actiondate;
                    temptaskid = taskData[i].id;
                    task_action.task_id = taskData[i].id
                    task_action_arr.push(task_action);
                    task_action = {};
                }

            }
        }
        //console.log(task_action_arr);
        let ret = { data: [], columns: [] };
        let i = 1;

        ret.columns = [
            // {
            //     Header: "Sl No",
            //     id: "row",
            //     maxWidth: 50,
            //     filterable: false,
            //     Cell: (row) => {
            //         return <div>{row.index + 1}</div>;
            //     }
            // },
            { Header: 'FPR ID ', accessor: 'fpr_id', minWidth: 170, headerStyle: { textAlign: 'center' } },
            { Header: 'Company Name ', accessor: 'client_name', minWidth: 370, headerStyle: { textAlign: 'center' } },
            {
                Header: 'Last Modified Date',
                accessor: 'last_action_time',
                minWidth: 170,
                headerStyle: { textAlign: 'center' }
            },
            {
                'Header': 'Action', Cell: row => (
                    <div>
                        {

                            <React.Fragment>
                                <MRButton
                                    onClick={this.openActivityModal(row.original.task_id, row.original.module_id, row.original.client_name, row.original.changed_date)}
                                //onClick={() => window.location.href = `/${row.original.task_id}`}
                                >View
                                </MRButton>
                                <BiCommentDetail
                                    onClick={() => this.showModal(this.state.activitylog_data.filter(i => i.id == row.original.task_id))}
                                    style={{
                                        cursor: 'pointer',
                                        fontSize: '18px',
                                        marginLeft: '10px',
                                        color: 'inherit',
                                        display: 'inline-block',

                                    }}
                                />
                            </React.Fragment>
                        }
                    </div>
                ), width: 160, headerStyle: { textAlign: 'center' }
            },
        ];
        //console.log("task_action_arr.length ==>"+task_action_arr.length );
        for (let k = 0; k < task_action_arr.length; k++) {
            let CompanyName = '';
            if (parseInt(task_action_arr[k].client_id) < 100000) {
                CompanyName = this.state.client_name
            } else {
                CompanyName = this.getGroupCompanies(task_action_arr[k].client_id)
            }

            let changed_date = new Date(task_action_arr[k].date_time_stamp);
            //let changed_date = new Date(task_action_arr[k].last_action_time);
            let compare_date = this.addMonths(new Date(), -parseInt(this.state.ddldate_val));
            let start_date = this.state.startDate;
            let endDate = this.state.endDate
            let changed_date_int = changed_date.getFullYear() * 10000 + (changed_date.getMonth() + 1) * 100 + changed_date.getDate();
            let compare_date_int = compare_date.getFullYear() * 10000 + (compare_date.getMonth() + 1) * 100 + compare_date.getDate();


            if (this.state.ddldate_val == 10000) {
                let start_date_int = start_date.getFullYear() * 10000 + (start_date.getMonth() + 1) * 100 + start_date.getDate();
                let end_date_int = endDate.getFullYear() * 10000 + (endDate.getMonth() + 1) * 100 + endDate.getDate();

                if (changed_date_int >= start_date_int && changed_date_int <= end_date_int) {
                    let elem = {
                        'index': k,
                        'module_id': Source_Map[task_action_arr[k].module_id],
                        'activity_type': Activity_Text[task_action_arr[k].activity_type],
                        'client_name': CompanyName,
                        'changed_by': this.props.contact_list[task_action_arr[k].changed_by],
                        'changed_date': task_action_arr[k].changed_date,
                        'task_id': task_action_arr[k].task_id,
                        'fpr_id': task_action_arr[k].fpr_id,
                        'last_action_time': task_action_arr[k].last_action_time,
                    };
                    ret.data.push(elem);
                }

            } else if (changed_date_int >= compare_date_int) {
                let elem = {
                    'index': k,
                    'module_id': Source_Map[task_action_arr[k].module_id],
                    'activity_type': Activity_Text[task_action_arr[k].activity_type],
                    'client_name': CompanyName,
                    'changed_by': this.props.contact_list[task_action_arr[k].changed_by],
                    'changed_date': task_action_arr[k].changed_date,
                    'task_id': task_action_arr[k].task_id,
                    'fpr_id': task_action_arr[k].fpr_id,
                    'last_action_time': task_action_arr[k].last_action_time
                };
                ret.data.push(elem);
            }
        }
        Store.updateStore('table_cur_data', ret.data)
        //debugger;
        return ret;
    }
    refreshState = (view) => {
        this.setState({ modal_view: view })
    }
    changeModuleFilter = (event) => {
        event.preventDefault();
        this.setState({ ddlmodule_val: event.target.value });
    }
    handleEndChange = (date) => {
        if (date < this.state.startDate) {
            alert('End date must be greater than start date');
            return;
        }
        this.setState({
            endDate: date
        });
    }
    changeDateFilter = (event) => {
        event.preventDefault();
        this.setState({ ddldate_val: event.target.value });
    }
    handleStartChange = (date) => {
        if (date > this.state.endDate) {
            alert('Start date must be smaller than end date');
            return;
        }
        this.setState({
            startDate: date
        });
    }

    render() {
        let headerText = "General Activity Log";
        if (this.state.module_id !== 'general') {
            headerText = typeof Source_Map[this.state.module_id] === "undefined" ? this.state.module_id + " Activity Log" : Source_Map[this.state.module_id] + " Activity Log";
        }
        return (
            <div style={{ display: "inline-block", width: "100%", height: "900px", backgroundColor: "#ffffff" }}>
                <div style={{ position: "absolute", padding: "30px 30px 30px 30px" }}>
                    {
                        (() => {
                            if (this.state.ddldate_val == 10000) {
                                return (<div style={{
                                    position: "absolute",
                                    marginLeft: "300px",
                                    marginTop: "1px",
                                    zIndex: "100"
                                }}>
                                    <div style={{ display: "inline-block" }}>
                                        <label style={{ paddingLeft: "10px", color: "#9e9e9e", fontWeight: "600" }}>Start
                                            Date : </label>
                                        <DatePicker dateFormat="dd/MM/yyyy" selected={this.state.startDate}
                                            onChange={this.handleStartChange} />
                                    </div>
                                    <div style={{ display: "inline-block" }}>
                                        <label style={{ paddingLeft: "10px", color: "#9e9e9e", fontWeight: "600" }}>End
                                            Date : </label>
                                        <DatePicker dateFormat="dd/MM/yyyy" selected={this.state.endDate}
                                            onChange={this.handleEndChange} />
                                    </div>


                                </div>);
                            }
                        })()
                    }
                    <div style={{ float: "right" }}>
                        <Dropdownlist id="ddldate" onChange={this.changeDateFilter} value={this.state.ddldate_val}>
                            <option value="1000">Any date</option>
                            <option value="1">Last Month</option>
                            <option value="6">Last Six Months</option>
                            <option value="12">Last Year</option>
                            {/* <option value="10000">Custom</option> */}
                        </Dropdownlist>
                    </div>
                    <CSLTable add={false} processData={this.processAcceptanceQueueData}
                        headerText={headerText}
                        tableRows={18} />

                </div>
                {
                    (() => {
                        if (this.state.modal_view !== null) {
                            return <div style={{
                                position: 'absolute',
                                left: '10%',
                                top: '50px',
                                width: '100%',
                                height: '1186px'
                            }}><InactiveOverlay />
                                <ActivityCompletedModal
                                    refreshParent={this.refreshState}
                                    modalData={this.state.modal_data} />
                            </div>;
                        }
                    })()
                }
                {
                    (() => {
                        if (this.state.showmodal !== null) {
                            return <TaskDetailsLogModal contact_list={this.props.contact_list} client_name={this.state.client_name} closeModal={this.closeModal} taskdata={this.state.selectedTask} />
                        }
                        return null;
                    })()
                }

            </div>
        );
    }
}
