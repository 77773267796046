import React from 'react';
import styled from 'styled-components';
import { Store } from '../Common';
import TasksList from './TasksList';
import TaskModal from './TaskModal';
import APICall from '../../Common/APICall';

const SubmitButton = styled.button`
  display: inline-block;
  box-sizing: border-box;
  background-color: #223b5a;
  color: #ffffff;
  width: 160px;
  height: 35px;
  border-radius: 4px;
  border: 1px solid #37ada7;
  margin-left: 12px;
  font-size: 11px;
  padding-left: 12px;
  cursor: pointer;
  margin-top: 26px;
`;
const IndexContainer = styled.div`
  padding-top: 8px;
  padding-left: 5px;
`;

class TasksIndex extends React.Component {
    state = {
        isLoaded: false,
        pending_tasks: [],
        showModal: null,
        contact_id: Store.getStoreData('contact_id'),
        myTask: []
    };

    componentDidMount() {
        let myTask = Store.getStoreData('mytasks')
        console.log('tasks ==>', myTask);
        this.setState({ myTask })

    }

    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            let myTask = Store.getStoreData('mytasks')
            this.setState({ myTask })
        }
    }

    refreshCallback = () => {
        this.props.refreshCallback();
    }

    showTaskModal = (row) => (event) => {
        event.preventDefault();
        const index = row.index === null ? 0 : row.index;
        Store.updateStore('q_index', index);
        this.setState({ showModal: 'TaskModal' });
    };

    closeModal = () => {
        //debugger;
        this.setState({ showModal: null });
    }

    closeModalAndRefresh = (task) => {
        // let postData = { command: 'refreshTask' };
        // let api = new APICall();
        // api.command(postData, this.processTask);
        let myTask = Store.getStoreData('mytasks')
        for (let d of task) {
            myTask.push(d)
        }
        this.setState({
            myTask: myTask,
            showModal: null
        });
    };

    processTask = (result) => {
        this.setState({
            myTask: result.result['my_tasks'],
            showModal: null
        });
    };

    render() {
        // console.log('*************');
        // console.log('Inside Render()');
        // console.log('*************');
        const task_id = this.props.taskId ? this.props.taskId : 0;
        //console.log(`Inside TaskIndex.js , contact_id is ${this.state.contact_id}`);
        return (
            <IndexContainer>
                {task_id === 0 && (
                    <SubmitButton
                        id="add_financial_promo"
                        style={{ whiteSpace: 'nowrap', width: 'min-content', paddingRight: '14px' }}
                        onClick={this.showTaskModal({ index: -1 })}
                    >
                        ADD FINANCIAL PROMOTION
                    </SubmitButton>
                )}
                <TasksList add={false} taskId={task_id} mytaskData={this.state.myTask} refreshCallback={this.refreshCallback} />
                {this.state.showModal === 'TaskModal' && <TaskModal closeModal={this.closeModalAndRefresh} closeModalNormal={this.closeModal} />}
            </IndexContainer>
        );
    }
}

export default TasksIndex;
