import React from 'react';
import styled from 'styled-components';
import { FaTimes, FaEdit, FaPlusCircle, FaPlus } from 'react-icons/fa';
import { IoIosArrowForward } from 'react-icons/io';
import APICall from '../../Common/APICall';
import CSLLoader from '../../Components/Common/CSLLoader';
import Store from '../../Common/Store.js';
import Utils from '../../Common/Utils.js';
import EventBus from '../../Common/EventBus';
import ConfigTypes from './ConfigTypes'
import Email from './Email'
import Checklist from './Checklist'
import ReasonCodes from './ReasonCodes'
import PromotionTypes from './PromotionTypes'
import LeftMenu from './LeftMenu'
import GeneralConfigs from './GeneralConfigs'
import CustomFields from './CustomFields'
import CSLToggle from '../Common/CSLToggle'
import EditSectionNameModal from './EditSectionNameModal'
import ConfigEditModal from './ConfigEditModal'
import Reload from '../../Common/Reload.js';
import AddCheckListModal from "./AddCheckListModal";
import { Container, Draggable } from 'react-smooth-dnd';

const ReviewBodyContainer = styled.div`
  padding-top: 25px;
  width: 100%;
`;
const ReviewLeftContainer = styled.div`
  display: inline-table;
  overflow: hidden;
  text-overflow: ellipsis;

  position: sticky;
  top: 2px;
  float: left;
  padding-right: 20px;
  width: 20%;
  box-sizing: border-box;
`;
const ReviewRightContainer = styled.div`
  float: right;
  padding-left: 20px;
  width: 80%;
  box-sizing: border-box;
`;
const ReviewTab = styled.div`
  background-color: #ffffff;
  color: ${process.env.DEFAULT_TEXT_COLOR};
  padding: 10px 20px;
  cursor: pointer;
`;
const CloseBtn = styled.div`
  float: right;
  font-size: 20px;
  color: #9c9c9c;
  cursor: pointer;
  padding-top: 10px;
  padding-right: 5px;
`;
const FooterButtonsContainer = styled.div`
  text-align: right;
  padding-top: 25px;
  width: 90%;
`;
const CancelBtn = styled.button`
  padding: 10px 20px;
  background-color: #ffffff;
  border: 1px solid #ffffff;
  border-radius: 2px;
  color: #333333;
  cursor: pointer;
`;
const SaveBtn = styled.button`
  padding: 10px 30px;
  background-color: #37ada7;
  border: 1px solid #37ada7;
  border-radius: 2px;
  color: #ffffff;
  cursor: pointer;
  margin-left: 15px;
`;
const ErrorBar = styled.div`
  background-color: #f6dbdb;
  color: #de8d8e;
  font-weight: 600;
  padding: 10px 20px;
`;
const ExportButton = styled.button`
  box-sizing: border-box;
  background-color: #213e61;
  color: #f2f2f2;
  border: 1px solid #213e61;
  width: 125px;
  font-size: 14px;
  height: 32px;
  border-radius: 5px;
  margin-right: 3px;
  float: left;
`;
const LeftLabel = styled.div`float: left;`;
const RightArrow = styled.div`
  float: right;
  color: #39b3ad;
  margin-top: 2px;
  padding-left: 3px;
`;
const QuestionLine = styled.div`
  padding: 5px;
  height: 30px;
  cursor: grabbing;
`;
const QuestionTitle = styled.div`
  font-size: 13px;
  font-weight: 500;
  float: left;
  width: calc(100% - 150px);
`;
const QSearchInput = styled.input`
  height: 25px;
  width: 829px;
  box-shadow: rgb(196, 196, 196) 0px 0px 4px;
  color: #545454;
  font-family: montserrat;
  font-size: 15px;
  font-weight: 400;
  border-width: 1px;
  border-style: solid;
  border-color: rgb(255, 255, 255);
  border-image: initial;
  border-radius: 3px;
  padding: 2px;
`;

const AddButton = styled.div`
  width: 30px;
  height: 30px;
  margin: 0px;
  float: right;
  padding-left: 10px;
  font-size: 16px;
  cursor: pointer;
`;

const SerialHeader = styled.div`
  text-align: center;
  flex: 30 0 auto;
  width: 30px;
  max-width: 30px;
  cursor: pointer;
  padding: 5px 5px;
  line-height: normal;
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.05);
  transition: box-shadow .3s cubic-bezier(.175, .885, .32, 1.275);
  box-shadow: inset 0 0 0 0 transparent;
  white-space: nowrap;
  text-overflow: ellipsis;
  -webkit-box-flex: 1;
  height: 35px;
`;

const QuestionTitleHeader = styled.div`
  text-align: left;
  flex: 100 0 auto;
  width: 100px;
  cursor: pointer;
  overflow: visible;
  padding: 5px 5px;
  line-height: normal;
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.05);
  transition: box-shadow .3s cubic-bezier(.175, .885, .32, 1.275);
  box-shadow: inset 0 0 0 0 transparent;
  white-space: nowrap;
  text-overflow: ellipsis;
  -webkit-box-flex: 1;
  height: 35px;
`;

const ToggleHeader = styled.div`
  text-align: center;
  flex: 80 0 auto;
  width: 80px;
  max-width: 80px;
  overflow: visible;
  padding: 5px 5px;
  line-height: normal;
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.05);
  transition: box-shadow .3s cubic-bezier(.175, .885, .32, 1.275);
  box-shadow: inset 0 0 0 0 transparent;
  white-space: nowrap;
  text-overflow: ellipsis;
  -webkit-box-flex: 1;
  height: 35px;
`;
const ActionHeader = styled.div`
  text-align: center;
  flex: 55 0 auto;
  width: 55px;
  max-width: 55px;
  overflow: hidden;
  cursor: pointer;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 5px 5px;
  line-height: normal;
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.05);
  transition: box-shadow .3s cubic-bezier(.175, .885, .32, 1.275);
  box-shadow: inset 0 0 0 0 transparent;
  height: 35px;
`;

const TypesBoldLabel = styled.div`
  font-weight: 600;
  color: #595959;
  margin-top: 5px;
`;

const ClearBoth = styled.div`clear: both;`;

class ModuleConfigHOC extends React.Component {
    state = {
        active_section_id: 'general',
        active_checklist_index: 0,
        dirty: false,
        permissions: {},
        module_config: null,
        is_debug: Store.getStoreData('is_debug'),
        debug_port: Store.getStoreData('debug_port'),
        all_company_users: null,
        changed_user_roles: {},
        requester: '',
        section_name: '',
        promotion_types: {},
        checklists: null,
        checklist_items: {},
        ready: false,
        show_addchecklist_modal: null,
        section_id: '',
        reason_codes: {},
        showChangeSectionModal: null,
        show_edit_modal: null,
        curid: null,
        client_types_active_inactive: null,
        is_question_editing: false,
        isChecklistAdded: false
    };

    componentDidMount = () => {
        EventBus.registerEvent('checklist_added', 'LeftMenu', this.checklistAdded)
        if (Store.getStoreData('role') === 'admin_manager') {
            let api = new APICall();
            const postData = { command: 'list_company_and_users' };
            // console.log('api', api)
            api.command(postData, this.getInitialPermission); // <<<<-- populating checklist
        }
    }

    checklistAdded = (checklist) => {
        let checklists = this.state.checklists
        checklists.value.push(checklist)
        this.setState({checklists})
    }

    closeChecklistModal = () => {
        this.setState({show_addchecklist_modal: false})
    }

    getInitialPermission = (result) => {
        let requester = this.props.match ? this.props.match.params.requester : '';

        const permissions = {
            user_roles: result.user_roles,
            gc_companies: result.gc_companies,
            roles: result.roles,
            company_users: result.company_users
        };
        const all_company_users = JSON.parse(JSON.stringify(result.company_users));
        let module_config = JSON.parse(JSON.stringify(result.moduleconfig));
        console.log('module_config', module_config)
        let stateObj = {
            permissions,
            all_company_users,
            module_config: module_config,
            requester,
            promotion_types: module_config.promotion_types,
            checklists: module_config.checklists,
            reason_codes: module_config.reason_codes,
            ready: true
        };
        this.setState(stateObj);
    }

    returnSection = (section, index) => {
        // event.preventDefault();
        if(section === 'add_new') this.setState({show_addchecklist_modal: true})
        else this.setState({ active_section_id: section, active_checklist_index: index});
    };

    searchUsers = (search_string) => {
        if (search_string === '') {
            let permissions = JSON.parse(JSON.stringify(this.state.permissions));
            permissions.company_users = this.state.all_company_users;
            this.setState({ permissions });
        } else {
            let all_company_users = JSON.parse(JSON.stringify(this.state.all_company_users));
            let filtered_company_users = [];
            all_company_users.forEach((user) => {
                if (
                    user.name.toLowerCase().includes(search_string.toLowerCase()) ||
                    user.role.includes(search_string.toLowerCase())
                ) {
                    filtered_company_users.push(user);
                }
            });
            let permissions = JSON.parse(JSON.stringify(this.state.permissions));
            permissions.company_users = filtered_company_users;
            this.setState({ permissions });
        }
    };

    onChangeChecklist = (status, title) => {
        let checklists = this.state.checklists
        checklists.value[this.state.active_checklist_index].status = status;
        checklists.value[this.state.active_checklist_index].title = title;
        this.setState({checklists})
    }

    updatePermissions = (obj, single_obj) => {
        let api = new APICall()
        let changed_user_roles = JSON.parse(JSON.stringify(this.state.changed_user_roles));
        
        this.user_role = single_obj
        changed_user_roles[single_obj.user_id] = single_obj.role;
        // this.setState({ permissions: obj, changed_user_roles });
        let postData = { command: 'save_user_role', changed_user_roles: changed_user_roles };
        console.log('permissions postData', postData)
        api.command(postData, this.afterRoleUpdate);
        Reload.RestoreStorage(changed_user_roles); // add this line
    };

    afterRoleUpdate = (result) => {
        let permissions = this.state.permissions
        console.log('role', this.user_role)
        
        let item = permissions.company_users.find(obj => {
          return obj.user_id === this.user_role.user_id
        })
        console.log('item', item)
        item.role = this.user_role.role
        // console.log('this.user_role', this.user_role)
        
        console.log('permissions', permissions)
        this.setState({permissions})
    }

    closePage = (event) => {
        event.preventDefault();
        let url = "/";
        window.location.replace(url);
    }

    render() {
        let use_site_layout = false;
        if (localStorage.getItem('site_layout') !== null) {
            let site_layout = JSON.parse(localStorage.getItem('site_layout'));
            use_site_layout = site_layout.result.use_site_layout;
        }
        // console.log('this.state', this.state)
        //if (use_site_layout) return (<div></div>)

        if (Store.getStoreData('role') !== 'admin_manager') {
            return (
                <ErrorBar>
                    <div style={{ float: 'left' }}>
                        You are not authorised to access this page. If you think this is in error, please contact your
                        administrator.
                    </div>
                    {(() => {
                        if (use_site_layout) {
                            return (
                                ""
                            )
                        } else {
                            return (
                                <div style={{ float: 'right', fontSize: '16px', cursor: 'pointer' }} onClick={this.closePage}>
                                    x
                                </div>
                            )
                        }

                    })()}

                    <ClearBoth />
                </ErrorBar>
            );
        }

        if (this.state.module_config === null || this.state.isChecklistAdded == true || this.state.checklists === null)
            return (
                <div>
                    <CSLLoader />
                </div>
            );

        let checklists = this.state.checklists.value

        return (
            <div style={{ padding: '10px 10px 20px 10px', backgroundColor: '#F2F2F2' }}>
                {use_site_layout ? "" : <CloseBtn onClick={this.closePage}><FaTimes /></CloseBtn>}
                <ReviewBodyContainer>
                    <ReviewLeftContainer>
                        <LeftMenu checklists={this.state.checklists} returnSection={this.returnSection} />
                    </ReviewLeftContainer>
                </ReviewBodyContainer>

                <ReviewRightContainer>
                {this.state.active_section_id === 'general' && (
                    <GeneralConfigs
                        general={this.state.module_config.general}
                        updateGeneralSave={this.updateGeneralConfig}
                        has_gc={Store.getStoreData('has_gc')}
                    />
                )}
                {this.state.active_section_id === 'email' && (
                    <Email
                        email={this.state.module_config.email}
                        variables={this.state.module_config.variables}
                        general={this.state.module_config.general}
                        updateGeneralSave={this.updateEmailRemainder}
                        updateEmail={this.updateEmail}
                    />
                )}
                {this.state.active_section_id === 'checklist' && (
                    <Checklist
                        parentstate={this.state}
                        onChangeChecklist={this.onChangeChecklist}
                    />
                )}
                {this.state.active_section_id === 'custom_fields' && (
                    <CustomFields
                        custom_fields={this.state.module_config.custom_fields}
                        updateCustomFields={this.updateCustomFields}
                        closePage={this.closePageCustomField}
                    />
                )}
                {this.state.active_section_id === 'promotion_types' && (
                    <PromotionTypes
                        sectionName="Promotion Types"
                        general={this.state.module_config.general.value}
                        promotion_types={this.state.module_config.promotion_types}
                    />
                )}

                {this.state.active_section_id === 'reason_codes' && (
                    <ReasonCodes
                        sectionName="Reason Codes"
                        general={this.state.module_config.general.value}
                        reason_codes={this.state.module_config.reason_codes}
                    />
                )}

                </ReviewRightContainer>
                {(() => {
                    if (this.state.show_addchecklist_modal) {
                        return (
                            <AddCheckListModal
                                closeChecklistModal={this.closeChecklistModal}
                                parentstate={this.state}
                                onChangeChecklist={this.onChangeChecklist}
                            />
                        );
                    }
                })()}
                <ClearBoth />
            </div>

        );
    }
}

export default ModuleConfigHOC;
