import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import AlertBox from './Components/Common/AlertBox';
import ModuleConfigHOC from './Components/ModuleConfig/ModuleConfigHOC';
import FinancialPromotionLog from './Components/FPR/FinancialPromotionLog';
import CSLLoader from './Components/Common/CSLLoader';
import Store from './Common/Store.js';
import Utils from './Common/Utils.js';
import ComDecom from './Common/ComDecom.js';
import APICall from './Common/APICall';
import APIContact from './Common/APIContact';
import Index from './Components/Index';
import CSLPageloader from './Components/Common/CSLPageloader';
import Reload from './Common/Reload.js';
import EventBus from './Common/EventBus';
import authService from 'mcc-front-aux/dist/authService';


const moment = require('moment');
const last_action_time = moment.unix();

class AppContent extends React.Component {
	state = {
		OverlayHeight: null,
		cardata: null,
		ready: false,
		OverlayTaskHeight: null,
		role: 'noperm',
		refreshCount: 0,
		alert_param: null,
		task_loading: false
	};

	constructor (props) {
		super(props);
		Utils.setEnv('finpromo');
		this.callContact();
	}

	callContact() {
		if (!Store.getStoreData('is_admin')) {
			let postData = { command: "contactlist" };
			let api = new APIContact();
			let j = api.command(postData, this.processContactData);
		}
	}

	processContactData = (result) => {
		// localStorage.setItem("contactlist", JSON.stringify(result));
		let cl = ComDecom.deCompress(result.result['contactlist'], true, 'ID');
		result.result['contactlist'] = cl
		localStorage.setItem("contactlist", JSON.stringify(result));
	}

	componentDidMount() {
		EventBus.registerEvent('system_err', 'AppContent', this.systemErr)
		let windowUrl = window.location.href;
		console.log("windowUrl.indexOf('moduleconfig')==>", windowUrl.indexOf('moduleconfig'));
		if (windowUrl.indexOf('moduleconfig') != -1) {
			this.moduleIndexapi();
			//console.log("moduleIndexapi")
		} else {
			this.callApi();
		}
	}

	systemErr = (error) => {
		let alert_param = {
			title: 'System Error',
			message: error,
			ok_text: 'OK',
			confirm: false,
			alertHandler: this.alertHandler,
			stack: {id: 0}
		}
		this.setState({ ready: true, alert_param: alert_param, task_loading: false});
	}

	moduleIndexapi() {
		let postData = { command: "moduleindex" };
		let api = new APICall();
		api.command(postData, this.processModuleIndex);
	}

	processModuleIndex = (result) => {
		//console.log("result processModuleIndex", result)
		if (result.error_code === 0) {
			Store.updateStore('role', result.result['role']);
			//userStroage Section
			Store.updateStore('userroles', result.result['user_roles']);
			Store.updateStore('contact', result.result['contact']);
			Store.updateStore('contacts', result.result['contacts']);
			Store.updateStore('contactsemail', result.result['contractsemail']);
			Store.updateStore('reviewee_list', result.result.listcontacts);
			//userStroage Section

			let has_gc = result.result.gc_company.length > 1 ? true : false;
			Store.updateStore('has_gc', has_gc);

			let r = {};
			r['result'] = {};
			let cts = localStorage.getItem("contactlist") !== null ? JSON.parse(localStorage.getItem("contactlist")) : r;
			//console.log('cts==>', cts)
			let user_roles = {};
			let contactNameValue = '';
			let contacts_all = {};
			let contractsemail_all = {};
			let listcontacts_all = [];
			let contactid = result.result['contact_id'];
			// debugger;
			if ('contactlist' in cts.result && 'user_roles' in cts.result) {
				user_roles = cts.result.user_roles['finpromo'];
				contactNameValue = user_roles[contactid.toString()] !== undefined ? cts.result.contactlist[contactid.toString()].ContactName : '';
				for (let k in cts.result.contactlist) {
					if (user_roles[k.toString()] !== undefined) {
						let clists = cts.result.contactlist;
						if (clists[k.toString()].IsActive == true && clists[k.toString()].IsDeleted == false) {
							contractsemail_all[k.toString()] = cts.result.contactlist[k.toString()].EmailAddress;
							let listConObject = {};
							listConObject.ID = cts.result.contactlist[k.toString()].ID;
							listConObject.ContactName = cts.result.contactlist[k.toString()].ContactName;
							listConObject.LoginID = cts.result.contactlist[k.toString()].EmailAddress;
							listcontacts_all.push(listConObject);
							if (cts.result.user_roles['finpromo'][k.toString()] !== 'no_access') {
								contacts_all[k.toString()] = cts.result.contactlist[k.toString()].ContactName;
							}
						}
					}
				}
				//console.log("user_roles", user_roles);
				Store.updateStore('contact', contactNameValue);
				Store.updateStore('userroles', user_roles);
				Store.updateStore('contacts', contacts_all);
				Store.updateStore('contactsemail', contractsemail_all);
				Store.updateStore('reviewee_list', listcontacts_all);
			}
			this.setState({ ready: true });
		} else {
			let alert_param = {
				title: 'System Error', message: result.error_msg, ok_text: 'Ok', confirm: false,
				alertHandler: this.alertHandler, stack: { id: 0 }
			}
			this.setState({ ready: true, alert_param: alert_param });
		}
	}

	callApi() {
		let postData = { command: "index" };
		let api = new APICall();
		api.command(postData, this.processIndex)
		let task_loading = true;
		this.setState({ task_loading: task_loading })
	}

	processIndex = (result) => {
		if (result.error_code === 0) {
			if (Store.getStoreData('is_admin')) {
			} else {
				if (result.result['role'] === "no_access") {
					let alert_param = {
						title: 'No Access', message: result.error_msg, ok_text: 'Ok', confirm: false,
						alertHandler: this.alertHandler, stack: { id: 0 }
					};
					this.setState({ ready: true, alert_param: alert_param, task_loading: false });
					return
				}
				console.log('App tasks, initial index data', result);
				console.log("result.result['gc_companies'] ==>", result.result['gc_companies']);
				Store.updateStore('cardata', result.result);
				Store.updateStore('role', result.result['role']);
				Store.updateStore('module_config', result.result['module_config']);
				Store.updateStore('contact_id', result.result['contact_id']);
				Store.updateStore('curCompanyID', result.result['curCompanyID']);
				Store.updateStore('gc_company', result.result['gc_company']);
				Store.updateStore('gc_companies', result.result['gc_companies']);
				Store.updateStore('my_group', result.result['my_group']);

				Store.updateStore('myinitiatedtasks', result.result['my_initiated_tasks']);
				Store.updateStore('followup_tasks', result.result['followuptasks']);
				Store.updateStore('mytasks', result.result['my_tasks']);

				//userStroage Section
				Store.updateStore('userroles', result.result['user_roles']);
				Store.updateStore('contact', result.result['contact']);
				Store.updateStore('contacts', result.result['contacts']);
				Store.updateStore('contactsemail', result.result['contractsemail']);
				Store.updateStore('reviewee_list', result.result.listcontacts);
				//userStroage Section

				//filter mytask start
				if (result.result['role'] === 'admin_manager' || result.result['role'] === 'fprm') {
					let mytask = [];
					for (let task of result.result['my_tasks']) {
						if (!(task.cur_lane === 'COMP_FINPROMO_TEAMSAVE' && task.contact_id !== result.result['contact_id'])) {
							mytask.push(task);
						}
					}
					Store.updateStore('mytasks', mytask);
				}
				//filter mytask end
				//console.log("mytask==>", Store.getStoreData('mytasks'))

				let has_gc = result.result.gc_company.length > 1 ? true : false;
				Store.updateStore('has_gc', has_gc);

				/* Fields and Custom fields */
				const module_config = result.result['module_config'];
				const fields = module_config.fields;
				let custom_fields = [];
				if (module_config.custom_fields.enabled && module_config.custom_fields.additional_fields.length) {
					custom_fields = module_config.custom_fields.additional_fields;
				}
				custom_fields.forEach((field) => {
					fields.push(field.name);
					if (
						field.type === 'Dropdown' &&
						field.other //for the case Dropdown has an 'other' field
					)
						fields.push(`other_${field.name}`);
				});
				Store.updateStore('fields', fields);
				Store.updateStore('custom_fields', custom_fields);

				let gc_assoc = {}
				let gc_users = {}
				let gc_filters = {};
				for (let gc of result.result.gc_company) {
					let gc_id = gc.id.toString();
					gc_assoc[gc_id] = gc.company_name;
					if (gc.is_gc) {
						gc_filters[gc.id.toString()] = 1;
					}
					if (!gc.is_gc) {
						continue
					}
					for (let uid in gc.users) {
						if (!(uid in gc_users)) gc_users[uid] = {}
						gc_users[uid][gc_id] = 1
					}
				}
				//console.log("gc_users==>", gc_users)
				Store.updateStore('gc_assoc', gc_assoc);

				let r = {};
				r['result'] = {};
				let cts = localStorage.getItem("contactlist") !== null ? JSON.parse(localStorage.getItem("contactlist")) : r;
				//console.log('cts==>', cts)
				let user_roles = {};
				let contactNameValue = '';
				let contacts_all = {};
				let contractsemail_all = {};
				let listcontacts_all = [];
				let arrMgr = [];
				let contactid = result.result['contact_id'];
				if ('contactlist' in cts.result && 'user_roles' in cts.result) {
					user_roles = cts.result.user_roles['finpromo'];
					contactNameValue = user_roles[contactid.toString()] !== undefined ? cts.result.contactlist[contactid.toString()].ContactName : '';
					for (let k in cts.result.contactlist) {
						if (user_roles[k.toString()] !== undefined) {
							// debugger;
							contractsemail_all[k.toString()] = cts.result.contactlist[k.toString()].EmailAddress;
							let listConObject = {};
							listConObject.ID = cts.result.contactlist[k.toString()].ID;
							listConObject.ContactName = cts.result.contactlist[k.toString()].ContactName;
							listConObject.LoginID = cts.result.contactlist[k.toString()].EmailAddress;
							listcontacts_all.push(listConObject);
							if (cts.result.user_roles['finpromo'][k.toString()] !== 'no_access') {
								contacts_all[k.toString()] = cts.result.contactlist[k.toString()].ContactName;
							}
							if (cts.result.user_roles['finpromo'][k.toString()] === 'fprm' || cts.result.user_roles['finpromo'][k.toString()] === 'manager') {
								arrMgr.push({ ID: k.toString(), ContactName: cts.result.contactlist[k.toString()].ContactName });
							}
						}
					}
					//console.log("user_roles", user_roles);
					Store.updateStore('contact', contactNameValue);
					Store.updateStore('userroles', user_roles);
					Store.updateStore('contacts', contacts_all);
					Store.updateStore('contactsemail', contractsemail_all);
					Store.updateStore('reviewee_list', listcontacts_all);
					Store.updateStore('get_managers', arrMgr);
				}

				let myGroup = [];
				if (result.result['role'] === 'admin_manager') {
					let alluser = result.result.gc_company[0].users
					for (let key in alluser) {
						if (alluser[key] !== 'no_access') {
							myGroup.push(key);
						}
					}
				} else {
					for (let key in gc_users) {
						if (user_roles[key.toString()] !== 'no_access') {
							myGroup.push(key);
						}
					}
				}

				//console.log("myGroup===>", myGroup)
				Store.updateStore('my_group', myGroup);

				switch (result.result['role']) {
					case 'admin_manager':
						Store.updateStore('view', 'manager_index');
						break;
					case 'fprm':
						Store.updateStore('view', 'fprm_index');
						break;
					case 'no_access':
						Store.updateStore('view', 'no_access');
						break;
					default:
						Store.updateStore('view', 'team_index');
				}
				this.setState({ ready: true });
			}
		} else {
			const alert_param = {
				title: 'System Error',
				message: result.error_msg,
				ok_text: 'Ok',
				confirm: false,
				alertHandler: this.alertHandler,
				stack: { id: 0 }
			};
			this.setState({ ready: true, alert_param: alert_param });
		}
	};

	alertHandler = (result, stack) => {
		// this.setState({alert_param: null})
		//window.location = '/select/ServiceBasedLanding';
		Reload.ReloadPage();
	};

	refreshState = () => {
		this.setState({ refreshCount: this.state.refreshCount + 1 });
	};

	render() {
		//console.log('app.js rendered');
		let task_loading = this.state.task_loading;
		if (task_loading) {
			if (Store.getStoreData('finpromo-index') === null || localStorage.getItem('contactlist') === null) {
				return <div><CSLPageloader /></div>
			}
		}
		if (!this.state.ready) {
			return (<div><CSLLoader /></div>);
		}
		if (this.state.alert_param !== null) {
			return <AlertBox alertParam={this.state.alert_param} />;
		}

		return (
			<Router>
				<Switch>
					<Route path="/moduleconfig" exact>
						<ModuleConfigHOC />
					</Route>
					<Route path="/fprlog" exact>
						<FinancialPromotionLog />
					</Route>
					<Route path="/moduleconfig/:requester" exact component={ModuleConfigHOC} />
					<Route path="/" exact>
						<Index refreshCallback={this.refreshState} noParam={true} />
					</Route>
					<Route path="/:taskId" component={Index} exact />
				</Switch>
			</Router>
		);
	}
}

class App extends React.Component {
	state = { ready: false }

	setComponentState = () => {
		console.log('this.props', this.props)
		let is_debug = false
		let api_url = process.env.REACT_APP_API_URL;
		Store.updateStore('is_debug', is_debug)
		Store.updateStore('api_url', api_url)

		authService.refresh().then(() => {
			this.setState({ready: true})
		})
	}

	componentDidMount = () => {
		this.setComponentState()
	}

	componentDidUpdate = (prevprops) => {
		if (this.props === prevprops) return
		this.setComponentState()
	}

	render() {
		if (!this.state.ready) {
			return <div>Loading...</div>
		}
		// let appContent = <AppContent />
		//
		// return <ErrorBoundary><SiteLayout AppContent={appContent} /></ErrorBoundary>

		return <AppContent />
	}
}


export default App;
