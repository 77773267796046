import React, { Component } from "react";
import Switch from "react-switch";
import PropTypes from "prop-types";

class CSLToggle extends Component {

    static defaultProps = {
        width: 40,
        height: 20,
    }

    static propTypes = {
        /** */
        width: PropTypes.number,
        /** */
        height: PropTypes.number,
        /** */
        checked: PropTypes.bool,
        /** callback: on onClick */
        onClick: PropTypes.func,
    };

    state = {
        checked: false,
        change_state: true
    }

    constructor() {
        super();
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount(){
        let change_state = 'change_state' in this.props ? this.props.change_state : true
        this.setState( { checked: this.props.checked, change_state });
    }

    componentDidUpdate(prevprops){
        if(this.props === prevprops)return
        let change_state = 'change_state' in this.props ? this.props.change_state : true
        this.setState( { checked: this.props.checked, change_state });
    }

    handleChange(checked) {
        this.props.onClick( checked, this.props.id )
        if(this.state.change_state)this.setState({ checked });
    }

    render() {
        return (
            <Switch
                onChange={ this.handleChange }
                checked={ this.state.checked }
                onHandleColor='#11ABA6'
                offHandleColor='#CD5251'
                onColor='#F4F9FF'
                offColor='#F4F9FF'
                uncheckedIcon={false}
                checkedIcon={false}
                width={this.props.width}
                height={this.props.height}
                activeBoxShadow={null}
            />
        );
    }
}

export default CSLToggle;