import React from 'react';
import PropTypes from "prop-types";
import styled from 'styled-components';
import {FaTimes} from 'react-icons/fa';

const MRModalContainer = styled.div`
  position: absolute;
  background-color: #f7f7f7;
  border-radius: 5px;
  box-shadow: 0 0 20px #a7a6a6;
  z-index: 1;
  padding: 20px;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
`;

const MRModalHeader = styled.div`
  display: block;
  box-sizing: border-box;
  width: 100%;
`;

const MRModalLabel = styled.div`
  color: #212121;
  font-weight: 600;
`;

const MRModalHeaderCloseBtn = styled.div`
  box-sizing: border-box;
  float: right;
  font-size: 16px;
  color: #a0a0a0;
  cursor: pointer;
  font-size: 20px;
`;
const RegModalNameInput = styled.input`
  height: 28px;
  width: 97%;
  border: 1px solid #ffffff;
  border-radius: 5px;
  box-shadow: 0 0 4px #c4c4c4;
  color: #000000;
  background-color: #ffffff;
`;

const SaveBtn = styled.button`
  padding: 10px 30px;
  background-color: #37ADA7;
  border: 1px solid #37ADA7;
  border-radius: 2px;
  color: #ffffff;
  cursor: pointer;
  margin-left: 15px;
  margin-top: 10px;
`;

class EditSectionNameModal extends React.Component {

    static propTypes = {
        /** */
        sectionName: PropTypes.string,
        closeModal: PropTypes.func,
        updateConfigName: PropTypes
    };

    state = {
        section_name: this.props.sectionName
    }

    closeView = () => {
        this.props.closeModal();
    }


    handleChange = (event) => {
        event.preventDefault();
        this.setState({
            ...this.state,
            section_name: event.target.value
        })
    }

    submit = (event) => {
        // debugger;
        event.preventDefault();
        console.log('inside submit()');
        if (this.state.section_name === "")
            alert(`Please provide a checklist name.`);
        else {
            this.props.updateConfigName(this.state.section_name);
        }
    }

    render() {
        return (
            <MRModalContainer>
                <MRModalHeader>
                    <MRModalHeaderCloseBtn onClick={this.closeView}><FaTimes/></MRModalHeaderCloseBtn>
                </MRModalHeader>
                <MRModalLabel>Edit</MRModalLabel>
                <RegModalNameInput name="clientType" value={this.state.section_name} onChange={this.handleChange}/>
                <div style={{float: "right"}}>
                    <SaveBtn onClick={this.submit}>Update</SaveBtn>
                </div>
            </MRModalContainer>
        )
    }
}

export default EditSectionNameModal;
