import React from 'react';
import PropTypes from "prop-types";
import { IoIosCheckboxOutline, IoIosSquareOutline } from "react-icons/io";
import styled from 'styled-components';
import CSLTable from '../Common/CSLTable';
import EmailConfigModal from '../ModuleConfig/EmailConfigModal';
import APICall from '../../Common/APICall';

const EmailContainer = styled.div`
	width: 90%;
`;
const EmailInnerContainer = styled.div`
	background-color: #ffffff;
	padding: 30px;
`;
const EmailBoldLabel = styled.div`
	font-weight: 600;
	color: #595959;
	margin-top: 5px;
`;
const ConfigBtn = styled.button`
    box-sizing: border-box;
    background-color: #37ada7;
    color: #ffffff;
    border: 1px solid #37ada7;
    font-size: 13px;
    border-radius: 4px;
    padding: 5px 20px;
    cursor: pointer;
`;

const SelectedRadio = styled.div`
	background-color: #37ADA7;
	border: 1px solid #1f615e;
	padding: 5px 10px;
	color: #ffffff;
	display: inline-block;
	cursor: pointer;
`;
const UnselectedRadio = styled.div`
	background-color: #F4F4F4;
	border: 1px solid #8c8c8c;
	padding: 5px 10px;
	color: #262626;
	display: inline-block;
	cursor: pointer;
`;
const RadioBlock = styled.div`
	margin-top: 10px;
`;

class Email extends React.Component
{

	static propTypes = {
        /** */
		general: PropTypes.object,
        /** */
        email: PropTypes.array,
        /** */
		variables: PropTypes.array,
        /** */
		updateEmail: PropTypes.func,
        /** */
		updateGeneralSave: PropTypes.func,
    };

	state = {
		email: null,
		variables: [
			        {
			            "name": "FPR_ID",
			            "nickname": "FPR_ID"
			        },
			        {
			            "name": "discloser",
			            "nickname": "discloser"
			        },
			        {
			            "name": "FPR_Link",
			            "nickname": "FPR_Link"
			        },
			        {
			            "name": "status",
			            "nickname": "Status"
			        },
					{
						"name": "FPR_Name",
						"nickname": "Financial Promotion Name"
					},              
					{
						"name": "group_company_name",
						"nickname": "Group Company Name"
					}
			    ],
		show_email_config_modal: false,
		content: null,
		general: true
	};

	componentDidMount(){
		console.log('this.props', this.props)
		this.setState({
			email: this.props.email,
			general: this.props.general
		});
	}

	componentDidUpdate(prevProps){
		if (prevProps !== this.props) {
			this.setState({
				email: this.props.email,
				general: this.props.general
			});
		}
	}

	showEmailConfigModal = (email_id) => (event) => {
		event.preventDefault();
		const content = this.state.email.value.find((item) => item.id === email_id )
		this.setState({ content, show_email_config_modal: true});
	}

	modifyEmailsOld = (content) => {
		let {email} = this.state;
		let newemail = [];
		email.forEach((item) => {
			if (item.id === content.id)
				item = content;
			newemail.push(item);
		})
		this.props.updateEmail(newemail);
		this.setState({ email: newemail, show_email_config_modal: false });
	}

	modifyEmails = (content) => {
		let api = new APICall()
		let postData = {command: 'save_email', email: content}
		console.log('postData in section click', postData)
		api.command(postData, this.processModifyEmails)
	}

	processModifyEmails = (result) => {
		console.log("result:", result);
		if(result.error_code > 0) {
			alert('ERROR: The server encountered an unexpected error. Please try after some time')
            return
		}
		alert('Email modified successfully.')
		let email = this.state.email;
		let earr = JSON.parse(JSON.stringify(email.value))
		let index;
		for (index = 0; index < email.value.length; index++) {
			if(email.value[index].id === result.result.email.id)
				break;
		}
		if(index !== email.value.length){
			email.value[index] = result.result.email;
		}
		this.setState({ email:email, show_email_config_modal: false });
	}

	closeModal = () =>
		this.setState({ show_email_config_modal: null, content: null });

	tableData = () => {
		console.log("tabledata")
        let ret = {data: [], columns: []};
        ret.columns =[
                        {Header: 'Email', accessor: 'email', minWidth: 120, headerStyle: {textAlign: 'left'}}, 
						{Header: 'Subject', accessor: 'subject', minWidth: 240, headerStyle: {textAlign: 'left'}},
						{'Header' : 'Enabled', Cell: row => (
							<div>
								   {
									   (()=> {
										   if (row.original.enabled) {
											   return(<div style={{cursor: "pointer", fontSize:"21px", float: "right"}} onClick={this.emailStatusChange(row.original.id)}><IoIosCheckboxOutline /></div>);
										   } else {
											   return(<div  style={{cursor: "pointer", fontSize:"21px", float: "right"}} onClick={this.emailStatusChange(row.original.id)}><IoIosSquareOutline /></div>);
										   }
									   })()
								   }
							</div>
						 ), headerStyle:{textAlign: 'center'}},
                        {'Header' : 'Action', Cell: row => (
                           <div>
                               <ConfigBtn onClick={this.showEmailConfigModal(row.original.id)}>Configure</ConfigBtn>
                           </div>
                        ), headerStyle:{textAlign: 'left'}}];
        // this.state.email.value.forEach((item) => {
        // 	let elem = {id: item.id, email: item.name, subject: item.subject, enabled: item.enabled};
        // 	ret.data.push(elem);
        // })

        console.log('this.state.email.value',this.state.email.value)
        let emails = this.state.email.value
        for(let item of emails) {
        	ret.data.push({id: item.id, email: item.name, subject: item.subject, enabled: item.enabled})
        }
        return ret;
	}

	handlenaSectionIncludedOld = (section_id) => (event) => {
		let {email} = this.state;
		let newemail = [];
		email.value.forEach((item) => {
			if (item.id === section_id)
				item.enabled = item.enabled ? false : true;
			newemail.push(item);
		})
		this.props.updateEmail(newemail);
	}

	emailStatusChange = (section_id) => (event) => {
		let {email} = this.state;
		let item = email.value.find(e => {
		  return e.id === section_id
		})
		item.enabled = item.enabled ? false : true;
		let api = new APICall()
		let postData = {command: 'save_email', email: item}
		console.log('postData in section click', postData)
		api.command(postData, this.processEmailStatusChange)
		
	}

	processEmailStatusChange = (result) => {
		console.log('result', result)
		let ret_email = result.result.email
		let email = this.state.email 
		let item = email.value.find(e => {
		  return e.id === ret_email.id
		})
		item = ret_email

		this.setState({email})
	}

	switchRadio = (key) => (event) => {
		//debugger;
		event.preventDefault();
		console.log('key', key)
		let {general} = this.state;
		general.value[key] = general.value[key] ? false : true;
		let api = new APICall()
		let postData = {command: 'save_general', general: general.value}
		api.command(postData, this.proessSaveGeneral)
		// this.setState({general})
		// this.props.updateGeneralSave(general);
	}

	proessSaveGeneral = (result) => {
		console.log('result', result)
        if(result.error_code > 0) {
            alert('ERROR: The server encountered an unexpected error. Please try after some time')
            return
        }
        let general = this.state.general 
        general.value = result.result.general
        alert('Update successful.')
        this.setState({general})
	}

	render()
	{
		if (this.state.email === null)
			return (<div>Loading...</div>);

		console.log('email tab this.props, this.state', this.props, this.state)

		return (
			<div>
			{ this.state.show_email_config_modal &&
				<EmailConfigModal
					variables={this.state.variables}
					content={JSON.parse(JSON.stringify(this.state.content))}
					modifyEmails={this.modifyEmails}
					closeModal={this.closeModal} />
			}
			<EmailContainer>
				<EmailInnerContainer>
					<EmailBoldLabel style={{display: "inline-block"}}>Enable Email Reminder?</EmailBoldLabel>
					{
						(() => {
							if (this.state.general.value.email_remainder_enable) {
								return (
									<RadioBlock>
										<SelectedRadio>Yes</SelectedRadio>
										<UnselectedRadio onClick={this.switchRadio('email_remainder_enable')}>No</UnselectedRadio>
									</RadioBlock>
								);
							} else {
								return (
									<RadioBlock>
										<UnselectedRadio onClick={this.switchRadio('email_remainder_enable')}>Yes</UnselectedRadio>
										<SelectedRadio>No</SelectedRadio>
									</RadioBlock>
								);
							}
						})()
					}
				</EmailInnerContainer>
			</EmailContainer>
			<div styled={{clear:"both"}}></div>
				{
					(() => {
						if (this.state.general.value.email_remainder_enable) {
							return(
									<EmailContainer>
										<EmailInnerContainer>
											<EmailBoldLabel style={{display: "inline-block"}}>Emails</EmailBoldLabel>
											{/* <EmailLabel style={{display: "inline-block"}}>(inherited from Task Manager)</EmailLabel> */}
											<CSLTable add={false} processData={this.tableData} headerText={''} tableRows={10} refreshCallback={this.refreshState} />
										</EmailInnerContainer>
									</EmailContainer>
								)
						}
					})()
				}			
			</div>
		);
	}
}

export default Email;