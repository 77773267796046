import React from 'react';
import styled from 'styled-components';
import { FaTimes, FaEdit } from 'react-icons/fa';
import { APICall, Store } from '../Common'
// import { ClearBoth, CSLTable } from '@mcc-regtech/core-library';
import ClearBoth from '../Common/ClearBoth';
import CSLTable from '../../Components/Common/CSLTable';
import FollowupModal from './FollowupModal'
import EditFollowUpModal from "./EditFollowUpModal";
import CSLLoader from './../Common/CSLLoader';

const MRModalContainer = styled.div`
    display: block;
    box-sizing: border-box;
    height: auto;
    width: 812px;
    position: absolute;
    top: 38px;
    z-index: 1001;
    background-color: rgb(243, 247, 251);
    box-shadow: rgb(167, 166, 166) 0px 0px 5px;
    margin-bottom: 10px;
    border-radius: 5px;
    margin-left: 100px;
`;
const MRModalHeader = styled.div`
    background-color: white;
    display: block;
    padding-top: 5px;
    font-size: 14px;
    font-weight: 700;
    height: 100px;
    width: calc(100% - 10px);
    border-width: 1px 1px 1px 10px;
    border-left: 9px solid #04ADA8;
    border-bottom: 1px solid #DFDFDF;
`;
const MRModalHeaderText = styled.div`
    font-weight: 700;
    font-size: 22px;
    float: left;
    margin-top: 24px;
    margin-left: 35px;
    color: #143151;
`;
const MRModalSubHeaderText = styled.div`
    font-weight: 700;
    font-size: 13px;
    float: left;
    margin-left: 35px;
    margin-bottom: 20px;
    margin-top: 10px;
    color: #636363;
`;
const MRModalHeaderCloseBtn = styled.div`
    position: absolute;
    top: 8px;
    right: 10px;
    box-sizing: border-box;
    margin-top: 24px;
    margin-right: 20px;
    font-size: 25px;
    cursor: pointer;
    font-weight: 200;
`;
const MRModalBody = styled.div`
    position: relative;
    display: block;
    box-sizing: border-box;
    background-color: rgb(243, 247, 251);
    padding: 12px;
    border-radius: 4px;
`;
const RegCancelSubmitButtonOuterContainer = styled.div`
    display: block;
    box-sizing: border-box;
    padding-right: 20px;
    padding-bottom: 20px;
    text-align: right;
    margin-top: 30px;
`;
const RegCancelButton = styled.button`
    display: inline-block;
    box-sizing: border-box;
    background-color: #ffffff;
    color: #909090;
    border: 2px solid #dadada;
    width: 100px;
    height: 35px;
    border-radius: 4px;
    font-size: 13px;
    cursor: pointer;
`;
const RegDismisOuterContainer = styled.div`
    display: block;
    box-sizing: border-box;
    width: 100%;
    // padding: 6px 20px 20px 20px;
    // padding: 12px;`
    ;
const OverviewInfoBody = styled.div`
    padding: 15px;
    background-color: #EDF0F1;
    border: 1px solid #d7dddf;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    // border-top-right-radius: 5px;
    // border-top-left-radius: 5px;
    width: 97%;
    margin: 0 auto;
    margin-bottom: 30px;
    display: inline-block;
    margin-top: 28px;
`;
const MRButton = styled.button`
    box-sizing: border-box;
    background-color: #37ada7;
    color: #ffffff;
    border: 1px solid #37ada7;
    width: 84px;
    font-size: 13px;
    height: 25px;
    border-radius: 4px;
`;
const OverviewInfoHeader = styled.div`
    padding: 12px 15px;
    background-color: #04ada8;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    width: 97%;
    margin: 0 auto;
    margin-top: 30px;
`;

class FollowupListModal extends React.Component {

    state = { show_modal: null, isEditFollowUpModalOpen: false, isLoadedIcon: false };

    constructor (props) {
        super(props);
        this.api = new APICall();
    }

    componentDidMount() {

    }

    closeModal = (event) => {
        event.preventDefault();
        this.props.closeModal();
    }

    closefollowupModal = (event) => {
        //event.preventDefault();
        this.setState({ showModal: null })
    }

    showEditFollowUpModal = (task_id) => (event) => {
        event.preventDefault();
        let postData = { command: 'get_subtask', task_id: task_id };
        this.setState({ isLoadedIcon: true })
        this.api.command(postData, this.openFollowupEditModal);
    };

    openFollowupEditModal = (result) => {
        Store.updateStore('followup_task', result.result.task);
        this.setState({ isEditFollowUpModalOpen: true, isLoadedIcon: false });
    };

    processMyfollowupTaskData = () => {
        var taskdata = Store.getStoreData('current_followup_lists');
        let ret = { data: [], columns: [] };
        let i = 0;
        ret.columns = [{ Header: '#', accessor: 'index', width: 30, headerStyle: { textAlign: 'left' } },
        { Header: 'Assigned To', accessor: 'assign_to', width: 120, headerStyle: { textAlign: 'left' } },
        { Header: 'Notes', accessor: 'notes', minWidth: 200, headerStyle: { textAlign: 'left' } },
        {
            Header: 'Due Date', accessor: 'due_date', Cell: row => (
                <div style={{ textAlign: "center" }}>{row.original.due_date}</div>
            ), width: 100, headerStyle: { textAlign: 'center' }
        },
        { Header: 'Status', accessor: 'status', width: 100, headerStyle: { textAlign: 'center' }, Cell: cellInfo => (<div><MRButton style={{ cursor: 'default', backgroundColor: `${cellInfo.value.color}` }}>{cellInfo.value.text}</MRButton></div>) },
        {
            'Header': 'Action', Cell: row => (
                <div>
                    <MRButton style={{ width: '70px', cursor: 'pointer' }} onClick={this.showFollowUp(row.original.id)}>View</MRButton>
                    {(() => {
                        if ((Store.getStoreData('role') == 'fprm' || Store.getStoreData('role') == 'admin_manager') && row.original.status.text !== 'Completed') {
                            return (
                                <FaEdit
                                    onClick={this.showEditFollowUpModal(row.original.id)}
                                    style={{
                                        cursor: 'pointer',
                                        fontSize: '18px',
                                        marginLeft: '13px',
                                        color: '#b4b5b4',
                                        display: 'inline-block'
                                    }}
                                />
                            )
                        }
                        return null;
                    })()}
                </div>
            ), headerStyle: { textAlign: 'center', width: 105 }
        }
        ];
        for (let d of taskdata) {
            i++;
            //debugger;
            let tdata = JSON.parse(d.task_json).action_data;
            let taskdata1 = JSON.parse(d.task_json).task_data;
            let object_data = JSON.parse(d.task_json).object_data;
            let status = "Pending";
            if (d.last_action === 'COMP_FPR_FOLLOWUP_DISMISS') {
                status = "Dismissed";
            } else if (d.last_action === 'COMP_FPR_FOLLOWUP_COMPLETE') {
                status = "Completed";
            }
            let assigntoName = Store.getStoreData('contacts')[taskdata1.cur_assigned_to]
            let notes = object_data.note;//tdata[0].note;
            let due_date_str = d.due_date ? d.due_date : '1970-01-01T00:00:00.000Z';

            const c_date = new Date(due_date_str);
            let day = c_date.getDate().toString().length === 1 ? "0" + c_date.getDate().toString() : c_date.getDate().toString();
            let month = (c_date.getMonth() + 1).toString().length === 1 ? "0" + (c_date.getMonth() + 1).toString() : (c_date.getMonth() + 1).toString();
            let year = c_date.getFullYear().toString();
            const due_date = day + "/" + month + "/" + year;

            var dd = parseInt(due_date_str.substring(8, 12));
            var mm = parseInt(due_date_str.substring(5, 7));
            var yyyy = parseInt(due_date_str.substring(0, 4));

            let due_date_status = new Date(yyyy, mm - 1, dd);
            var due_date_status_final = new Date(due_date_status);
            due_date_status_final.setHours(due_date_status.getHours() + 23);
            due_date_status_final.setMinutes(due_date_status.getMinutes() + 59);
            due_date_status_final.setSeconds(due_date_status.getSeconds() + 59);

            // var day_str = dd > 10 ? dd.toString() : "0"+dd.toString();
            // var month_str = mm > 10 ? mm.toString() : "0"+mm.toString();
            // var str_due_date = day_str + "/"+ month_str +"/"+yyyy.toString();

            let today = new Date();

            let text = due_date_status_final > today ? status : 'Overdue';
            let color = due_date_status_final > today ? '#2E8B57' : '#FF0000';
            if (status === "Pending") {
                text = due_date_status_final > today ? status : 'Overdue';
                color = due_date_status_final > today ? '#2E8B57' : '#FF0000';
            } else if (status === "Completed") {
                text = status;
                color = '#2E8B57';
            }
            let elem = {
                'id': d.id,
                'index': i,
                'assign_to': assigntoName,
                'notes': notes,
                'due_date': due_date,
                'status': { text: text, color: color }
            };
            ret.data.push(elem);
        }
        return ret;
    }

    showFollowUp = (task_id) => (event) => {
        event.preventDefault();
        let postData = { command: "get_subtask", task_id: task_id };
        this.setState({ isLoadedIcon: true })
        this.api.command(postData, this.openFollowupModal);
    }
    openFollowupModal = (result) => {
        Store.updateStore('followup_task', result.result.task);
        this.setState({ showModal: 'view_followup_modal', isLoadedIcon: false });
    }

    closeEditFollowUpModal = () => {
        this.setState({ isEditFollowUpModalOpen: false });
    }

    render() {

        return (
            <div>
            {
                (() => {
                    if (this.state.isLoadedIcon) {
                        return <div><CSLLoader style={{ position: 'absolute' }} /></div>
                    }
                })()
            }
            <MRModalContainer>
                {(() => {
                    if (this.state.isEditFollowUpModalOpen) {
                        return <EditFollowUpModal closeModal={this.closeEditFollowUpModal} />
                    }
                })()}
                <MRModalHeader>
                    <MRModalHeaderText>{this.props.curID} - Follow Up Tasks</MRModalHeaderText>
                    <MRModalHeaderCloseBtn onClick={this.closeModal}><FaTimes /></MRModalHeaderCloseBtn>
                    <ClearBoth />
                    <MRModalSubHeaderText>These are the current active follow up tasks associated with this Financial Promotion.</MRModalSubHeaderText>
                    <ClearBoth />
                </MRModalHeader>
                <MRModalBody>
                    {/* <RegModalContainer>
                <div>
                    <RegModalHeaderText>{this.props.curID} - Follow Up Tasks</RegModalHeaderText>
                    <RegModalHeaderCloseBtn onClick={this.closeModal}><FaTimes /></RegModalHeaderCloseBtn>
                </div> */}
                    <RegDismisOuterContainer>
                        <OverviewInfoBody style={{ marginBottom: "10px" }}>
                            <div><span style={{ color: "#383939" }}>These are the current active follow up tasks associated with this Financial Promotion.</span></div>
                        </OverviewInfoBody>
                    </RegDismisOuterContainer>
                    <RegDismisOuterContainer>
                        <OverviewInfoHeader>
                            <div style={{ float: "left", color: "#ffffff", fontWeight: "600" }}>Follow up Tasks</div>
                            <ClearBoth />
                        </OverviewInfoHeader>
                        {
                            (() => {
                                if (Store.getStoreData('current_followup_lists').length !== 0) {
                                    return (
                                        <OverviewInfoBody style={{ marginBottom: "0px", marginTop: "0px", backgroundColor: "#ffffff" }}>
                                            <CSLTable add={false} processData={this.processMyfollowupTaskData} headerText={''} tableRows="8" refreshCallback={this.refreshCallback} />
                                        </OverviewInfoBody>
                                    )
                                } else {
                                    return (
                                        <OverviewInfoBody style={{ marginBottom: "0px", marginTop: "0px" }}>
                                            <div style={{ textAlign: "center", fontStyle: "italic", color: "#dddddd" }}>There are currently no Follow up Task Available</div>
                                        </OverviewInfoBody>
                                    );
                                }
                            })()
                        }
                    </RegDismisOuterContainer>
                    <RegCancelSubmitButtonOuterContainer>
                        <RegCancelButton onClick={this.closeModal}>Cancel</RegCancelButton>
                    </RegCancelSubmitButtonOuterContainer>
                    {
                        (() => {
                            if (this.state.showModal !== null) {
                                switch (this.state.showModal) {
                                    case 'view_followup_modal': return <FollowupModal closeModal={this.closefollowupModal} />; break;
                                }
                            }
                        })()
                    }
                    {/* </RegModalContainer> */}
                </MRModalBody>
            </MRModalContainer>
            </div>
        )
    }

}
export default FollowupListModal;
