import React from 'react';
import PropTypes from "prop-types";
import { FaEdit, FaTimes, FaPlusCircle } from "react-icons/fa";
import styled from 'styled-components';
import CSLTable from '../Common/CSLTable';
import ConfigEditModal from './ConfigEditModal';
import CSLToggle from '../Common/CSLToggle';
import EditSectionNameModal from './EditSectionNameModal';
import APICall from '../../Common/APICall.js';
import AlertBox from '../Common/AlertBox';
import CSLLoader from '../Common/CSLLoader';


const SearchInput = styled.input`
    height: 30px;
    width: 98%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #222222;
    font-size: 15px;
    font-family: 'Montserrat', sans-serif;
`;
const MRModalLabel = styled.div`
    color: #212121;
    font-weight: 600;
    margin-top: 10px;
    margin-bottom: 10px;
    display: inline-block;
`;
const TypesContainer = styled.div`
	width: 100%;
`;
const TypesInnerContainer = styled.div`
	background-color: #ffffff;
	padding: 18px;
`;
const TypesBoldLabel = styled.div`
	font-weight: 600;
	color: #595959;
	margin-top: 5px;
`;

class ConfigTypes extends React.Component {

    static propTypes = {
        /** */
        sectionName: PropTypes.string,
        /** object with Types */
        client_types: PropTypes.object,
        /** callback to update table current data */
        updateTableCurData: PropTypes.func,
        /** callback with selection*/
        updateConfigTypes: PropTypes.func,
        sectionId: PropTypes.string,
        updateSectionName: PropTypes.func,
        canEditSectionName: PropTypes.bool
    };

    state = {
        section_name: null,
        section_id: null,
        client_types: null,
        client_types_active_inactive: null,
        active_filter: null,
        inactive_filter: null,
        show_edit_modal: null,
        curid: null,
        showActiveCheckbox: false,
        showChangeSectionModal: null,
        rowchecked: false,
        isToggleToViewTable: false,
        is_exist_promotion: false,
        promotion_id: 0,
        show_delete_confirm_modal: false,
        is_clicked_yes: false,
        search_string: ''
    }

    constructor () {
        super();
        this.toggleStatus = this.toggleStatus.bind(this);
        this.api = new APICall();
    }

    componentDidMount() {
        ////debugger;
        let retactive = this.filterBy(this.props.client_types, 'isActive', 1);
        let retinactive = this.filterBy(this.props.client_types, 'isActive', 0);

        this.setState({
            client_types_active_inactive: this.props.client_types,
            client_types: this.props.client_types,
            active_filter: retactive,
            inactive_filter: retinactive,
            section_name: this.props.sectionName,
            section_id: this.props.sectionId,
            //if atleast one item is active  then set toggler button to true
            rowchecked: Object.keys(retactive).length > 0 ? true : false

        });
        console.log('Inside ConfigTypes');
        this.forceUpdate();
    }

    componentDidUpdate(prevProps) {
        // if (prevProps !== this.props)
        // 	this.setState({client_types: this.props.client_types});
    }

    filterBy(obj, filter, value) {
        return Object.fromEntries(Object.entries(obj).filter(el => el[1][filter] === value))
    }

    selectAll = (evt) => {
        //debugger;
        let rowchecked = !this.state.rowchecked; //rowchecked: false
        for (const key of Object.keys(this.state.client_types)) {
            //console.log(key, this.state.client_types[key]);
            this.state.client_types[key].isActive = rowchecked == true ? 1 : 0;
        }
        console.log(this.state.client_types);
        this.setState({ ...this.state, rowchecked: rowchecked });
        this.props.updateConfigTypes(this.state.client_types);
    }


    tableData = () => {

        let table = { data: [], columns: [] };
        console.log('table this.state', this.state)

        table.columns = [
            {
                Header: '#',
                accessor: 'id',
                width: 30,
                headerStyle: { textAlign: 'center' },
                style: { fontFamily: "monospace", fontSize: "14px", textAlign: 'right' }
            },
            {
                Header: 'Type / Question',
                accessor: 'type',
                headerStyle: { textAlign: 'left' },
                style: { whiteSpace: 'unset' }
            },
            {
                Header: <div><CSLToggle id={this.state.sectionName} checked={this.state.rowchecked}
                    onClick={this.selectAll} /></div>,
                accessor: 'status',
                width: 80,
                headerStyle: { textAlign: 'center' },
                sortable: false,
                Cell: row => (
                    <CSLToggle id={row.original.id} checked={row.original.status === 1} onClick={this.toggleStatus} />
                ),
                style: { display: 'flex', alignItems: 'center', justifyContent: 'center' }
            },
            {
                Header: 'Action', Cell: row => (
                    <div>
                        {
                            (() => {
                                if (row.original.isDelete === 0) {
                                    return (<div>
                                        <FaEdit onClick={this.openModal(row.original.id)} style={{
                                            cursor: 'pointer',
                                            fontSize: '18px',
                                            color: '#b4b5b4',
                                            display: 'inline-block',
                                            marginRight: "5px"
                                        }} />
                                        <FaTimes onClick={this.deleteType(row.original.id)} style={{
                                            cursor: 'pointer',
                                            fontSize: '18px',
                                            color: '#b4b5b4',
                                            display: 'inline-block'
                                        }} />
                                    </div>)
                                } else {
                                    return (<div>
                                        <FaEdit onClick={this.notDeleteType} style={{
                                            cursor: 'pointer',
                                            fontSize: '18px',
                                            color: '#b4b5b4',
                                            display: 'inline-block',
                                            marginRight: "5px"
                                        }} />
                                        <FaTimes onClick={this.notDeleteType} style={{
                                            cursor: 'pointer',
                                            fontSize: '18px',
                                            color: '#b4b5b4',
                                            display: 'inline-block'
                                        }} />
                                    </div>)
                                }
                            })()
                        }
                    </div>
                ), width: 55, headerStyle: { textAlign: 'center' }
            },
        ];

        for (const [key, value] of Object.entries(this.state.client_types_active_inactive.value)) {
            if (value.isDelete != 1 && (this.state.search_string === '' || value.title.toLowerCase().includes(this.state.search_string.toLowerCase(), 0))) {
                table.data.push({
                    'id': key,
                    'type': value.title,
                    'status': value.isActive,
                    'isDelete': value.isDelete
                });
            }
        }

        // let client_types_export = [];
        // Object.keys(this.props.client_types).map((d) => {
        //     let obj = {};
        //     obj.type = this.props.client_types[d].title;
        //     obj.status = this.props.client_types[d].isActive;
        //     obj.id = d;
        //     client_types_export.push(obj);
        // });
        // // update data for Export
        // this.props.updateTableCurData(client_types_export);
        return table;
    }

    notDeleteType = () =>
        alert(`This ${this.state.section_name} is in use.`);

    deleteType = (id) => (event) => {
        event.preventDefault();
        this.setState({
            promotion_id: id,
            isSubmitClicked: false,
            show_delete_confirm_modal: true
        });
    }

    checkIfPromotionCanBeDelete = (result) => {
        ////debugger;
        let id = this.state.promotion_id;
        if (result.return == 0 && result.error == 0 && result.message == "not exist") {
            this.state.client_types[id.toString()].isDelete = 1;
            this.state.client_types_active_inactive[id.toString()].isDelete = 1;
            this.props.updateConfigTypes(this.state.client_types);
            this.setState({
                client_types: this.state.client_types,
                client_types_active_inactive: this.state.client_types_active_inactive,
                isSubmitClicked: false,
                show_delete_confirm_modal: false
            })

        } else {
            this.setState({
                is_exist_promotion: true,
                isSubmitClicked: false,
                show_delete_confirm_modal: false
            })
        }
        console.log('inside checkIfPromotionCanBeDelete ==>', result);
    }

    toggleStatus(state, id) {
        //alert('called');
        this.state.client_types[id.toString()].isActive = state ? 1 : 0;
        this.props.updateConfigTypes(this.state.client_types);
    }

    openModal = (id) => (event) => {
        event.preventDefault();
        this.setState({ show_edit_modal: true, curid: id });
    }

    changeSectionName = (event) => {
        ////debugger;
        event.preventDefault();
        this.setState({ showChangeSectionModal: true, curid: 0 });
    }
    updateConfigName = (sectionName) => {
        this.props.updateSectionName(this.state.section_id, sectionName);
    }


    saveTypeValue = (id, title, isActive, isDelete, isSave) => {
        //debugger;
        let val = {
            title,
            isActive,
            isDelete
        }
        this.state.client_types[id] = val;
        console.log('inside config types');
        console.log(`id ${id}, title ${title}, isActive ${isActive}, isDelete ${isDelete}`);
        console.log(this.state.client_types);
        console.log(this.state.client_types_active_inactive);
        this.state.client_types_active_inactive[id] = val;
        this.setState({
            client_types: this.state.client_types,
            client_types_active_inactive: this.state.client_types_active_inactive,
            show_edit_modal: null
        })

        // let selection = this.filterBy( this.state.client_types_active_inactive, 'isActive', this.state.showActiveCheckbox ? 0 : 1);
        // this.setState({client_types_active_inactive: selection})

        this.props.updateConfigTypes(this.state.client_types);
    }

    closeModal = (event) => {
        // event.preventDefault();
        this.setState({ show_edit_modal: null })
    }
    closeEditSectionNameModal = (event) => {
        this.setState({ showChangeSectionModal: null })
    }

    searchUsers = (event) => {
        event.preventDefault();
        this.setState({search_string: event.target.value})
    }

    searchUsersOld = (event) => {
        event.preventDefault();
        let search_string = event.target.value;
        if (!this.state.showActiveCheckbox) {
            if (search_string === "") {
                let clientType = this.state.active_filter;
                this.setState({ client_types_active_inactive: clientType })
            } else {
                let clientTypeAll = this.state.active_filter;
                let ret = {};
                Object.keys(clientTypeAll).forEach((id) => {
                    if (clientTypeAll[id].title.toLowerCase().includes(search_string.toLowerCase())) {
                        let val = {}
                        val.title = clientTypeAll[id].title;
                        val.isActive = clientTypeAll[id].isActive;
                        val.isDelete = clientTypeAll[id].isDelete;
                        ret[id.toString()] = val;
                    }
                })
                this.setState({ client_types_active_inactive: ret })
            }
        } else {
            if (search_string === "") {
                let clientType = this.state.inactive_filter;
                this.setState({ client_types_active_inactive: clientType })
            } else {
                let clientTypeAll = this.state.inactive_filter;
                let ret = {};
                Object.keys(clientTypeAll).forEach((id) => {
                    if (clientTypeAll[id].title.toLowerCase().includes(search_string.toLowerCase())) {
                        let val = {}
                        val.title = clientTypeAll[id].title;
                        val.isActive = clientTypeAll[id].isActive;
                        val.isDelete = clientTypeAll[id].isDelete;
                        ret[id.toString()] = val;
                    }
                })
                let client_types_active_inactive = {}
                client_types_active_inactive.value = ret
                this.setState({ client_types_active_inactive })
            }
        }
        //this.props.searchType(event.target.value);
    }

    addType = (event) => {
        event.preventDefault();
        this.setState({ show_edit_modal: true, curid: 0 });
    }
    alertHandler = (result, stack) => {
        this.setState({ is_exist_promotion: false, is_clicked_yes: false })
    };

    alertHandler1 = (result, stack) => {
        let id = this.state.promotion_id;
        if (result) {
            this.setState({
                promotion_id: id, is_clicked_yes: true
            })
            const postData = { command: 'check_promotion_can_delete', promotion_type_id: id };
            this.api.command(postData, this.checkIfPromotionCanBeDelete);
        } else {
            this.setState({
                promotion_id: id, isSubmitClicked: false, show_delete_confirm_modal: false
            })
        }
    };

    render() {
        //console.log('delete clicked?==>', this.state);
        if (!this.state.client_types)
            return (<div>Loading...</div>);
        const alert_param = {
            title: 'Error',
            message:
                'There are records attached to this promotion type, so it cannot be deleted. Please make this promotion type inactive instead.',
            ok_text: 'Ok',
            confirm: false,
            alertHandler: this.alertHandler,
            stack: { id: 0 }
        };
        const alert_param1 = {
            title: 'Alert',
            message: `Are you sure you want to delete this ${this.state.section_name} from the list?`,
            cancel_text: 'No',
            ok_text: 'Ok',
            confirm: true,
            alertHandler: this.alertHandler1,
            stack: { id: 0 }
        };
        if (this.state.isSubmitClicked) {
            return (
                <div>
                    <CSLLoader />
                </div>
            );
        }

        return (
            <div>

                {this.state.showChangeSectionModal &&
                    <EditSectionNameModal
                        sectionName={this.state.section_name}
                        updateConfigName={this.updateConfigName}
                        closeModal={this.closeEditSectionNameModal} />
                }

                {this.state.show_edit_modal &&
                    <ConfigEditModal
                        sectionName={this.state.section_name}
                        saveValue={this.saveTypeValue}
                        client_types={this.state.client_types}
                        curid={this.state.curid}
                        closeModal={this.closeModal} />
                }


                <div style={{ width: "90%" }}>
                    <div>
                        <SearchInput style={{ width: "97%", marginTop: "15px" }} placeholder="Search..."
                            onChange={this.searchUsers} />
                    </div>
                    <TypesContainer>
                        <TypesInnerContainer>
                            <div style={{ display: "flow-root" }}>
                                <div style={{ float: "left" }}>
                                    <TypesBoldLabel
                                        style={{ display: "inline-block" }}>{this.state.section_name}</TypesBoldLabel>
                                    {(() => {
                                        if (this.props.canEditSectionName) {
                                            return (
                                                <FaEdit onClick={this.changeSectionName} style={{
                                                    cursor: 'pointer',
                                                    fontSize: '18px',
                                                    color: '#b4b5b4',
                                                    display: 'inline-block',
                                                    marginLeft: "12px"
                                                }} />
                                            )
                                        }
                                        return null;
                                    })()}
                                </div>

                                <div onClick={this.addType}
                                    style={{ float: "right", cursor: "pointer", fontSize: "17px", color: "#04ADA8" }}>
                                    <FaPlusCircle />
                                </div>
                            </div>

                            <CSLTable add={false} processData={this.tableData} tableRows={20} />

                        </TypesInnerContainer>
                    </TypesContainer>
                </div>
                {(() => {
                    if (this.state.show_delete_confirm_modal) {
                        return <AlertBox alertParam={alert_param1} />;
                    }
                })()}
                {(() => {
                    if (this.state.is_clicked_yes && !this.state.is_exist_promotion) {
                        return <CSLLoader />;
                    }
                })()}
                {(() => {
                    if (this.state.is_exist_promotion) {
                        return <AlertBox alertParam={alert_param} />;
                    }
                })()}
            </div>
        );
    }
}

export default ConfigTypes;
