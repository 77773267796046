import React from 'react';
import PropTypes from "prop-types";
import styled from 'styled-components';
import { FaTimes } from 'react-icons/fa';
import Utils from '../../Common/Utils.js';

const MRModalContainer = styled.div`
  width: calc(100% - 450px);
  position: fixed;
  background-color: #f7f7f7;
  border-radius: 5px;
  box-shadow: 0 0 20px #a7a6a6;
  z-index: 100000;
  padding: 20px;
  top: 20vh;
  left: 360px

`;

const MRModalHeader = styled.div`
  display: block;
  box-sizing: border-box;
  width: 100%;
  padding: 24px 24px 15px 0px;
`;

const MRModalLabel = styled.div`
  color: #212121;
  font-weight: 600;
  margin-top: 10px;
  margin-bottom: 10px;
`;
const MRModalHeaderText = styled.div`
  box-sizing: border-box;
  float: left;
  color: #1A3552;
  font-weight: 600;
  font-size: 17px;
`;
const MRModalHeaderCloseBtn = styled.div`
  box-sizing: border-box;
  float: right;
  font-size: 16px;
  color: #a0a0a0;
  cursor: pointer;
  font-size: 20px;
`;
const RegModalNameInput = styled.input`
  height: 28px;
  width: 97%;
  border: 1px solid #ffffff;
  border-radius: 5px;
  box-shadow: 0 0 4px #c4c4c4;
  padding: 5px;
  color: #000000;
  background-color: #ffffff;
`;
const MRModalTextarea = styled.textarea`
  width: 98%;
  border: 1px solid #ffffff;
  border-radius: 3px;
  box-shadow: 0 0 4px #c4c4c4;
  padding: 5px;
  color: #000000;
`;
const SaveBtn = styled.button`
  padding: 10px 30px;
  background-color: #37ADA7;
  border: 1px solid #37ADA7;
  border-radius: 2px;
  color: #ffffff;
  cursor: pointer;
  margin-left: 15px;
`;
const MRModalSelect = styled.select`
  display: block;
  box-sizing: border-box;
  height: 40px;
  width: 20%;
  background-color: #fff;
  border: 1px solid #ffffff;
  border-radius: 3px;
  box-shadow: 0 0 4px #c4c4c4;
  font-family: 'Montserrat', sans-serif;
  color: #000000;
  margin-top: 10px;
  margin-bottom: 10px;
`;

class PromotionEditModal extends React.Component {

  static propTypes = {
    /** */
    sectionName: PropTypes.string,
    /** current ID*/
    curid: PropTypes.string,
    /** object with Types */
    client_types: PropTypes.object,
    /** callback to close Modal */
    closeModal: PropTypes.func,
    /** callback with selection*/
    saveValue: PropTypes.func,
  };

  state = {
    promotion_type: null, is_new: true
  }

  closeView = () => {
    this.props.closeModal();
  }

  componentDidMount() {
    let promotion_types = this.props.promotion_types
    let pr_id = this.props.pr_id
    let is_new = pr_id === '0' ? true : false
    let promotion_type = pr_id === '0' ? { title: '', isActive: 1, isDelete: 0, pr_id: Utils.genKey(7), question_id: Utils.genKey(7), record_id: Utils.genKey(15), parent_id: this.props.parent_id } : promotion_types[pr_id]
    this.setState({ promotion_type, is_new })
  }

  handleChange = (evt) => {
    let key = [evt.target.name]
    let promotion_type = this.state.promotion_type
    promotion_type[key] = evt.target.value
    this.setState({ promotion_type });
  }

  submit = (event) => {
    let r = this.state.promotion_type
    r.isActive = parseInt(r.isActive)
    this.props.savePromotionType(r, this.props.pr_id, this.state.is_new)
  }

  render() {
    console.log('this.state', this.state)
    if (this.state.promotion_type === null) return <div />
    return (
      <MRModalContainer>
        <MRModalHeader>
          <MRModalHeaderText>{this.props.pr_id !== '0' ? 'Edit Promotion Type' : 'Add Promotion Type'}</MRModalHeaderText>
          <MRModalHeaderCloseBtn onClick={this.closeView}><FaTimes /></MRModalHeaderCloseBtn>
          <div style={{ clear: "both" }}></div>
        </MRModalHeader>
        <MRModalLabel style={{ marginTop: "30px" }}>PROMOTION Type</MRModalLabel>
        <RegModalNameInput name="title" value={this.state.promotion_type.title} onChange={this.handleChange} />
        <MRModalLabel style={{ marginTop: "30px" }}>Status</MRModalLabel>
        <MRModalSelect onChange={this.handleChange} name="isActive" value={this.state.promotion_type.isActive}>
          <option value="1">Active</option>
          <option value="0">Inactive</option>
        </MRModalSelect>
        <div style={{ float: "right" }}>
          <SaveBtn onClick={this.submit}>Submit</SaveBtn>
        </div>
      </MRModalContainer>
    )
  }
}

export default PromotionEditModal;
