import React from 'react'
import PropTypes from "prop-types"
import styled from 'styled-components'
import {FaPlus} from 'react-icons/fa'
import ReactTable from 'react-table'
import 'react-table/react-table.css'
import CSLPagination from "./CSLPagination"
import Pagination from "./Pagination"
import "./table_styles.css";

const HeaderText = styled.div`
  margin-left: 5px;
  margin-top: 10px;
  margin-bottom: 10px;
  color: #1A3552;
  font-weight: 600;
  letter-spacing: 1px;
`;
const TableHeaderIcon = styled.div`
  float: right;
  margin-right: 20px;
  margin-top: 15px;
  color: #c0c0c0;
`;
const TableContainer = styled.div`
  background-color: #ffffff;
`
const ColumnHeaderComponent = styled.div`
  cursor: pointer;
`

class ColumnHeader extends React.Component {
    render() {
        
        let id = this.props.id === null ? '0' : this.props.id
        console.log('this.props ', this.props )
        return (<>
            <ColumnHeaderComponent onClick={() => this.props.onHeaderClick(id)}>{this.props.label}</ColumnHeaderComponent>
        </>)
    }
}

/**
 * This component wraps the *[react.table](https://www.npmjs.com/package/react-table)*, adds headers and pagination
 */
class CSLTableSinglePage extends React.Component {
    static defaultProps = {
        tableRows: 5,
        headerText: null,
        zebra: false
    }

    static propTypes = {
        /** Add + sign to fire this.showAddAskModal -- needs to change to pass function dynamically */
        add: PropTypes.bool,
        /**  Works together with Add -- needs to change to pass function dynamically */
        addTitle: PropTypes.string,
        /** Function that should return an object in format {data:[], colums:[]}*/
        processData: PropTypes.func,
        /** Number of rows */
        tableRows: PropTypes.number,
        /** Text for header */
        headerText: PropTypes.string,
        /** Alternated row background-colour */
        zebra: PropTypes.bool
    }

    // this prop is lost  needs to cross check
    // refreshCallback: undefined

    filterMethod = (filter, row, column) => {
        const id = filter.pivotId || filter.id
        return row[id] !== undefined ? String(row[id]).toLowerCase().includes(filter.value.toLowerCase()) : true
    }


    render() {
        let tableData = this.props.processData();
        
        let prop_columns = tableData.columns
        let columns = []
        for(let c of prop_columns) {
            let col = c;

            col.text = c.Header
            col.Header = <ColumnHeader 
                    label={c.text} 
                    sortOrder={'sortorder' in c ? c.sortorder : 0} 
                    id={'accessor' in c ? c.accessor : null} 
                    onHeaderClick={this.props.onHeaderClick}
                />
            columns.push(col)
        }
        console.log('visiblePages columns', columns);
        console.log('visiblePages tableData.data', tableData.data);
        return (
            <TableContainer>
                <HeaderText
                    style={{display: this.props.headerText ? 'inherit' : 'none'}}>{this.props.headerText}</HeaderText>
                {this.props.add &&
                <TableHeaderIcon><FaPlus style={{cursor: "pointer"}} onClick={this.showAddAskModal}
                                         title={this.props.addTitle}/></TableHeaderIcon>
                }
                <div style={{width: '100%'}}>

                            <ReactTable
                                className={this.props.zebra ? "-striped" : ""}
                                PaginationComponent={Pagination}
                                showPagination={false}
                                data={tableData.data}
                                columns={columns}
                                defaultPageSize={parseInt(this.props.tableRows)}
                                filterable={false}
                                defaultFilterMethod={this.filterMethod}
                                
                                onPageClick={this.props.onPageClick}
                            />
                </div>


                <CSLPagination totalPages={this.props.pages} currentPage={this.props.page} onPageClick={this.props.onPageClick} />
            </TableContainer>

        )
    }
}

export default CSLTableSinglePage;

                                // PaginationComponent={Pagination}