import React from 'react';
import { saveAs } from 'file-saver';
import styled, { css } from 'styled-components';
import {
    FaTimes,
    FaPlusCircle,
    FaArrowAltCircleLeft,
    FaArrowAltCircleRight,
    FaEdit,
    FaCalendarAlt
} from 'react-icons/fa';
import { APICall, Store, Utils } from '../Common';
import ClearBoth from '../Common/ClearBoth';
import CSLTable from '../Common/CSLTable';
import Dropzone from '../Common/Dropzone';
import AlertBox from '../Common/AlertBox';
import moment from 'moment';
import FollowupTask from './FollowupTask';
import DatePicker from "react-date-picker";
import HtmlEditor from "./HtmlEditor";
import EditFollowUpModal from "./EditFollowUpModal";
import FollowupModal from './FollowupModal';
import '../Common/CSLDatePickerForModal.css';
import CSLLoader from './../Common/CSLLoader';

/* for radiobutton start */
const GeneralLabel = styled.div`
  font-weight: 600;
  color: #595959;
  margin-top: 20px;
`;

const RadioBlock = styled.div`
  margin-top: 10px;
`;

const SelectedRadio = styled.div`
  background-color: #37ADA7;
  border: 1px solid #1f615e;
  padding: 5px 10px;
  color: #ffffff;
  display: inline-block;
  cursor: pointer;
`;

const UnselectedRadio = styled.div`
  background-color: #F4F4F4;
  border: 1px solid #8c8c8c;
  padding: 5px 10px;
  color: #262626;
  display: inline-block;
  cursor: pointer;
`;

/* end for radiobutton */


const MRButton = styled.button`
  box-sizing: border-box;
  background-color: #37ada7;
  color: #ffffff;
  border: 1px solid #37ada7;
  width: 84px;
  font-size: 13px;
  height: 25px;
  border-radius: 4px;
`;
const MRModalContainer = styled.div`
  display: block;
  box-sizing: border-box;
  height: auto;
  width: 812px;
  position: absolute;
  top: 38px;
  z-index: 1001;
  background-color: rgb(243, 247, 251);
  box-shadow: rgb(167, 166, 166) 0px 0px 5px;
  margin-bottom: 10px;
  border-radius: 5px;
  margin-left: 100px;
`;
const MRModalListContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;
const MRDataContainer = styled.div`
  display: block;
  box-sizing: border-box;
  height: auto;
  width: 90%;
  margin: 0 auto;
  margin-top: 30px;
  background-color: #e7f0f7;
  // margin-bottom: 10px;
  border: 1px solid #d0d0d0;
  border-radius: 5px;
  padding: 5px 10px;
`;
const MRTasksContainer = styled.div`
  display: block;
  box-sizing: border-box;
  height: auto;
  width: 92%;
  margin: 0 auto;
  // top: 30px;
  margin-bottom: 10px;
  // border-radius: 5px;
  padding: 5px 10px;
`;
const MRModalHeader = styled.div`
  background-color: white;
  display: block;
  padding-top: 5px;
  font-size: 14px;
  font-weight: 700;
  height: 100px;
  width: calc(100% - 10px);
  border-width: 1px 1px 1px 10px;
  border-left: 9px solid #04ada8;
  border-bottom: 1px solid #dfdfdf;
`;
const MRModalHeaderText = styled.div`
  font-weight: 700;
  font-size: 22px;
  float: left;
  margin-top: 24px;
  margin-left: 35px;
  color: #143151;
`;
const MRModalHeaderCloseBtn = styled.div`
  position: absolute;
  top: 8px;
  right: 10px;
  box-sizing: border-box;
  margin-top: 24px;
  margin-right: 20px;
  font-size: 25px;
  cursor: pointer;
  font-weight: 200;
`;
const MRModalBody = styled.div`
  position: relative;
  display: block;
  box-sizing: border-box;
  background-color: rgb(243, 247, 251);
  padding: 10px;
  border-radius: 4px;
  color: #1a3552;
`;
const MRModalLabel = styled.div`
  color: #1a3552;
  font-weight: 600;
  margin-top: 15px;
  font-size: 15px;
  margin-bottom: 15px;
`;
const MRModalLabelReasonCode = styled.div`
  color: #1a3552;
  font-weight: 600;
  margin-top: 5px;
  font-size: 15px;
  margin-bottom: 5px;
  margin-left: 15px;
`;

const MRModalFooter = styled.div`
  position: relative;
  padding: 17px;
  background-color: white;
  border-style: solid;
  border-width: 1px;
  border-color: #f3f7fb;
  // width: 100%;
  border-top: 1px solid #dfdfdf;
`;
const MRModalButton = styled.button`
  display: inline-block;
  box-sizing: border-box;
  color: #ffffff;
  width: 115px;
  height: 45px;
  border-radius: 3px;
  margin-left: 5px;
  font-size: 15px;
  float: right;
  cursor: pointer;
`;
const MRModalWithdrawBtn = styled(MRModalButton)`
  background-color: #CE6666;
  border: 1px solid #CE6666;
`;
const MRModalNextBtn = styled(MRModalButton)`
  background-color: #37ada7;
  border: 1px solid #37ada7;
  ${(props) =>
        props.disabled
            ? css`
                    background-color: #cccccc;
                    cursor: not-allowed !important;
                    border: none;
                  `
            : css`
                    cursor: pointer !important;
                  `};
`;
const MRModalReviseBtn = styled(MRModalButton)`
  background-color: rgb(240, 183, 46);
  border: 1px solid rgb(240, 183, 46);
  ${(props) =>
        props.disabled
            ? css`
                    background-color: #cccccc;
                    cursor: not-allowed !important;
                    border: none;
                  `
            : css`
                    cursor: pointer !important;
                  `};
`;
const MRModalSaveCloseBtn = styled(MRModalButton)`
  background-color: #143151;
  border: 1px solid #143151;
  ${(props) =>
        props.disabled
            ? css`
                    background-color: #cccccc;
                    cursor: not-allowed !important;
                    border: none;
                  `
            : css`
                    cursor: pointer !important;
                  `};
`;
const MRModalCancelBtn = styled(MRModalButton)`
  background-color: #ffffff;
  color: #545454;
  border: 2px solid #dadada;
  ${(props) =>
        props.disabled
            ? css`
                    background-color: #cccccc;
                    cursor: not-allowed !important;
                    border: none;
                  `
            : css`
                    cursor: pointer !important;
                  `};
`;

const MRModalUpdateBtn = styled(MRModalButton)`
  background-color: #ffffff;
  color: #545454;
  border: 2px solid #dadada;
  ${(props) =>
        props.disabled
            ? css`
                    background-color: #cccccc;
                    cursor: not-allowed !important;
                    border: none;
                  `
            : css`
                    cursor: pointer !important;
                  `};
`;

const MRModalCancelBtnWithdrawPopup = styled(MRModalButton)`
  background-color: #ffffff;
  color: #545454;
  border: 2px solid #dadada;
`;

const MRModalEditDetailslBtn = styled(MRModalButton)`
  background-color: #ffffff;
  color: #545454;
  border: 2px solid #dadada;
  height: 25px;
  font-size: 12px;
  width: auto;
`;
const MRModalTextarea = styled.textarea`
  width: 99%;
  border: 1px solid #ffffff;
  border-radius: 3px;
  box-shadow: 0 0 4px #c4c4c4;
  padding: 5px;
  color: #000000;
  font-family: 'Montserrat', sans-serif;
`;
const MRModalSelect = styled.select`
  box-sizing: border-box;
  height: 40px;
  width: 100%;
  background-color: #fff;
  border: 1px solid #ffffff;
  border-radius: 3px;
  box-shadow: 0 0 4px #c4c4c4;
  font-family: Montserrat, sans-serif;
  color: #5f6368;
`;
const MRModalSelectReasonCodes = styled.select`
  box-sizing: border-box;
  height: 40px;
  width: 45%;
  background-color: #fff;
  border: 1px solid #ffffff;
  border-radius: 3px;
  box-shadow: 0 0 4px #c4c4c4;
  font-family: Montserrat, sans-serif;
  color: #5f6368;
  margin-left: 14px;
`;
const MRFollowUpTasksContainer = styled.div`
  display: block;
  box-sizing: border-box;
  width: 100%;
`;
const OverviewInfoHeader = styled.div`
  padding: 12px 15px;
  background-color: #04ada8;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  width: 96%;
  margin: 0 auto;
  margin-top: 30px;
`;
const OverviewInfoBody = styled.div`
  padding: 15px;
  background-color: #ffffff;
  border: 1px solid #d7dddf;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  width: 96%;
  margin: 0 auto;
  margin-bottom: 30px;
`;
const ModalContainerGrid = styled.div`
  display: grid;
  grid-template-columns: 20% calc(80% - 15px);
  grid-gap: 5px;
  padding: 10px;
`;
const ModalNameLabelDiv = styled.div`
  height: auto;
  width: 100%;
  padding-top: 10px;
  font-weight: 600;
  color: #1a3552;
`;
const ModalNameInputDiv = styled.div`
  height: auto;
  width: 100%;
  padding-top: 10px;
  color: #1a3552;
  font-family: 'Montserrat', sans-serif;
`;
const CommentInputDiv = styled.div`
  height: auto;
  width: 100%;
  color: #1a3552;
  font-family: 'Montserrat', sans-serif;
`;
const DescriptionInputDiv = styled.div`
  height: auto;
  width: 100%;
  color: #1a3552;
  font-family: 'Montserrat', sans-serif;
`;
const DownloadLinkLi = styled.li`
  cursor: pointer;
  color: rgb(137, 215, 216);
  text-decoration: underline;
  font-weight: bold;
  list-style-type: none;
`;
const RadioOption = styled.input`
  margin: 10px;
  width: 16px;
  height: 16px;
`;
const CSLDateCover = styled.div`
  margin-top: 8px;
  width: 200px;
  border-radius: 5px;
  background-color: #ffffff;
  height: 33px;
  padding-top: 5px;
  padding-left: 10px;
`;

const RadioGroup = (props) =>
    React.Children.map(props.children, (child) => {
        if (child.type === RadioOption)
            return React.cloneElement(child, {
                type: 'radio',
                defaultChecked: props.value === child.props.value,
                disabled: props.disabled,
                name: props.name,
                onChange: props.onChange
            });
        return child;
    });

class TaskApprovalModal extends React.Component {

    state = {
        //module_conf:Store.getStoreData('module_config'),
        contact_id: Store.getStoreData('contact_id'),
        contacts: Store.getStoreData('contacts'),
        promotions: Store.getStoreData('module_config').promotion_types,
        checklists: Utils.getChecklists(),
        comments: '',
        Ref_ID: null,
        submit_form: null,
        curlane: null,
        taskid: null,
        showModal: false,
        review_list: [],
        followupTask: [],
        ready: false,
        toggleView: false, // helper to toggle between DETAILS and CHECKLIST
        toggleConfirm: false, // helper to know if changes were made before Confirm
        toggleEdit: false, // helper to toggle Edit Details mode
        showfollowupclose: true,
        groupcompanyName: '',
        discloserid: 0,
        header_message: '',
        reasonID: 0,
        module_config: null,
        binFiles: [],
        cur_files_json: null,
        is_disabled: false,
        user_role: '',
        readOnly: false,
        ctaWithdraw: true,
        isReviewDateEnabledFromConfig: Store.getStoreData('module_config').general.add_review_date_for_finpromo,
        isReviewDateEnabled: false,
        //isReviewDateEnabled: true,
        due_by: moment().format('YYYY/MM/DD'),
        review_date: null,
        admin_fp_managers: [],
        assign_to: 0,
        object_data: null, //required for showing details when viewing review-required status fp
        review_comments: '',
        isEditDetailsReviewRequiredModalOpen: false,
        isWithdrawnReasonCodeEnabled: Store.getStoreData('module_config').general.withdrawn_reason_codes_enable,
        reasonCodes: Store.getStoreData('module_config').reason_codes,
        selectedReasonCode: '',
        isEnabledExpiryDate: Store.getStoreData('module_config').general.add_expiry_date_for_financial_promotions,
        isExpiryDateEnabled: false,
        isEnabledForOutStandingFollowUpTasks: Store.getStoreData('module_config').general.complete_financial_promotions_with_outstanding_follow_up_tasks,
        expiry_date: null,
        expiry_due_by: moment(Date.now() + 24 * 60 * 60 * 1000).format('YYYY/MM/DD'),
        isReadOnlyForRevision: false,
        isDiscloserSavedAlertModal: null,
        managers_questions: null,
        //**************************
        roles: Store.getStoreData('userroles'),
        gc_company: Store.getStoreData('gc_company'),
        gc_companies: Store.getStoreData('gc_companies'),
        my_group: Store.getStoreData('my_group'),
        //**************************
        isEditFollowUpModalView: false,
        isEditFollowUpModalOpen: false,
        isLoadedIcon: false,
        submitFromValue: 0,
        assign_data: null,
        isExpiryCalendarClicked: false
    };

    constructor (props) {
        super(props);
        this.api = new APICall();
        this.toggleView = this.toggleView.bind(this);
        this.toggleComments = this.toggleComments.bind(this);
    }

    componentDidMount = async () => {
        let index = Store.getStoreData('q_index') === null ? 0 : Store.getStoreData('q_index');

        let field_data = {};

        let assign_data = {}

        let additional_fields = [];
        let module_config = Store.getStoreData('module_config');

        if (Object.keys(module_config).length > 0) {
            if (module_config.custom_fields.additional_fields.length !== 0 && module_config.custom_fields.enabled) {
                module_config.custom_fields.additional_fields.forEach((item) => {
                    let copy_item = JSON.parse(JSON.stringify(item));
                    copy_item.value = item.type === "Calendar" ? new Date() : "";
                    copy_item.value = item.type === "Dropdown" ? item.dropdown_options[0] : copy_item.value;
                    additional_fields.push(copy_item);
                })
            }
        }
        if (index >= 0) {

            field_data = Store.getStoreData('cur_q_modal_data');
            let company_contacts = Store.getStoreData('contacts');
            let task_json = JSON.parse(field_data.task_json);

            let gc_assoc = Store.getStoreData('gc_assoc');
            let gcid = parseInt(task_json.task_data.group_company_id) % 100000;
            let gcname = '';
            if (gcid in gc_assoc) gcname = gc_assoc[gcid];
            let gc_users = [];
            let my_company = null;
            let gc_company = Store.getStoreData('gc_company');
            for (let gc of gc_company) {
                if (gc.id === gcid) my_company = gc;
            }

            const com_roles = Store.getStoreData('userroles');
            const myGroup = Store.getStoreData('my_group');


            for (let id of Object.keys(my_company.users)) {
                // checks for myGroup.length to cover the scenario where there is NO group set
                if (
                    com_roles[id.toString()] !== 'no_access' &&
                    (myGroup.length ? myGroup.includes(id.toString()) : true)
                )
                    gc_users.push({ ID: id, ContactName: company_contacts[id.toString()] });
            }

            if (task_json.object_data.bin_files.length !== 0) {
                task_json.object_data.bin_files.forEach((item) => {
                    item.uid = 'uid' in item ? item.uid : this.genKey(10);
                });
            }
            assign_data.additional_fields = "additional_fields" in task_json.object_data.headerinfo ? task_json.object_data.headerinfo.additional_fields : [];

            this.setState(
                {
                    Ref_ID: task_json.object_data.ref_id,
                    comments: task_json.object_data.headerinfo.comments,
                    curlane: field_data.cur_lane,
                    taskid: field_data.id,
                    review_list: gc_users,
                    groupcompanyName: gcname,
                    discloserid: field_data.contact_id,
                    user_role: Store.getStoreData('role'),
                    cur_files_json: task_json.object_data.bin_files,
                    //also added for dropdown in fp and admin manager
                    admin_fp_managers: Store.getStoreData('get_managers'),
                    object_data: task_json.object_data,
                    assign_data: assign_data
                },
                () => {

                    const status = this.state.curlane;

                    if (status === 'COMP_FINPROMO_TEAMSAVE' || status === 'COMP_FINPROMO_WITHDRAWN' || status === 'COMP_FINPROMO_EXPIRED')
                        this.setState({ ctaWithdraw: false });

                    if (status === 'COMP_FINPROMO_CONFIRMED' || status === 'COMP_FINPROMO_WITHDRAWN' || status === 'COMP_FINPROMO_EXPIRED')
                        this.setState({ readOnly: true });

                    if (
                        (this.state.user_role === 'admin_manager' || this.state.user_role === 'fprm' || this.state.user_role === 'manager') &&
                        this.state.curlane !== 'COMP_FINPROMO_REGISTERED' &&
                        this.state.curlane !== 'COMP_FINPROMO_CONFIRMED' &&
                        this.state.curlane !== 'COMP_FINPROMO_WITHDRAWN' &&
                        this.state.curlane !== 'COMP_FINPROMO_EXPIRED'
                        // this.state.curlane !== 'COMP_FINPROMO_REVISION'
                    ) {
                        if (this.state.curlane === 'COMP_FINPROMO_REVIEW_REQUIRED') {
                            //debugger;
                            if (this.state.user_role == 'admin_manager' || this.state.user_role == 'manager') {
                                this.setState({ toggleEdit: true });
                            }
                            if (task_json.object_data.hasOwnProperty('rvw_rqrd_assignto')) {
                                let reviewerid = task_json.object_data.rvw_rqrd_assignto;
                                if (this.state.contact_id == reviewerid) {
                                    this.setState({ toggleEdit: true });
                                }
                                if (this.state.contact_id == this.state.discloserid) {
                                    this.setState({ toggleEdit: true });
                                }
                            } else {
                                if (this.state.contact_id == this.state.discloserid) {
                                    this.setState({ toggleEdit: true });
                                }
                            }

                        } else {
                            if (this.state.contact_id == this.state.discloserid && this.state.curlane === 'COMP_FINPROMO_REVISION') {
                                this.setState({ toggleEdit: true });
                            }
                        }
                    }

                    //revision status work
                    //save and close:==> save review date and toggle


                    if (task_json.object_data.hasOwnProperty('review_toggle')) {
                        this.setState({
                            isReviewDateEnabled: task_json.object_data.review_toggle,
                        });
                    }

                    if (task_json.object_data.hasOwnProperty('rvw_rqrd_assignto')) {
                        this.setState({
                            assign_to: task_json.object_data.rvw_rqrd_assignto,
                            isReadOnlyForRevision: true
                        });
                    }
                    if (task_json.object_data.hasOwnProperty('rvw_rqrd_rvwdate')) {
                        this.setState({
                            review_date: new Date(task_json.object_data.rvw_rqrd_rvwdate),
                            isReadOnlyForRevision: true
                        });
                    }

                    if (task_json.object_data.hasOwnProperty('expiry_toggle')) {
                        this.setState({
                            isExpiryDateEnabled: task_json.object_data.expiry_toggle,
                        });
                    }
                    //rvw_rqrd_expirydt
                    if (task_json.object_data.hasOwnProperty('rvw_rqrd_expirydt')) {
                        this.setState({
                            expiry_date: new Date(task_json.object_data.rvw_rqrd_expirydt),
                            expiry_due_by: task_json.object_data.rvw_rqrd_expirydt
                            //isReadOnlyForRevision: true
                        });
                    }
                    //debugger;
                    if (this.state.curlane == 'COMP_FINPROMO_REVISION' || this.state.curlane == 'COMP_FINPROMO_REGISTERED') {
                        //debugger;
                        //1-> manager click on save and close but discloser does not..
                        if (task_json.object_data.hasOwnProperty("is_managerSaved") && !task_json.object_data.hasOwnProperty("is_discloser_saved")) {
                            this.setState({ questions: task_json.object_data.headerinfo.managers_questions });
                        }
                        // 2-> manager does not click on save and close but discloser does ..
                        else if (!task_json.object_data.hasOwnProperty("is_managerSaved") && task_json.object_data.hasOwnProperty("is_discloser_saved")) {
                            this.setState({ managers_questions: task_json.object_data.headerinfo.questions });
                        }
                        //3-> manager  click on save and close and  discloser also clicked on save and close ..
                        // then show discloser questions..

                        else if (task_json.object_data.hasOwnProperty("is_managerSaved") && task_json.object_data.hasOwnProperty("is_discloser_saved")) {

                            if (task_json.object_data["is_managerSaved"] == true && task_json.object_data["is_discloser_saved"] == false) {
                                this.setState({ managers_questions: task_json.object_data.headerinfo.managers_questions });
                            } if (task_json.object_data["is_managerSaved"] == true && task_json.object_data["is_discloser_saved"] == true) {
                                this.setState({ managers_questions: task_json.object_data.headerinfo.questions });
                            } if (task_json.object_data["is_managerSaved"] == false && task_json.object_data["is_discloser_saved"] == true) {
                                this.setState({ managers_questions: task_json.object_data.headerinfo.questions });
                            }
                            if (task_json.object_data["is_managerSaved"] == true && task_json.object_data["is_discloser_saved"] == false && this.state.curlane == 'COMP_FINPROMO_REGISTERED') {
                                this.setState({ questions: task_json.object_data.headerinfo.managers_questions });
                            }

                        }
                    }
                    if (this.state.curlane == 'COMP_FINPROMO_EXPIRED') {
                        if (task_json.object_data.hasOwnProperty('rvw_rqrd_expirydt')) {
                            this.setState({
                                expiry_date: new Date(task_json.object_data.rvw_rqrd_expirydt),
                                //isReadOnlyForRevision: true
                            });
                        }
                    }
                }
            );
            const data = task_json.object_data.headerinfo;
            //            debugger
            this.setState({
                company: data.company,
                name: data.name,
                promotion: data.promotion,
                description: data.description,
                urlLink: data.urlLink,
                comments: data.comments,
                comments_reviewer: data.comments_reviewer,
                checklist: data.checklist,
                questions: data.questions,
                entrydate: task_json.task_data.actiondate
            }, () => {
                //use for followup task creation dropdown(users+FPrm+admin) and reviewee dropdown(FP+Managers)
                this.setAdminFpWithUsers();
            });

            this.setState({ ready: true });
            // let command_data = { command: 'listfollowups', parent_id: field_data.id };
            // this.api.command(command_data, this.showFollowupsTask);
        }
    }

    // showFollowupsTask = (result) => {
    //     console.log(result)
    //     Store.updateStore('updated_followups', result.result.followuptasks);
    //     this.setState({ ready: true });
    // };


    setAdminFpWithUsers = () => {

        let company_id = this.state.company;
        let companies = this.state.gc_company;
        let companyDetails = companies.find(comp => comp.id == company_id);
        let companyUsers = companyDetails.users;
        let is_group_company = companyDetails.is_gc;
        let user_roles = this.state.roles;
        let adminFpManagers = [];
        let allUsers = [];

        // if group company
        if (is_group_company) {

            // push admin managers
            for (const [key, value] of Object.entries(user_roles)) {
                let userid = key;
                let role = value;
                if (role == 'manager') {
                    //{Store.getStoreData('contacts')[this.state.object_data.rvw_rqrd_assignto]}
                    if (typeof Store.getStoreData('contacts')[userid] !== 'undefined') {
                        adminFpManagers.push({ 'ID': userid, 'ContactName': Store.getStoreData('contacts')[userid] });
                        allUsers.push({ 'ID': userid, 'ContactName': Store.getStoreData('contacts')[userid] });
                    }
                }
            }

            for (const [key, value] of Object.entries(companyUsers)) {
                let userid = key;
                let userrole = user_roles[userid];
                if (userrole !== 'no_access') {
                    if (!allUsers.find(user => user.ID == userid)) {
                        if (typeof Store.getStoreData('contacts')[userid] !== 'undefined') {
                            allUsers.push({ 'ID': userid, 'ContactName': Store.getStoreData('contacts')[userid] })
                        }
                    }
                }
                if (userrole == 'fprm' || userrole == 'manager') {
                    if (!adminFpManagers.find(user => user.ID == userid)) {
                        if (typeof Store.getStoreData('contacts')[userid] !== 'undefined') {
                            adminFpManagers.push({ 'ID': userid, 'ContactName': Store.getStoreData('contacts')[userid] });
                        }
                    }
                }
            }
        } else {
            for (const [key, value] of Object.entries(companyUsers)) {
                let userid = key;
                let userrole = value;
                if (userrole != 'no_access') {
                    if (userrole == 'manager' || userrole == 'fprm') {
                        if (typeof Store.getStoreData('contacts')[userid] !== 'undefined') {
                            adminFpManagers.push({ 'ID': userid, 'ContactName': Store.getStoreData('contacts')[userid] });
                        }
                    }
                    if (typeof Store.getStoreData('contacts')[userid] !== 'undefined') {
                        allUsers.push({ 'ID': userid, 'ContactName': Store.getStoreData('contacts')[userid] });
                    }
                }
            }
        }
        this.setState({ admin_fp_managers: adminFpManagers, review_list: allUsers });
    }

    toggleView = () => this.setState((prevState) => ({ toggleView: !prevState.toggleView }));

    questionsIDs = (checklist) => {
        return this.state.checklists.questions[checklist].map((question) => {
            let obj = { id: question.id, question_id: question.question_id };
            return obj
        });
    }

    getChecklistValues() {
        let answer,
            comments,
            questions = {};

        if (this.state.checklist)

            this.questionsIDs(this.state.checklist).map((question) => {
                answer = document.querySelector(`input[name="field_question_${question.id}"]:checked`);
                comments = document.querySelector(`textarea[name="field_question_comments_${question.id}"]`);
                questions[question.id] = {
                    answer: answer ? answer.value : null,
                    comments: comments ? comments.value : null,
                    question_id: question.question_id
                };
            });
        return questions;
    }


    closeView = () => {
        if (Store.getStoreData('isreferenceUrl') === true) window.location = '/';
        this.props.closeModalAndRefreshNormal();
    };

    handleDownload = (index) => {
        var binaryString = window.atob(this.state.cur_files_json[index].bin_file);
        var binaryLen = binaryString.length;
        var bytes = new Uint8Array(binaryLen);
        for (var i = 0; i < binaryLen; i++) {
            var ascii = binaryString.charCodeAt(i);
            bytes[i] = ascii;
        }
        var blob = new Blob([bytes]);
        saveAs(blob, this.state.cur_files_json[index].name);
    };

    showAddFollowupTaskModal = (index) => (event) => {
        //event.preventDefault();
        Store.updateStore('ftask_index', -1);
        this.setState({ showModal: true, showfollowupclose: false });
    };

    closeFollowupModal = () => {
        this.setState({ showModal: false, showfollowupclose: true });
    };


    //creating followup task here

    followupTaskValueFetchNew = (taskValue) => {
        let ref_id = Utils.genKey(12);
        let task = {
            task_data: {
                module_id: 'finpromo',
                last_action: 'COMP_FPR_FOLLOWUP',
                ref_id: ref_id,
                actiondate: Date.now(),
                cur_assigned_to: taskValue.assignedto,
                task_type: 'COMP_FPR_FOLLOWUP',
                parent_task_id: this.state.taskid,
                cur_lane: 'COMP_FPR_FOLLOWUP_ASSIGNMENT',
                due_date: taskValue.dueby
            },
            object_data: {
                subject: taskValue.subject,
                note: taskValue.note
            },
            action_data: [
                {
                    action: 'COMP_FPR_FOLLOWUP',
                    actiondate: Date.now(),
                    assigned_to: taskValue.assignedto,
                    cur_lane: 'COMP_FPR_FOLLOWUP_ASSIGNMENT',
                    due_by: taskValue.dueby,
                    note: taskValue.note,
                    performed_by: this.state.contact_id,

                }
            ]
        };
        //console.log('followup task', task);
        const postData = { command: 'createfollowuptask', task: task, bin_files: taskValue.binFiles };
        this.setState({ isLoadedIcon: true })
        this.api.command(postData, this.processAddTaskNew);
    };

    processAddTaskNew = (result) => {
        this.setState({ showModal: false, showfollowupclose: true, isLoadedIcon: false });
    };


    followupTaskValueFetch = (taskValue) => {
        let ref_id = Utils.genKey(12);
        let task = {
            task_data: {
                module_id: 'finpromo',
                last_action: 'COMP_FPR_FOLLOWUP',
                ref_id: ref_id,
                actiondate: Date.now(),
                cur_assigned_to: taskValue.assignedto,
                task_type: 'COMP_FPR_FOLLOWUP',
                parent_task_id: this.state.taskid,
                cur_lane: 'COMP_FPR_FOLLOWUP_ASSIGNMENT',
                due_date: taskValue.dueby
            },
            object_data: {
                subject: taskValue.subject,
                note: taskValue.note
            },
            action_data: [
                {
                    action: 'COMP_FPR_FOLLOWUP',
                    actiondate: Date.now(),
                    assigned_to: taskValue.assignedto,
                    cur_lane: 'COMP_FPR_FOLLOWUP_ASSIGNMENT',
                    due_by: taskValue.dueby,
                    note: taskValue.note,
                    performed_by: this.state.contact_id,

                }
            ]
        };

        const postData = { command: 'createfollowuptask', task: task, bin_files: taskValue.binFiles };
        this.setState({ isLoadedIcon: true })
        this.api.command(postData, this.processAddTask);

        // maybe it shouldn't run
        this.state.followupTask.push(taskValue);
        this.setState({ followupTask: this.state.followupTask });
    };

    processAddTask = (result) => {
        // alert("A Followup Task has been successfully added to this Financial Promotion.");
        const command_data = { command: 'listfollowups', parent_id: this.state.taskid };
        this.api.command(command_data, this.updateFollowups);
    };

    updateFollowups = (result) => {
        Store.updateStore('updated_followups', result.result.followuptasks);
        this.setState({ showModal: false, showfollowupclose: true, isLoadedIcon: false });
    };

    showEditFollowUpModal = (task_id) => (event) => {
        event.preventDefault();
        let postData = { command: 'get_subtask', task_id: task_id };
        this.setState({ isLoadedIcon: true })
        this.api.command(postData, this.openFollowupEditModal);
    };
    openFollowupEditModal = (result) => {
        Store.updateStore('followup_task', result.result.task);
        this.setState({ isEditFollowUpModalOpen: true, isLoadedIcon: false });
    };

    processMyfollowupTaskData = () => {
        var taskdata = Store.getStoreData('updated_followups');
        let ret = { data: [], columns: [] };
        let i = 0;
        ret.columns = [
            // {Header: '#', accessor: 'index', width: 30, headerStyle: {textAlign: 'left'}},
            {
                Header: 'Status',
                accessor: 'status',
                width: 96,
                headerStyle: { textAlign: 'center' },
                Cell: (cellInfo) => (
                    <MRButton style={{ cursor: 'default', backgroundColor: `${cellInfo.value.color}` }}>
                        {cellInfo.value.text}
                    </MRButton>
                )
            },
            { Header: 'Subject', accessor: 'notes', minWidth: 200, headerStyle: { textAlign: 'left' } },
            { Header: 'Assigned To', accessor: 'assign_to', width: 120, headerStyle: { textAlign: 'left' } },
            {
                Header: 'Due Date',
                accessor: 'due_date',
                width: 100,
                headerStyle: { textAlign: 'center' },
                Cell: (row) => <div style={{ textAlign: 'center' }}>{row.original.due_date}</div>
            },
            {
                Header: 'Action',
                Cell: (row) => (
                    <div>
                        <MRButton
                            style={{ width: '70px', cursor: 'pointer' }}
                            onClick={this.showFollowUp(row.original.id)}
                        >
                            View
                        </MRButton>

                        {(() => {
                            if ((Store.getStoreData('role') == 'fprm' || Store.getStoreData('role') == 'admin_manager' || Store.getStoreData('role') == 'manager')
                                && row.original.status.text !== 'Completed') {
                                return (
                                    <FaEdit
                                        onClick={this.showEditFollowUpModal(row.original.id)}
                                        style={{
                                            cursor: 'pointer',
                                            fontSize: '18px',
                                            marginLeft: '13px',
                                            color: '#b4b5b4',
                                            display: 'inline-block'
                                        }}
                                    />
                                )
                            }
                            return null;
                        })()}
                    </div>
                ),
                headerStyle: { textAlign: 'center', lineHeight: '24px' }
            }
        ];
        for (let d of taskdata) {
            i++;

            let tdata = JSON.parse(d.task_json).action_data;
            let taskdata1 = JSON.parse(d.task_json).task_data;
            let status = 'Pending';
            if (d.last_action === 'COMP_FPR_FOLLOWUP_DISMISS') {
                status = 'Dismissed';
            } else if (d.last_action === 'COMP_FPR_FOLLOWUP_COMPLETE') {
                status = 'Completed';
            }
            let assigntoName = Store.getStoreData('contacts')[taskdata1.cur_assigned_to];
            let notes = tdata[tdata.length - 1].note;//tdata[0].note;
            let due_date_str = d.due_date ? d.due_date : '1970-01-01T00:00:00.000Z';

            const c_date = new Date(due_date_str);
            let day =
                c_date.getDate().toString().length === 1
                    ? '0' + c_date.getDate().toString()
                    : c_date.getDate().toString();
            let month =
                (c_date.getMonth() + 1).toString().length === 1
                    ? '0' + (c_date.getMonth() + 1).toString()
                    : (c_date.getMonth() + 1).toString();
            let year = c_date.getFullYear().toString();
            const due_date = day + '/' + month + '/' + year;

            var dd = parseInt(due_date_str.substring(8, 12));
            var mm = parseInt(due_date_str.substring(5, 7));
            var yyyy = parseInt(due_date_str.substring(0, 4));

            let due_date_status = new Date(yyyy, mm - 1, dd);
            var due_date_status_final = new Date(due_date_status);
            due_date_status_final.setHours(due_date_status.getHours() + 23);
            due_date_status_final.setMinutes(due_date_status.getMinutes() + 59);
            due_date_status_final.setSeconds(due_date_status.getSeconds() + 59);

            let today = new Date();

            let text = due_date_status_final > today ? status : 'Overdue';
            let color = due_date_status_final > today ? '#2E8B57' : '#FF0000';
            if (status === 'Pending') {
                text = due_date_status_final > today ? status : 'Overdue';
                color = due_date_status_final > today ? '#2E8B57' : '#FF0000';
            } else if (status === 'Completed') {
                text = status;
                color = '#2E8B57';
            }
            let elem = {
                index: i,
                assign_to: assigntoName,
                notes: notes,
                due_date: due_date,
                status: { text: text, color: color, id: d.id },
                id: d.id
            };
            ret.data.push(elem);
        }
        return ret;
    };

    // Not used by - cleanup
    showFollowUp = (task_id) => (event) => {
        event.preventDefault();
        let postData = { command: 'get_subtask', task_id: task_id };
        this.setState({ isLoadedIcon: true })
        this.api.command(postData, this.openFollowupModal);
    };

    openFollowupModal = (result) => {
        Store.updateStore('followup_task', result.result.task);
        this.setState({ isEditFollowUpModalView: true, isLoadedIcon: false });
    };

    showfollowtaskModal = (row) => (event) => {
        event.preventDefault();
        Store.updateStore('ftask_index', row.index);
        this.setState({ showModal: true });
    };

    incompleteFollowupTasks = () => {
        const child_task = Store.getStoreData('updated_followups');

        let child_task_incomplete = 0;
        for (let i = 0; i < child_task.length; i++) {
            if (child_task[i].cur_lane == 'COMP_FPR_FOLLOWUP_ASSIGNMENT') child_task_incomplete++;
        }
        return child_task_incomplete;
    };

    submitApprove = (submit_form) => {
        if (submit_form && !this.state.isEnabledForOutStandingFollowUpTasks) {
            if (this.incompleteFollowupTasks()) {
                alert('Please complete any outstanding follow-up tasks associated with this Financial Promotion');
                return;
            }
        }

        if (
            this.state.toggleConfirm && submit_form === 1 && !window.confirm(`Details within the checklist have been changed. Are you sure you want to approve this financial promotion?`)
        ) {
            return;
        }

        this.setState({ ...this.state, submitFromValue: submit_form, disabled: true });
        this.refs.dropZone.uploadFilesFromChild(this.state.binFiles);
    };

    onUploadComplete = async (files_list) => {
        // get previous data
        //debugger;
        let submit_form = this.state.submitFromValue;

        let returnedFiles = JSON.parse(files_list);
        this.setState({ ...this.state, disabled: false });
        const binfiles = this.state.binFiles;


        if (binfiles.length > 0) {
            binfiles.forEach((file) => {
                if (file.hasOwnProperty('bin_file')) {
                    let fileName = file.name;
                    let refID = returnedFiles.find((o) => o.inserted_file_name == fileName)['ref_id'];
                    delete file.bin_file;
                    file.ref_id = refID;
                }
            });
        }


        //debugger;
        let bin_files = [...this.state.cur_files_json, ...binfiles];
        const curmytaskdata = Store.getStoreData('cur_q_modal_data');
        const curtask_jsonValue = JSON.parse(curmytaskdata.task_json);
        const headerinfo = curtask_jsonValue.object_data.headerinfo;
        // current fields
        headerinfo.comments_reviewer = this.state.comments_reviewer;


        let action = curtask_jsonValue.task_data.cur_lane; // submit_form === 0
        if (submit_form === 1) action = 'COMP_FINPROMO_CONFIRMED';
        if (submit_form === 2) action = 'COMP_FINPROMO_WITHDRAWN';
        if (submit_form === 3) action = 'COMP_FINPROMO_REVISION';


        const task_json = {
            task_data: {
                module_id: 'finpromo',
                last_action: curtask_jsonValue.task_data.last_action,
                actiondate: Date.now(),
                system_id: '1001',
                contact_id: curtask_jsonValue.task_data.contact_id,
                cur_lane: action,
                cur_assigned_to: 0,
                task_type: 'COMP_FINPROMO',
                parent_task_id: 0
            },
            object_data: {
                headerinfo,
                ref_id: this.state.Ref_ID
            },
            action_data: {
                action: action,
                actiondate: Date.now(),
                assigned_to: 0,
                cur_lane: action
            }
        };

        // https://freedcamp.com/view/2983627/tasks/panel/task/47102371
        // comment remove readonly
        let comment = Store.getStoreData('comment');

        if (comment === null) {
            comment = this.state.comments == null ? '' : this.state.comments;
        }
        //Utils.isBase64(qtext) ? window.decodeURIComponent(atob(qtext)) : qtext

        let comment_summary = '';
        if (!Utils.isBase64(comment)) {
            comment_summary = btoa(window.encodeURIComponent(comment));
        } else {
            comment_summary = comment
        }

        task_json.object_data.headerinfo['comments'] = comment_summary;
        //end comment remove readonly

        //*********** code for save manager question on Save n Close and revesion
        if (this.state.isReviewDateEnabledFromConfig) {
            if (this.state.isReviewDateEnabled) {
                task_json.object_data['rvw_rqrd_rvwdate'] = this.state.due_by;
                task_json.object_data['review_toggle'] = this.state.isReviewDateEnabled;
                task_json.object_data['rvw_rqrd_assignto'] = this.state.assign_to;
            } else {
                task_json.object_data['review_toggle'] = false;
            }
        }

        if (this.state.isEnabledExpiryDate) {
            task_json.object_data['expiry_toggle'] = this.state.isExpiryDateEnabled;
        }

        //task_json.object_data['expirydt'] = this.state.expiry_due_by; //rvw_rqrd_expirydt
        if (this.state.expiry_date !== null) {
            task_json.object_data['rvw_rqrd_expirydt'] = this.state.expiry_due_by;
        }

        if (this.state.isEnabledExpiryDate && this.state.isExpiryDateEnabled) {
            if (this.state.expiry_date === null) {
                if (this.state.isExpiryDateEnabled) {
                    if (task_json.object_data.hasOwnProperty('rvw_rqrd_expirydt')) {
                        //let exp_date = moment(task_json.object_data.rvw_rqrd_expirydt);
                        //let result = exp_date.isSameOrAfter(moment(new Date()));
                        this.setState({
                            expiry_date: new Date(task_json.object_data.rvw_rqrd_expirydt),
                            expiry_due_by: task_json.object_data.rvw_rqrd_expirydt
                            //isReadOnlyForRevision: true
                        });
                    } else {
                        this.setState({ ...this.state, expiry_date: moment(Date.now() + 24 * 60 * 60 * 1000).format('MM/DD/YYYY') });
                        task_json.object_data['rvw_rqrd_expirydt'] = moment(Date.now() + 24 * 60 * 60 * 1000).format('YYYY/MM/DD');
                    }
                }
            } else {
                if (this.state.isExpiryCalendarClicked == false) {
                    this.setState({ ...this.state, expiry_date: moment(Date.now() + 24 * 60 * 60 * 1000).format('MM/DD/YYYY'), expiry_due_by: moment(Date.now() + 24 * 60 * 60 * 1000).format('YYYY/MM/DD') });
                    task_json.object_data['rvw_rqrd_expirydt'] = moment(Date.now() + 24 * 60 * 60 * 1000).format('YYYY/MM/DD');
                }
            }
        }


        //0=> Save and close
        if ((this.state.curlane == 'COMP_FINPROMO_REGISTERED' || this.state.curlane == 'COMP_FINPROMO_REVISION') && submit_form === 0) {
            headerinfo.managers_questions = this.getChecklistValues();

            if (this.state.isReviewDateEnabledFromConfig && this.state.isReviewDateEnabled) {
                task_json.object_data['rvw_rqrd_rvwdate'] = this.state.due_by;
                task_json.object_data['review_toggle'] = this.state.isReviewDateEnabled;
                task_json.object_data['rvw_rqrd_assignto'] = this.state.assign_to;
            }

            if (this.state.isEnabledExpiryDate && this.state.isEnabledExpiryDate) {
                task_json.object_data['expiry_toggle'] = this.state.isExpiryDateEnabled;
                if (this.state.expiry_date !== null) {
                    task_json.object_data['rvw_rqrd_expirydt'] = this.state.expiry_due_by;
                }
            }


            task_json.object_data.is_managerSaved = true;
            if (curtask_jsonValue.object_data.hasOwnProperty("is_discloser_saved")) {
                task_json.object_data['is_discloser_saved'] = false;
            }

        }
        // 3 Revision
        else if ((this.state.curlane == 'COMP_FINPROMO_REGISTERED' || this.state.curlane == 'COMP_FINPROMO_REVISION') && submit_form === 3) {
            headerinfo.questions = this.getChecklistValues();
            task_json.object_data.is_managerSaved = false;
            if (curtask_jsonValue.object_data.hasOwnProperty("is_discloser_saved")) {
                task_json.object_data['is_discloser_saved'] = true;
            } else {
                task_json.object_data['is_discloser_saved'] = true;
            }
        }

        //if withdrawn then store reason code..
        if (submit_form == 2) {
            if (this.state.selectedReasonCode == 0 && this.state.isWithdrawnReasonCodeEnabled) {
                alert('Please select a reason code');
                return;
            }
            task_json.object_data.reason_codes = this.state.selectedReasonCode;
            // store withdrawn reason so that log should not get override
            task_json.action_data['reason_codes'] = this.state.selectedReasonCode;
        }
        if (submit_form == 3) {
            if (this.state.isReviewDateEnabledFromConfig) {
                if (this.state.isReviewDateEnabled) {
                    task_json.object_data['rvw_rqrd_rvwdate'] = this.state.due_by;
                    task_json.object_data['review_toggle'] = this.state.isReviewDateEnabled;
                    task_json.object_data['rvw_rqrd_assignto'] = this.state.assign_to;
                } else {
                    task_json.object_data['review_toggle'] = false;
                }
            }
            // store approval comment so that log should not get override
            task_json.action_data['comments_reviewer'] = this.state.comments_reviewer;
        }

        if (submit_form == 3 && this.state.curlane == 'COMP_FINPROMO_REVISION') {
            if (this.state.object_data.hasOwnProperty('is_discloser_saved')) {
                if (this.state.object_data.is_discloser_saved == true) {
                    this.setState({ isDiscloserSavedAlertModal: true });
                    return;
                }
            } else {
                task_json.object_data.is_discloser_saved = false;
            }
        }
        //debugger;
        // expiry date is null , review date is present
        if (submit_form == 1 && (this.state.curlane == 'COMP_FINPROMO_REVISION' || this.state.curlane == 'COMP_FINPROMO_REGISTERED')) {
            if (this.state.isReviewDateEnabledFromConfig && this.state.isReviewDateEnabled && this.state.expiry_date != null) {
                if (this.state.assign_to == 0) {
                    alert('Please select a reviewer');
                    this.setState({ disabled: false });
                    return;
                }

                if (this.state.curlane == 'COMP_FINPROMO_REGISTERED' || this.state.curlane == 'COMP_FINPROMO_REVISION') {
                    task_json.task_data.cur_lane = 'COMP_FINPROMO_REVIEW_REQUIRED';
                    task_json.action_data.cur_lane = 'COMP_FINPROMO_REVIEW_REQUIRED';
                    task_json.action_data.action = 'COMP_FINPROMO_REVIEW_REQUIRED';
                    task_json.object_data['rvw_rqrd_rvwdate'] = this.state.due_by;
                    task_json.object_data['rvw_rqrd_assignto'] = this.state.assign_to; //rvw=>review rqrd=>required
                    // store approval comment so that log should not get override
                    if (this.state.isExpiryDateEnabled == true) {
                        task_json.object_data['rvw_rqrd_expirydt'] = this.state.expiry_due_by;
                    }
                    task_json.action_data['comments_reviewer'] = this.state.comments_reviewer;
                    if (this.state.assign_to != 0) {
                        task_json.object_data.rvw_rqrd_assignto = this.state.assign_to;
                        task_json.object_data.rvw_rqrd_rvwdate = this.state.due_by;
                        //create followup task from registered fp and if review date is enabled.
                        let task = {};
                        task.subject = `Review ${this.state.name}-${this.state.taskid}`;
                        task.note = `Review ${this.state.name}-${this.state.taskid}`;
                        task.assignedto = this.state.assign_to;
                        task.dueby = this.state.due_by;
                        task.binFiles = [];

                        // Set state is_disabled to true
                        this.setState({ is_disabled: true });

                        this.followupTaskValueFetch(task);
                    }
                }

            } else {
                if (this.state.isExpiryDateEnabled == false && this.state.isReviewDateEnabled == false) {
                    task_json.task_data.cur_lane = 'COMP_FINPROMO_CONFIRMED';
                    task_json.action_data.cur_lane = 'COMP_FINPROMO_CONFIRMED';
                    task_json.action_data.action = 'COMP_FINPROMO_CONFIRMED';


                    // if (this.state.expiry_date !== null && this.state.isExpiryDateEnabled) {
                    //     task_json.object_data.rvw_rqrd_expirydt = this.state.expiry_due_by;
                    // }
                    // store approval comment so that log should not get override
                    task_json.action_data['comments_reviewer'] = this.state.comments_reviewer;
                    task_json.object_data.headerinfo.comments_reviewer = this.state.comments_reviewer;
                }

                if (this.state.isExpiryDateEnabled == true && this.state.isReviewDateEnabled == false
                    && this.state.expiry_date !== null && this.state.isEnabledExpiryDate) {
                    if (this.state.curlane == 'COMP_FINPROMO_REGISTERED') {
                        task_json.task_data.cur_lane = 'COMP_FINPROMO_CONFIRMED';
                        task_json.action_data.cur_lane = 'COMP_FINPROMO_CONFIRMED';
                        task_json.action_data.action = 'COMP_FINPROMO_CONFIRMED';

                    }
                    if (this.state.curlane == 'COMP_FINPROMO_REVISION') {
                        task_json.task_data.cur_lane = this.state.curlane; //'COMP_FINPROMO_EXPIRED'
                        task_json.action_data.cur_lane = this.state.curlane;
                        task_json.action_data.action = this.state.curlane;

                    }
                    task_json.object_data.rvw_rqrd_expirydt = this.state.expiry_due_by;
                    // store approval comment so that log should not get override
                    task_json.action_data['comments_reviewer'] = this.state.comments_reviewer;
                    task_json.object_data.headerinfo.comments_reviewer = this.state.comments_reviewer;
                }
                if (this.state.isReviewDateEnabledFromConfig && this.state.isReviewDateEnabled == false && this.state.isEnabledExpiryDate == false
                ) {
                    task_json.task_data.cur_lane = 'COMP_FINPROMO_CONFIRMED';
                    task_json.action_data.cur_lane = 'COMP_FINPROMO_CONFIRMED';
                    task_json.action_data.action = 'COMP_FINPROMO_CONFIRMED';


                    // store approval comment so that log should not get override
                    task_json.action_data['comments_reviewer'] = this.state.comments_reviewer;
                    task_json.object_data.headerinfo.comments_reviewer = this.state.comments_reviewer;
                }

                else if (this.state.isReviewDateEnabledFromConfig && this.state.isReviewDateEnabled && this.state.expiry_date == null) {
                    if (this.state.assign_to == 0) {
                        alert('Please select a reviewer');
                        this.setState({ disabled: false });
                        return;
                    }
                    task_json.task_data.cur_lane = 'COMP_FINPROMO_REVIEW_REQUIRED';
                    task_json.action_data.cur_lane = 'COMP_FINPROMO_REVIEW_REQUIRED';
                    task_json.action_data.action = 'COMP_FINPROMO_REVIEW_REQUIRED';
                    task_json.object_data['rvw_rqrd_rvwdate'] = this.state.due_by;
                    task_json.object_data['rvw_rqrd_assignto'] = this.state.assign_to; //rvw=>review rqrd=>required

                    task_json.action_data['comments_reviewer'] = this.state.comments_reviewer;
                    if (this.state.assign_to != 0) {
                        task_json.object_data.rvw_rqrd_assignto = this.state.assign_to;
                        task_json.object_data.rvw_rqrd_rvwdate = this.state.due_by;
                        //create followup task from registered fp and if review date is enabled.
                        let task = {};
                        task.subject = `Review ${this.state.name}-${this.state.taskid}`;
                        task.note = `Review ${this.state.name}-${this.state.taskid}`;
                        task.assignedto = this.state.assign_to;
                        task.dueby = this.state.due_by;
                        task.binFiles = [];
                        this.followupTaskValueFetch(task);
                    }
                }

            }
        }

        if (submit_form == 4 && this.state.curlane == 'COMP_FINPROMO_WITHDRAWN' || this.state.curlane == 'COMP_FINPROMO_EXPIRED') {
            if (this.state.isReviewDateEnabledFromConfig) {
                if (this.state.assign_to == 0) {
                    alert('Please select a reviewer');
                    this.setState({ disabled: false });
                    return;
                }
                task_json.task_data.cur_lane = 'COMP_FINPROMO_REVIEW_REQUIRED';
                task_json.action_data.cur_lane = 'COMP_FINPROMO_REVIEW_REQUIRED';
                task_json.action_data.action = 'COMP_FINPROMO_REVIEW_REQUIRED';
                task_json.object_data['rvw_rqrd_rvwdate'] = this.state.due_by;
                task_json.object_data['rvw_rqrd_assignto'] = this.state.assign_to; //rvw=>review rqrd=>required
            }
            if (this.state.isEnabledExpiryDate && this.state.isExpiryDateEnabled) {
                task_json.object_data['rvw_rqrd_expirydt'] = this.state.expiry_due_by;
            }
            // store approval comment so that log should not get override
            task_json.action_data['comments_reviewer'] = this.state.comments_reviewer;

            if (this.state.isReviewDateEnabledFromConfig && this.state.isReviewDateEnabled) {
                if (this.state.assign_to != 0) {
                    task_json.object_data.rvw_rqrd_assignto = this.state.assign_to;
                    task_json.object_data.rvw_rqrd_rvwdate = this.state.due_by;
                    //create followup task from registered fp and if review date is enabled.
                    let task = {};
                    task.subject = `Review ${this.state.name}-${this.state.taskid}`;
                    task.note = `Review ${this.state.name}-${this.state.taskid}`;
                    task.assignedto = this.state.assign_to;
                    task.dueby = this.state.due_by;
                    task.binFiles = [];
                    this.followupTaskValueFetch(task);
                }
            }
        }

        if (submit_form == 1 && this.state.expiry_date && this.state.isExpiryDateEnabled && this.state.isEnabledExpiryDate &&
            this.state.isReviewDateEnabled == false &&
            (this.state.curlane == 'COMP_FINPROMO_REVIEW_REQUIRED' || this.state.curlane == 'COMP_FINPROMO_CONFIRMED')) {
            task_json.task_data.cur_lane = 'COMP_FINPROMO_CONFIRMED'; //'COMP_FINPROMO_EXPIRED'
            task_json.action_data.cur_lane = 'COMP_FINPROMO_CONFIRMED';
            task_json.action_data.action = 'COMP_FINPROMO_CONFIRMED';
            //task_json.object_data.rvw_rqrd_assignto = curtask_jsonValue.object_data.rvw_rqrd_assignto;
            //task_json.object_data.rvw_rqrd_rvwdate = curtask_jsonValue.object_data.rvw_rqrd_rvwdate;
            task_json.action_data['comments_reviewer'] = this.state.review_comments;
            task_json.object_data.headerinfo['comments_reviewer'] = this.state.review_comments;
            if (this.state.expiry_date !== null && this.state.isExpiryDateEnabled) {
                task_json.object_data.rvw_rqrd_expirydt = this.state.expiry_due_by;
            }
        }

        // when user click confirm button while having review required status
        if (submit_form === 1 && this.state.curlane === 'COMP_FINPROMO_REVIEW_REQUIRED' && this.state.isExpiryDateEnabled == false) {
            if (this.state.isReviewDateEnabled && this.state.isReviewDateEnabledFromConfig) {
                if (this.state.assign_to == 0) {
                    this.setState({ disabled: false });
                    alert('Please select a reviewer');
                    return;
                } else if (this.state.review_date == null) {
                    this.setState({ disabled: false });
                    alert('Please set a review date');
                    return;
                } else {
                    //only changes  reviewee and review date field
                    task_json.task_data.cur_lane = 'COMP_FINPROMO_REVIEW_REQUIRED';
                    task_json.action_data.cur_lane = 'COMP_FINPROMO_REVIEW_REQUIRED';
                    task_json.action_data.action = 'COMP_FINPROMO_REVIEW_REQUIRED';
                    //store additional information(reviewee, review date, expiry date etc)
                    task_json.object_data.rvw_rqrd_assignto = this.state.assign_to; //rvw=>review rqrd=>required
                    task_json.object_data.rvw_rqrd_rvwdate = this.state.due_by;
                    // store approval comment so that log should not get override
                    task_json.action_data['comments_reviewer'] = this.state.review_comments;
                    task_json.object_data.headerinfo['comments_reviewer'] = this.state.review_comments;

                    //create followup task from registered fp and if review date is enabled.

                    let task = {};
                    task.subject = `Review ${this.state.name}-${this.state.taskid}`;
                    task.note = `Review ${this.state.name}-${this.state.taskid}`;
                    task.assignedto = this.state.assign_to;
                    task.dueby = this.state.due_by;
                    task.binFiles = [];
                    //this.followupTaskValueFetch(task);   //====>>>>> uncomment for followup task
                    this.followupTaskValueFetchNew(task);   //====>>>>> uncomment for followup task
                }
            }
        } else {
            //debugger;
            if (submit_form === 1 && this.state.curlane === 'COMP_FINPROMO_REVIEW_REQUIRED' && this.state.isExpiryDateEnabled && this.state.expiry_date !== null) {
                if (this.state.isReviewDateEnabled && this.state.isReviewDateEnabledFromConfig) {
                    if (this.state.assign_to == 0) {
                        alert('Please select a reviewer');
                        this.setState({ disabled: false });
                        return;
                    } else if (this.state.review_date == null) {
                        alert('Please set a review date');
                        this.setState({ disabled: false });
                        return;
                    } else {
                        //only changes  reviewee and review date field
                        task_json.task_data.cur_lane = 'COMP_FINPROMO_REVIEW_REQUIRED';
                        task_json.action_data.cur_lane = 'COMP_FINPROMO_REVIEW_REQUIRED';
                        task_json.action_data.action = 'COMP_FINPROMO_REVIEW_REQUIRED';
                        //store additional information(reviewee, review date, expiry date etc)
                        task_json.object_data.rvw_rqrd_assignto = this.state.assign_to; //rvw=>review rqrd=>required
                        task_json.object_data.rvw_rqrd_rvwdate = this.state.due_by;
                        if (this.state.isExpiryDateEnabled == true && this.state.expiry_date !== null) {
                            task_json.object_data['rvw_rqrd_expirydt'] = this.state.expiry_due_by;
                        }
                        // store approval comment so that log should not get override
                        task_json.action_data['comments_reviewer'] = this.state.review_comments;
                        task_json.object_data.headerinfo['comments_reviewer'] = this.state.review_comments;

                        //create followup task from registered fp and if review date is enabled.

                        let task = {};
                        task.subject = `Review ${this.state.name}-${this.state.taskid}`;
                        task.note = `Review ${this.state.name}-${this.state.taskid}`;
                        task.assignedto = this.state.assign_to;
                        task.dueby = this.state.due_by;
                        task.binFiles = [];
                        //this.followupTaskValueFetch(task);   //====>>>>> uncomment for followup task
                        this.followupTaskValueFetchNew(task);   //====>>>>> uncomment for followup task
                    }
                }
            }
            if (submit_form === 1 && this.state.curlane === 'COMP_FINPROMO_REVIEW_REQUIRED' && this.state.isExpiryDateEnabled && this.state.expiry_date == null) {
                if (this.state.isReviewDateEnabled && this.state.isReviewDateEnabledFromConfig) {
                    if (this.state.assign_to == 0) {
                        alert('Please select a reviewer');
                        this.setState({ disabled: false });
                        return;
                    } else if (this.state.review_date == null) {
                        alert('Please set a review date');
                        this.setState({ disabled: false });
                        return;
                    } else {
                        //only changes  reviewee and review date field
                        task_json.task_data.cur_lane = 'COMP_FINPROMO_REVIEW_REQUIRED';
                        task_json.action_data.cur_lane = 'COMP_FINPROMO_REVIEW_REQUIRED';
                        task_json.action_data.action = 'COMP_FINPROMO_REVIEW_REQUIRED';
                        //store additional information(reviewee, review date, expiry date etc)
                        task_json.object_data.rvw_rqrd_assignto = this.state.assign_to; //rvw=>review rqrd=>required
                        task_json.object_data.rvw_rqrd_rvwdate = this.state.due_by;
                        // store approval comment so that log should not get override
                        task_json.action_data['comments_reviewer'] = this.state.review_comments;
                        task_json.object_data.headerinfo['comments_reviewer'] = this.state.review_comments;

                        //create followup task from registered fp and if review date is enabled.

                        let task = {};
                        task.subject = `Review ${this.state.name}-${this.state.taskid}`;
                        task.note = `Review ${this.state.name}-${this.state.taskid}`;
                        task.assignedto = this.state.assign_to;
                        task.dueby = this.state.due_by;
                        task.binFiles = [];
                        //this.followupTaskValueFetch(task);   //====>>>>> uncomment for followup task
                        this.followupTaskValueFetchNew(task);   //====>>>>> uncomment for followup task
                    }
                }
            }
            if (submit_form == 1 && this.state.curlane == 'COMP_FINPROMO_REVIEW_REQUIRED' && this.state.isEnabledExpiryDate == false && this.state.isReviewDateEnabled == false) {
                task_json.task_data.cur_lane = 'COMP_FINPROMO_CONFIRMED';
                task_json.action_data.cur_lane = 'COMP_FINPROMO_CONFIRMED';
                task_json.action_data.action = 'COMP_FINPROMO_CONFIRMED';
                // store approval comment so that log should not get override
                task_json.object_data.headerinfo['comments_reviewer'] = this.state.review_comments;
                task_json.action_data['comments_reviewer'] = this.state.review_comments;

            }
        }


        const from_api = curtask_jsonValue.object_data.from_api || 0;

        const postData = {
            command: 'update_task',
            task_json,
            bin_files,
            from_api,
            submit: submit_form,
            taskid: this.state.taskid,
            discloser_id: this.state.discloserid
        };

        //        debugger;
        //alert(postData.task_json.task_data.cur_lane + "--->>" + postData.task_json.action_data['comments_reviewer']);
        this.setState({ isLoadedIcon: true })
        this.api.command(postData, this.handleSubmitAfterClick);
        this.setState({ submit_form, is_disabled: false });
    }

    handleSubmitAfterClick = (result) => {
        //this.setState({ isLoadedIcon: false })
        this.props.closeModalAndRefresh();
    }

    //
    submitWithdraw = (taskid) => {
        if (window.confirm(`Are you sure you want to WITHDRAW task (FPR-${taskid})?`)) {
            if (this.state.isWithdrawnReasonCodeEnabled) {
                this.setState({ ...this.state, is_disabled: true })
            } else {
                this.setState({ ...this.state, is_disabled: false })
                this.submitApprove(2);
            }
        }
    };

    processUpdateRemoveTask = (result) => {
        alert('Financial Promotion updated Successfully');
        const postData = { command: 'get_task', task_id: this.state.taskid };
        this.setState({ isLoadedIcon: true })
        this.api.command(postData, this.processTaskData);
    };

    processTaskData = (result) => {
        this.setState({ isLoadedIcon: false })
        Store.updateStore('cur_q_modal_data', result.result.parenttask);
    };

    showalertFollowup = (event) => {
        event.preventDefault();
        alert('Adding follow up tasks to a removed Financial Promotion is not allowed.');
    };

    filesLoaded = (files) => {
        const contact_id = Store.getStoreData('contact_id');
        for (let i = 0; i < files.length; i++) {
            files[i].name = files[i].name.replace('&', '');
            if (!('user_id' in files[i])) {
                files[i].user_id = contact_id;
                files[i].upload_date = Date.now();
            }
        }
        this.setState({ binFiles: files });
    };

    toggleComments(e) {

        //console.log('inside toggleComments');
        this.setState({ toggleConfirm: true });
        const field = e.target.name.split('_').pop();
        const el = document.getElementById(`group_question_comments_${field}`);
        el.style.display = 'block';
    }


    //code for radio button check change
    switchRadio = (key) => (event) => {
        event.preventDefault();
        let { isReviewDateEnabled } = this.state;
        this.setState({ ...this.state, isReviewDateEnabled: !isReviewDateEnabled });
    }

    switchRadioExpiryDate = (key) => (event) => {
        event.preventDefault();
        let { isExpiryDateEnabled } = this.state;
        this.setState({ ...this.state, isExpiryDateEnabled: !isExpiryDateEnabled });
    }

    onDateChangeDueBy = (date) => {
        let curmonth = parseInt(date.getMonth());
        let currmonth = curmonth + 1;
        var dateStr =
            currmonth >= 10
                ? date.getFullYear() + '/' + currmonth + '/' + date.getDate()
                : date.getFullYear() + '/0' + currmonth + '/' + date.getDate();
        this.setState({ due_by: dateStr, review_date: date });
    };

    onExpiryDateChanged = (date) => {
        let curmonth = parseInt(date.getMonth());
        let currmonth = curmonth + 1;
        var dateStr2 =
            currmonth >= 10
                ? date.getFullYear() + '/' + currmonth + '/' + date.getDate()
                : date.getFullYear() + '/0' + currmonth + '/' + date.getDate();
        this.setState({ expiry_due_by: dateStr2, expiry_date: date, isExpiryCalendarClicked: true });
    }

    handleChangeDropDown = (event) => {
        event.preventDefault();
        this.setState({ ...this.state, assign_to: event.target.value });
    }

    alertHandler = async (val, evt) => {
        if (val == true) {
            let data = { ...this.state.object_data };
            delete data.is_discloser_saved;
            await this.setState({ object_data: data, isDiscloserSavedAlertModal: null });
            this.submitApprove(3);
        } else {
            await this.setState({ isDiscloserSavedAlertModal: null });
        }
    }

    closeViewEditFollowUpModal = () => {
        this.setState({ isEditFollowUpModalView: false });
    }

    closeEditFollowUpModal = () => {
        this.setState({ isEditFollowUpModalOpen: false });
    }

    generateKey = (pre) => {
        return `${pre}_${new Date().getTime()}`;
    }


    render() {
        // console.log('this.state.checklists.questions==>', this.state.checklists.questions[this.state.checklist]);
        // console.log('this.state.checklist ===>', this.state.checklist);
        // console.log('this.state.questions ===>', this.state.questions);


        let role = Store.getStoreData('role'); // adding for checking Review Date field
        //console.log('role ==>', role);
        const curmytaskdata = Store.getStoreData('cur_q_modal_data');
        const task_json = JSON.parse(curmytaskdata.task_json);
        let data = task_json.object_data.headerinfo;

        //const promotion = this.state.promotions[data.promotion].title;
        let promotion = "";
        //debugger;
        if (typeof this.state.promotions[data.promotion] != 'undefined') {
            if (this.state.promotions[data.promotion].isDelete == 0) {
                promotion = this.state.promotions[data.promotion].title
            }
        }

        const discloserDate = moment(curmytaskdata.due_date).format('DD/MM/YYYY');
        const discloserName = Store.getStoreData('contacts')[curmytaskdata.contact_id];
        //const discloserName = Store.getStoreData('reviewee_list').find(user => user.ID == curmytaskdata.contact_id).ContactName;
        const updatedFollowups = Store.getStoreData('updated_followups');

        if (!this.state.ready) {
            return <div />;
        }

        return (
            <div>
                {
                    (() => {
                        if (this.state.isLoadedIcon) {
                            return <div><CSLLoader style={{ position: 'absolute' }} /></div>
                        }
                    })()
                }
                <MRModalContainer>
                    {(() => {
                        if (this.state.isDiscloserSavedAlertModal == true) {
                            return (
                                <AlertBox alertParam={{
                                    title: 'Alert',
                                    ok_text: 'Yes',
                                    cancel_text: 'No',
                                    confirm: true,
                                    alertHandler: this.alertHandler,
                                    message: 'The discloser has made some updates to this Financial Promotion.' +
                                        ' Sending for revision will overwrite these changes, are you sure you want to continue?'
                                }} />
                            )
                        }
                    })()}

                    {(() => {
                        if (this.state.isEditFollowUpModalView) {
                            return <FollowupModal closeModal={this.closeViewEditFollowUpModal} />
                        }

                        if (this.state.isEditFollowUpModalOpen) {
                            return <EditFollowUpModal closeModal={this.closeEditFollowUpModal} />
                        }
                    })()}

                    <MRModalHeader>
                        {(() => {
                            if (this.state.curlane == 'COMP_FINPROMO_REVIEW_REQUIRED') {
                                return (
                                    <MRModalHeaderText>{this.state.Ref_ID} - Review</MRModalHeaderText>
                                )
                            }
                            return (
                                <MRModalHeaderText>{this.state.Ref_ID} - Approval</MRModalHeaderText>
                            )
                        })()}

                        <MRModalHeaderCloseBtn onClick={this.closeView}>
                            <FaTimes />
                        </MRModalHeaderCloseBtn>
                        <ClearBoth />
                    </MRModalHeader>

                    <MRModalBody style={{ display: this.state.toggleView ? 'none' : 'block' }}>
                        <MRDataContainer>
                            <MRModalEditDetailslBtn
                                style={{ display: this.state.toggleEdit ? 'inherit' : 'none' }}
                                onClick={() => this.props.switchToTaskModal(true)}
                            >
                                Edit Details
                            </MRModalEditDetailslBtn>

                            <ModalContainerGrid>
                                <ModalNameLabelDiv>Added by</ModalNameLabelDiv>
                                <ModalNameInputDiv>{discloserName}</ModalNameInputDiv>
                                <ModalNameLabelDiv>Added Date</ModalNameLabelDiv>
                                <ModalNameInputDiv>{discloserDate}</ModalNameInputDiv>
                                <ModalNameLabelDiv>Promotion Name</ModalNameLabelDiv>
                                <ModalNameInputDiv>{data.name}</ModalNameInputDiv>
                                <ModalNameLabelDiv>Description</ModalNameLabelDiv>
                                <DescriptionInputDiv>
                                    {(() => {
                                        let qtext = data.description;
                                        if (Utils.isBase64(qtext)) {
                                            qtext = window.decodeURIComponent(atob(qtext));
                                        }
                                        return (
                                            <div
                                                dangerouslySetInnerHTML={{__html: qtext}}
                                                style={{
                                                    textAlign: 'justify',
                                                    fontFamily: "'Montserrat', sans-serif",
                                                    fontSize: '13px'
                                                }}
                                            />
                                        );
                                    })()}
                                </DescriptionInputDiv>
                                <ModalNameLabelDiv>Promotion Type</ModalNameLabelDiv>
                                <ModalNameInputDiv>{promotion}</ModalNameInputDiv>
                                <React.Fragment>
                                    <ModalNameLabelDiv>Attachments</ModalNameLabelDiv>
                                    <ModalNameInputDiv>
                                        <ul style={{paddingLeft: '0'}}>
                                            {this.state.cur_files_json.map((file, index) => (
                                                <DownloadLinkLi key={index} onClick={() => this.handleDownload(index)}>
                                                    {file.name}
                                                </DownloadLinkLi>
                                            ))}
                                        </ul>
                                    </ModalNameInputDiv>
                                </React.Fragment>
                                <ModalNameLabelDiv>Promotion URL</ModalNameLabelDiv>
                                <ModalNameInputDiv>
                                    {/* split url (seperated using ,; or space)*/}
                                    {data.urlLink.split(/[;, ]/).map((link, index) => {
                                        if (link != '') {
                                            return (
                                                <React.Fragment key={link}>
                                                    {/* <li key={index} style={{ listStyleType: 'none' }}> */}
                                                    <a
                                                        style={{textDecoration: 'none', display: 'block'}}
                                                        href={Utils.URLsanityCheck(link)}
                                                        target="_blank" rel="noreferrer"
                                                    >
                                                        {link}
                                                    </a>
                                                    {/* </li> */}
                                                    &nbsp;&nbsp;
                                                </React.Fragment>
                                            );
                                        }
                                    })}
                                </ModalNameInputDiv>
                                <ModalNameLabelDiv>Comments</ModalNameLabelDiv>
                                <CommentInputDiv>
                                    {(() => {
                                        let qtext = data.comments !== undefined ? data.comments : '';
                                        if (Utils.isBase64(qtext)) {
                                            qtext = window.decodeURIComponent(atob(qtext));
                                        }
                                        return (
                                            <div
                                                dangerouslySetInnerHTML={{__html: qtext}}
                                                style={{
                                                    fontFamily: "'Montserrat', sans-serif",
                                                    fontSize: '13px',
                                                    margin: '0px'
                                                }}
                                            />
                                        );
                                    })()}
                                </CommentInputDiv>
                                {
                                    (() => {
                                        if (this.state.assign_data.additional_fields.length === 0) {
                                            return null;
                                        }

                                        let custom_html = [];
                                        this.state.assign_data.additional_fields.forEach((item) => {
                                            const field = <React.Fragment>
                                                <ModalNameLabelDiv
                                                    key={`name_${item.nickname}`}>{item.nickname}</ModalNameLabelDiv>
                                                    <span key={`value_${item.value}`}
                                                          dangerouslySetInnerHTML={{__html: item.value == 'other' ? item.other_value : item.value}}
                                                          style={{
                                                              fontFamily: "'Montserrat', sans-serif",
                                                              fontSize: '13px',
                                                              margin: '0px',
                                                              marginTop: '9px',
                                                              zIndex: '1'
                                                          }}
                                                    ></span>
                                                </React.Fragment>
                                                custom_html.push(field);
                                            })
                                            return custom_html;
                                    })()
                                }
                                {(() => {
                                    if (this.state.curlane == "COMP_FINPROMO_WITHDRAWN") {
                                        return (
                                            <React.Fragment>
                                                <ModalNameLabelDiv>Withdrawn Reason</ModalNameLabelDiv>
                                                <ModalNameInputDiv>
                                                    {/*{this.state.object_data.hasOwnProperty('reason_codes') ? this.state.reasonCodes[this.state.object_data.reason_codes].title : ''}*/}
                                                    {(() => {
                                                        if (this.state.object_data.hasOwnProperty('reason_codes')) {
                                                            if (this.state.object_data.reason_codes !== '') {
                                                                return (
                                                                    this.state.reasonCodes[this.state.object_data.reason_codes].title
                                                                )
                                                            } else {
                                                                return ''
                                                            }
                                                        }
                                                    })()}

                                                </ModalNameInputDiv>

                                            </React.Fragment>
                                        )
                                    }
                                    return null;
                                })()}

                                {
                                    (() => {
                                            if ((role === 'fprm' || role == 'admin_manager' || role == 'manager') && this.state.curlane == 'COMP_FINPROMO_REVIEW_REQUIRED') {
                                                return (
                                                    <React.Fragment>
                                                        <ModalNameLabelDiv>Review Date</ModalNameLabelDiv>
                                                        <ModalNameInputDiv>{moment(this.state.object_data.rvw_rqrd_rvwdate).format("DD/MM/YYYY")}</ModalNameInputDiv>

                                                        <ModalNameLabelDiv>Reviewer</ModalNameLabelDiv>
                                                        <ModalNameInputDiv>{Store.getStoreData('contacts')[this.state.object_data.rvw_rqrd_assignto]}</ModalNameInputDiv>

                                                        {(() => {
                                                            if (this.state.expiry_date !== null) {
                                                                return (
                                                                    <React.Fragment>
                                                                        <ModalNameLabelDiv>Expiry
                                                                            Date</ModalNameLabelDiv>
                                                                        <ModalNameInputDiv>{moment(this.state.expiry_date).format('DD/MM/YYYY')}</ModalNameInputDiv>
                                                                    </React.Fragment>
                                                                )
                                                            }

                                                            return null;
                                                        })()}


                                                        <ModalNameLabelDiv>Approval Comments</ModalNameLabelDiv>
                                                        <ModalNameInputDiv>{this.state.comments_reviewer}</ModalNameInputDiv>
                                                    </React.Fragment>
                                                )
                                            }
                                            return null;
                                        }
                                    )()
                                }

                                {
                                    (() => {
                                            if ((role === 'fprm' || role == 'admin_manager' || role == 'manager') && this.state.curlane == 'COMP_FINPROMO_EXPIRED') {
                                                return (
                                                    <React.Fragment>
                                                        {(() => {
                                                            if (this.state.expiry_due_by !== null) {
                                                                return (
                                                                    <React.Fragment>
                                                                        <ModalNameLabelDiv>Expiry
                                                                            Date</ModalNameLabelDiv>
                                                                        <ModalNameInputDiv>{moment(this.state.expiry_due_by).format('DD/MM/YYYY')}</ModalNameInputDiv>
                                                                    </React.Fragment>
                                                                )
                                                            }

                                                            return null;
                                                        })()}
                                                    </React.Fragment>
                                                )
                                            }
                                            return null;
                                        }
                                    )()
                                }
                            </ModalContainerGrid>
                        </MRDataContainer>


                        <MRTasksContainer>
                            {/*review date for fp manager*/}
                            {(() => {
                                if ((role === 'fprm' || role == 'admin_manager' || role == 'manager') &&
                                    (this.state.curlane == 'COMP_FINPROMO_REGISTERED'
                                        || this.state.curlane == 'COMP_FINPROMO_REVISION'
                                        || this.state.curlane == 'COMP_FINPROMO_REVIEW_REQUIRED'
                                        || this.state.curlane == 'COMP_FINPROMO_WITHDRAWN'
                                        || this.state.curlane == 'COMP_FINPROMO_EXPIRED'
                                    )) {
                                    return (
                                        <React.Fragment>
                                            {
                                                (() => {
                                                    if (this.state.isReviewDateEnabledFromConfig) {
                                                        if (this.state.isReviewDateEnabled) {
                                                            return (
                                                                <React.Fragment>
                                                                    <GeneralLabel>{this.state.curlane == 'COMP_FINPROMO_REVIEW_REQUIRED' ? 'Set next review date' : 'Approval Required?'}</GeneralLabel>
                                                                    <RadioBlock>
                                                                        <SelectedRadio>Yes</SelectedRadio>
                                                                        <UnselectedRadio
                                                                            onClick={this.switchRadio('configure_review_date_enable')}>No</UnselectedRadio>
                                                                    </RadioBlock>
                                                                </React.Fragment>
                                                            );
                                                        } else {
                                                            return (
                                                                <React.Fragment>
                                                                    <GeneralLabel>{this.state.curlane == 'COMP_FINPROMO_REVIEW_REQUIRED' ? 'Set next review date' : 'Approval Required?'}</GeneralLabel>
                                                                    <RadioBlock>
                                                                        <UnselectedRadio
                                                                            onClick={this.switchRadio('configure_review_date_enable')}>Yes</UnselectedRadio>
                                                                        <SelectedRadio>No</SelectedRadio>
                                                                    </RadioBlock>
                                                                </React.Fragment>
                                                            );
                                                        }
                                                    }
                                                })()
                                            }
                                            {
                                                (() => {
                                                    if (this.state.isReviewDateEnabledFromConfig && this.state.isReviewDateEnabled) {
                                                        return (
                                                            <React.Fragment>
                                                                <CSLDateCover>
                                                                    <DatePicker
                                                                        onChange={this.onDateChangeDueBy}
                                                                        clearIcon={null}
                                                                        // calendarIcon={null}
                                                                        calendarIcon={<FaCalendarAlt
                                                                            style={{ color: '#243E57' }} />}
                                                                        locale={'en-GB'}
                                                                        value={this.state.review_date == null ? new Date() : this.state.review_date}
                                                                    />
                                                                </CSLDateCover>

                                                                <MRModalLabel>Select Approver</MRModalLabel>
                                                                <MRModalSelect
                                                                    onChange={this.handleChangeDropDown}
                                                                    value={this.state.assign_to}
                                                                    name="assigned_to"
                                                                    readOnly={this.state.isReadOnlyForRevision}
                                                                >
                                                                    {this.state.admin_fp_managers != null && (
                                                                        <option key={0} value={0}>
                                                                            Select
                                                                        </option>
                                                                    )}
                                                                    {this.state.admin_fp_managers.map((reviewer) => (
                                                                        <option key={reviewer.ID}
                                                                            value={reviewer.ID}>
                                                                            {reviewer.ContactName}
                                                                        </option>
                                                                    ))}
                                                                </MRModalSelect>
                                                            </React.Fragment>
                                                        )
                                                    }
                                                })()
                                            }
                                        </React.Fragment>
                                    )
                                }
                                return null;
                            })()}

                            {(() => {
                                if ((role === 'fprm' || role == 'admin_manager' || role == 'manager')
                                    && this.state.isEnabledExpiryDate && this.state.curlane !== 'COMP_FINPROMO_WITHDRAWN'
                                    && this.state.curlane !== 'COMP_FINPROMO_EXPIRED') {
                                    return (
                                        <React.Fragment>
                                            <GeneralLabel>Expiry Date</GeneralLabel>
                                            {
                                                (() => {
                                                    if (this.state.isExpiryDateEnabled) {
                                                        return (
                                                            <React.Fragment>
                                                                <RadioBlock>
                                                                    <SelectedRadio>Yes</SelectedRadio>
                                                                    <UnselectedRadio
                                                                        onClick={this.switchRadioExpiryDate('configure_expiry_date_enable')}>No</UnselectedRadio>
                                                                </RadioBlock>
                                                            </React.Fragment>
                                                        );
                                                    } else {
                                                        return (
                                                            <React.Fragment>
                                                                <RadioBlock>
                                                                    <UnselectedRadio
                                                                        onClick={this.switchRadioExpiryDate('configure_expiry_date_enable')}>Yes</UnselectedRadio>
                                                                    <SelectedRadio>No</SelectedRadio>
                                                                </RadioBlock>
                                                            </React.Fragment>
                                                        );
                                                    }

                                                })()
                                            }
                                            {
                                                (() => {
                                                    if (this.state.isExpiryDateEnabled && this.state.isEnabledExpiryDate) {
                                                        return (
                                                            <CSLDateCover>
                                                                <DatePicker
                                                                    onChange={this.onExpiryDateChanged}
                                                                    clearIcon={null}
                                                                    //calendarIcon={null}
                                                                    calendarIcon={<FaCalendarAlt
                                                                        style={{ color: '#243E57' }} />}
                                                                    locale={'en-GB'}
                                                                    id="date"
                                                                    name="date"
                                                                    minDate={moment().add(1, 'days').toDate()}
                                                                    value={this.state.expiry_date == null ? new Date(Date.now() + 24 * 60 * 60 * 1000) : this.state.expiry_date}
                                                                />
                                                            </CSLDateCover>
                                                        )
                                                    } else {
                                                        return null;
                                                    }
                                                })()
                                            }
                                        </React.Fragment>
                                    )
                                } else {
                                    return null
                                }

                            })()}


                            <MRModalLabel
                                style={{ marginTop: '50px' }}>{this.state.curlane == 'COMP_FINPROMO_REVIEW_REQUIRED' ? 'Review Comments' : 'Approval Comments'}</MRModalLabel>

                            {(() => {
                                if (this.state.curlane == 'COMP_FINPROMO_REVIEW_REQUIRED') {
                                    return (
                                        <MRModalTextarea
                                            rows="5"
                                            name="finpromo_comments_review_required"
                                            readOnly={this.state.readOnly}
                                            value={this.state.review_comments}
                                            onChange={(e) => this.setState({ review_comments: e.target.value })}
                                        />
                                    )
                                }

                                {
                                    return (() => {
                                        if ((role === 'fprm' || role == 'admin_manager' || role == 'manager') && this.state.curlane !== 'COMP_FINPROMO_REVIEW_REQUIRED') {
                                            return (
                                                <MRModalTextarea
                                                    rows="5"
                                                    name="finpromo_comments_reviewer"
                                                    readOnly={this.state.curlane == 'COMP_FINPROMO_EXPIRED' || this.state.curlane == 'COMP_FINPROMO_WITHDRAWN' ? true : this.state.readOnly}
                                                    value={this.state.comments_reviewer}
                                                    onChange={(e) => this.setState({ comments_reviewer: e.target.value })}
                                                />
                                            )
                                        }
                                    })()
                                }

                                // );

                            })()}

                            <ModalNameLabelDiv>Attachments</ModalNameLabelDiv>
                            <ModalNameInputDiv>
                                <Dropzone
                                    onFilesAdded={this.filesLoaded}
                                    disabled={this.state.readOnly}
                                    initFiles={this.state.cur_files_json}
                                    addnew={!this.state.readOnly}
                                    onUploadComplete={this.onUploadComplete}
                                    //onUploadStatus = {this.onUploadStatus}
                                    ref="dropZone"
                                />
                            </ModalNameInputDiv>
                            <hr style={{ marginTop: '20px', color: '#CCCFD4' }} />
                            <MRFollowUpTasksContainer>
                                <OverviewInfoHeader>
                                    <div style={{ float: 'left', color: '#ffffff', fontWeight: '600' }}>
                                        Follow up Tasks
                                    </div>
                                    {!this.state.readOnly && (
                                        <div
                                            style={{
                                                float: 'right',
                                                color: '#ffffff',
                                                fontSize: '17px',
                                                cursor: 'pointer'
                                            }}
                                            onClick={this.showAddFollowupTaskModal(-1)}
                                        >
                                            <FaPlusCircle />
                                        </div>
                                    )}
                                    <ClearBoth />
                                </OverviewInfoHeader>
                                {this.state.showModal && (
                                    <FollowupTask
                                        followuptaskvalue={this.state.followupTask}
                                        discloserId={this.state.discloserid}
                                        taskId={this.state.taskid}
                                        revieweeList={this.state.review_list}
                                        closeFollowupTaskModal={this.closeFollowupModal}
                                        refreshTaskValue={this.followupTaskValueFetch}
                                    />
                                )}
                                <OverviewInfoBody>
                                    {updatedFollowups.length !== 0 ? (
                                        <CSLTable
                                            add={false}
                                            processData={this.processMyfollowupTaskData}
                                            headerText={''}
                                            tableRows="5"
                                        />
                                    ) : (
                                        <div style={{
                                            textAlign: 'center',
                                            fontStyle: 'italic',
                                            color: '#dddddd'
                                        }}>
                                            There are no follow up tasks assigned.
                                        </div>
                                    )}
                                </OverviewInfoBody>
                            </MRFollowUpTasksContainer>
                        </MRTasksContainer>
                    </MRModalBody>

                    {/* checklist pane     */
                    }
                    <MRModalBody style={{ display: this.state.toggleView ? 'block' : 'none' }}>
                        <MRTasksContainer>
                            <MRModalLabel style={{ marginTop: '30px' }}>Checklist</MRModalLabel>
                            <MRModalSelect id="checklist_dropdown" name="field_checklist" value={this.state.checklist} readOnly>

                                {/* remove empty checklists */}
                                {this.state.checklists.sets.map((checklist) => {
                                    let checklist_id = checklist.id; //new_list_checklist
                                    if (this.state.checklists.questions[checklist_id].length > 0 && checklist.isActive === true) {
                                        return (
                                            <option key={checklist.id} value={checklist.id}>
                                                {checklist.title}
                                            </option>
                                        );
                                    }
                                })}
                            </MRModalSelect>
                            <MRModalListContainer>
                                <MRModalLabel
                                    style={{ marginTop: '30px', flexGrow: '1' }}>Questions</MRModalLabel>
                                <div style={{ width: '120px', display: 'flex', flexDirection: 'row' }}>
                                    <MRModalLabel id="yes"
                                        style={{ marginTop: '30px', flex: '1 1 auto' }}>Yes</MRModalLabel>
                                    <MRModalLabel id="no"
                                        style={{ marginTop: '30px', flex: '1 1 auto' }}>No</MRModalLabel>
                                    <MRModalLabel id="na"
                                        style={{ marginTop: '30px', flex: '1 1 auto' }}>N/A</MRModalLabel>
                                </div>
                            </MRModalListContainer>

                            {(() => {
                                if (this.state.managers_questions !== null && this.state.curlane === 'COMP_FINPROMO_REVISION') {
                                    return (
                                        <div style={{ maxHeight: '600px', overflow: 'auto' }} >
                                            {this.state.checklist &&
                                                this.state.checklists.questions[this.state.checklist].map((question) => {
                                                    let question_id = question.question_id;
                                                    //debugger;
                                                    const result = {};

                                                    const answerFound = Object.values(this.state.managers_questions).find(x => question.question_id === x.question_id);
                                                    if (answerFound) {
                                                        result[question.question_id] = {
                                                            question_id: question.question_id,
                                                            title: question.title,
                                                            answer: answerFound.answer,
                                                            comments: answerFound.comments
                                                        }
                                                    }

                                                    return <div key={`key_${question_id}`}>
                                                        <MRModalListContainer>
                                                            {(() => {
                                                                if (typeof result[question_id] !== 'undefined' && result[question_id].answer != null) {
                                                                    return (
                                                                        <MRModalLabel
                                                                            style={{ flexGrow: '1' }}>{result[question_id].title}</MRModalLabel>
                                                                    )
                                                                } else {
                                                                    return null;
                                                                }
                                                            })()}

                                                            <div
                                                                style={{ width: '120px', flex: '0 0 120px' }}>

                                                                {(() => {
                                                                    if (typeof result[question_id] !== 'undefined' && result[question_id].answer != null) {

                                                                        return (
                                                                            <RadioGroup
                                                                                name={`field_question_${question.id}`}
                                                                                disabled={this.state.readOnly}
                                                                                value={result[question_id].answer}
                                                                                onChange={this.toggleComments}
                                                                            >
                                                                                <RadioOption id="yes" value="yes" />
                                                                                <RadioOption id="no" value="no" />
                                                                                <RadioOption id="na" value="na" />
                                                                            </RadioGroup>
                                                                        )
                                                                    } else {
                                                                        return null;
                                                                    }

                                                                })()}

                                                            </div>
                                                        </MRModalListContainer>

                                                        {
                                                            (() => {
                                                                if (result[question_id] !== undefined && result[question_id].answer != null) {
                                                                    return (
                                                                        <div
                                                                            id={`group_question_comments_${question.id}`}
                                                                            style={{
                                                                                margin: '20px 0',
                                                                                display: result[question_id].comments ? 'block' : 'none'
                                                                            }}
                                                                        >
                                                                            <MRModalLabel>Comments</MRModalLabel>
                                                                            <MRModalTextarea
                                                                                rows="5"
                                                                                readOnly={this.state.readOnly}
                                                                                name={`field_question_comments_${question.id}`}
                                                                                defaultValue={result[question_id].comments}
                                                                                onChange={this.toggleComments}
                                                                            />
                                                                        </div>
                                                                    )
                                                                }
                                                            })()
                                                        }

                                                    </div>
                                                })}
                                        </div>
                                    )
                                } else {
                                    return (
                                        <div style={{ maxHeight: '600px', overflow: 'auto' }}>
                                            {this.state.checklist &&
                                                this.state.checklists.questions[this.state.checklist].map((question, idx) => {

                                                    let question_id = question.question_id;

                                                    const result = {};

                                                    const answerFound = Object.values(this.state.questions).find(x => question.question_id === x.question_id);
                                                    if (answerFound) {
                                                        result[question.question_id] = {
                                                            question_id: question.question_id,
                                                            title: question.title,
                                                            answer: answerFound.answer,
                                                            comments: answerFound.comments
                                                        }
                                                    }

                                                    return (
                                                        <div key={`${question_id}`}>
                                                            <MRModalListContainer>
                                                                {(() => {
                                                                    if (typeof result[question_id] !== 'undefined' && result[question_id].answer != null) {
                                                                        return (
                                                                            <MRModalLabel
                                                                                style={{ flexGrow: '1' }}>{result[question_id].title}</MRModalLabel>
                                                                        )
                                                                    } else {
                                                                        return null;
                                                                    }
                                                                })()}

                                                                <div
                                                                    style={{ width: '120px', flex: '0 0 120px' }}>
                                                                    {(() => {
                                                                        if (typeof result[question_id] !== 'undefined' && result[question_id].answer != null) {
                                                                            //console.log('result[question_id].answer==>', result[question_id].answer)
                                                                            return (
                                                                                <RadioGroup
                                                                                    key={`${question_id}_${idx}_${result[question_id].answer}`}
                                                                                    name={`field_question_${question.id}`}
                                                                                    disabled={this.state.readOnly}
                                                                                    value={typeof result[question_id].answer !== 'undefined' ? result[question_id].answer : Object.keys(this.state.questions).reduce((arr, key) => arr.concat(this.state.questions[key]), []).find((q) => {
                                                                                        return q.question_id == question_id
                                                                                    }).answer}
                                                                                    onChange={this.toggleComments}
                                                                                >
                                                                                    <RadioOption id="yes" value="yes" />
                                                                                    <RadioOption id="no" value="no" />
                                                                                    <RadioOption id="na" value="na" />
                                                                                </RadioGroup>
                                                                            )
                                                                        } else {
                                                                            return null;
                                                                        }

                                                                    })()}

                                                                </div>
                                                            </MRModalListContainer>


                                                            {
                                                                (() => {
                                                                    if (result[question_id] !== undefined && result[question_id].answer != null) {
                                                                        return (
                                                                            <div
                                                                                id={`group_question_comments_${question.id}`}
                                                                                style={{
                                                                                    margin: '20px 0',
                                                                                    display: result[question_id].comments ? 'block' : 'none'
                                                                                }}
                                                                            >
                                                                                <MRModalLabel>Comments</MRModalLabel>
                                                                                <MRModalTextarea
                                                                                    rows="5"
                                                                                    readOnly={this.state.readOnly}
                                                                                    name={`field_question_comments_${question.id}`}
                                                                                    defaultValue={result[question_id].comments}
                                                                                    onChange={this.toggleComments}
                                                                                />
                                                                            </div>
                                                                        )
                                                                    }
                                                                })()
                                                            }

                                                        </div>
                                                    )
                                                })}
                                        </div>
                                    )
                                }
                            })()}


                            <hr style={{ width: '100%' }} />
                            <MRModalLabel style={{ marginTop: '20px' }}>Comments</MRModalLabel>
                            {
                                (() => {
                                    let qtext = this.state.comments;
                                    qtext = Utils.isBase64(qtext) ? window.decodeURIComponent(atob(qtext)) : qtext;
                                    // return <HtmlEditor readOnly={this.state.curlane == 'COMP_FINPROMO_REGISTERED' ? false : true} content={qtext} section_id="1"
                                    //     id="field_comments" />;
                                    return <HtmlEditor readOnly={false} content={qtext} section_id="1"
                                        id="field_comments" />;
                                })()
                            }
                        </MRTasksContainer>
                    </MRModalBody>

                    <MRModalFooter>
                        {!this.state.toggleView ? (
                            <MRModalNextBtn disabled={this.state.is_disabled}
                                onClick={() => this.toggleView()}>
                                CHECKLIST <FaArrowAltCircleRight />
                            </MRModalNextBtn>
                        ) : (
                            <MRModalNextBtn disabled={this.state.is_disabled}
                                onClick={() => this.toggleView()}>
                                <FaArrowAltCircleLeft /> DETAILS
                            </MRModalNextBtn>
                        )}

                        {(() => {
                            if ((this.state.curlane == 'COMP_FINPROMO_WITHDRAWN' || this.state.curlane == 'COMP_FINPROMO_EXPIRED') && this.state.isReviewDateEnabledFromConfig) {
                                return (
                                    <MRModalReviseBtn onClick={() => this.submitApprove(4)}>
                                        Review
                                    </MRModalReviseBtn>
                                )
                            } else {
                                if ((this.state.curlane == 'COMP_FINPROMO_WITHDRAWN' || this.state.curlane == 'COMP_FINPROMO_EXPIRED') && this.state.isReviewDateEnabledFromConfig == false) {
                                    return null;
                                } else if (this.state.curlane === 'COMP_FINPROMO_CONFIRMED' && this.state.isEnabledExpiryDate) {
                                    return (
                                        <MRModalUpdateBtn
                                            // commented because after confirm, user can add expiry date and then save
                                            // style={{display: this.state.readOnly ? 'none' : 'inherit'}}
                                            onClick={() => this.submitApprove(1)}
                                            disabled={this.state.is_disabled}
                                        >
                                            Update
                                        </MRModalUpdateBtn>
                                    )
                                } else {
                                    return (
                                        this.state.curlane !== 'COMP_FINPROMO_CONFIRMED' &&
                                        <MRModalNextBtn
                                            // commented because after confirm, user can add expiry date and then save
                                            // style={{display: this.state.readOnly ? 'none' : 'inherit'}}
                                            onClick={() => this.submitApprove(1)}
                                            disabled={this.state.is_disabled}
                                        >
                                            Confirm
                                        </MRModalNextBtn>
                                    )
                                }
                            }

                        })()}

                        {(() => {
                            if (this.state.curlane == 'COMP_FINPROMO_REVIEW_REQUIRED') {
                                return null
                            }
                            return (
                                <MRModalReviseBtn
                                    style={{ display: this.state.readOnly ? 'none' : 'inherit' }}
                                    onClick={() => this.submitApprove(3)}
                                    disabled={this.state.is_disabled}
                                >
                                    Revise
                                </MRModalReviseBtn>
                            )
                        })()}

                        <MRModalSaveCloseBtn
                            style={{ display: this.state.readOnly ? 'none' : 'inherit' }}
                            onClick={() => this.submitApprove(0)}
                            disabled={this.state.is_disabled}
                        >
                            Save and Close
                        </MRModalSaveCloseBtn>
                        <MRModalWithdrawBtn
                            style={{ display: this.state.ctaWithdraw ? 'inherit' : 'none' }}
                            onClick={() => this.submitWithdraw(this.state.taskid)}
                        >
                            WITHDRAW
                        </MRModalWithdrawBtn>
                        <MRModalCancelBtn disabled={this.state.is_disabled}
                            onClick={() => this.closeView()}>Cancel</MRModalCancelBtn>
                        <ClearBoth />
                    </MRModalFooter>
                    {
                        (() => {
                            if (this.state.is_disabled && this.state.isWithdrawnReasonCodeEnabled) {
                                return (
                                    <React.Fragment>
                                        <MRModalLabelReasonCode style={{ marginTop: '20px' }}>Withdraw
                                            Reason (*)</MRModalLabelReasonCode>
                                        <MRModalSelectReasonCodes
                                            name="select_reason_code"
                                            value={this.state.selectedReasonCode}
                                            onChange={(e) => this.setState({ selectedReasonCode: e.target.value })}
                                        >
                                            {this.state.reasonCodes != null && (
                                                <option key={0} value={0}>
                                                    Select
                                                </option>
                                            )}
                                            {Object.keys(this.state.reasonCodes).map((rc) => {

                                                if (this.state.reasonCodes[rc].isActive == 1 && this.state.reasonCodes[rc].isDelete == 0) {
                                                    return (<option key={rc} value={rc}>
                                                        {this.state.reasonCodes[rc].title}
                                                    </option>)
                                                }
                                            })}
                                        </MRModalSelectReasonCodes>
                                        <div style={{ marginRight: '17px', marginBottom: '17px' }}>

                                            <MRModalNextBtn
                                                onClick={() => this.submitApprove(2)}>
                                                Confirm
                                            </MRModalNextBtn>

                                            <MRModalCancelBtnWithdrawPopup
                                                onClick={() => this.setState({ is_disabled: false })}>Cancel
                                            </MRModalCancelBtnWithdrawPopup>

                                        </div>
                                    </React.Fragment>
                                )
                            }
                            return null;
                        })()
                    }
                </MRModalContainer>
            </div >
        );
    }
}

export default TaskApprovalModal;
