import React from 'react';
import { FaTimes, FaEdit } from 'react-icons/fa';
import { AiFillDelete } from "react-icons/ai";
import styled from 'styled-components';
import { APICall, Store, Utils, CSLLoader } from '../Common';
// import {ClearBoth, CSLTable} from '@mcc-regtech/core-library';
import ClearBoth from '../Common/ClearBoth';
import CSLTable from '../../Components/Common/CSLTable';
import CSLTableSinglePage from '../../Components/Common/CSLTableSinglePage';
import AdvancedFilter from './AdvancedFilter';
import TaskApprovalModal from './TaskApprovalModal';
import TaskModal from './TaskModal';
import FollowupModal from './FollowupModal';
import FollowupListModal from './FollowupListModal';
import EditFollowUpModal from "./EditFollowUpModal";
import moment from 'moment';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import XcelLoader from '../Common/XcelLoader.js';


const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const fileExtension = '.xlsx';

const MRButton = styled.button`
  box-sizing: border-box;
  background-color: #37ada7;
  color: #ffffff;
  border: 1px solid #37ada7;
  width: 84px;
  font-size: 13px;
  height: 25px;
  border-radius: 4px;
`;
const OverviewInfoHeader = styled.div`
  // padding: 0 5px;
  background-color: #ffffff;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  width: 98%;
  margin: 0 auto;
  margin-top: 30px;
`;
const OverviewInfoBody = styled.div`
  // padding: 5px;
  background-color: #ffffff;
  border: 1px solid #d7dddf;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  width: 98%;
  margin: 0 auto;
  margin-bottom: 30px;
`;
const OverviewInfoHeaderTitle = styled.div`
  position: relative;
  padding: 12px;
  float: left;
  color: #323232;
  font-weight: 600;
  margin: 2px;
`;
const ExportButton = styled.button`
  box-sizing: border-box;
  background-color: #213e61;
  color: #f2f2f2;
  border: 1px solid #213e61;
  width: 125px;
  font-size: 14px;
  height: 32px;
  border-radius: 5px;
  margin-right: 3px;
  margin-top: 19px;
  cursor: pointer;
`;
const ExportsLine = styled.div`
  color: #04ada8;
  font-weight: 600;
  font-style: italic;
  margin-top: 28px;
  margin-left: 15px;
`;

const ExportButtonDisable = styled.button`
  box-sizing: border-box;
  background-color: #213e61a1;
  color: #F2F2F2;
  border: 1px solid #213e61a1;
  width: 125px;
  font-size: 14px;
  height: 32px;
  border-radius: 5px;
  margin-right: 3px;
  margin-top: 19px;
`;

class TasksList extends React.Component {
    state = {
        contact_id: Store.getStoreData('contact_id'),
        contacts: Store.getStoreData('contacts'),
        promotions: Store.getStoreData('module_config').promotion_types,
        statuses: Store.getStoreData('module_config').status,
        general_config: Store.getStoreData('module_config').general,
        isclear: false,
        isLoaded: false,
        pending_tasks: [],
        advanceFilter: false,
        show_modal: null,
        //open edit follow up modal
        show_edit_followup_modal: null,
        filterRequestedFromDate: new Date(),
        filterRequestedToDate: new Date(),
        ready: false,
        tasksList: null,
        tasksListFilter: null,
        curID: 0,
        userRole: '',
        isEditedFromReviewRequired: null,
        isEnabledExpiryDate: Store.getStoreData('module_config').general.add_expiry_date_for_financial_promotions,
        isDeletionComplete: true,
        isReviewDateEnabledFromConfig: Store.getStoreData('module_config').general.add_review_date_for_finpromo,
        isLoadedIcon: false
    };

    constructor (props) {

        super(props);
        this.api = new APICall();
    }
    onHeaderClick = (id) => {
        console.log('visiblePages header clicked', id)
    }
    onPageClick = (page) => {
        console.log('visiblePages page clicked', page)
    }

    initialLoading = async () => {
        const myTasks = this.props.mytaskData;
        //console.log('myTasks ==>', myTasks)
        let userrole = Store.getStoreData('role');
        //console.log('userrole==>', userrole);
        //console.log(`Current companyID ${Store.getStoreData('curCompanyID')}`);
        let updateTaskNew = []
        if ((userrole === 'fprm' || userrole === 'team')) {
            //debugger;
            if ('general' in Store.getStoreData('module_config') && 'show_parent_company_financial_promotions' in Store.getStoreData('module_config').general && Store.getStoreData('module_config').general.show_parent_company_financial_promotions) {
                myTasks.forEach((value) => {
                    // if (value.company_id === Store.getStoreData('curCompanyID')) {
                    //     updateTaskNew.push(value);
                    // } else {
                    //     //added else block show parent+child companies
                    //     updateTaskNew.push(value);
                    // }
                    updateTaskNew.push(value);
                })
            } else {
                myTasks.forEach((value) => {
                    //console.log(value.company_id);
                    //console.log(Store.getStoreData('curCompanyID'));
                    if (value.company_id !== Store.getStoreData('curCompanyID')) {
                        updateTaskNew.push(value);
                    }
                })
            }

        } else {
            updateTaskNew = myTasks;
        }

        console.log('updateTaskNew ==>', updateTaskNew)
        //console.log(myTasks);
        const updateFromDate = new Date(
            this.state.filterRequestedFromDate.getFullYear(),
            this.state.filterRequestedFromDate.getMonth() - 1,
            1
        );
        const task_id = this.props.taskId;
        await this.setState({
            // tasksList: myTasks,
            // tasksListFilter: myTasks,
            tasksList: updateTaskNew,
            tasksListFilter: updateTaskNew,
            filterRequestedFromDate: updateFromDate,
            userRole: Store.getStoreData('role')
        });

        if (task_id > 0) this.goToTaskUrlBare(task_id);
        else this.setState({ ready: true });
    }

    componentDidMount() {
        this.initialLoading();
    }

    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            this.initialLoading();
        }
    }


    changeClear = () => this.setState({ isclear: false });

    goToTaskUrlBare = (taskID) => {
        const mytaskdata = Store.getStoreData('mytasks');
        let checkid = false;
        for (let i = 0; i < mytaskdata.length; i++) if (mytaskdata[i].id.toString() === taskID) checkid = true;
        if (checkid) this.showApprovalModal(taskID);
        else this.currentfollowupModal(taskID);
    };

    currentfollowupModal = (task_id) => {
        //event.preventDefault();
        const postData = { command: 'get_subtask', task_id: task_id };
        this.api.command(postData, this.openCurrentFollowupModal);
    };

    openCurrentFollowupModal = (result) => {
        Store.updateStore('followup_task', result.result.task);
        this.setState({ showModal: 'triggerFollowupModal', ready: true });
    };

    goToTaskUrl = (row) => (event) => {
        event.preventDefault();
        this.showApprovalModal(row);
    };

    showApprovalModal = (row) => {
        if (this.props.taskId > 0) {
            const mytaskdata = Store.getStoreData('mytasks');
            let curlane = '';
            for (let i = 0; i < mytaskdata.length; i++) {
                if (mytaskdata[i].id === parseInt(row)) curlane = mytaskdata[i].cur_lane;
            }
            Store.updateStore('q_index', 1);
            const postData = { command: 'get_task', task_id: row };
            this.api.command(postData, this.taskDataModal);
        } else {
            Store.updateStore('q_index', row.index);
            const postData = { command: 'get_task', task_id: row.original.id };
            this.setState({ isLoadedIcon: true })
            this.api.command(postData, this.taskDataModal);
        }
    };

    taskDataModal = (result) => {
        console.log("result==>", result)
        const task = result.result.parenttask;
        const modal =
            this.state.userRole === 'team' || task.cur_lane === 'COMP_FINPROMO_TEAMSAVE'
                ? 'triggerTaskModal'
                : 'triggerTaskApprovalModal';

        Store.updateStore('cur_q_modal_data', task);
        Store.updateStore('updated_followups', result.result.childtask.result);
        this.setState({ showModal: modal, ready: true, isLoadedIcon: false });
    };

    processDataTableSP = () => {
        let contact_lists = Store.getStoreData('reviewee_list');
        const mytaskdata = this.state.tasksList;
        console.log('mytaskdata ==>', mytaskdata)
        //console.log('Inside processDataTable mytaskdata is', this.state.promotions);
        // console.log('contact_lists===>',contact_lists);
        const ret = { data: [], columns: [] };
        let i = 0;
        const has_gc = Store.getStoreData('has_gc');
 
        ret.columns = [
            {
                className: 'react-table__status-colour',
                accessor: 'statusColour',
                width: 20,
                filterable: false,
                sortable: false,
                headerStyle: { textAlign: 'center', lineHeight: '24px' },
                Cell: (row) => <div style={{ backgroundColor: `${row.original.statusColour}`, height: '34px' }} />
            },
            {
                Header: 'STATUS',
                accessor: 'statusLabel',
                width: 100,
                headerStyle: { textAlign: 'center', lineHeight: '24px' },
                style: { lineHeight: '24px' }
            },
            {
                Header: 'FPR ID',
                accessor: 'refID',
                minWidth: 70,
                headerStyle: { textAlign: 'center', lineHeight: '24px' },
                Cell: (row) => (
                    <div
                        style={{
                            fontFamily: 'monospace',
                            fontSize: '14px',
                            textDecoration: 'underline',
                            color: '#00B5B2',
                            textAlign: 'center',
                            cursor: 'pointer',
                            lineHeight: '24px'
                        }}
                        onClick={this.goToTaskUrl(row)}
                    >
                        {row.original.refID.split('-')[1].replace(/^0+/, '')}
                    </div>
                )
            },
            {
                Header: 'NAME',
                accessor: 'name',
                minWidth: 130,
                headerStyle: { textAlign: 'left', lineHeight: '24px' },
                style: { lineHeight: '24px' }
            },
            {
                Header: 'TYPE',
                accessor: 'promotion',
                minWidth: 130,
                headerStyle: { textAlign: 'left', lineHeight: '24px' },
                style: { lineHeight: '24px' }
            },
            {
                Header: 'Added By',
                accessor: 'addedBy',
                minWidth: 130,
                headerStyle: { textAlign: 'left', lineHeight: '24px' },
                style: { lineHeight: '24px' }
            },
            {
                Header: 'Company',
                accessor: 'company',
                minWidth: 130,
                headerStyle: { textAlign: 'left' },
                show: has_gc ? true : false
            },

            {
                Header: 'Added Date',
                accessor: 'addedDate',
                minWidth: 100,
                headerStyle: { textAlign: 'center', lineHeight: '24px' },
                style: { lineHeight: '24px', textAlign: 'center' },
                sortMethod: (a, b) => {
                    if (a === b) {
                        return 0;
                    }
                    let adue = a.split("/");
                    const aReverse = parseInt(adue[2]) * 10000 + parseInt(adue[1]) * 100 + parseInt(adue[0]);
                    let bbdue = b.split("/");
                    const bReverse = parseInt(bbdue[2]) * 10000 + parseInt(bbdue[1]) * 100 + parseInt(bbdue[0]);
                    return aReverse > bReverse ? 1 : -1;
                }

            },
            {
                Header: 'Tasks',
                width: 60,
                accessor: 'tasks',
                headerStyle: { textAlign: 'center', lineHeight: '24px' },
                Cell: (row) => (
                    <div
                        style={{
                            textDecoration: 'underline',
                            color: '#00B5B2',
                            textAlign: 'center',
                            lineHeight: '24px',
                            cursor: 'pointer'
                        }}
                        onClick={this.goToFollowupTask(row)}
                    >
                        {row.original.no_of_followup}
                    </div>
                )
            },
            {
                Header: 'Approved By',
                accessor: 'approvedBy',
                minWidth: 130,
                headerStyle: { textAlign: 'left', lineHeight: '24px' },
                style: { lineHeight: '24px' }
            },
            {
                Header: 'Approved Date',
                accessor: 'approvedDate',
                minWidth: 100,
                headerStyle: { textAlign: 'center', lineHeight: '24px' },
                style: { lineHeight: '24px', textAlign: 'center' }
                ,
                sortMethod: (a, b) => {
                    if (a === b) {
                        return 0;
                    }
                    let adue = a.split("/");
                    const aReverse = parseInt(adue[2]) * 10000 + parseInt(adue[1]) * 100 + parseInt(adue[0]);
                    let bbdue = b.split("/");
                    const bReverse = parseInt(bbdue[2]) * 10000 + parseInt(bbdue[1]) * 100 + parseInt(bbdue[0]);
                    return aReverse > bReverse ? 1 : -1;
                }
            },
            //review date for confirmed and review required FP
            {
                Header: 'Review Date',
                accessor: 'reviewDate',
                minWidth: 100,
                headerStyle: { textAlign: 'center', lineHeight: '24px' },
                style: { lineHeight: '24px', textAlign: 'center' },
                show: this.state.isReviewDateEnabledFromConfig ? true : false
                ,
                sortMethod: (a, b) => {
                    if (a === b) {
                        return 0;
                    }
                    let adue = a.split("/");
                    const aReverse = parseInt(adue[2]) * 10000 + parseInt(adue[1]) * 100 + parseInt(adue[0]);
                    let bbdue = b.split("/");
                    const bReverse = parseInt(bbdue[2]) * 10000 + parseInt(bbdue[1]) * 100 + parseInt(bbdue[0]);
                    return aReverse > bReverse ? 1 : -1;
                }
            },
            {
                Header: 'Review By',
                accessor: 'reviewBy',
                minWidth: 130,
                headerStyle: { textAlign: 'left', lineHeight: '24px' },
                style: { lineHeight: '24px' },
                show: this.state.isReviewDateEnabledFromConfig ? true : false
            },
            {
                Header: 'Expiry Date',
                accessor: 'expiryDate',
                minWidth: 100,
                headerStyle: { textAlign: 'center', lineHeight: '24px' },
                style: { lineHeight: '24px', textAlign: 'center' },
                show: this.state.isEnabledExpiryDate ? true : false
                ,
                sortMethod: (a, b) => {
                    if (a === b) {
                        return 0;
                    }
                    let adue = a.split("/");
                    const aReverse = parseInt(adue[2]) * 10000 + parseInt(adue[1]) * 100 + parseInt(adue[0]);
                    let bbdue = b.split("/");
                    const bReverse = parseInt(bbdue[2]) * 10000 + parseInt(bbdue[1]) * 100 + parseInt(bbdue[0]);
                    return aReverse > bReverse ? 1 : -1;
                }
            },
            {
                Header: 'Action',
                width: 120,
                filterable: false,
                sortable: false,
                headerStyle: { textAlign: 'center', lineHeight: '24px' },
                Cell: (row) => (
                    <div style={{ display: 'inline-flex' }}>
                        <MRButton style={{ cursor: 'pointer', height: '22px' }} onClick={this.goToTaskUrl(row)}>
                            View
                        </MRButton>
                        {this.state.userRole === 'admin_manager' && (
                            <AiFillDelete
                                onClick={this.deleteTask(row.original.id)}
                                style={{
                                    cursor: 'pointer',
                                    fontSize: '18px',
                                    marginLeft: '5px',
                                    color: 'black',
                                    display: 'inline-block',
                                }}
                            />
                        )}
                    </div>
                )
            }
        ];
        for (let d of mytaskdata) {
            i++;

            let numberOfFollowup = 0;
            let review_Date = '-';
            let review_By = '-';
            let expiry_Date = '-';
            if (d.child_task_ids !== null) numberOfFollowup = JSON.parse(d.child_task_ids).length;

            let task_json = typeof d.task_json === 'string' ? JSON.parse(d.task_json) : d.task_json;

            let nooffollowup = numberOfFollowup < 10 ? '0' + numberOfFollowup.toString() : numberOfFollowup;

            let gc_assoc = Store.getStoreData('gc_assoc');
            let gcid = parseInt(d.group_company_id) % 100000;
            let gcname = '';
            if (gcid in gc_assoc) gcname = gc_assoc[gcid];

            const headerinfo = task_json.object_data.headerinfo;
            if (d.id == 21307) {
                //debugger;
                let a = this.state.promotions;
                let b = [headerinfo.promotion];
            }
            //console.log('headerinfo.promotion', headerinfo.promotion);
            //review date column for confirmed / review required
            if (d.cur_lane == 'COMP_FINPROMO_CONFIRMED' || d.cur_lane == 'COMP_FINPROMO_REVIEW_REQUIRED' || d.cur_lane == 'COMP_FINPROMO_EXPIRED') {
                if (task_json.object_data.hasOwnProperty("rvw_rqrd_rvwdate")) {
                    if (task_json.object_data["rvw_rqrd_rvwdate"]) {
                        review_Date = moment(task_json.object_data["rvw_rqrd_rvwdate"]).format("DD/MM/YYYY");
                    }
                }
                try {
                    if (task_json.object_data.hasOwnProperty("rvw_rqrd_assignto")) {
                        if (task_json.object_data["rvw_rqrd_assignto"]) {
                            let userid = task_json.object_data["rvw_rqrd_assignto"];
                            review_By = contact_lists.find(user => user.ID == userid).ContactName;//task_json.object_data["rvw_rqrd_assignto"];//this.state.contacts[task_json.object_data["rvw_rqrd_assignto"]];
                        }
                    }
                }
                catch (e) {
                    //console.log('inside catch');
                    let userid = task_json.object_data["rvw_rqrd_assignto"];
                    //console.log('userid ====>', userid);

                }

                if (task_json.object_data.hasOwnProperty("expiryToggle")) {
                    let isExpiry = task_json.object_data["expiryToggle"];
                    if (isExpiry) {
                        if (task_json.object_data.hasOwnProperty("expirydt")) {
                            if (task_json.object_data["expirydt"]) {
                                expiry_Date = moment((task_json.object_data["expirydt"])).format("DD/MM/YYYY");
                            }
                        }
                    }
                }
            }

            let elem = {
                id: d.id,
                status: d.cur_lane,
                statusLabel: this.state.statuses[d.cur_lane].label,
                statusColour: this.state.statuses[d.cur_lane].colour,
                refID: task_json.object_data.ref_id,
                name: headerinfo.name,
                promotion: headerinfo.promotion && headerinfo.promotion !== "" && typeof this.state.promotions[headerinfo.promotion] !== 'undefined' ? this.state.promotions[headerinfo.promotion].isDelete == 0 ? this.state.promotions[headerinfo.promotion].title : '' : '',
                addedBy: this.state.contacts[d.contact_id],
                company: gcname,
                addedDate: moment.unix(d.created_at).format('DD/MM/YYYY'),
                approvedBy: this.state.contacts[task_json.object_data.headerinfo.approvedBy],
                approvedDate: headerinfo.approvedDate,
                reviewDate: review_Date,
                reviewBy: review_By,
                expiryDate: expiry_Date,
                comments_reviewer: headerinfo.comments_reviewer || '',
                no_of_followup: nooffollowup,
                group_company_name: gcname
            };
            //console.log("elem.id==>", elem.id);
            ret.data.push(elem);
        }
        // sorting using dateadded desc..
        let actualData = ret.data;
        actualData.sort((a, b) => Number(b.id) - Number(a.id));
        Store.updateStore('table_cur_data', actualData);
        return ret;
    }

    processDataTable = () => {
        let contact_lists = Store.getStoreData('reviewee_list');
        const mytaskdata = this.state.tasksList;
        console.log('mytaskdata ==>', mytaskdata)
        //console.log('Inside processDataTable mytaskdata is', this.state.promotions);
        // console.log('contact_lists===>',contact_lists);
        const ret = { data: [], columns: [] };
        let i = 0;
        const has_gc = Store.getStoreData('has_gc');
        ret.columns = [
            {
                Header: '',
                accessor: 'statusColour',
                className: 'react-table__status-colour',
                width: 20,
                filterable: false,
                headerStyle: { textAlign: 'center', lineHeight: '24px' },
                Cell: (row) => <div style={{ backgroundColor: `${row.original.statusColour}`, height: '34px' }} />
            },
            {
                Header: 'STATUS',
                accessor: 'statusLabel',
                width: 100,
                headerStyle: { textAlign: 'center', lineHeight: '24px' },
                style: { lineHeight: '24px' }
            },
            {
                Header: 'FPR ID',
                accessor: 'refID',
                minWidth: 70,
                headerStyle: { textAlign: 'center', lineHeight: '24px' },
                Cell: (row) => (
                    <div
                        style={{
                            fontFamily: 'monospace',
                            fontSize: '14px',
                            textDecoration: 'underline',
                            color: '#00B5B2',
                            textAlign: 'center',
                            cursor: 'pointer',
                            lineHeight: '24px'
                        }}
                        onClick={this.goToTaskUrl(row)}
                    >
                        {row.original.refID.split('-')[1].replace(/^0+/, '')}
                    </div>
                )
            },
            {
                Header: 'NAME',
                accessor: 'name',
                minWidth: 130,
                headerStyle: { textAlign: 'left', lineHeight: '24px' },
                style: { lineHeight: '24px' }
            },
            {
                Header: 'TYPE',
                accessor: 'promotion',
                minWidth: 130,
                headerStyle: { textAlign: 'left', lineHeight: '24px' },
                style: { lineHeight: '24px' }
            },
            {
                Header: 'Added By',
                accessor: 'addedBy',
                minWidth: 130,
                headerStyle: { textAlign: 'left', lineHeight: '24px' },
                style: { lineHeight: '24px' }
            },
            {
                Header: 'Company',
                accessor: 'company',
                minWidth: 130,
                headerStyle: { textAlign: 'left' },
                show: has_gc ? true : false
            },

            {
                Header: 'Added Date',
                accessor: 'addedDate',
                minWidth: 100,
                headerStyle: { textAlign: 'center', lineHeight: '24px' },
                style: { lineHeight: '24px', textAlign: 'center' },
                sortMethod: (a, b) => {
                    if (a === b) {
                        return 0;
                    }
                    let adue = a.split("/");
                    const aReverse = parseInt(adue[2]) * 10000 + parseInt(adue[1]) * 100 + parseInt(adue[0]);
                    let bbdue = b.split("/");
                    const bReverse = parseInt(bbdue[2]) * 10000 + parseInt(bbdue[1]) * 100 + parseInt(bbdue[0]);
                    return aReverse > bReverse ? 1 : -1;
                }

            },
            {
                Header: 'Tasks',
                width: 60,
                headerStyle: { textAlign: 'center', lineHeight: '24px' },
                Cell: (row) => (
                    <div
                        style={{
                            textDecoration: 'underline',
                            color: '#00B5B2',
                            textAlign: 'center',
                            lineHeight: '24px',
                            cursor: 'pointer'
                        }}
                        onClick={this.goToFollowupTask(row)}
                    >
                        {row.original.no_of_followup}
                    </div>
                )
            },
            {
                Header: 'Approved By',
                accessor: 'approvedBy',
                minWidth: 130,
                headerStyle: { textAlign: 'left', lineHeight: '24px' },
                style: { lineHeight: '24px' }
            },
            {
                Header: 'Approved Date',
                accessor: 'approvedDate',
                minWidth: 100,
                headerStyle: { textAlign: 'center', lineHeight: '24px' },
                style: { lineHeight: '24px', textAlign: 'center' }
                ,
                sortMethod: (a, b) => {
                    if (a === b) {
                        return 0;
                    }
                    let adue = a.split("/");
                    const aReverse = parseInt(adue[2]) * 10000 + parseInt(adue[1]) * 100 + parseInt(adue[0]);
                    let bbdue = b.split("/");
                    const bReverse = parseInt(bbdue[2]) * 10000 + parseInt(bbdue[1]) * 100 + parseInt(bbdue[0]);
                    return aReverse > bReverse ? 1 : -1;
                }
            },
            //review date for confirmed and review required FP
            {
                Header: 'Review Date',
                accessor: 'reviewDate',
                minWidth: 100,
                headerStyle: { textAlign: 'center', lineHeight: '24px' },
                style: { lineHeight: '24px', textAlign: 'center' },
                show: this.state.isReviewDateEnabledFromConfig ? true : false
                ,
                sortMethod: (a, b) => {
                    if (a === b) {
                        return 0;
                    }
                    let adue = a.split("/");
                    const aReverse = parseInt(adue[2]) * 10000 + parseInt(adue[1]) * 100 + parseInt(adue[0]);
                    let bbdue = b.split("/");
                    const bReverse = parseInt(bbdue[2]) * 10000 + parseInt(bbdue[1]) * 100 + parseInt(bbdue[0]);
                    return aReverse > bReverse ? 1 : -1;
                }
            },
            {
                Header: 'Review By',
                accessor: 'reviewBy',
                minWidth: 130,
                headerStyle: { textAlign: 'left', lineHeight: '24px' },
                style: { lineHeight: '24px' },
                show: this.state.isReviewDateEnabledFromConfig ? true : false
            },
            {
                Header: 'Expiry Date',
                accessor: 'expiryDate',
                minWidth: 100,
                headerStyle: { textAlign: 'center', lineHeight: '24px' },
                style: { lineHeight: '24px', textAlign: 'center' },
                show: this.state.isEnabledExpiryDate ? true : false
                ,
                sortMethod: (a, b) => {
                    if (a === b) {
                        return 0;
                    }
                    let adue = a.split("/");
                    const aReverse = parseInt(adue[2]) * 10000 + parseInt(adue[1]) * 100 + parseInt(adue[0]);
                    let bbdue = b.split("/");
                    const bReverse = parseInt(bbdue[2]) * 10000 + parseInt(bbdue[1]) * 100 + parseInt(bbdue[0]);
                    return aReverse > bReverse ? 1 : -1;
                }
            },
            {
                Header: 'Action',
                width: 120,
                filterable: false,
                headerStyle: { textAlign: 'center', lineHeight: '24px' },
                Cell: (row) => (
                    <div style={{ display: 'inline-flex' }}>
                        <MRButton style={{ cursor: 'pointer', height: '22px' }} onClick={this.goToTaskUrl(row)}>
                            View
                        </MRButton>
                        {this.state.userRole === 'admin_manager' && (
                            <AiFillDelete
                                onClick={this.deleteTask(row.original.id)}
                                style={{
                                    cursor: 'pointer',
                                    fontSize: '18px',
                                    marginLeft: '5px',
                                    color: 'black',
                                    display: 'inline-block',
                                }}
                            />
                        )}
                    </div>
                )
            }
        ];
        for (let d of mytaskdata) {
            i++;

            let numberOfFollowup = 0;
            let review_Date = '-';
            let review_By = '-';
            let expiry_Date = '-';
            if (d.child_task_ids !== null) numberOfFollowup = JSON.parse(d.child_task_ids).length;

            let task_json = typeof d.task_json === 'string' ? JSON.parse(d.task_json) : d.task_json;

            let nooffollowup = numberOfFollowup < 10 ? '0' + numberOfFollowup.toString() : numberOfFollowup;

            let gc_assoc = Store.getStoreData('gc_assoc');
            let gcid = parseInt(d.group_company_id) % 100000;
            let gcname = '';
            if (gcid in gc_assoc) gcname = gc_assoc[gcid];

            const headerinfo = task_json.object_data.headerinfo;
            if (d.id == 21307) {
                //debugger;
                let a = this.state.promotions;
                let b = [headerinfo.promotion];
            }
            //console.log('headerinfo.promotion', headerinfo.promotion);
            //review date column for confirmed / review required
            if (d.cur_lane == 'COMP_FINPROMO_CONFIRMED' || d.cur_lane == 'COMP_FINPROMO_REVIEW_REQUIRED' || d.cur_lane == 'COMP_FINPROMO_EXPIRED') {
                if (task_json.object_data.hasOwnProperty("rvw_rqrd_rvwdate")) {
                    if (task_json.object_data["rvw_rqrd_rvwdate"]) {
                        review_Date = moment(task_json.object_data["rvw_rqrd_rvwdate"]).format("DD/MM/YYYY");
                    }
                }
                try {
                    if (task_json.object_data.hasOwnProperty("rvw_rqrd_assignto")) {
                        if (task_json.object_data["rvw_rqrd_assignto"]) {
                            let userid = task_json.object_data["rvw_rqrd_assignto"];
                            review_By = contact_lists.find(user => user.ID == userid).ContactName;//task_json.object_data["rvw_rqrd_assignto"];//this.state.contacts[task_json.object_data["rvw_rqrd_assignto"]];
                        }
                    }
                }
                catch (e) {
                    //console.log('inside catch');
                    let userid = task_json.object_data["rvw_rqrd_assignto"];
                    //console.log('userid ====>', userid);

                }

                if (task_json.object_data.hasOwnProperty("expiryToggle")) {
                    let isExpiry = task_json.object_data["expiryToggle"];
                    if (isExpiry) {
                        if (task_json.object_data.hasOwnProperty("expirydt")) {
                            if (task_json.object_data["expirydt"]) {
                                expiry_Date = moment((task_json.object_data["expirydt"])).format("DD/MM/YYYY");
                            }
                        }
                    }
                }
            }

            let elem = {
                id: d.id,
                status: d.cur_lane,
                statusLabel: this.state.statuses[d.cur_lane].label,
                statusColour: this.state.statuses[d.cur_lane].colour,
                refID: task_json.object_data.ref_id,
                name: headerinfo.name,
                promotion: headerinfo.promotion && headerinfo.promotion !== "" && typeof this.state.promotions[headerinfo.promotion] !== 'undefined' ? this.state.promotions[headerinfo.promotion].isDelete == 0 ? this.state.promotions[headerinfo.promotion].title : '' : '',
                addedBy: this.state.contacts[d.contact_id],
                company: gcname,
                addedDate: moment.unix(d.created_at).format('DD/MM/YYYY'),
                approvedBy: this.state.contacts[task_json.object_data.headerinfo.approvedBy],
                approvedDate: headerinfo.approvedDate,
                reviewDate: review_Date,
                reviewBy: review_By,
                expiryDate: expiry_Date,
                comments_reviewer: headerinfo.comments_reviewer || '',
                no_of_followup: nooffollowup,
                group_company_name: gcname
            };
            //console.log("elem.id==>", elem.id);
            ret.data.push(elem);
        }
        // sorting using dateadded desc..
        let actualData = ret.data;
        actualData.sort((a, b) => Number(b.id) - Number(a.id));
        Store.updateStore('table_cur_data', actualData);
        return ret;
    };

    deleteTask = (taskid) => (event) => {
        event.preventDefault();
        if (window.confirm('Are you sure you want to delete this record?')) {
            // event.preventDefault();
            let postData = { command: 'remove_task', task_id: taskid };
            this.setState({ isLoadedIcon: true })
            this.api.command(postData, this.processRemoveData(taskid));
        }
    };

    processRemoveData = (taskidvalue) => (result) => {
        let taskid = taskidvalue;
        console.log("taskid==>", taskidvalue)
        let mytaskdata = this.state.tasksList;
        let tasksList = [];
        for (let d of mytaskdata) {
            if (d.id !== taskid) {
                tasksList.push(d)
            }
        }

        this.setState({ tasksList, isLoadedIcon: false })
    };

    goToFollowupTask = (row) => (event) => {
        event.preventDefault();
        let command_data = { command: 'listfollowups', parent_id: row.original.id };
        this.setState({ isLoadedIcon: true })
        this.api.command(command_data, this.updateFollowups(row.original.refID));
    };

    updateFollowups = (refID) => (result) => {
        Store.updateStore('current_followup_lists', result.result.followuptasks);
        this.setState({ showModal: 'current_followup_list', curID: refID, isLoadedIcon: false });
    };


    sortByDate = (arr) => {
        debugger;
        arr.data.sort(function (a, b) {
            return Number(new Date(a.due_date)) - Number(new Date(b.due_date));
        });
        return arr;
    }

    processMyfollowupValue = () => {
        var followup_tasks = Store.getStoreData('followup_tasks');
        let userrole = Store.getStoreData('role');
        let updateFollowupTaskNew = []
        if (userrole === 'fprm' && 'general' in Store.getStoreData('module_config') && 'show_parent_company_financial_promotions' in Store.getStoreData('module_config').general && Store.getStoreData('module_config').general.show_parent_company_financial_promotions === false) {
            followup_tasks.forEach((value) => {
                if (value.company_id !== Store.getStoreData('curCompanyID')) {
                    updateFollowupTaskNew.push(value);
                }
            })
        } else {
            updateFollowupTaskNew = followup_tasks;
        }
        followup_tasks = updateFollowupTaskNew;

        let ret = { data: [], columns: [] };
        let i = 0;
        ret.columns = [
            { Header: 'Task', accessor: 'task', minWidth: 240, headerStyle: { textAlign: 'left', lineHeight: '24px' } },
            {
                Header: 'Assigned To',
                accessor: 'assigned_to',
                minWidth: 120,
                headerStyle: { textAlign: 'left', lineHeight: '24px' }
            },
            {
                Header: 'Status',
                accessor: 'status',
                width: 120,
                headerStyle: { textAlign: 'center', lineHeight: '24px' },
                Cell: (cellInfo) => (
                    <div>
                        <MRButton style={{ cursor: 'default', backgroundColor: `${cellInfo.value.color}` }}>
                            {cellInfo.value.text}
                        </MRButton>
                    </div>
                )
            },
            {
                Header: 'Due Date',
                accessor: 'due_date',
                minWidth: 120,
                headerStyle: { textAlign: 'left', lineHeight: '24px' },

                sortMethod: (a, b) => {
                    if (a === b) {
                        return 0;
                    }
                    let adue = a.split("/");
                    const aReverse = parseInt(adue[2]) * 10000 + parseInt(adue[1]) * 100 + parseInt(adue[0]);
                    let bbdue = b.split("/");
                    const bReverse = parseInt(bbdue[2]) * 10000 + parseInt(bbdue[1]) * 100 + parseInt(bbdue[0]);
                    return aReverse > bReverse ? 1 : -1;
                }
            },
            {
                Header: 'Action',
                Cell: (row) => (
                    <div>
                        <MRButton
                            style={{ width: '100px', cursor: 'pointer' }}
                            onClick={this.showFollowUp(row.original.id)}
                        >
                            View
                        </MRButton>

                        {(() => {
                            if (Store.getStoreData('role') == 'fprm' || Store.getStoreData('role') == 'admin_manager') {
                                return (
                                    <FaEdit
                                        onClick={this.showEditFollowUpModal(row.original.id)}
                                        style={{
                                            cursor: 'pointer',
                                            fontSize: '18px',
                                            marginLeft: '13px',
                                            color: '#b4b5b4',
                                            display: 'inline-block'
                                        }}
                                    />
                                )
                            }
                            return null;
                        })()}
                    </div>
                ),
                headerStyle: { textAlign: 'center', lineHeight: '24px' }
            }
        ];
        for (let d of followup_tasks) {
            i++;
            let task_json = typeof d.task_json === 'string' ? JSON.parse(d.task_json) : d.task_json;
            let today = moment().unix();
            let text = (d.due_date + 86400) > today ? 'Pending' : 'Overdue';
            let color = (d.due_date + 86400) > today ? '#2E8B57' : '#FF0000';

            let task =
                Store.getStoreData('contacts')[parseInt(d.cur_assigned_to)] +
                ' has been assigned a task titled ' +
                task_json.object_data.subject;

            let elem = {
                task: task,
                assigned_to: Store.getStoreData('contacts')[parseInt(d.cur_assigned_to)],
                status: { text: text, color: color },
                due_date: moment.unix(d.due_date).format('DD/MM/YYYY'),
                id: d.id
            };
            ret.data.push(elem);
        }

        return ret;
    };




    showFollowUp = (task_id) => (event) => {
        event.preventDefault();
        let postData = { command: 'get_subtask', task_id: task_id };
        this.setState({ isLoadedIcon: true })
        this.api.command(postData, this.openFollowupModal);
    };

    //open for edit modal
    showEditFollowUpModal = (task_id) => (event) => {
        event.preventDefault();
        let postData = { command: 'get_subtask', task_id: task_id };
        this.setState({ isLoadedIcon: true })
        this.api.command(postData, this.openFollowupEditModal);
    };
    openFollowupModal = (result) => {
        Store.updateStore('followup_task', result.result.task);
        this.setState({ showModal: 'view_followup_modal', isLoadedIcon: false });
    };


    openFollowupEditModal = (result) => {
        Store.updateStore('followup_task', result.result.task);
        this.setState({ showModal: 'view_followup_edit_modal', isLoadedIcon: false });
    };

    advanceFiltershow = (val) => this.setState({ advanceFilter: val });

    closeModal = (event) => this.setState({ showModal: null });

    switchToTaskModal = (data) => {
        this.setState({ showModal: null }, () => this.setState({
            showModal: 'triggerTaskModal',
            isEditedFromReviewRequired: data
        }));
    }

    closeModalAndRefresh = () => {
        let postData = { command: 'refreshTask' };
        this.api.command(postData, this.processTask);
    };

    closeModalAndRefreshFollowUp = () => {
        window.location.reload(true);
    }

    processTask = (result) => {
        let AllMyTask = result.result['my_tasks'];
        //filter mytask start
        if (Store.getStoreData('role') === 'admin_manager' || Store.getStoreData('role') === 'fprm') {
            let mytask = [];
            for (let task of result.result['my_tasks']) {
                if (!(task.cur_lane === 'COMP_FINPROMO_TEAMSAVE' && task.contact_id.toString() !== Store.getStoreData('contact_id'))) {
                    mytask.push(task);
                }
            }
            AllMyTask = mytask;
        }
        //filter mytask end
        this.setState({
            tasksList: AllMyTask,
            showModal: null
        });
    };
    exportToCSV = (event) => {
        event.preventDefault();
        const cur_table_data = Store.getStoreData('table_cur_data');
        const headerCSV = {
            status: 'Status',
            id: 'FPRID',
            name: 'Name',
            promotion: 'Type',
            addedBy: 'Added By',
            addedDate: 'Added Date',
            no_of_followup: 'Number of Tasks',
            approvedBy: 'Approved by',
            approvedDate: 'Approved Date',
            reviewDate: 'Review Date',
            reviewBy: 'Review By',
            expiryDate: 'Expiry Date',
            group_company_name: 'Company',
            comments_reviewer: 'Approval Comments',
            withdraw_reason: 'WithDraw Reason'
        };

        const cur_table_data_change = [headerCSV];
        for (let k = 0; k < cur_table_data.length; k++) {
            let reason_code = '';
            if (this.state.tasksList.find(task => task.id == cur_table_data[k].id).task_json.object_data.hasOwnProperty('reason_codes')) {
                let task_reasoncode = this.state.tasksList.find(task => task.id == cur_table_data[k].id).task_json.object_data.reason_codes;
                if (task_reasoncode == '') {
                    reason_code = 'This FP was withdrawn. A reason has not been provided'
                } else {
                    let code = Store.getStoreData('module_config').reason_codes;
                    if (typeof code[task_reasoncode] != 'undefined') {
                        reason_code = code[task_reasoncode].title
                    } else {
                        reason_code = ''
                    }
                }
            }

            cur_table_data_change.push({
                status: cur_table_data[k].status,
                id: cur_table_data[k].id,
                name: cur_table_data[k].name,
                promotion: cur_table_data[k].promotion,
                addedBy: cur_table_data[k].addedBy,
                addedDate: cur_table_data[k].addedDate,
                no_of_followup: cur_table_data[k].no_of_followup,
                approvedBy: cur_table_data[k].approvedBy,
                approvedDate: cur_table_data[k].approvedDate,
                reviewDate: cur_table_data[k].reviewDate,
                reviewBy: cur_table_data[k].reviewBy,
                expiryDate: cur_table_data[k].expiryDate,
                group_company_name: cur_table_data[k].group_company_name,
                comments_reviewer: cur_table_data[k].comments_reviewer,
                withdraw_reason: reason_code

            });
        }

        const cur_table_arr = cur_table_data_change.map((obj) => Object.values(obj)).map((e) => e.join(',')).join('\n');

        const csvContent = 'data:text/csv;charset=utf-8,' + cur_table_arr;
        const link = document.createElement('a');
        link.setAttribute('href', encodeURI(csvContent));
        link.setAttribute('download', 'Financial_Promotions_Register.csv');
        document.body.appendChild(link);
        link.click();
    };

    handleChange = (evt) => this.setState({ [evt.target.name]: evt.target.value });

    onRequestedFromDate = (date) => this.setState({ filterRequestedFromDate: date });

    onRequestedToDate = (date) => this.setState({ filterRequestedToDate: date });

    filterConfigList = (list) => Utils.getLists(list).map((item) => ({ id: item.id, label: item.title }));

    filterSubmit = (tasksListFilter) => this.setState({ tasksList: tasksListFilter });

    exportToExcel = () => {
        debugger;
        let wb = { SheetNames: [], Sheets: {} };
        let open_tasks = Store.getStoreData('table_cur_data') === null ? [] : Store.getStoreData('table_cur_data');
        for (let k = 0; k < open_tasks.length; k++) {
            let reason_code = '';
            if (this.state.tasksList.find(task => task.id == open_tasks[k].id)) {
                let taskJson = this.state.tasksList.find(task => task.id == open_tasks[k].id).task_json;
                if (taskJson.object_data.hasOwnProperty('reason_codes')) {
                    let task_reasoncode = taskJson.object_data.reason_codes;
                    if (task_reasoncode == '') {
                        reason_code = 'This FP was withdrawn. A reason has not been provided'
                        open_tasks[k].reason_code = reason_code
                    } else {
                        let code = Store.getStoreData('module_config').reason_codes;
                        if (typeof code[task_reasoncode] != 'undefined') {
                            reason_code = code[task_reasoncode].title
                            open_tasks[k].reason_code = reason_code
                        } else {
                            reason_code = ''
                            open_tasks[k].reason_code = reason_code
                        }
                    }
                } else {
                    reason_code = ''
                    open_tasks[k].reason_code = reason_code
                }

                if (taskJson.object_data.headerinfo.hasOwnProperty("additional_fields")) {
                    open_tasks[k].additional_fields = taskJson.object_data.headerinfo.additional_fields;
                } else {
                    open_tasks[k].additional_fields = [];
                }

            }
        }



        wb.SheetNames.push("Financial Promotions");
        wb.Sheets["Financial Promotions"] = XLSX.utils.json_to_sheet(XcelLoader.conflicts_export_tasks(open_tasks));
        const format = "DD-MM-YYYY HH:mm:ss"
        let tm = moment().format(format);
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, 'Financial Promotions' + '-' + tm + fileExtension);
    }

    render() {
        let task_id = this.props.taskId;
        if (!this.state.ready) return <div>Loading...</div>;

        if (this.state.isDeletionComplete === false)
            return (
                <div>
                    <CSLLoader />
                </div>
            );

        if (task_id > 0) {
            return (
                <div>
                    {(() => {
                        if (this.state.showModal !== null) {
                            switch (this.state.showModal) {
                                case 'triggerTaskApprovalModal':
                                    return <TaskApprovalModal closeModal={this.closeModal} />;
                                case 'triggerTaskModal':
                                    return <TaskModal closeModal={this.closeModal} />;
                                case 'triggerFollowupModal':
                                    return <FollowupModal closeModal={this.closeModal} />;
                            }
                        }
                    })()}
                </div>
            );
        }
        return (
            <div>
                {
                    (() => {
                        if (this.state.isLoadedIcon) {
                            return <div><CSLLoader style={{ position: 'absolute' }} /></div>
                        }
                    })()
                }
                <div style={{ boxSizing: 'border-box', padding: '12px 10px 0px 10px' }}>
                    <AdvancedFilter
                        contacts={this.state.contacts}
                        PromotionsList={this.filterConfigList('promotion_types')}
                        Tasks={this.state.tasksListFilter}
                        filterSubmitValue={this.filterSubmit}
                    />
                </div>

                <OverviewInfoHeader>
                    <OverviewInfoHeaderTitle>Financial Promotions Register</OverviewInfoHeaderTitle>
                    {/* <OverviewInfoHeaderSearch onClick={() => this.toggleSideBar()}><IoIosOptions /></OverviewInfoHeaderSearch>   */}
                    {/* <div style={{float: "right", borderLeft: "2px solid #EBEEF3",height: "24px",marginRight: "17px"}}></div>                      */}

                    <ClearBoth />
                </OverviewInfoHeader>
                <OverviewInfoBody>

                    

                    <CSLTable
                        add={false} 
                        processData={this.processDataTable} 
                        onHeaderClick={this.onHeaderClick}
                        onPageClick={this.onPageClick}
                        zebra={true} 
                        tableRows="10" 
                        pages={100}
                        page={99}
                    />

                                        
                    <div style={{ padding: '12px', display: 'flex' }}>
                        {
                            (() => {
                                if (this.state.tasksList.length !== 0) {
                                    return (
                                        <ExportButton onClick={() => this.exportToExcel()}>Export</ExportButton>)
                                } else {
                                    return (<ExportButtonDisable>Export</ExportButtonDisable>)
                                }
                            })()
                        }

                        <ExportsLine>{this.state.tasksList.length} Financial Promotion(s) listed</ExportsLine>
                        {/* <ClearBoth /> */}
                    </div>
                </OverviewInfoBody>

                <OverviewInfoHeader>
                    <OverviewInfoHeaderTitle>Follow up Tasks</OverviewInfoHeaderTitle>
                    <ClearBoth />
                </OverviewInfoHeader>
                <OverviewInfoBody>
                    <CSLTable add={false} processData={this.processMyfollowupValue} headerText={''} tableRows="5" />
                </OverviewInfoBody>
                {
                    (() => {
                        if (this.state.showModal !== null) {
                            switch (this.state.showModal) {
                                case 'triggerTaskApprovalModal':
                                    return (
                                        <TaskApprovalModal
                                            switchToTaskModal={this.switchToTaskModal}
                                            closeModalAndRefresh={this.closeModalAndRefresh}
                                            closeModalAndRefreshNormal={this.closeModal}
                                        />
                                    );
                                    break;
                                case 'triggerTaskModal':
                                    return <TaskModal closeModal={this.closeModal}
                                        isEditedFromReviewRequired={this.state.isEditedFromReviewRequired} />;
                                    break;
                                case 'view_followup_modal':
                                    return <FollowupModal closeModal={this.closeModal} closeModalAndRefresh={this.closeModalAndRefreshFollowUp} />;
                                    break;
                                case 'current_followup_list':
                                    return <FollowupListModal curID={this.state.curID} closeModal={this.closeModal} closeModalAndRefresh={this.closeModalAndRefreshFollowUp} />;
                                    break;

                                case 'view_followup_edit_modal':
                                    return <EditFollowUpModal closeModal={this.closeModal} closeModalAndRefresh={this.closeModalAndRefreshFollowUp} />;
                                    break;
                            }
                        }
                    })()
                }
            </div>
        );
    }
}

export default TasksList;


// <CSLTable add={false} processData={this.processDataTable} zebra={true} tableRows="10" />
