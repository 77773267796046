import React from 'react';
import styled from 'styled-components';
import { FaTimes, FaEdit, FaPlusCircle, FaPlus } from 'react-icons/fa';
import { IoIosArrowForward } from 'react-icons/io';
import APICall from '../../Common/APICall';
import EventBus from '../../Common/EventBus';
import CSLLoader from '../../Components/Common/CSLLoader';
import Store from '../../Common/Store.js';
import Utils from '../../Common/Utils.js';
import ConfigTypes from './ConfigTypes'
import Email from './Email'
import Checklist from './Checklist'
import ReasonCodes from './ReasonCodes'
import GeneralConfigs from './GeneralConfigs'
import CustomFields from './CustomFields'
import CSLToggle from '../Common/CSLToggle'
import EditSectionNameModal from './EditSectionNameModal'
import ConfigEditModal from './ConfigEditModal'
import Reload from '../../Common/Reload.js';
import AddCheckListModal from "./AddCheckListModal";
import { Container, Draggable } from 'react-smooth-dnd';

const ReviewBodyContainer = styled.div`
  padding-top: 25px;
  width: 100%;
`;
const ReviewLeftContainer = styled.div`
  display: inline-table;
  overflow: hidden;
  text-overflow: ellipsis;

  position: sticky;
  top: 2px;
  float: left;
  padding-right: 20px;
  width: 20%;
  box-sizing: border-box;
`;
const ReviewRightContainer = styled.div`
  float: right;
  padding-left: 20px;
  width: 80%;
  box-sizing: border-box;
`;
const ReviewTab = styled.div`
  background-color: #ffffff;
  color: ${process.env.DEFAULT_TEXT_COLOR};
  padding: 10px 20px;
  cursor: pointer;
`;
const CloseBtn = styled.div`
  float: right;
  font-size: 20px;
  color: #9c9c9c;
  cursor: pointer;
  padding-top: 10px;
  padding-right: 5px;
`;
const FooterButtonsContainer = styled.div`
  text-align: right;
  padding-top: 25px;
  width: 90%;
`;
const CancelBtn = styled.button`
  padding: 10px 20px;
  background-color: #ffffff;
  border: 1px solid #ffffff;
  border-radius: 2px;
  color: #333333;
  cursor: pointer;
`;
const SaveBtn = styled.button`
  padding: 10px 30px;
  background-color: #37ada7;
  border: 1px solid #37ada7;
  border-radius: 2px;
  color: #ffffff;
  cursor: pointer;
  margin-left: 15px;
`;
const ErrorBar = styled.div`
  background-color: #f6dbdb;
  color: #de8d8e;
  font-weight: 600;
  padding: 10px 20px;
`;
const ExportButton = styled.button`
  box-sizing: border-box;
  background-color: #213e61;
  color: #f2f2f2;
  border: 1px solid #213e61;
  width: 125px;
  font-size: 14px;
  height: 32px;
  border-radius: 5px;
  margin-right: 3px;
  float: left;
`;
const LeftLabel = styled.div`float: left;`;
const RightArrow = styled.div`
  float: right;
  color: #39b3ad;
  margin-top: 2px;
  padding-left: 3px;
`;
const QuestionLine = styled.div`
  padding: 5px;
  height: 30px;
  cursor: grabbing;
`;
const QuestionTitle = styled.div`
  font-size: 13px;
  font-weight: 500;
  float: left;
  width: calc(100% - 150px);
`;
const QSearchInput = styled.input`
  height: 25px;
  width: 829px;
  box-shadow: rgb(196, 196, 196) 0px 0px 4px;
  color: #545454;
  font-family: montserrat;
  font-size: 15px;
  font-weight: 400;
  border-width: 1px;
  border-style: solid;
  border-color: rgb(255, 255, 255);
  border-image: initial;
  border-radius: 3px;
  padding: 2px;
`;

const AddButton = styled.div`
  width: 30px;
  height: 30px;
  margin: 0px;
  float: right;
  padding-left: 10px;
  font-size: 16px;
  cursor: pointer;
`;

const SerialHeader = styled.div`
  text-align: center;
  flex: 30 0 auto;
  width: 30px;
  max-width: 30px;
  cursor: pointer;
  padding: 5px 5px;
  line-height: normal;
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.05);
  transition: box-shadow .3s cubic-bezier(.175, .885, .32, 1.275);
  box-shadow: inset 0 0 0 0 transparent;
  white-space: nowrap;
  text-overflow: ellipsis;
  -webkit-box-flex: 1;
  height: 35px;
`;

const QuestionTitleHeader = styled.div`
  text-align: left;
  flex: 100 0 auto;
  width: 100px;
  cursor: pointer;
  overflow: visible;
  padding: 5px 5px;
  line-height: normal;
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.05);
  transition: box-shadow .3s cubic-bezier(.175, .885, .32, 1.275);
  box-shadow: inset 0 0 0 0 transparent;
  white-space: nowrap;
  text-overflow: ellipsis;
  -webkit-box-flex: 1;
  height: 35px;
`;

const ToggleHeader = styled.div`
  text-align: center;
  flex: 80 0 auto;
  width: 80px;
  max-width: 80px;
  overflow: visible;
  padding: 5px 5px;
  line-height: normal;
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.05);
  transition: box-shadow .3s cubic-bezier(.175, .885, .32, 1.275);
  box-shadow: inset 0 0 0 0 transparent;
  white-space: nowrap;
  text-overflow: ellipsis;
  -webkit-box-flex: 1;
  height: 35px;
`;
const ActionHeader = styled.div`
  text-align: center;
  flex: 55 0 auto;
  width: 55px;
  max-width: 55px;
  overflow: hidden;
  cursor: pointer;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 5px 5px;
  line-height: normal;
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.05);
  transition: box-shadow .3s cubic-bezier(.175, .885, .32, 1.275);
  box-shadow: inset 0 0 0 0 transparent;
  height: 35px;
`;

const TypesBoldLabel = styled.div`
  font-weight: 600;
  color: #595959;
  margin-top: 5px;
`;

const ClearBoth = styled.div`clear: both;`;

class LeftMenu extends React.Component {
    state = {active_section_id: 'general', checklists: null, index: null}

    componentDidMount = () => {
        EventBus.registerEvent('checklist_added', 'LeftMenu', this.checklistAdded)
        this.setComponentState()
    }

    checklistAdded = (checklist) => {
        console.log('checklist LeftMenu', checklist)
        let checklists = this.state.checklists
        checklists.value.push(checklist)
        this.setState({checklists})
    }

    componentDidUpdate = (prevprops) => {
        if(this.props === prevprops)return

        this.setComponentState()
    }

    setComponentState = () => {
        let checklists = this.props.checklists
        this.setState({checklists})
    }

    returnSection = (id, i) => {
        this.setState({active_section_id: id, index: i})
        this.props.returnSection(id, i)
    }


    render() {
      console.log('this.props', this.props)
      if(this.state.checklists === null)return <div />
      let checklists = this.state.checklists.value
      console.log('this.state inside LeftMenu.js',this.state);
      return (<>
        <ReviewTab onClick={() => this.returnSection('general', 0)} style={{ marginTop: '2px' }}>
            <LeftLabel
                style={this.state.active_section_id === 'general' ? { fontWeight: '600' } : {}}
            >
                General
            </LeftLabel>
            <RightArrow>
                <IoIosArrowForward />
            </RightArrow>
            <ClearBoth />
        </ReviewTab>
        <ReviewTab onClick={() => this.returnSection('email', 0)} style={{ marginTop: '2px' }}>
            <LeftLabel style={this.state.active_section_id === 'email' ? { fontWeight: '600' } : {}}>
                Email
            </LeftLabel>
            <RightArrow>
                <IoIosArrowForward />
            </RightArrow>
            <ClearBoth />
        </ReviewTab>
        {/*custom field*/}
        <ReviewTab onClick={() => this.returnSection('custom_fields', 0)} style={{ marginTop: '2px' }}>
            <LeftLabel
                style={this.state.active_section_id === 'custom_fields' ? { fontWeight: '600' } : {}}
            >
                Custom Fields
            </LeftLabel>
            <RightArrow>
                <IoIosArrowForward />
            </RightArrow>
            <ClearBoth />
        </ReviewTab>
        <ReviewTab style={{ marginTop: '2px' }}>
            <LeftLabel style={this.state.active_section_id === 'checklist' ? { fontWeight: '600' } : {}}>CHECKLISTS</LeftLabel>
            <ClearBoth />
        </ReviewTab>
        {
            (() => {

                if(checklists.length > 0) return (<>
                        {
                            checklists.map((c,i) => 
                                <ReviewTab key={i} onClick={() => this.returnSection('checklist', i)}>
                                    <LeftLabel
                                        style={{
                                            paddingLeft: '10px',
                                            fontWeight: this.state.index === i && this.state.active_section_id === 'checklist' ? '600' : 'normal'
                                        }}
                                    >
                                        {c.title}
                                    </LeftLabel>
                                    <RightArrow>
                                        <IoIosArrowForward />
                                    </RightArrow>
                                    <ClearBoth />
                                </ReviewTab>
                            )
                        }
                    </>)
                else return (<div />)                                
            })()
        }
        <ReviewTab key='add_new' onClick={() => this.returnSection('add_new', 0)}>
            <LeftLabel
                style={{
                    paddingLeft: '10px',
                    fontWeight: this.state.active_section_id === 'add_new' ? '600' : 'normal'
                }}
            >
                Add New
            </LeftLabel>
            <RightArrow>
                <IoIosArrowForward />
            </RightArrow>
            <ClearBoth />
        </ReviewTab>
        {/* Promotion types section left side column */}
        <ReviewTab onClick={() => this.returnSection('promotion_types', 0)} style={{ marginTop: '2px' }}>
            <LeftLabel
                style={this.state.active_section_id === 'promotion_types' ? { fontWeight: '600' } : {}}
            >
                Promotion Types
            </LeftLabel>
            <RightArrow>
                <IoIosArrowForward />
            </RightArrow>
            <ClearBoth />
        </ReviewTab>

        {/*Reason Codes*/}
        <ReviewTab onClick={() => this.returnSection('reason_codes', 0)} style={{ marginTop: '2px' }}>
            <LeftLabel
                style={this.state.active_section_id === 'reason_codes' ? { fontWeight: '600' } : {}}
            >
                Reason Codes
            </LeftLabel>
            <RightArrow>
                <IoIosArrowForward />
            </RightArrow>
            <ClearBoth />
        </ReviewTab>

        </>)
    }
}

export default LeftMenu;
