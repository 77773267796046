import React from 'react';
import styled from 'styled-components';
import { FaTimes } from 'react-icons/fa';
// import {ClearBoth, CSLTable} from '@mcc-regtech/core-library';
import ClearBoth from '../Common/ClearBoth';
import CSLTable from '../Common/CSLTable';
import { Store, Utils } from "../Common";
import moment from 'moment';


const MRModalContainer = styled.div`
  display: block;
  box-sizing: border-box;
  height: auto;
  width: 837px;
  position: absolute;
  top: 38px;
  z-index: 1001;
  background-color: rgb(243, 247, 251);
  box-shadow: rgb(167, 166, 166) 0px 0px 5px;
  margin-bottom: 10px;
  border-radius: 5px;
  margin-left: 100px;
`;
const MRModalHeader = styled.div`
  background-color: white;
  display: block;
  padding-top: 5px;
  font-size: 14px;
  font-weight: 700;
  height: 100px;
  width: calc(100% - 10px);
  border-width: 1px 1px 1px 10px;
  border-left: 9px solid #04ADA8;
  border-bottom: 1px solid #DFDFDF;
`;
const MRModalHeaderText = styled.div`
  font-weight: 700;
  font-size: 22px;
  float: left;
  margin-top: 24px;
  margin-left: 35px;
  color: #143151;
`;

const MRModalHeaderCloseBtn = styled.div`
  position: absolute;
  top: 8px;
  right: 10px;
  box-sizing: border-box;
  margin-top: 24px;
  margin-right: 20px;
  font-size: 25px;
  cursor: pointer;
  font-weight: 200;
`;
const MRModalBody = styled.div`
  position: relative;
  display: block;
  box-sizing: border-box;
  background-color: rgb(243, 247, 251);
  padding: 12px;
  border-radius: 4px;
`;
const RegCancelSubmitButtonOuterContainer = styled.div`
  display: block;
  box-sizing: border-box;
  padding-right: 20px;
  padding-bottom: 20px;
  text-align: right;
  margin-top: 30px;
`;
const RegCancelButton = styled.button`
  display: inline-block;
  box-sizing: border-box;
  background-color: #ffffff;
  color: #909090;
  border: 2px solid #dadada;
  width: 100px;
  height: 35px;
  border-radius: 4px;
  font-size: 13px;
  cursor: pointer;
`;
const RegDismisOuterContainer = styled.div`
  display: block;
  box-sizing: border-box;
  width: 100%;
`;
const OverviewInfoBody = styled.div`
  padding: 15px;
  background-color: #EDF0F1;
  border: 1px solid #d7dddf;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  width: 97%;
  margin: 0 auto;
  margin-bottom: 30px;
  display: inline-block;
  margin-top: 28px;
`;
const OverviewInfoHeader = styled.div`
  padding: 12px 15px;
  background-color: #04ada8;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  width: 97%;
  margin: 0 auto;
  margin-top: 30px;
`;

const Activity_Shown = {
    COMP_FINPROMO_REGISTERED: 1,
    COMP_FINPROMO_REVISION: 1,
    COMP_FINPROMO_WITHDRAWN: 1,
    COMP_FINPROMO_CONFIRMED: 1,
    COMP_FINPROMO_REVIEW_REQUIRED: 1,
    COMP_FINPROMO_EXPIRED: 1
};

class TaskDetailsLogModal extends React.Component {
    state = {
        client_name: this.props.client_name,
        contact_list: this.props.contact_list,
        task_data: this.props.taskdata[0],
        // coming from ActivityLog component
        //followup_details_log: Store.getStoreData('module_config')
        contacts: Store.getStoreData('contacts'),
    }

    constructor (props) {
        super(props);
    }


    calculateDateInTimestamp = function (inputDate) {
        var date_input = new Date(inputDate);
        var date_output = date_input.getDate() + '/' + (date_input.getMonth() + 1) + '/' + date_input.getFullYear()
        return date_output;
    }

    closeModal = (event) => {
        event.preventDefault();
        this.props.closeModal();
    }
    processTaskDetails = () => {
        let followuptasksLog = Store.getStoreData('followuptasks_from_log');
        let taskData = this.state.task_data;
        let task_id = taskData.id;
        let str_child_tasks = taskData.child_task_ids;
        var task_action_arr = [];
        var task_action = {};
        //var followup_task_action_arr = [];


        var taskjson = JSON.parse(taskData.task_json);
        var action_data = taskjson.action_data;


        //console.log("Action Data", action_data);
        for (let j = 0; j < action_data.length; j++) {
            if (action_data[j].action in Activity_Shown) {
                let lane_parts = action_data[j].action.split('_')
                let lane_end = lane_parts[lane_parts.length - 1]
                if (action_data[j].action === 'COMP_FINPROMO_REGISTERED') {
                    task_action.action_taken_type = 'New FP Submitted';
                    let qtext = taskjson.object_data.headerinfo.comments;
                    let desc = Utils.isBase64(qtext) ? window.decodeURIComponent(atob(qtext)) : qtext
                    task_action.description = <span dangerouslySetInnerHTML={{ __html: desc, style: 'margin:0' }} />;
                }
                if (action_data[j].action === 'COMP_FINPROMO_REVISION') {
                    task_action.action_taken_type = 'FP sent for Revision';
                    if (action_data[j].hasOwnProperty('comments_reviewer')) {
                        task_action.description = action_data[j].comments_reviewer;
                    } else {
                        task_action.description = taskjson.object_data.headerinfo.comments_reviewer

                    }
                }

                if (action_data[j].action === 'COMP_FINPROMO_WITHDRAWN') {
                    task_action.action_taken_type = 'FP Withdrawn';

                    if (action_data[j].hasOwnProperty('reason_codes')) {
                        if (action_data[j].reason_codes !== '') {
                            task_action.description = Store.getStoreData('module_config').reason_codes[action_data[j].reason_codes].title
                        } else {
                            task_action.description = 'This FP was withdrawn. A reason has not been provided';
                        }
                    }

                    else if (taskjson.object_data.hasOwnProperty('reason_codes')) {
                        if (taskjson.object_data.reason_codes !== '') {
                            task_action.description = Store.getStoreData('module_config').reason_codes[taskjson.object_data.reason_codes].title
                        } else {
                            task_action.description = 'This FP was withdrawn. A reason has not been provided';
                        }
                    } else {
                        task_action.description = 'This FP was withdrawn. A reason has not been provided';
                    }
                }
                if (action_data[j].action === 'COMP_FINPROMO_REVIEW_REQUIRED') {
                    if (taskjson.object_data.hasOwnProperty('is_edit_button_clicked')) {
                        task_action.action_taken_type = 'FP Review Details Submitted';
                        task_action.description = 'The initial details of this Financial Promotion were changed during Review'
                    }
                    else {
                        task_action.action_taken_type = 'FP Reviewed';
                        if (action_data[j].hasOwnProperty('comments_reviewer')) {
                            task_action.description = action_data[j].comments_reviewer;
                        } else {
                            task_action.description = taskjson.object_data.headerinfo.comments_reviewer;
                        }
                    }
                }

                if (action_data[j].action === 'COMP_FINPROMO_EXPIRED') {
                    task_action.action_taken_type = 'Expiry Date Changed';
                    debugger;
                    task_action.description = this.state.contacts[action_data[j].performed_by] + ' changed the Expiry Date to ' + moment(taskjson.object_data.expirydt).format('DD/MM/YYYY')
                }

                if (action_data[j].action === 'COMP_FINPROMO_CONFIRMED') {
                    task_action.action_taken_type = 'FP Confirmed';
                    if (action_data[j].hasOwnProperty('comments_reviewer')) {
                        task_action.description = action_data[j].comments_reviewer;
                    } else {
                        task_action.description = taskjson.object_data.headerinfo.comments_reviewer;
                    }
                }


                // task_action.client_id = taskData[i].company_id; //.unix(date).toDate()
                // task_action.last_action_time = moment.unix(taskData[i].last_action_time).format("DD/MM/YYYY");
                if (lane_end == 'CREATE') {
                    task_action.changed_by = this.state.contact_list[taskData.contact_id];
                } else {
                    task_action.changed_by = this.state.contact_list[action_data[j].performed_by];
                }
                task_action.changed_date = this.calculateDateInTimestamp(action_data[j].actiondate);
                task_action_arr.push(task_action);

                task_action = {};
                // for changed review date
                if (action_data[j].action === 'COMP_FINPROMO_REVIEW_REQUIRED') {
                    if (taskjson.object_data.hasOwnProperty('is_edit_button_clicked')) {
                        task_action.action_taken_type = 'Review Date Changed';
                        task_action.changed_by = this.state.contact_list[action_data[j].performed_by];
                        task_action.changed_date = this.calculateDateInTimestamp(action_data[j].actiondate);
                        task_action.description = this.state.contact_list[action_data[j].performed_by] + ' changed the Review Date to ' + moment(taskjson.object_data.rvw_rqrd_rvwdate).format('DD/MM/YYYY')
                    } else {
                        task_action.action_taken_type = 'Review Date Changed';
                        task_action.changed_by = this.state.contact_list[action_data[j].performed_by];
                        task_action.changed_date = this.calculateDateInTimestamp(action_data[j].actiondate);
                        task_action.description = this.state.contact_list[action_data[j].performed_by] + ' changed the Review Date to ' + moment(taskjson.object_data.rvw_rqrd_rvwdate).format('DD/MM/YYYY')
                    }
                    task_action_arr.push(task_action);
                    task_action = {};
                }
            }
        }
        // check if the current task has any child tasks,
        if (str_child_tasks !== null || str_child_tasks !== '') {
            let followup_task_action = {};
            let followuptasks = followuptasksLog.filter(x => x.task_data.parent_task_id === task_id);
            for (let index = 0; index < followuptasks.length; index++) {
                let followuptask = followuptasks[index];
                let followup_actions = followuptask.action_data;
                for (let i = 0; i < followup_actions.length; i++) {
                    let followup_action = followup_actions[i];
                    if (followup_action.action === 'COMP_FPR_FOLLOWUP') {
                        followup_task_action.action_taken_type = 'Follow-up task assigned';
                        followup_task_action.description = followuptask.object_data.subject;
                        if (followup_action.hasOwnProperty('performed_by')) {
                            followup_task_action.changed_by = this.state.contact_list[followup_action.performed_by];
                        } else {
                            followup_task_action.changed_by = this.state.contact_list[followuptask.task_data.contact_id];
                        }
                        followup_task_action.changed_date = moment(followup_action.actiondate).format('DD/MM/YYYY');
                    }
                    else if (followup_action.action === 'COMP_FPR_FOLLOWUP_COMPLETE') {
                        followup_task_action.action_taken_type = 'Follow-up task completed';
                        followup_task_action.description = followup_action.comments;
                        if (followup_action.hasOwnProperty('performed_by')) {
                            followup_task_action.changed_by = this.state.contact_list[followup_action.performed_by];
                        } else {
                            followup_task_action.changed_by = this.state.contact_list[followuptask.task_data.contact_id];
                        }
                        followup_task_action.changed_date = moment(followup_action.actiondate).format('DD/MM/YYYY');
                    }

                    task_action_arr.push(followup_task_action);
                    followup_task_action = {};
                    debugger;
                }
            }
        }
        //}
        console.log(task_action_arr);
        let ret = { data: [], columns: [] };
        let i = 1;
        ret.columns = [{
            Header: 'Action Taken Type',
            accessor: 'action_taken_type',
            minWidth: 170,
            headerStyle: { textAlign: 'center' }
        },
        { Header: 'Action Taken By ', accessor: 'changed_by', minWidth: 100, headerStyle: { textAlign: 'center' } },
        { Header: 'Action Taken Date', accessor: 'changed_date', minWidth: 100, headerStyle: { textAlign: 'center' } },
        { Header: 'Description', accessor: 'description', minWidth: 400, style: { 'whiteSpace': 'unset', margin: 0 } }
        ];

        for (let k = 0; k < task_action_arr.length; k++) {
            let elem = {
                'action_taken_type': task_action_arr[k].action_taken_type,
                'changed_by': task_action_arr[k].changed_by,
                'changed_date': task_action_arr[k].changed_date,
                'description': task_action_arr[k].description
            };
            ret.data.push(elem);
        }

        return ret;
    }


    render() {
        return (

            <MRModalContainer>
                <MRModalHeader>
                    <MRModalHeaderText>{this.state.task_data.alt_ref_id} Logs</MRModalHeaderText>
                    <MRModalHeaderCloseBtn onClick={this.closeModal}><FaTimes /></MRModalHeaderCloseBtn>
                    <ClearBoth />
                </MRModalHeader>
                <MRModalBody>
                    <RegDismisOuterContainer>
                        <OverviewInfoHeader>
                            <div style={{ float: "left", color: "#ffffff", fontWeight: "600" }}>Details</div>
                            <ClearBoth />
                        </OverviewInfoHeader>

                        <OverviewInfoBody style={{ marginBottom: "0px", marginTop: "0px", backgroundColor: "#ffffff" }}>
                            <CSLTable add={false}
                                processData={this.processTaskDetails}
                                headerText={''} tableRows="5" />
                        </OverviewInfoBody>

                    </RegDismisOuterContainer>
                    <RegCancelSubmitButtonOuterContainer>
                        <RegCancelButton onClick={this.closeModal}>Cancel</RegCancelButton>
                    </RegCancelSubmitButtonOuterContainer>

                </MRModalBody>
            </MRModalContainer>
        )
    }

}

export default TaskDetailsLogModal;
