/* eslint-disable */
import React from 'react';
import styled from 'styled-components';
import Store from '../Common/Store.js';
//import { CSLHeader, AlertBox } from '@mcc-regtech/core-library';
import AlertBox from './Common/AlertBox';
import CSLHeader from './Common/CSLHeader';
import TasksIndex from './FPR/TasksIndex';

const NoPermission = styled.div`
	display: block;
	box-sizing: border-box;
	height: 40px;
	font-size: 20px;
	font-weight: 700;
	text-align: center;
	background-color: #ffffff;
	position: absolute;
	top: 100px;
	left: 10px;
	right: 10px;
	bottom: 10px;
	border: 2px solid #37ada7;
	border-radius: 10px;
	color: #37ada7;
	z-index: 1000;
`;

class Index extends React.Component {
	state = { view_count: 0, cur_view: null };

	componentDidMount = () => {
		let role = Store.getStoreData('view');
		const task_id = parseInt(this.props.match ? this.props.match.params.taskId : 0);

		if (task_id) {
			const task = Store.getStoreData('mytasks').find((task) => task.id === task_id);
			const FUtask = Store.getStoreData('followup_tasks').find((task) => task.id === task_id);
			if (!task && !FUtask)
				role = 'no_access'; // deny access if task does not belong to user
			else Store.updateStore('isreferenceUrl', true);
		}

		this.setState({ cur_view: role });
	};

	alertHandler = (result, stack) => {
		window.location = '/select/ServiceBasedLanding';
	};

	render() {
		//console.log('inside render() in index.js');
		const task_id = this.props.match ? this.props.match.params.taskId : 0;
		//console.log(task_id);
		const alert_param = {
			title: 'No Access',
			message:
				'You are not authorised to access this module. If you think this is an error, please contact your administrator.',
			ok_text: 'Ok',
			confirm: false,
			alertHandler: this.alertHandler,
			stack: { id: 0 }
		};

		return (
			<div style={{ backgroundColor: '#f2f2f3', position: 'relative' }}>
				<CSLHeader
					isAdmin={this.state.cur_view === 'manager_index'}
					isDebug={Store.getStoreData('is_debug')}
					taskHeader={task_id > 0}
					module={'finpromo'}
					headerText={'Financial Promotions Register'}
				/>
				{(() => {
					switch (this.state.cur_view) {
						case 'manager_index':
						case 'fprm_index':
						case 'team_index':
							return <TasksIndex refreshCallback={this.refreshState} taskId={task_id} />;
							break;
						case 'no_access':
							return <AlertBox alertParam={alert_param} />;
							break;
						default:
							return <NoPermission>Sorry, you do not have permission to view this page. </NoPermission>;
					}
				})()}
				{/* <CSLLoader /> */}
			</div>
		);
	}
}

export default Index;
