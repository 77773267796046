import React from 'react';
import styled from 'styled-components';
import { IoIosArrowUp, IoIosArrowDown } from 'react-icons/io';
import { Icon } from 'react-icons-kit';
import { androidRemove } from 'react-icons-kit/ionicons/androidRemove';
import Store from '../../Common/Store.js';
import Utils from '../../Common/Utils.js';
// import {ClearBoth, CSLTypeAhead, CSLDatePicker} from '@mcc-regtech/core-library';
import ClearBoth from '../Common/ClearBoth';
import CSLTypeAhead from '../Common/CSLTypeAhead';
import CSLDatePicker from '../Common/CSLDatePicker';
import moment from 'moment';

moment.defaultFormat = "DD/MM/YYYY";

const FilterTopBar = styled.div`
  background-color: #ffffff;
  font-weight: 600;
  color: ${process.env.DEFAULT_TEXT_COLOR};
  padding: 12px 16px;
`;
const FilterText = styled.div`
  float: left;
`;
const FilterChevron = styled.div`
  float: right;
  font-size: 16px;
  cursor: pointer;
`;
const FilterBody = styled.div`
  background-color: #ffffff;
  padding: 15px 20px;
  margin-top: 2px;
  width: 100%;
  box-sizing: border-box;
`;
const Section45 = styled.div`
  float: left;
  width: 45%;
  box-sizing: border-box;
  position: relative;
`;
const Section40 = styled.div`
  float: left;
  width: 40%;
  box-sizing: border-box;
  padding-right: 20px;
  position: relative;
  width: ${props => (props.has_gc ? '33%' : '40%')};
`;

const Section10 = styled.div`
  float: left;
  width: 10%;
  text-align: center;
  box-sizing: border-box;
  padding-top: 10px;
`;
const FieldLabel = styled.div`
  font-weight: 600;
  color: ${process.env.DEFAULT_TEXT_COLOR};
  padding-bottom: 15px;
`;
const FilterButtons = styled.div`
  float: right;
  display: inline-block;
  whiteSpace: nowrap;
  min-width: 200px;
  margin-top: 10px;
`;
const FilterClearBtn = styled.button`
  background-color: #ffffff;
  border: 1px solid #DBDBDB;
  border-radius: 4px;
  color: ${process.env.DEFAULT_TEXT_COLOR};
  float: left;
  padding: 12px 13%;
  cursor: pointer;
`;
const FilterApplyBtn = styled.button`
  background-color: #4FB2AF;
  border: 1px solid #4FB2AF;
  border-radius: 4px;
  color: #ffffff;
  float: right;
  padding: 12px 18%;
  cursor: pointer;
`;

class AdvancedFilter extends React.Component {

    state = {
        statuses: Store.getStoreData('module_config').status,
        show_filter: false,
        usedNames: [],
        usedAddedBy: [],
        usedApprovedBy: [],
        usedReviewedBy: [],
        isclear: false,
        statusData: [],
        filterAddedBy: {},
        filterApprovedBy: {},
        filterPromotion: {},
        filterStatus: {},
        filterRequestedFromDate: new Date(),
        filterRequestedToDate: new Date(),
        filterApprovedFromDate: new Date(),
        filterApprovedToDate: new Date(),
        updateFromDate: null,

        filterReviewedFromDate: new Date(),
        filterReviewedToDate: new Date(),
        filterReviewedBy: {},

        filterExpiredFromDate: new Date(),
        filterExpiredToDate: new Date(),

        isReviewDateEnabledFromConfig: Store.getStoreData('module_config').general.add_review_date_for_finpromo,
        isExpiryDateEnabledFromConfig: Store.getStoreData('module_config').general.add_expiry_date_for_financial_promotions,
        hasGc: Store.getStoreData('has_gc'),
        usedCompanyName: [],
        filterCompany: {}
    };

    componentDidMount() {
        let groupCompanies = [];
        if (this.state.hasGc) {
            const gcCompanies = Store.getStoreData('gc_company');
            for (let gcCompObject in gcCompanies) {
                if (gcCompanies[gcCompObject].is_active === 1) {
                    const gcCompany = gcCompanies[gcCompObject];
                    const gcObject = {};

                    if (gcCompany.hasOwnProperty('id')) {
                        gcObject.id = gcCompany.id;
                    }

                    if (gcCompany.hasOwnProperty('company_name')) {
                        gcObject.label = gcCompany.company_name;
                    }
                    if (gcCompany.hasOwnProperty('is_gc')) {
                        gcObject.is_gc = gcCompany.is_gc;
                    }

                    if (Object.keys(gcObject).length > 0) {
                        groupCompanies.push(gcObject);
                    }
                }
            }
        }

        let filteredGcRoleWise = this.getGcsLoggedInRoleWise(groupCompanies);

        this.props.Tasks.forEach((task, idx) => {
            this.props.Tasks[idx].task_json = typeof task.task_json === "string"
                ? JSON.parse(task.task_json)
                : task.task_json
        })
        // filtered array of contacts (the ones used in task_json)
        let usedAddedBy = this.props.Tasks.map(task => task.contact_id)
        usedAddedBy = [...new Set(usedAddedBy)]
        usedAddedBy = usedAddedBy.map(key => ({ id: key, label: this.props.contacts[key] }))

        // filtered array of approvedBy (the ones used in task_json)
        let usedApprovedBy = this.props.Tasks.map(task => task.task_json.object_data.headerinfo.approvedBy)
        usedApprovedBy = [...new Set(usedApprovedBy)].filter(Boolean)
        usedApprovedBy = usedApprovedBy.map(key => ({ id: key, label: this.props.contacts[key] }))

        // filter array of review by

        let usedReviewedBy = this.props.Tasks.map(task => task.task_json.object_data.hasOwnProperty('rvw_rqrd_assignto') ? task.task_json.object_data.rvw_rqrd_assignto : null)
        usedReviewedBy = [...new Set(usedReviewedBy)].filter(Boolean)
        usedReviewedBy = usedReviewedBy.map(key => ({ id: key, label: this.props.contacts[key] }))

        const statusData = Object.keys(this.state.statuses)
            .filter(status => [
                "COMP_FINPROMO_REGISTERED",
                "COMP_FINPROMO_TEAMSAVE",
                "COMP_FINPROMO_CONFIRMED",
                "COMP_FINPROMO_REVISION",
                "COMP_FINPROMO_REVIEW_REQUIRED",
                "COMP_FINPROMO_EXPIRED",
                "COMP_FINPROMO_WITHDRAWN"].includes(status))
            .map(status =>
                ({ id: status, label: this.state.statuses[status].label }));

        let updateFromDate = new Date(
            this.state.filterRequestedFromDate.getFullYear(),
            this.state.filterRequestedFromDate.getMonth() - 12,
            this.state.filterRequestedFromDate.getDate());

        this.setState({
            usedApprovedBy,
            usedAddedBy,
            promotion_types: this.props.PromotionsList,
            statusData,
            updateFromDate,
            filterRequestedFromDate: updateFromDate,
            filterApprovedFromDate: updateFromDate,
            filterReviewedFromDate: updateFromDate,
            filterExpiredFromDate: updateFromDate,
            usedReviewedBy: usedReviewedBy,
            usedCompanyName: filteredGcRoleWise
        });
    }


    getGcsLoggedInRoleWise = (gcs) => {
        let role = Store.getStoreData('role');
        let showParentCompanyFinancialProms = Store.getStoreData('module_config').general.show_parent_company_financial_promotions;
        if (role == 'team' && !showParentCompanyFinancialProms) {
            gcs = gcs.filter(obj => obj.is_gc === true);
        }
        return gcs;
    }

    toggleFilter = (event) => {
        event.preventDefault();
        let show_filter = this.state.show_filter ? false : true;
        this.setState({ show_filter });
    }

    onAddedBy = (selected_options) => {
        this.setState({ filterAddedBy: Utils.selectedOtions(selected_options) });
    }

    onApprovedBy = (selected_options) => {
        this.setState({ filterApprovedBy: Utils.selectedOtions(selected_options) });
    }

    onReviewedBy = (selected_options) => {
        this.setState({ filterReviewedBy: Utils.selectedOtions(selected_options) });
    }

    onPromotion = (selected_options) => {
        this.setState({ filterPromotion: Utils.selectedOtions(selected_options) });
    }

    onCompanyClicked = (selected_options) => {
        this.setState({ filterCompany: Utils.selectedOtions(selected_options) });
    }

    onStatus = (selected_options) => {
        this.setState({ filterStatus: Utils.selectedOtions(selected_options) });
    }

    onRequestedFromDate = (date) => {
        this.setState({ filterRequestedFromDate: date });
    }

    onRequestedToDate = (date) => {
        this.setState({ filterRequestedToDate: date });
    }

    onApprovedFromDate = (date) => {
        this.setState({ filterApprovedFromDate: date });
    }
    onApprovedToDate = (date) => {
        this.setState({ filterApprovedToDate: date });
    }

    onReviewedFromDate = (date) => {
        this.setState({ filterReviewedFromDate: date });
    }
    onReviewedToDate = (date) => {
        this.setState({ filterReviewedToDate: date });
    }

    onExpiryFromDate = (date) => {
        this.setState({ filterExpiredFromDate: date });
    }

    onExpiryToDate = (date) => {
        this.setState({ filterExpiredToDate: date });
    }

    filterSubmit = () => {
        let toDate, fromDate, filteredData = this.props.Tasks;
        if (this.state.hasGc) {
            const stateFilterCompany = this.state.filterCompany;
            if (Object.keys(this.state.filterCompany).length) {
                filteredData = filteredData.filter(function (item) {
                    if (item.gc_id !== 0) {
                        return item.gc_id in stateFilterCompany;
                    } else {
                        return item.company_id in stateFilterCompany;
                    }
                });
            }
        }

        if (Object.keys(this.state.filterAddedBy).length) {
            filteredData = filteredData.filter(item => item.contact_id in this.state.filterAddedBy);
        }

        if (Object.keys(this.state.filterApprovedBy).length) {
            filteredData = filteredData.filter(item => item.task_json.object_data.headerinfo.approvedBy in this.state.filterApprovedBy);
        }

        if (Object.keys(this.state.filterPromotion).length) {
            filteredData = filteredData.filter(item => item.task_json.object_data.headerinfo.promotion in this.state.filterPromotion);
        }

        if (Object.keys(this.state.filterStatus).length) {
            filteredData = filteredData.filter(item => item.cur_lane in this.state.filterStatus);
        }

        if (Object.keys(this.state.filterReviewedBy).length) {
            filteredData = filteredData.filter(item => item.task_json.object_data.rvw_rqrd_assignto in this.state.filterReviewedBy);
        }


        // Dates

        //added date and approved date and review date and expiry date filter using OR
        toDate = Utils.endOfTheDay(this.state.filterRequestedToDate);
        fromDate = this.state.filterRequestedFromDate;

        let appprovedToDate = Utils.endOfTheDay(this.state.filterApprovedToDate);
        let appprovedFromDate = this.state.filterApprovedFromDate;

        let reviewToDate = Utils.endOfTheDay(this.state.filterReviewedToDate)
        let reviewFromDate = this.state.filterReviewedFromDate;

        let expiryToDate = Utils.endOfTheDay(this.state.filterExpiredToDate);
        let expiryFromDate = this.state.filterExpiredFromDate;

        filteredData = filteredData
            .filter(item => {
                let actionDate = new Date(moment.unix(item.created_at).format('MM/DD/YYYY')); //item.actiondate; //
                let approvedDate = item.task_json.object_data.headerinfo.hasOwnProperty('approvedDate') ? item.task_json.object_data.headerinfo.approvedDate : null;
                if (approvedDate !== "") {
                    approvedDate = moment(approvedDate, 'DD/MM/YYYY').toDate();
                } else {
                    approvedDate = null;
                }

                let reviewedDate = item.task_json.object_data.hasOwnProperty('rvw_rqrd_rvwdate') ? item.task_json.object_data.rvw_rqrd_rvwdate : null;
                if (reviewedDate !== null) {
                    if (item.cur_lane !== 'COMP_FINPROMO_REGISTERED' || item.cur_lane !== 'COMP_FINPROMO_REVISION') {
                        reviewedDate = new Date(reviewedDate);
                    }
                } else {
                    reviewedDate = null;
                }

                let expiryDate = item.task_json.object_data.hasOwnProperty('expirydt') ? item.task_json.object_data.expirydt : null;
                if (expiryDate !== null) {
                    expiryDate = new Date(expiryDate);
                } else {
                    expiryDate = null;
                }

                if (!actionDate) {
                    return true;
                }
                return ((actionDate >= fromDate && actionDate <= toDate)
                    || (approvedDate != null && (approvedDate >= appprovedFromDate && approvedDate <= appprovedToDate))
                    || (reviewedDate != null && (reviewedDate >= reviewFromDate && reviewedDate <= reviewToDate))
                    || (expiryDate != null && (expiryDate >= expiryFromDate && expiryDate <= expiryToDate))
                )
            });


        filteredData.sort((a, b) => Number(b.id) - Number(a.id));
        this.props.filterSubmitValue(filteredData);
    }

    filterClear = () => {
        let filterFromdate = new Date();
        let updateFromDate = new Date(filterFromdate.getFullYear(), filterFromdate.getMonth() - 12, 1);
        this.setState({
            isclear: true,
            filterAddedBy: {},
            filterApprovedBy: {},
            filterReviewedBy: {},
            filterPromotion: {},
            filterStatus: {},
            filterRequestedFromDate: updateFromDate,
            filterRequestedToDate: new Date(),
            filterApprovedFromDate: updateFromDate,
            filterApprovedToDate: new Date(),
            filterReviewedFromDate: updateFromDate,
            filterReviewedToDate: new Date(),
            filterExpiredFromDate: updateFromDate,
            filterExpiredToDate: new Date(),
            filterCompany: {}
        });

        const updateTasks = [];
        this.props.Tasks.forEach((item) => {
            if (item.cur_lane !== "COMP_FINPROMO_DELETED")
                updateTasks.push(item)
        })
        this.props.filterSubmitValue(updateTasks);
    }

    changeClear = () => {
        this.setState({ isclear: false })
    }

    render() {
        let hasGroupCompany = this.state.hasGc;
        return (
            <div>
                <FilterTopBar style={{ cursor: "pointer" }} onClick={this.toggleFilter}>
                    <FilterText>Advanced Filters</FilterText>
                    <FilterChevron>
                        {(this.state.show_filter)
                            ? <IoIosArrowUp />
                            : <IoIosArrowDown />
                        }
                    </FilterChevron>
                    <ClearBoth />
                </FilterTopBar>
                {this.state.show_filter &&
                    <FilterBody>
                        <ClearBoth />
                        {hasGroupCompany && <Section40 has_gc={hasGroupCompany}>
                            <FieldLabel>Company</FieldLabel>
                            <CSLTypeAhead
                                data={this.state.usedCompanyName}
                                recipientValue={this.onCompanyClicked}
                                isclear={this.state.isclear}
                                changeClear={this.changeClear}
                            />
                        </Section40>}
                        <Section40 has_gc={hasGroupCompany}>
                            <FieldLabel>Promotion Types</FieldLabel>
                            <CSLTypeAhead
                                data={this.state.promotion_types}
                                recipientValue={this.onPromotion}
                                isclear={this.state.isclear}
                                changeClear={this.changeClear}
                            />
                        </Section40>
                        <Section40 has_gc={hasGroupCompany}>
                            <FieldLabel>Status</FieldLabel>
                            <CSLTypeAhead
                                data={this.state.statusData}
                                recipientValue={this.onStatus}
                                isclear={this.state.isclear}
                                changeClear={this.changeClear}
                            />
                        </Section40>
                        <ClearBoth />

                        <Section40 has_gc={hasGroupCompany} style={{ paddingTop: '8px' }}>
                            <FieldLabel>Added By</FieldLabel>
                            <CSLTypeAhead
                                data={this.state.usedAddedBy}
                                recipientValue={this.onAddedBy}
                                isclear={this.state.isclear}
                                changeClear={this.changeClear}
                            />
                        </Section40>
                        <Section40 has_gc={hasGroupCompany} style={{ paddingTop: '8px' }}>
                            <FieldLabel>Approved By</FieldLabel>
                            <CSLTypeAhead
                                data={this.state.usedApprovedBy}
                                recipientValue={this.onApprovedBy}
                                isclear={this.state.isclear}
                                changeClear={this.changeClear}
                            />
                        </Section40>
                        <ClearBoth />

                        <Section40 has_gc={hasGroupCompany}>
                            <FieldLabel style={{ marginTop: "20px" }}>Added Date</FieldLabel>
                            <Section45>
                                <CSLDatePicker
                                    updateFromDate={this.state.updateFromDate}
                                    ondateValue={this.onRequestedFromDate}
                                    isclear={this.state.isclear}
                                    changeClear={this.changeClear}
                                />
                            </Section45>
                            <Section10>
                                <Icon icon={androidRemove} />
                            </Section10>
                            <Section45>
                                <CSLDatePicker
                                    ondateValue={this.onRequestedToDate}
                                    isclear={this.state.isclear}
                                    changeClear={this.changeClear}
                                />
                            </Section45>
                            <ClearBoth />
                        </Section40>
                        <Section40 has_gc={hasGroupCompany}>
                            <FieldLabel style={{ marginTop: "20px" }}>Approved Date</FieldLabel>
                            <Section45>
                                <CSLDatePicker
                                    updateFromDate={this.state.updateFromDate}
                                    ondateValue={this.onApprovedFromDate}
                                    isclear={this.state.isclear}
                                    changeClear={this.changeClear}
                                />
                            </Section45>
                            <Section10>
                                <Icon icon={androidRemove} />
                            </Section10>
                            <Section45>
                                <CSLDatePicker
                                    ondateValue={this.onApprovedToDate}
                                    isclear={this.state.isclear}
                                    changeClear={this.changeClear}
                                />
                            </Section45>
                            <ClearBoth />
                        </Section40>
                        <ClearBoth />
                        {(() => {
                            if (this.state.isReviewDateEnabledFromConfig) {
                                return (
                                    <React.Fragment>
                                        <Section40 has_gc={hasGroupCompany}>
                                            <FieldLabel style={{ marginTop: "20px" }}>Review By</FieldLabel>
                                            <CSLTypeAhead
                                                data={this.state.usedReviewedBy}
                                                recipientValue={this.onReviewedBy}
                                                isclear={this.state.isclear}
                                                changeClear={this.changeClear}
                                            />
                                        </Section40>
                                        <Section40 has_gc={hasGroupCompany}>
                                            <FieldLabel style={{ marginTop: "20px" }}>Review Date</FieldLabel>
                                            <Section45>
                                                <CSLDatePicker
                                                    updateFromDate={this.state.updateFromDate}
                                                    ondateValue={this.onReviewedFromDate}
                                                    isclear={this.state.isclear}
                                                    changeClear={this.changeClear}
                                                />
                                            </Section45>
                                            <Section10>
                                                <Icon icon={androidRemove} />
                                            </Section10>
                                            <Section45>
                                                <CSLDatePicker
                                                    ondateValue={this.onReviewedToDate}
                                                    isclear={this.state.isclear}
                                                    changeClear={this.changeClear}

                                                />
                                            </Section45>
                                            <ClearBoth />
                                        </Section40>
                                    </React.Fragment>
                                )
                            }
                            return null;
                        })()}
                        <ClearBoth />
                        {(() => {
                            if (this.state.isExpiryDateEnabledFromConfig) {
                                return (
                                    <React.Fragment>
                                        <Section40 has_gc={hasGroupCompany}>
                                            <FieldLabel style={{ marginTop: "20px" }}>Expiry Date</FieldLabel>
                                            <Section45>
                                                <CSLDatePicker
                                                    updateFromDate={this.state.updateFromDate}
                                                    ondateValue={this.onExpiryFromDate}
                                                    isclear={this.state.isclear}
                                                    changeClear={this.changeClear}
                                                />
                                            </Section45>
                                            <Section10>
                                                <Icon icon={androidRemove} />
                                            </Section10>
                                            <Section45>
                                                <CSLDatePicker
                                                    ondateValue={this.onExpiryToDate}
                                                    isclear={this.state.isclear}
                                                    changeClear={this.changeClear}
                                                />
                                            </Section45>
                                            <ClearBoth />
                                        </Section40>
                                    </React.Fragment>
                                )
                            }
                            return null;
                        })()}


                        <ClearBoth />

                        <FilterButtons>
                            <FilterClearBtn onClick={this.filterClear}>Clear</FilterClearBtn>
                            <FilterApplyBtn onClick={this.filterSubmit}>Apply</FilterApplyBtn>
                        </FilterButtons>
                        <ClearBoth />

                    </FilterBody>
                }
            </div>
        );
    }
}

export default AdvancedFilter;
