import Store from '../../Common/Store.js';
import axios from 'axios';
import http from 'mcc-front-aux/dist/httpCommon'


class UploadFile {
    constructor () {
        this.is_debug = Store.getStoreData('is_debug');
        this.api_url = Store.getStoreData('base_api_url');
        this.contact_id = Store.getStoreData('contact_id');
        this.company_id = Store.getStoreData('curCompanyID');

    }

    command(postData, callback, uploadCallback = null) {
        let url = this.api_url + "/uploaddata"

        let jsonDataString = JSON.stringify(postData);

        const payload = JSON.parse(jsonDataString);
        console.log('jsonDataString ==>', payload);
        const data = new FormData();



        for (var i = 0; i < payload.length; i++) {
            data.append(`model[${i}].name`, payload[i].name);
            data.append(`model[${i}].bin_file`, payload[i].bin_file);
            data.append(`model[${i}].company_id`, this.company_id);
            data.append(`model[${i}].contact_id`, this.contact_id);
        }

        var config = {
            method: 'post',
            url: url,
            headers: {
                // 'Content-Type': 'application/json'
                'Content-Type': 'multipart/form-data'
            },
            data: data,
            onUploadProgress: (progressEvent) => {
                const { loaded, total } = progressEvent;
                if (uploadCallback !== null) uploadCallback(progressEvent);
            }
        };

        console.log("config data", config)

        http.request(config)
            .then(function (response) {
                callback(response.data);
            })
            .catch(function (error) {
                //console.log(error);
                callback(error);
                // callback("null");
            });


    }
}

export default UploadFile;
