import React from 'react';
import { FaTimes } from 'react-icons/fa';
import styled from "styled-components";

const InactiveOverlay = styled.div`
  display: block;
  box-sizing: border-box;
  height: calc(100vh - 60px);
  width: calc(100vw - 300px);
  background-color: #ffffff;
  position: fixed;
  left: 300px;
  top: 60px;
  z-index: 1000;
  opacity: 0.8;
`;

const ModalContainer = styled.div`
  position: fixed;
  background-color: #ffffff;
  top: 100px;
  left: calc(50vw - 250px);
  width: 650px;
  box-sizing: border-box;
  height: 400px;
  min-height: 300px;
  box-sizing: border-box;
  background-color: #f3f7fb;
  border: 1px solid #f3f7fb;
  border-radius: 5px;
  z-index: 1010;
  opacity: 1;
`;
const ModalHeader = styled.div`
  width: 100%;
  background-color: #ffffff;
  box-sizing: border-box;
  height: 100px;
  min-height: 100px;
  box-sizing: border-box;
  border: 1px solid #f3f7fb;
  border-left: 7px solid #04ada8;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  z-index: 1020;
  opacity: 1;
`;
const ModalBody = styled.div`
  width: 100%;
  padding-left: 15px;
  background-color: #ffffff;
  box-sizing: border-box;
  height: 200px;
  min-height: 200px;
  box-sizing: border-box;
  border: 1px solid #f3f7fb;
  z-index: 1020;
  opacity: 1;
`;
const ModalFooter = styled.div`
  width: 100%;
  background-color: #ffffff;
  box-sizing: border-box;
  height: 100px;
  min-height: 100px;
  box-sizing: border-box;
  border: 1px solid #f3f7fb;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  z-index: 1020;
  opacity: 1;
`;

const ModalHeaderTextLarge = styled.div`
  padding-left: 10px;
  padding-top: 20px;
  padding-bottom: 2px;
  padding-right: 0px;
  width: calc(100% - 60px);
  color: #5d748e;
  font-size: 16px;
  font-weight: 700;
  float: left;
`;
const ModalHeaderClose = styled.div`
  padding-right: 10px;
  padding-top: 20px;
  padding-bottom: 2px;
  padding-left: 0px;
  width: 30px;
  color: #5d748e;
  font-size: 20px;
  font-weight: 700;
  float: right;
  cursor: pointer;
`;
const ModalHeaderTextSmall = styled.div`
  padding-left: 10px;
  padding-top: 3px;
  width: calc(100% - 10px);
  color: #5d748e;
  font-size: 12px;
  font-weight: 700;
`;

const ClearFloat = styled.div`
  clear: both;
`;

const ModalLabel = styled.div`
  color: #212121;
  font-weight: 600;
  margin-top: 15px;
  margin-bottom: 5px;
  display: inline-block;
`;

const ModalInput = styled.input`
  height: 25px;
  width: 99%;
  box-shadow: rgb(196, 196, 196) 0px 0px 4px;
  color: #545454;
  font-family: montserrat;
  font-size: 12px;
  font-weight: 600;
  border-width: 1px;
  border-style: solid;
  border-color: rgb(255, 255, 255);
  border-image: initial;
  border-radius: 3px;
  padding: 6px;
`;

const RadioBlock = styled.div`
  margin-top: 10px;
`;

const SelectedRadio = styled.div`
  background-color: #37ada7;
  border: 1px solid #1f615e;
  padding: 5px 10px;
  color: #ffffff;
  display: inline-block;
  cursor: pointer;
`;
const UnselectedRadio = styled.div`
  background-color: #f4f4f4;
  border: 1px solid #8c8c8c;
  padding: 5px 10px;
  color: #262626;
  display: inline-block;
  cursor: pointer;
`;

const CancelButton = styled.button`
  padding: 10px 20px;
  background-color: #ffffff;
  border: 1px solid #37ada7;
  border-radius: 5px;
  color: #333333;
  cursor: pointer;
  margin-right: 5px;
`;
const SaveButton = styled.button`
  padding: 10px 30px;
  background-color: #37ada7;
  border: 1px solid #37ada7;
  border-radius: 5px;
  color: #ffffff;
  cursor: pointer;
  margin-right: 25px;
`;

class AddCheckListModal extends React.Component {
    state = {
        title: '',
        disabled: false

    };
    switchRadio = (value) => {
        this.setState({
            is_active: value,
        });
    };

    changeNickName = (event) => {
        let title = event.target.value;
        this.setState({
            title,
        });
    };

    saveCheckList = (event) => {
        event.preventDefault();
        if (this.state.title.trim() === "") {
            alert("You must specify a name for the checklist.");
            return;
        }
        if (this.state.disabled) {
            return;
        }
        this.setState({ disabled: true });

        const insertedTitle = this.state.title;
        let generated_id = insertedTitle.trim().replace(/\s+/g, '_').concat('_checklist').toLowerCase();
        let obj = {
            id: generated_id,
            title: this.state.title,
            questions: {

            }
        }
        this.props.saveCheckList(obj);
    };

    render() {
        return (
            <div>
                <InactiveOverlay />
                <ModalContainer>
                    <ModalHeader>
                        <ModalHeaderTextLarge> Add New Checklist </ModalHeaderTextLarge>{" "}
                        <ModalHeaderClose onClick={this.props.closeChecklistModal}>
                            <FaTimes />
                        </ModalHeaderClose>
                        <ClearFloat />
                        <ModalHeaderTextSmall>
                            Complete the required fields to create a new checklist
                        </ModalHeaderTextSmall>
                    </ModalHeader>{" "}
                    <ModalBody>
                        <ModalLabel> Checklist Name </ModalLabel>
                        <ModalInput
                            style={{
                                width: "95%",
                            }}
                            onChange={this.changeNickName}
                            value={this.state.title}
                        />
                        <ModalLabel> Active ? </ModalLabel>
                        {(() => {
                            if (this.state.is_active === 1) {
                                return (
                                    <RadioBlock>
                                        <SelectedRadio> Yes </SelectedRadio>{" "}
                                        <UnselectedRadio onClick={() => this.switchRadio(0)}>
                                            No
                                        </UnselectedRadio>
                                    </RadioBlock>
                                );
                            }
                            return (
                                <RadioBlock>
                                    <UnselectedRadio onClick={() => this.switchRadio(1)}>
                                        Yes
                                    </UnselectedRadio>
                                    <SelectedRadio> No </SelectedRadio>
                                </RadioBlock>
                            );
                        })()}
                    </ModalBody>
                    <ModalFooter>
                        <SaveButton
                            onClick={this.saveCheckList}
                            disabled={this.state.disabled}
                            style={{
                                float: "right",
                                marginTop: 15,
                                backgroundColor: "#143151",
                            }}
                        >
                            {this.state.disabled ? 'Submitting...' : 'Submit'}
                        </SaveButton>
                        <CancelButton
                            onClick={this.props.closeChecklistModal}
                            style={{
                                float: "right",
                                marginTop: 15,
                                backgroundColor: "#ffffff",
                            }}
                        >
                            Cancel
                        </CancelButton>
                    </ModalFooter>
                </ModalContainer>
            </div>
        );
    }
}

export default AddCheckListModal;