import React from 'react';
import PropTypes from "prop-types";
import { FaEdit, FaTimes, FaPlusCircle } from "react-icons/fa";
import styled from 'styled-components';
import CSLTable from '../Common/CSLTable';
// import PromotionEditModal from './PromotionEditModal';
import PromotionEditModal from './PromotionEditModal';
import CSLToggle from '../Common/CSLToggle';
import APICall from '../../Common/APICall';



const SearchInput = styled.input`
    height: 30px;
    width: 98%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #222222;
    font-size: 15px;
    font-family: 'Montserrat', sans-serif;
`;
const TypesContainer = styled.div`
	width: 100%;
`;
const TypesInnerContainer = styled.div`
	background-color: #ffffff;
	padding: 18px;
`;
const TypesBoldLabel = styled.div`
	font-weight: 600;
	color: #595959;
	margin-top: 5px;
`;
const EmailContainer = styled.div`
	width: 90%;
`;
const EmailInnerContainer = styled.div`
	background-color: #ffffff;
	padding: 30px;
`;
const EmailBoldLabel = styled.div`
	font-weight: 600;
	color: #595959;
	margin-top: 5px;
`;
const SelectedRadio = styled.div`
	background-color: #37ADA7;
	border: 1px solid #1f615e;
	padding: 5px 10px;
	color: #ffffff;
	display: inline-block;
	cursor: pointer;
`;
const UnselectedRadio = styled.div`
	background-color: #F4F4F4;
	border: 1px solid #8c8c8c;
	padding: 5px 10px;
	color: #262626;
	display: inline-block;
	cursor: pointer;
`;
const RadioBlock = styled.div`
	margin-top: 10px;
`;

class PromotionTypes extends React.Component {
    state = {
        section_name: null,
        client_types: null,
        client_types_active_inactive: null,
        active_filter: null,
        inactive_filter: null,
        show_edit_modal: false,
        curid: null,
        showActiveCheckbox: false,
        showChangeSectionModal: null,
        rowchecked: false,
        isToggleToViewTable: false,
        general: null,
        promotion_types: null,
        check_all: false
    }

    setComponentState = () => {
        this.setState({
            general: this.props.general,
            promotion_types: this.props.promotion_types
        });
    }
    componentDidMount = () => {
        this.setComponentState()
    }

    componentDidUpdate = (prevprops) => {
        if(this.props === prevprops)return
        this.setComponentState()
    }

    savePromotionType = (r, pr_id, is_new) => {
        console.log('r, pr_id, is_new', r, pr_id, is_new)
        let api = new APICall()
        let postData = {command: is_new ? 'add_record' : 'save_record'}
        postData.record = is_new ? {__type: 'obj', value: r} : r
        api.command(postData, this.processToggleStatus)
    }

    addReasonCode = () => {
        // event.preventDefault();
        this.setState({ show_edit_modal: true, curid: '0' });
    }

    openModal = (id) => {
        console.log('id', id)
        // event.preventDefault();
        this.setState({ show_edit_modal: true, curid: id });
    }

    deleteType = (pr_id) => {
        let record = JSON.parse(JSON.stringify(this.state.promotion_types.value[pr_id]))
        record.isDelete = 1
        let api = new APICall()
        let postData = {command: 'save_record', record: record}
        api.command(postData, this.processToggleStatus)
    }

    toggleStatus = (s, pr_id) => {
        console.log('s, pr_id, promotion_type', s, pr_id, this.state.promotion_types.value[pr_id])
        let record = JSON.parse(JSON.stringify(this.state.promotion_types.value[pr_id]))
        record.isActive = record.isActive === 1 ? 0 : 1
        let api = new APICall()
        let postData = {command: 'save_record', record: record}
        api.command(postData, this.processToggleStatus)
    }

    processToggleStatus = (result) => {
        if(result.error_code > 0) {
            alert('ERROR: The server encountered an unexpected error. Please try after some time')
            return
        }
        // alert('Custom fields saved successfully.')
        let promotion_types = this.state.promotion_types
        let record = result.result.record
        promotion_types.value[record.pr_id] = record
        console.log('promotion_types, record', promotion_types, record)
        this.setState({promotion_types, show_edit_modal: false})

    }

    toggleCheckAll = () => {
        let check_all = !this.state.check_all
        this.setState({check_all})
    }

    tableData = () => {

        let table = { data: [], columns: [] };
        let count = 0
        let ToggleCheckAll = <CSLToggle id={'0'} change_state={false} checked={this.state.check_all} onClick={this.toggleCheckAll} />

        table.columns = [
            {
                Header: '#',
                accessor: 'index',
                width: 30,
                headerStyle: { textAlign: 'center' },
                style: { fontFamily: "monospace", fontSize: "14px", textAlign: 'right' }
            },
            {
                Header: 'Type / Questions',
                accessor: 'type',
                headerStyle: { textAlign: 'left' },
                style: { whiteSpace: 'unset' }
            },
            {
                Header: 'Status', accessor: 'status', width: 80, headerStyle: { textAlign: 'center' }, Cell: row => (
                    <CSLToggle id={row.original.id} change_state={false} checked={row.original.status === 1} onClick={this.toggleStatus} />
                ), style: { display: 'flex', alignItems: 'center', justifyContent: 'center' }
            }
            ,
            {
                Header: 'Action', Cell: row => (
                    <div>
                        {
                            (() => {
                                if (true) {
                                    return (<div>
                                        <FaEdit onClick={() => this.openModal(row.original.id)} style={{
                                            cursor: 'pointer',
                                            fontSize: '18px',
                                            color: '#b4b5b4',
                                            display: 'inline-block',
                                            marginRight: "5px"
                                        }} />
                                        <FaTimes onClick={() => this.deleteType(row.original.id)} style={{
                                            cursor: 'pointer',
                                            fontSize: '18px',
                                            color: '#b4b5b4',
                                            display: 'inline-block'
                                        }} />
                                    </div>)
                                } else {
                                    return (<div>
                                        <FaEdit onClick={this.notDeleteType} style={{
                                            cursor: 'pointer',
                                            fontSize: '18px',
                                            color: '#b4b5b4',
                                            display: 'inline-block',
                                            marginRight: "5px"
                                        }} />
                                        <FaTimes onClick={this.notDeleteType} style={{
                                            cursor: 'pointer',
                                            fontSize: '18px',
                                            color: '#b4b5b4',
                                            display: 'inline-block'
                                        }} />
                                    </div>)
                                }
                            })()
                        }
                    </div>
                ), width: 55, headerStyle: { textAlign: 'center' }
            },
        ];

        for (const [key, value] of Object.entries(this.state.promotion_types.value)) {
            console.log('value', value)
            
            if(value.isDelete === 0) {
                count++
                table.data.push({
                    index: count,
                    'id': key,
                    'type': value.title,
                    'status': value.isActive,
                    'isDelete': value.isDelete
                });
            }
                
        }
        console.log('table.data', table.data)
        return table;
    }

    switchRadio = () => {
        // event.preventDefault()
        let general = this.state.general;
        general.withdrawn_reason_codes_enable = !general.withdrawn_reason_codes_enable 
        console.log('general', general)
        let api = new APICall()
        let postData = {command: 'save_general', general: general}
        api.command(postData, this.processUpdateGeneral)
        // this.setState({general})
    }

    processUpdateGeneral = (result) => {
        console.log('result', result)
        if(result.error_code > 0) {
            alert('ERROR: The server encountered an unexpected error. Please try after some time')
            return
        }
        let general = this.state.general 
        general.value = result.result.general
        alert('Update successful.')
        this.setState({general})
    }

    closeModal = () => {
        this.setState({show_edit_modal: false})
    }

    render() {
        if (this.state.general === null)return (<div>Loading...</div>);
        console.log('this.state reasons', this.state)
        return (
            <div>

                {this.state.show_edit_modal &&
                    <PromotionEditModal
                                  promotion_types={this.state.promotion_types.value}
                                  parent_id={this.state.promotion_types.parent_id}
                                  savePromotionType={this.savePromotionType}
                                  pr_id={this.state.curid}
                                  closeModal={this.closeModal} />
                }
                <div style={{ width: "90%" }}>
                    {
                        (() => {
                                return (
                                    <React.Fragment>
                                        <div>
                                            <SearchInput style={{ width: "97%", marginTop: "15px" }}
                                                placeholder="Search..."
                                                onChange={this.searchUsers} />
                                        </div>
                                        <TypesContainer>
                                            <TypesInnerContainer>
                                                <div style={{ display: "flow-root" }}>
                                                    <div style={{ float: "left" }}>
                                                        <TypesBoldLabel
                                                            style={{ display: "inline-block" }}>PROMOTION Types</TypesBoldLabel>
                                                        
                                                    </div>

                                                    <div onClick={this.addReasonCode}
                                                        style={{
                                                            float: "right",
                                                            cursor: "pointer",
                                                            fontSize: "17px",
                                                            color: "#04ADA8"
                                                        }}>
                                                        <FaPlusCircle />
                                                    </div>
                                                </div>

                                                <CSLTable add={false} processData={this.tableData} tableRows={20} />
                                            </TypesInnerContainer>
                                        </TypesContainer>
                                    </React.Fragment>
                                )
                            
                        })()
                    }
                </div>
            </div>
        );
    }
}

export default PromotionTypes;


// {(() => {
//                                                             if (true) {
//                                                                 return (
//                                                                     <FaEdit onClick={this.addReasonCode} style={{
//                                                                         cursor: 'pointer',
//                                                                         fontSize: '18px',
//                                                                         color: '#b4b5b4',
//                                                                         display: 'inline-block',
//                                                                         marginLeft: "12px"
//                                                                     }} />
//                                                                 )
//                                                             }
//                                                             return null;
//                                                         })()}
