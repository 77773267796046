import React from "react";
import PropTypes from "prop-types";
import styled from 'styled-components';

const MRButton = styled.button`
    box-sizing: border-box;
    background-color: #01B0A2;
    color: #ffffff;
    border: 1px solid #37ada7;
    min-width: 22px;
    font-size: 11px;
    height: 20px;
    border-radius: 1px;
    margin-right:3px;
`;
const MRButtonLight = styled.button`
    box-sizing: border-box;
    background-color: #F2F2F3;
    color: #37ada7;
    border: 1px solid #37ada7;
    min-width: 22px;
    font-size: 11px;
    height: 20px;
    border-radius: 1px;
    margin-right:3px;
    cursor: pointer;
`;


export default class Pagination extends React.Component {
  state = {visiblePages: []}

  setComponentState = () => {
    let visiblePages = this.getVisiblePages(this.props.currentPage, this.props.totalPages)
    this.setState({visiblePages})
  }
  componentDidMount = () => {
    this.setComponentState()
  }
  componentDidUpdate = (prevprops) => {
    if(prevprops === this.props)return
    this.setComponentState()
  }

  getVisiblePages = (page, total) => {
    console.log('visiblePages page, total', page, total)
    if (total < 7) {
      return this.filterPages([1, 2, 3, 4, 5, 6], total);
    } else {
      if (page % 5 >= 0 && page > 4 && page + 2 < total) {
        return [1, page - 1, page, page + 1, total];
      } else if (page % 5 >= 0 && page > 4 && page + 2 >= total) {
        return [1, total - 3, total - 2, total - 1, total];
      } else {
        return [1, 2, 3, 4, 5, total];
      }
    }
  };

  // changePage(page) {
  //   const activePage = this.props.page + 1;

  //   if (page === activePage) {
  //     return;
  //   }

  //   const visiblePages = this.getVisiblePages(page, this.props.pages);

  //   this.setState({
  //     visiblePages: this.filterPages(visiblePages, this.props.pages)
  //   });

  //   this.props.onPageChange(page - 1);
  // }

  render() {
    const { visiblePages } = this.state;
    const activePage = this.props.page + 1;
    console.log('visiblePages, this.props', visiblePages, this.props)

    return (
      <div className="Table__pagination">
        <div className="Table__prevPageWrapper">
            <MRButton onClick={() => {
              if (activePage === 1) return;
              this.changePage(activePage - 1);
            }}>{'<'}</MRButton>
        </div>
        <div className="Table__visiblePagesWrapper">
          {
            visiblePages.map((page, index, array) => {
              return (<>
                  {
                    (() => {
                      console.log('visiblePages this_page', page, this.props.currentPage)
                      if(page !== this.props.currentPage) {
                        return (
                          <MRButtonLight key={index} onClick={() => this.props.onPageClick(page)}>{array[index - 1] + 2 < page ? `...${page}` : page}</MRButtonLight>
                        );
                      } else {
                        return (
                          <MRButton key={index}>{array[index - 1] + 2 < page ? `...${page}` : page}</MRButton>
                        );
                      }
                    })()   
                  }             
              </>)
            
            })
          }
        </div>
        <div className="Table__nextPageWrapper">
            <MRButton onClick={() => {
              if (activePage === this.props.pages) return;
              this.changePage(activePage + 1);
            }}>{'>'}</MRButton>
        </div>
      </div>
    );
  }
}


