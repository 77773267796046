import React from 'react';
import PropTypes from "prop-types";
import styled from 'styled-components';
import { FaTimes } from 'react-icons/fa';
import Utils from '../../Common/Utils.js';

const MRModalContainer = styled.div`
  width: calc(100% - 450px);
  position: fixed;
  background-color: #f7f7f7;
  border-radius: 5px;
  box-shadow: 0 0 20px #a7a6a6;
  z-index: 100000;
  padding: 20px;
  top: 20vh;
  left: 360px

`;

const MRModalHeader = styled.div`
  display: block;
  box-sizing: border-box;
  width: 100%;
  padding: 24px 24px 15px 0px;
`;

const MRModalLabel = styled.div`
  color: #212121;
  font-weight: 600;
  margin-top: 10px;
  margin-bottom: 10px;
`;
const MRModalHeaderText = styled.div`
  box-sizing: border-box;
  float: left;
  color: #1A3552;
  font-weight: 600;
  font-size: 17px;
`;
const MRModalHeaderCloseBtn = styled.div`
  box-sizing: border-box;
  float: right;
  font-size: 16px;
  color: #a0a0a0;
  cursor: pointer;
  font-size: 20px;
`;
const RegModalNameInput = styled.input`
  height: 28px;
  width: 97%;
  border: 1px solid #ffffff;
  border-radius: 5px;
  box-shadow: 0 0 4px #c4c4c4;
  padding: 5px;
  color: #000000;
  background-color: #ffffff;
`;
const MRModalTextarea = styled.textarea`
  width: 98%;
  border: 1px solid #ffffff;
  border-radius: 3px;
  box-shadow: 0 0 4px #c4c4c4;
  padding: 5px;
  color: #000000;
`;
const SaveBtn = styled.button`
  padding: 10px 30px;
  background-color: #37ADA7;
  border: 1px solid #37ADA7;
  border-radius: 2px;
  color: #ffffff;
  cursor: pointer;
  margin-left: 15px;
`;
const MRModalSelect = styled.select`
  display: block;
  box-sizing: border-box;
  height: 40px;
  width: 20%;
  background-color: #fff;
  border: 1px solid #ffffff;
  border-radius: 3px;
  box-shadow: 0 0 4px #c4c4c4;
  font-family: 'Montserrat', sans-serif;
  color: #000000;
  margin-top: 10px;
  margin-bottom: 10px;
`;

class ConfigEditModal extends React.Component {

  static propTypes = {
    /** */
    sectionName: PropTypes.string,
    /** current ID*/
    curid: PropTypes.string,
    /** object with Types */
    client_types: PropTypes.object,
    /** callback to close Modal */
    closeModal: PropTypes.func,
    /** callback with selection*/
    saveValue: PropTypes.func,
  };

  state = {
    question: null,
  }

  closeView = () => {
    this.props.closeModal();
  }

  componentDidMount() {
    let questions = this.props.questions
    let q_id = this.props.q_id
    let question = q_id === '0' ? { title: '', isActive: 1, isDelete: 0, q_id: Utils.genKey(7), question_id: Utils.genKey(7), record_id: Utils.genKey(15), parent_id: this.props.parent_id } : questions[q_id]
    this.setState({ question })
  }

  handleChange = (evt) => {
    let key = [evt.target.name]
    let question = this.state.question
    question[key] = evt.target.value
    this.setState({ question });
  }

  submit = (event) => {
    let q = this.state.question
    q.isActive = parseInt(q.isActive)
    this.props.saveQuestion(q, this.props.q_id)
  }

  render() {
    console.log('this.state', this.state)
    if (this.state.question === null) return <div />
    return (
      <MRModalContainer>
        <MRModalHeader>
          <MRModalHeaderText>{this.props.q_id !== '0' ? 'Edit Checklist Item' : 'Add Checklist Item'}</MRModalHeaderText>
          <MRModalHeaderCloseBtn onClick={this.closeView}><FaTimes /></MRModalHeaderCloseBtn>
          <div style={{ clear: "both" }}></div>
        </MRModalHeader>
        <MRModalLabel style={{ marginTop: "30px" }}>Checklist item text</MRModalLabel>
        <RegModalNameInput name="title" value={this.state.question.title} onChange={this.handleChange} />
        <MRModalLabel style={{ marginTop: "30px" }}>Status</MRModalLabel>
        <MRModalSelect onChange={this.handleChange} name="isActive" value={this.state.question.isActive}>
          <option value="1">Active</option>
          <option value="0">Inactive</option>
        </MRModalSelect>
        <div style={{ float: "right" }}>
          <SaveBtn onClick={this.submit}>Submit</SaveBtn>
        </div>
      </MRModalContainer>
    )
  }
}

export default ConfigEditModal;
