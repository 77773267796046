import React from 'react';
import styled, { css } from 'styled-components';
// import {ClearBoth, Dropzone, CustomField, AlertBox} from '@mcc-regtech/core-library';
import ClearBoth from '../Common/ClearBoth';
import Dropzone from '../Common/Dropzone';
import AlertBox from '../Common/AlertBox';
import { FaArrowAltCircleLeft, FaArrowAltCircleRight, FaCalendarAlt, FaTimes, FaStarOfLife } from 'react-icons/fa';
import moment from 'moment';
import { APICall, CSLLoader, Utils } from '../Common';
import Store from '../../Common/Store.js';
import '../../App.css';
import HtmlEditor from './HtmlEditor';
import HtmlEditorDescription from './HtmlEditorDescription';
import DatePicker from 'react-date-picker';
import '../../Components/Common/CSLDatePickerForModal.css';
import CSLTextInput from './CSLTextInput';

moment.defaultFormat = 'DD/MM/YYYY';

const MRModalContainer = styled.div`
	display: block;
	box-sizing: border-box;
	height: auto;
	width: 812px;
	position: absolute;
	top: 38px;
	z-index: 1001;
	background-color: rgb(243, 247, 251);
	box-shadow: rgb(167, 166, 166) 0px 0px 5px;
	margin-bottom: 10px;
	border-radius: 5px;
	margin-left: 100px;
`;
const MRModalListContainer = styled.div`
	display: flex;
	flex-direction: row;
	width: 100%;
`;
const MRModalHeader = styled.div`
	background-color: white;
	display: block;
	padding-top: 5px;
	font-size: 14px;
	font-weight: 700;
	height: 100px;
	width: calc(100% - 10px);
	border-width: 1px 1px 1px 10px;
	border-left: 9px solid #04ada8;
	border-bottom: 1px solid #dfdfdf;
`;
const MRModalHeaderText = styled.div`
	font-weight: 700;
	font-size: 22px;
	float: left;
	margin-top: 24px;
	margin-left: 35px;
	color: #143151;
`;
const MRModalSubHeaderText = styled.div`
	font-weight: 700;
	font-size: 13px;
	float: left;
	margin-left: 35px;
	margin-bottom: 20px;
	margin-top: 10px;
	color: #636363;
`;
const MRModalHeaderCloseBtn = styled.div`
	position: absolute;
	top: 8px;
	right: 10px;
	box-sizing: border-box;
	margin-top: 24px;
	margin-right: 20px;
	font-size: 25px;
	cursor: pointer;
	font-weight: 200;
`;
const MRModalBody = styled.div`
	position: relative;
	display: block;
	box-sizing: border-box;
	background-color: rgb(243, 247, 251);
	padding: 25px;
	border-radius: 3px;
`;
const MRModalLabel = styled.div`
	color: #030303;
	font-weight: 600;
	margin-top: 15px;
	font-size: 15px;
	margin-bottom: 15px;
`;
const MRModalFooter = styled.div`
	position: relative;
	padding: 17px;
	background-color: white;
	height: 80px;
	border-style: solid;
	border-width: 1px;
	border-color: #f3f7fb;
	// width: 100%;
	border-top: 1px solid #dfdfdf;
`;
const MRModalButton = styled.button`
	display: inline-block;
	box-sizing: border-box;
	color: #ffffff;
	width: 145px;
	height: 45px;
	border-radius: 3px;
	margin-left: 5px;
	font-size: 15px;
	margin-left: 10px;
	float: right;
	cursor: pointer;
`;
const MRModalWithdrawBtn = styled(MRModalButton)`
	background-color: #CE6666;
	border: 1px solid #CE6666;
`;


const MRModalNextBtn = styled(MRModalButton)`
	background-color: #37ada7;
	border: 1px solid #37ada7;
	${(props) =>
		props.disabled
			? css`
                    background-color: #cccccc;
                    cursor: not-allowed !important;
                    border: none;
                  `
			: css`
                    cursor: pointer !important;
                  `};
`;


const MRModalSaveCloseBtn = styled(MRModalButton)`
	background-color: #143151;
	border: 1px solid #143151;
	${(props) =>
		props.disabled
			? css`
				background-color: #cccccc;
				cursor: not-allowed !important;
				border: none;
			`
			: css`
				cursor: pointer !important;
			`};
`;
const MRModalCancelBtn = styled(MRModalButton)`
	background-color: #ffffff;
	color: #545454;
	border: 2px solid #dadada;
	${(props) =>
		props.disabled
			? css`
				background-color: #cccccc;
				cursor: not-allowed !important;
				border: none;
			`
			: css`
				cursor: pointer !important;
			`};
`;
const MRModalTextarea = styled.textarea`
	width: 99%;
	border: 1px solid #ffffff;
	border-radius: 3px;
	box-shadow: 0 0 4px #c4c4c4;
	padding: 5px;
	color: #000000;
	font-family: 'Montserrat', sans-serif;
`;
const MRModalSelect = styled.select`
	box-sizing: border-box;
	height: 40px;
	width: 100%;
	background-color: #fff;
	border: 1px solid #ffffff;
	border-radius: 3px;
	box-shadow: 0 0 4px #c4c4c4;
	font-family: Montserrat, sans-serif;
	color: #5f6368;
`;
const MRModalInput = styled.input`
	height: 25px;
	width: 99%;
	box-shadow: rgb(196, 196, 196) 0px 0px 4px;
	color: #545454;
	font-family: montserrat;
	font-size: 14px;
	font-weight: 600;
	border-width: 1px;
	border-style: solid;
	border-color: rgb(255, 255, 255);
	border-image: initial;
	border-radius: 3px;
	padding: 6px;
`;
const CSLDateCover = styled.div`
	width: 30%;
	box-sizing: border-box;
	height: 40px;
	box-shadow: rgb(196, 196, 196) 0px 0px 4px;
	color: #545454;
	// font-size: 14px;
	// font-weight: 600;
	border-width: 1px;
	border-style: solid;
	border-color: rgb(255, 255, 255);
	border-image: initial;
	border-radius: 3px;
	background-color: #ffffff;
	position: relative;

	& > div {
		padding: 3px 4px;
		border: none;

		& > div > button {
			padding-top: 9px;
		}
	}
`;
const ModalNameLabelDiv = styled.div`
	height: auto;
	width: 100%;
	padding-top: 30px;
	font-weight: 600;
	color: #1a3552;
`;
const ModalNameInputDiv = styled.div`
	height: auto;
	width: 100%;
	padding-top: 30px;
	color: #1a3552;
	font-family: 'Montserrat', sans-serif;
`;
const RadioOption = styled.input`
	margin: 10px;
	width: 16px;
	height: 16px;
`;
const MRModalLabelReasonCode = styled.div`
	color: #1a3552;
	font-weight: 600;
	margin-top: 5px;
	font-size: 15px;
	margin-bottom: 5px;
	margin-left: 15px;
`;

const MRModalSelectReasonCodes = styled.select`
	box-sizing: border-box;
	height: 40px;
	width: 45%;
	background-color: #fff;
	border: 1px solid #ffffff;
	border-radius: 3px;
	box-shadow: 0 0 4px #c4c4c4;
	font-family: Montserrat, sans-serif;
	color: #5f6368;
	margin-left: 14px;
`;
const RadioGroup = (props) => {
	return React.Children.map(props.children, (child) => {
		if (child.type === RadioOption)
			/* The React.cloneElement() function returns a copy of a specified element.
			 Additional props and children can be passed on in the function.
			You would use this function when a parent component wants to add or modify the prop(s) of its children. */
			return React.cloneElement(child, {
				type: 'radio',
				defaultChecked: props.value === child.props.value,
				name: props.name,
				disabled: props.disabled,
				//onChange: props.handleChange
				onChange: props.onChange
			});
		return child;
	});
};

class TaskModal extends React.Component {
	state = {
		contact_id: Store.getStoreData('contact_id'),
		contacts: Utils.getContacts(),
		promotions: Utils.getLists('promotion_types'),
		checklists: Utils.getChecklists(),
		fields: Store.getStoreData('fields'),
		role: Store.getStoreData('role'),
		gc_company: Store.getStoreData('gc_company'),
		curCompanyID: Store.getStoreData('curCompanyID'),
		gc_companies: null,
		q_index: null,
		/* Form fields */
		company: 0,
		name: '',
		description: '',
		promotion: null,
		urlLink: '',
		checklist: '',
		comments: '',
		questions: {},
		/* Task fields */
		Ref_ID: null,
		submit_form: null,
		curlane: null,
		taskid: null,
		is_gc: false,
		cur_gc_id: null,
		companyID: 0,
		due_date: '1970-01-01',
		binFiles: [],
		cur_files_json: null,
		/* Helpers */
		ready: false,
		toggleView: false,
		ctaWithdraw: true,
		ctaSaveClose: true,
		ctaSubmit: true,
		readOnly: false,
		// preventing multiple insert
		disabled: false,
		review_date: new Date(),
		expiry_date: new Date(),
		reviewee_name: '',
		due_by: moment().format('DD/MM/YYYY'),
		expiry_due_by: moment().format('DD/MM/YYYY'),
		assign_to: 0,
		isWithdrawClicked: false,
		reasonCodes: Store.getStoreData('module_config').reason_codes,
		selectedReasonCode: '',
		custom_fields: Store.getStoreData('custom_fields'),
		isRevieweeDropDownChanged: false,
		followUpTaskShowPrompt: false,
		isSubmitClicked: false,
		ischecked: false,
		all_options: [],
		//**************************
		roles: Store.getStoreData('userroles'),
		isLoadedIcon: false,
		//**************************

		submitFromValue: 0,
		submittedQuestions: {},
		//isFilesUploaded : false
		//submittedBinFiles:[]
		//regulatorLists: [],
		assign_data: null,
		current_question_ids: [],
		isEnabledExpiryDate: Store.getStoreData('module_config').general.add_expiry_date_for_financial_promotions,
	};

	constructor (props) {
		super(props);
		this.api = new APICall();
		this.resetChecklist = this.resetChecklist.bind(this);
		this.toggleView = this.toggleView.bind(this);
		this.keyCount = 0;
		this.getKey = this.getKey.bind(this);
	}

	getKey() {
		return this.keyCount++;
	}

	validateCustomFields = (state) => {

		let err_msg, message = '';
		let problems = true;
		const module_config = Store.getStoreData('module_config');
		if (module_config.custom_fields.enabled) {


			let fields = [];
			if (module_config.custom_fields.additional_fields.length !== 0) {
				this.state.assign_data.additional_fields.forEach((item) => {
					if (item.required === true && item.value === "") {
						fields.push(item.nickname);
					}
				})
			}
			if (fields.length !== 0) {
				let err_msg = "";
				fields.forEach((item) => {
					err_msg = err_msg + item + ", ";
				})
				err_msg = err_msg.substring(0, err_msg.length - 2);
				if (fields.length === 1) {
					err_msg = err_msg + " field is mandatory. ";
				} else {
					err_msg = err_msg + " fields are mandatory. ";
				}
				problems = false
				message += err_msg
				if (problems == false) {
					alert(err_msg);
				}

			}
		}
		return problems;
	};

	//first render
	componentDidMount() {
		//debugger;
		const gc_companies = Store.getStoreData('gc_company');
		const gc_company = Store.getStoreData('gc_company');
		console.log('gc_companies ==>', gc_companies);
		console.log('gc_company ==>', gc_company);
		const q_index = Store.getStoreData('q_index');
		let module_config = Store.getStoreData('module_config');
		let cur_gc_id = 0;
		let is_gc = false;
		let form_data = {};
		let assign_data = {}

		let gcs = []
		for (let g in gc_companies) {
			if (gc_companies[g].is_active === 1) {
				gcs.push(gc_companies[g])
			}
		}

		gcs = this.getGcsLoggedInRoleWise(gcs);
		console.log("gcs", gcs)


		let additional_fields = [];
		if (Object.keys(module_config).length > 0) {
			if (module_config.custom_fields.additional_fields.length !== 0 && module_config.custom_fields.enabled) {
				module_config.custom_fields.additional_fields.forEach((item) => {
					let copy_item = JSON.parse(JSON.stringify(item));
					copy_item.value = item.type === "Calendar" ? new Date() : "";
					copy_item.value = item.type === "Dropdown" ? "" : copy_item.value;
					additional_fields.push(copy_item);
				})
			}
		}
		//debugger;
		//end displaying review list
		assign_data.additional_fields = additional_fields;
		//assign_data.additional_fields = "additional_fields" in task_json.object_data.headerinfo ? task_json.object_data.headerinfo.additional_fields : [];
		//console.log("assign_data", assign_data)
		this.setState({ assign_data: assign_data });
		if (q_index >= 0) {
			form_data = Store.getStoreData('cur_q_modal_data');
			//console.log(form_data);
			const task_json = JSON.parse(form_data.task_json);
			const headerinfo = task_json.object_data.headerinfo;
			let description = 'description' in headerinfo ? headerinfo.description : '';
			if (Utils.isBase64(description)) {
				description = window.decodeURIComponent(atob(description));
			}
			let comments = 'comments' in headerinfo ? headerinfo.comments : '';
			if (Utils.isBase64(comments)) {
				comments = window.decodeURIComponent(atob(comments));
			}
			//displaying review list inside dropdown while clicking on edit on TaskApprovalModal.js
			let gcid = parseInt(task_json.task_data.group_company_id) % 100000;
			let company_contacts = Store.getStoreData('contacts');
			let gc_users = [];
			let my_company = null;
			let gc_company = Store.getStoreData('gc_company');
			for (let gc of gc_company) {
				if (gc.id === gcid)
					my_company = gc;
			}

			const com_roles = Store.getStoreData('userroles');
			const myGroup = Store.getStoreData('my_group');
			for (let id of Object.keys(my_company.users)) {
				// checks for myGroup.length to cover the scenario where there is NO group set
				if (
					com_roles[id.toString()] !== 'no_access' &&
					(myGroup.length ? myGroup.includes(id.toString()) : true)
				)
					gc_users.push({ ID: id, ContactName: company_contacts[id.toString()] });
			}

			assign_data.additional_fields = "additional_fields" in task_json.object_data.headerinfo ? task_json.object_data.headerinfo.additional_fields : [];

			this.setState(
				{
					questions: this.state.ischecked ? this.state.questions : headerinfo.questions,
					company: headerinfo.company,
					name: headerinfo.name,
					promotion: headerinfo.promotion,
					//description: headerinfo.description,
					description: description,
					urlLink: headerinfo.urlLink,
					//comments: headerinfo.comments,
					comments: comments,
					checklist: headerinfo.checklist,
					entrydate: task_json.task_data.actiondate,
					review_date: task_json.object_data.hasOwnProperty('rvw_rqrd_rvwdate')
						? new Date(task_json.object_data.rvw_rqrd_rvwdate)
						: new Date(),
					expiry_date: task_json.object_data.hasOwnProperty('expirydt')
						? new Date(task_json.object_data.expirydt)
						: new Date(Date.now() + (3600 * 1000 * 24)), //change to expiry date here
					reviewee_name: task_json.object_data.rvw_rqrd_assignto,
					admin_fp_managers: Store.getStoreData('get_managers'), //gc_users,
					headerinfo: headerinfo,
					assign_data: assign_data
				},
				() => {
					//use for followup task creation dropdown(users+FPrm+admin) and reviewee dropdown(FP+Managers)
					this.setAdminFpWithUsers();
				}
			);



			// move this to APP
			const curgc_id = task_json.task_data.group_company_id;
			const lastDigitcurgc_id = curgc_id % 100000;

			for (let gc of this.state.gc_company) {
				if (parseInt(gc.id) === parseInt(lastDigitcurgc_id)) is_gc = gc.is_gc;
			}
			cur_gc_id = parseInt(lastDigitcurgc_id);
			const companyID = is_gc ? parseInt(this.state.gc_company[0].id) * 100000 + cur_gc_id : cur_gc_id;

			if (task_json.object_data.bin_files.length !== 0) {
				task_json.object_data.bin_files.forEach((item) => {
					item.uid = 'uid' in item ? item.uid : this.genKey(10);
				});
			}

			const readOnly =
				form_data.cur_lane === 'COMP_FINPROMO_CONFIRMED' ||
				form_data.cur_lane === 'COMP_FINPROMO_WITHDRAWN' ||
				form_data.cur_lane === 'COMP_FINPROMO_REVIEW_REQUIRED' ||
				form_data.cur_lane === 'COMP_FINPROMO_EXPIRED' ||
				(this.state.role && form_data.cur_lane === 'COMP_FINPROMO_REGISTERED');

			this.setState(
				{
					Ref_ID: task_json.object_data.ref_id,
					curlane: form_data.cur_lane,
					readOnly,
					taskid: form_data.id,
					gcs,
					gc_company: gcs,
					q_index,
					cur_gc_id: lastDigitcurgc_id,
					is_gc,
					companyID,
					due_date: new Date(form_data.due_date),
					cur_files_json: task_json.object_data.bin_files
				},
				() => this.setCTAs()
			);
		} else {
			//reset modal data for new insert
			form_data = Store.updateStore('cur_q_modal_data', null);
			this.resetChecklist();

			if (gcs.length === 0) cur_gc_id = this.state.curCompanyID;

			//if (this.state.role === 'team') this.setState({ company: this.state.curCompanyID });

			this.setState(
				{
					Ref_ID: 'Not Yet Generated',
					companyID: cur_gc_id,
					gcs,
					gc_company: gcs,
					q_index,
					cur_gc_id,
					is_gc,
					due_date: new Date(),
					promotion: Utils.getLists('promotion_types')[0].id
				},
				() => this.setCTAs()
			);
		}
		//console.log(`Current user is : ${this.state.contact_id}`);


	}

	getGcsLoggedInRoleWise = (gcs) => {
		// debugger;
		let role = Store.getStoreData('role');
		let showParentCompanyFinancialProms = Store.getStoreData('module_config').general.show_parent_company_financial_promotions;
		if (role == 'team' && !showParentCompanyFinancialProms) {
			gcs = gcs.filter(obj => obj.is_gc === true);
		}
		return gcs;
	}

	setAdminFpWithUsers = () => {
		//debugger;
		let company_id = this.state.company;
		if (company_id !== 0) {
			let companies = this.state.gc_company;
			let companyDetails = companies.find((comp) => comp.id == company_id);
			let companyUsers = companyDetails.users;
			let is_group_company = companyDetails.is_gc;
			let user_roles = this.state.roles;
			let adminFpManagers = [];
			//let allUsers = [];

			// if group company
			if (is_group_company) {
				// push admin managers
				for (const [key, value] of Object.entries(user_roles)) {
					let userid = key;
					let role = value;
					if (role == 'manager') {
						//{Store.getStoreData('contacts')[this.state.object_data.rvw_rqrd_assignto]}
						if (typeof Store.getStoreData('contacts')[userid] !== 'undefined') {
							adminFpManagers.push({ ID: userid, ContactName: Store.getStoreData('contacts')[userid] });
							//allUsers.push({'ID': userid, 'ContactName': Store.getStoreData('contacts')[userid]});
						}
					}
				}

				for (const [key, value] of Object.entries(companyUsers)) {
					let userid = key;
					let userrole = user_roles[userid];
					if (userrole == 'fprm' || userrole == 'manager') {
						if (!adminFpManagers.find((user) => user.ID == userid)) {
							if (typeof Store.getStoreData('contacts')[userid] !== 'undefined') {
								adminFpManagers.push({ ID: userid, ContactName: Store.getStoreData('contacts')[userid] });
							}
						}
					}
				}
			} else {
				// if parent
				for (const [key, value] of Object.entries(companyUsers)) {
					let userid = key;
					let userrole = value;
					if (userrole != 'no_access') {
						if (userrole == 'manager' || userrole == 'fprm') {
							if (typeof Store.getStoreData('contacts')[userid] !== 'undefined') {
								adminFpManagers.push({ ID: userid, ContactName: Store.getStoreData('contacts')[userid] });
							}
						}
					}
				}
			}
			this.setState({ admin_fp_managers: adminFpManagers });
		}
	};

	setCTAs() {
		const status = this.state.curlane;
		if (status === 'COMP_FINPROMO_REGISTERED')
			this.setState({ ctaWithdraw: false, ctaSaveClose: false, ctaSubmit: false });
		if (!this.state.taskid || status === 'COMP_FINPROMO_TEAMSAVE' || status === 'COMP_FINPROMO_WITHDRAWN')
			this.setState({ ctaWithdraw: false });
		if (status === 'COMP_FINPROMO_CONFIRMED' || status === 'COMP_FINPROMO_WITHDRAWN')
			this.setState({ ctaSaveClose: false, ctaSubmit: false, ctaWithdraw: false });

		if (status === 'COMP_FINPROMO_REVIEW_REQUIRED') {
			if (this.state.role == 'admin_manager' || this.state.role == 'fprm' || this.state.role == 'manager') {
				this.setState({ ctaWithdraw: true, ctaSaveClose: true, ctaSubmit: true, readOnly: false });
			} else {
				this.setState({ ctaWithdraw: false, ctaSaveClose: false, ctaSubmit: false, readOnly: true });
			}
		}

		//display button only after expire
		if (status === 'COMP_FINPROMO_EXPIRED') {
			this.setState({ ctaWithdraw: false, ctaSaveClose: false, ctaSubmit: false });
		}
		// for comments while revision
		if (status === 'COMP_FINPROMO_REVISION') {
			//debugger;
			let ids = []
			let questions = this.state.headerinfo.questions;
			for (let key in questions) {
				if (questions.hasOwnProperty(key)) {
					//check if comment is not '' then store that question id
					if (Boolean(questions[key].comments)) {
						ids.push(questions[key].question_id);
					}
					//console.log(this_state_questions[key]);
				}
			}
			this.setState({ current_question_ids: ids });
		}

		this.setState({ ready: true });
	}

	closeView = () => {
		if (this.state.q_index < 0) {
			if (window.confirm(`All data will be lost if you cancel. Would you like to continue?`))
				this.props.closeModalNormal();
		} else {
			if (this.state.toggleView) {
				this.setState({ toggleView: false });
			} else {
				this.props.closeModal();
			}
		}
	};

	toggleView = () => this.setState((prevState) => ({ toggleView: !prevState.toggleView }));

	questionsIDs = (checklist) => {
		//debugger;
		return this.state.checklists.questions[checklist].map((question) => {
			let obj = { id: question.id, question_id: question.question_id };
			return obj;
		});
	};

	resetChecklist(e) {
		//debugger;
		const checklist = e ? e.target.value : '';
		let form_data = Store.getStoreData('cur_q_modal_data');
		if (form_data !== null) {
			const task_json = JSON.parse(form_data.task_json);
			const headerinfo = task_json.object_data.headerinfo;
			if (checklist === headerinfo.checklist) {
				if (this.state.ischecked) {
					if (this.state.curlane == 'COMP_FINPROMO_REVISION') {
						let dictionary = {};
						let question_array = this.state.all_options[headerinfo.checklist];

						question_array.forEach(function (item, index) {
							dictionary[index + 1] = question_array[index];
						});
						this.setState({ questions: dictionary, checklist });
					}
				} else {
					this.setState({ questions: headerinfo.questions, checklist });
				}
			} else {
				if (this.state.ischecked) {
					let dictionary = {};
					let question_array = this.state.all_options[checklist];

					question_array.forEach(function (item, index) {
						dictionary[index + 1] = question_array[index];
					});
					this.setState({ questions: dictionary, checklist });
					//this.setState({questions: this.state.all_options[checklist], checklist});
				} else {
					const questions = checklist
						? this.questionsIDs(checklist).reduce((acc, cur) => {
							acc[cur] = { value: '', comment: '' };
							return acc;
						}, {})
						: {};

					this.setState({ questions, checklist }, () =>
						document.querySelectorAll(`[type="radio"]`).forEach((el) => {
							el.checked = false;
						})
					);
					if (this.state.curlane == 'COMP_FINPROMO_REVISION') {
						this.performOperation('', '');
					}
				}
			}
		} else {
			// first time saving
			const questions = checklist // placeholder for all questions (empty 'value' and 'comment')
				? this.questionsIDs(checklist).reduce((acc, cur) => {
					acc[cur.id] = { value: '', comment: '' };
					return acc;
				}, {})
				: {}; // empty object for 'Select' option
			this.setState(
				{ questions, checklist }, // set questions, checklist and clear all radio buttons
				() =>
					document.querySelectorAll(`[type="radio"]`).forEach((el) => {
						el.checked = false;
					})
			);
		}
	}

	getChecklistValues() {

		let answer,
			comments,
			questions = {};

		if (this.state.checklist)
			this.questionsIDs(this.state.checklist).map((question) => {
				answer = document.querySelector(`input[name="field_question_${question.id}"]:checked`);
				comments = document.querySelector(`textarea[name="field_question_comments_${question.id}"]`);
				questions[question.id] = {
					answer: answer ? answer.value : null,
					comments: comments ? comments.value : null,
					question_id: question.question_id
				};
			});

		return questions;
	}

	submitTask = async (submit_form) => {
		let update_checklist = {};
		const checklist = this.getChecklistValues();
		if (submit_form) {
			// Validation
			if (this.state.company === 0) {
				alert('Please select a company before submitting.');
				return
			}
			if (!this.state.name.length) {
				alert('Promotion Name is required');
				return;
			}

			// if( !this.state.description ) { alert('Description is required'); return }
			if (!this.state.checklist) {
				alert('To Submit, please choose and complete a Checklist');
				return;
			}

			const checklistComplete = Object.values(checklist).every((question) => question.answer);
			if (!checklistComplete) {
				alert('Please complete all checklist questions to submit');
				return;
			}
			if (this.state.custom_fields.length) if (!this.validateCustomFields(this.state)) return;
		}
		// console.log('submit_form===>', submit_form);
		// console.log('checklist===>', checklist);
		update_checklist = checklist;

		if (Object.keys(update_checklist).length !== 0) {
			// console.log('submit_form===>', submit_form);
			// console.log('checklist===>', update_checklist);
			this.submitForm(submit_form, update_checklist);
		}
		if (submit_form === 0 && Object.keys(update_checklist).length === 0) {
			// console.log('submit_form===>', submit_form);
			// console.log('checklist===>', update_checklist);
			this.submitForm(submit_form, update_checklist);
		}
	};

	dummy = (result) => {
		//console.log(result);
	};

	processData = (result) => {
		//console.log("result===>", result)
		//this.setState({ isSubmitClicked: false });
		//window.location.reload();
		this.setState({ isLoadedIcon: false })
		this.props.closeModal(result.CurrentTask);
	};


	submitWithdraw = () => {
		if (window.confirm(`Are you sure you want to WITHDRAW task (FPR-${this.state.taskid})?`)) {
			this.setState({ ...this.state, isWithdrawClicked: true });
		}
	};

	filesLoaded = (files) => {
		let contact_id = Store.getStoreData('contact_id');
		for (let i = 0; i < files.length; i++) {
			files[i].name = files[i].name.replace('&', '');
			if (!('user_id' in files[i])) {
				files[i].user_id = contact_id;
				files[i].upload_date = Date.now();
			}
		}

		//console.log('filesLoaded==>', files);
		this.setState({ ...this.state, binFiles: files });
	};

	async submitForm(submit_form, questions) {
		this.setState({ ...this.state, submitFromValue: submit_form, submittedQuestions: questions, disabled: true });
		this.refs.dropZone.uploadFilesFromChild(this.state.binFiles);
	}

	onUploadComplete = async (files_list) => {
		let bin_files = [];
		let returnedFiles = JSON.parse(files_list);
		let submit_form = this.state.submitFromValue;
		let questions = this.state.submittedQuestions;

		let action = this.state.taskid ? 'update' : 'create';

		let task_json = {
			task_data: { due_date: Utils.dueDateString(this.state.due_date) },
			object_data: { headerinfo: {} },
			action_data: []
		};

		if (action === 'update') {
			// replace with previous data
			const curTaskData = Store.getStoreData('cur_q_modal_data');
			task_json = JSON.parse(curTaskData.task_json);
		}

		//#region add attachment
		if (action === 'create') {
			//const bin_files = this.state.submittedBinFiles;
			bin_files =
				this.state.cur_files_json === null
					? this.state.binFiles
					: [...this.state.binFiles, ...this.state.cur_files_json];

			if (typeof bin_files !== 'undefined' && bin_files.length > 0) {
				bin_files.forEach((file) => {
					let fileName = file.name;
					let refID = returnedFiles.find((o) => o.inserted_file_name == fileName)['ref_id'];
					delete file.bin_file;
					file.ref_id = refID;
				});
			}
		}

		if (action === 'update') {

			const binfiles = this.state.binFiles;
			if (binfiles.length > 0) {
				binfiles.forEach((file) => {
					if (file.hasOwnProperty('bin_file')) {
						let fileName = file.name;
						let refID = returnedFiles.find((o) => o.inserted_file_name == fileName)['ref_id'];
						delete file.bin_file;
						file.ref_id = refID;
					}
				});
			}
			//debugger;
			bin_files = [...this.state.cur_files_json, ...binfiles];
			if (binfiles.length > 0) {
				task_json.object_data.bin_files = bin_files;
			}
		}

		//#endregion end add attachment

		// update data fields
		this.state.fields.forEach((field) => {
			task_json.object_data.headerinfo[field] = this.state[field];
			// saving previous record inside new object
		});

		let description = Store.getStoreData('description');
		if (description === null) {
			description = this.state.description;
		}
		let description_summary = btoa(window.encodeURIComponent(description));

		let comment = Store.getStoreData('comment');

		if (comment === null) {
			comment = this.state.comments == null ? '' : this.state.comments;
		}
		let comment_summary = '';
		if (!Utils.isBase64(comment)) {
			comment_summary = btoa(window.encodeURIComponent(comment));
		} else {
			comment_summary = comment
		}

		task_json.object_data.headerinfo['comments'] = comment_summary;
		//let comment_summary = btoa(window.encodeURIComponent(comment));

		//task_json.object_data.headerinfo["comments"] = comment_summary;
		task_json.object_data.headerinfo.description = description_summary;

		task_json.object_data.headerinfo.questions = questions;

		task_json.object_data.headerinfo.additional_fields = this.state.assign_data.additional_fields;


		let postData = null;

		postData = {
			command: `${action}_task`, // "create_task" or "update_task"
			task_json,
			bin_files,
			submit: submit_form,
			is_gc: this.state.is_gc,
			gc_company_id: this.state.cur_gc_id
		};



		//if withdrawn then store reason code..
		if (submit_form == 2) {
			task_json.object_data.reason_codes = this.state.selectedReasonCode;
			// store withdrawn reason so that log should not get override
			task_json.action_data.reason_codes = this.state.selectedReasonCode;
		}

		let status = submit_form ? 'COMP_FINPROMO_REGISTERED' : 'COMP_FINPROMO_TEAMSAVE';
		if (task_json.task_data.last_action === 'COMP_FINPROMO_REVISE') action = 'COMP_FINPROMO_REVISE'; // keep Revision status

		if (submit_form === 2) {
			task_json.task_data.last_action = 'COMP_FINPROMO_WITHDRAWN';
			task_json.task_data.last_action = 'COMP_FINPROMO_WITHDRAWN';
			status = 'COMP_FINPROMO_WITHDRAWN';
		}
		if (submit_form === 1 && task_json.task_data.cur_lane === 'COMP_FINPROMO_REVISION') {
			//status = 'COMP_FINPROMO_REVISION';
			task_json.object_data.headerinfo["comments"] = comment_summary;
			task_json.object_data.headerinfo["comments_reviewer"] = this.state.headerinfo.comments_reviewer;
		}
		//save and close clicked-> save the changes but dont update to save status
		if (submit_form === 0 && task_json.task_data.cur_lane === 'COMP_FINPROMO_REVISION') {
			status = 'COMP_FINPROMO_REVISION';
			task_json.object_data.headerinfo["comments"] = comment_summary;
			task_json.object_data.headerinfo["comments_reviewer"] = this.state.headerinfo.comments_reviewer;
			if (task_json.object_data.hasOwnProperty('is_discloser_saved')) {
				delete task_json.object_data.is_discloser_saved;
				task_json.object_data.is_discloser_saved = true;
			} else {
				task_json.object_data.is_discloser_saved = true;
			}
		}
		//debugger;
		//when clicking on the edit button of the modal and then fillup the form and submit
		if (task_json.task_data.cur_lane === 'COMP_FINPROMO_REVIEW_REQUIRED' && submit_form != 2) {
			task_json.task_data.last_action = 'COMP_FINPROMO_REVIEW_REQUIRED';
			status = 'COMP_FINPROMO_REVIEW_REQUIRED';
			task_json.object_data.is_edit_button_clicked = true;
			if (this.state.review_date !== null && (this.state.role == 'admin_manager' || this.state.role == 'fprm')) {
				task_json.object_data.rvw_rqrd_assignto = this.state.assign_to;
				task_json.object_data.rvw_rqrd_rvwdate = moment(this.state.review_date.toString()).format('YYYY/MM/DD');
				//task_json.object_data.expiry_toggle = true;
			}

			if (this.state.isEnabledExpiryDate) {
				if (this.state.expiry_date !== null && (this.state.role == 'admin_manager' || this.state.role == 'fprm')) {
					task_json.object_data.rvw_rqrd_expirydt = moment(new Date(this.state.expiry_date)).format('YYYY/MM/DD');
					task_json.object_data.expiry_toggle = true;
				}
			}
		}

		if (action === 'update') {
			postData.taskid = this.state.taskid;
			postData.role = this.state.role;
			postData.task_json.task_data.last_action = task_json.task_data.cur_lane;
			postData.task_json.task_data.cur_lane = status;
			postData.task_json.action_data = {
				action: status,
				actiondate: Date.now(),
				assigned_to: 0,
				cur_lane: status
			};
		}

		//debugger;
		this.setState({ submit_form, isLoadedIcon: true });
		console.log("postData====>", postData);
		await this.api.command(postData, this.processData);
	}



	dummy = (result) => {
		//console.log(result);
	};

	processData = (result) => {
		//console.log("result===>", result)
		//this.setState({ isSubmitClicked: false });
		//window.location.reload();
		this.setState({ isLoadedIcon: false })
		this.props.closeModal(result.CurrentTask);
	};

	submitWithdraw = () => {
		if (window.confirm(`Are you sure you want to WITHDRAW task (FPR-${this.state.taskid})?`)) {
			this.setState({ ...this.state, isWithdrawClicked: true });
		}
	};

	filesLoaded = (files) => {
		let contact_id = Store.getStoreData('contact_id');
		for (let i = 0; i < files.length; i++) {
			files[i].name = files[i].name.replace('&', '');
			if (!('user_id' in files[i])) {
				files[i].user_id = contact_id;
				files[i].upload_date = Date.now();
			}
		}
		//console.log('files==>', files);
		//this.uploadApi.command(files, this.dummy);
		this.setState({ binFiles: files });
	};

	onRadioChange = (e) => {
		if (this.state.curlane == 'COMP_FINPROMO_REVISION') {
			this.performOperation(e.target.parentElement.parentElement.children[0].innerText, e.target.value);
		}
	};

	performOperation = async (question_text = "", answer = "") => {
		//debugger;
		if (this.state.all_options.length == 0) {
			let options = this.state.questions;
			let questions_chklist = this.state.checklists.questions;
			let jsonObj = {};
			for (var key in questions_chklist) {
				jsonObj[key] = [];
				if (questions_chklist.hasOwnProperty(key)) {
					if (key !== this.state.headerinfo.checklist) {
						let option_count = questions_chklist[key].length;
						for (let i = 0; i < option_count; i++) {
							let item = {};
							item['answer'] = '';
							item['comments'] = '';
							item['question_id'] = questions_chklist[key][i].question_id;
							item['id'] = questions_chklist[key][i].id;
							jsonObj[key].push(item);
						}
					} else {
						//debugger;
						for (let key1 in options) {
							if (options.hasOwnProperty(key1)) {
								let item = {};
								item['answer'] = options[key1].answer;
								item['comments'] = options[key1].comments;
								item['question_id'] = options[key1].question_id;
								item["id"] = key1;
								jsonObj[key].push(item);
							}
						}
					}
					await this.setState({ all_options: jsonObj, ischecked: true });
				}
			}
		}

		//operation...
		// console.log('handleRadios', e);


		let question = question_text;
		let currentChecklist = this.state.checklist;
		let questions_chklist = this.state.checklists.questions[currentChecklist];
		if (question_text !== '' && answer !== '') {
			let question_id = questions_chklist.find((quest) => quest.title === question).question_id;
			let id = questions_chklist.find((quest) => quest.title === question).id;
			let current_checklist_options = this.state.all_options[currentChecklist];
			//debugger;
			for (let i = 0; i < current_checklist_options.length; i++) {
				if (current_checklist_options[i].question_id == question_id) {
					current_checklist_options[i].answer = answer;
					current_checklist_options[i].id = id;
				}
			}
			if (Array.isArray(current_checklist_options)) {
				current_checklist_options = this.factory(current_checklist_options);
			}
			//debugger;
			let current_question_ids = this.state.current_question_ids;
			current_question_ids.push(question_id);
			await this.setState({ questions: current_checklist_options, ischecked: true, current_question_ids: current_question_ids });
		} else {
			let current_checklist_options = this.state.all_options[currentChecklist];
			for (let i = 0; i < current_checklist_options.length; i++) {
				current_checklist_options[i].answer = "";
				//current_checklist_options[i].comments = "";
			}
		}
	};
	//construct --> [{},{}] into  {{},{}}
	factory = (a) => {
		//debugger;
		const obj = {}
		let c = a.forEach(({ answer, comments, question_id, id }, i) => {
			obj[id] = { answer, comments, question_id }
		})
		return obj;
	}


	toggleComments(e) {
		const field = e.target.name.split('_').pop();
		const el = document.getElementById(`group_question_comments_${field}`);
		el.style.display = 'block';
	}

	handleChangeDropDown = (event) => {
		event.preventDefault();
		let new_reviewerid = event.target.value;
		if (new_reviewerid !== '0') {
			let form_data = Store.getStoreData('cur_q_modal_data');
			const task_json = JSON.parse(form_data.task_json);
			let reviewer_id = task_json.object_data.rvw_rqrd_assignto;
			if (new_reviewerid !== reviewer_id) {
				this.setState({
					...this.state,
					assign_to: event.target.value,
					reviewee_name: event.target.value,
					isRevieweeDropDownChanged: true
				});
			} else {
				this.setState({
					...this.state,
					assign_to: event.target.value,
					reviewee_name: event.target.value,
					isRevieweeDropDownChanged: false
				});
			}
		}
	};

	followupTaskValueFetch = async (taskValue) => {
		let ref_id = Utils.genKey(12);
		let task = {
			task_data: {
				module_id: 'finpromo',
				last_action: 'COMP_FPR_FOLLOWUP',
				ref_id: ref_id,
				actiondate: Date.now(),
				cur_assigned_to: taskValue.assignedto,
				task_type: 'COMP_FPR_FOLLOWUP',
				parent_task_id: this.state.taskid,
				cur_lane: 'COMP_FPR_FOLLOWUP_ASSIGNMENT',
				due_date: taskValue.dueby
			},
			object_data: {
				subject: taskValue.subject,
				note: taskValue.note
			},
			action_data: [
				{
					action: 'COMP_FPR_FOLLOWUP',
					actiondate: Date.now(),
					assigned_to: taskValue.assignedto,
					cur_lane: 'COMP_FPR_FOLLOWUP_ASSIGNMENT',
					due_by: taskValue.dueby,
					note: taskValue.note,
					performed_by: this.state.contact_id
				}
			]
		};
		//console.log('followup task', task);
		const postData = { command: 'createfollowuptask', task: task, bin_files: taskValue.binFiles };
		await this.setState({ ...this.state, isLoadedIcon: true, followUpTaskShowPrompt: false });
		await this.api.command(postData, this.processAddTask);

	};

	processAddTask = async (result) => {
		//debugger;
		if (result.error_code == 0) {
			await this.setState({ ...this.state, followUpTaskShowPrompt: false, isLoadedIcon: true });
		} else if (result.error_code == 1111 && Object.keys(result.error_msg).length === 0) {
			await this.setState({ ...this.state, followUpTaskShowPrompt: false, isLoadedIcon: true });
		}
		this.closeFollowUpCreatedAlert();
	};

	onDateChangeDueBy = (date) => {
		let curmonth = parseInt(date.getMonth());
		let currmonth = curmonth + 1;
		var dateStr =
			currmonth >= 10
				? date.getFullYear() + '/' + currmonth + '/' + date.getDate()
				: date.getFullYear() + '/0' + currmonth + '/' + date.getDate();
		this.setState({ due_by: dateStr, review_date: date });
	};

	onExpiryDateChanged = (date) => {
		let curmonth = parseInt(date.getMonth());
		let currmonth = curmonth + 1;
		var dateStr2 =
			currmonth >= 10
				? date.getFullYear() + '/' + currmonth + '/' + date.getDate()
				: date.getFullYear() + '/0' + currmonth + '/' + date.getDate();
		this.setState({ expiry_due_by: dateStr2, expiry_date: date });
	};

	handleChangeCurGc = (evt) => {
		let gc_company = Store.getStoreData('gc_company');
		let is_gc = false;
		let companyUsers = {};
		for (let gc of gc_company) {
			//gc.id = (gc.id * 100000) + gc_company.id;
			if (parseInt(gc.id) === parseInt(evt.target.value)) {
				is_gc = gc.is_gc;
				companyUsers = gc.users;
			}
		}
		let cur_gc_id = parseInt(evt.target.value);
		//let companyID = is_gc ? parseInt(gc_company[0].id) * 100000 + cur_gc_id : cur_gc_id;
		this.setState({ company: evt.target.value, is_gc: is_gc, cur_gc_id: cur_gc_id });
	};

	alertHandler = async (val, evt) => {
		//debugger;
		if (val == true) {
			await this.setState({ isRevieweeDropDownChanged: false, isLoadedIcon: true });
			let task = {};
			task.subject = `Review ${this.state.name}-${this.state.taskid}`;
			task.note = `Review ${this.state.name}-${this.state.taskid}`;
			task.assignedto = this.state.assign_to;
			task.dueby = this.state.review_date;
			task.binFiles = [];
			this.followupTaskValueFetch(task);
		}
		if (val == false) {
			let form_data = Store.getStoreData('cur_q_modal_data');
			const task_json = JSON.parse(form_data.task_json);
			let reviewer_id = task_json.object_data.rvw_rqrd_assignto;
			this.setState({
				...this.state,
				assign_to: reviewer_id,
				reviewee_name: reviewer_id,
			});
		}
		await this.setState({ isRevieweeDropDownChanged: false });
	};

	//after creation of another followup task, change the review date and reviewee of the parent task
	closeFollowUpCreatedAlert = async () => {

		const curmytaskdata = Store.getStoreData('cur_q_modal_data');
		const curtask_jsonValue = JSON.parse(curmytaskdata.task_json);

		curtask_jsonValue['object_data'].rvw_rqrd_assignto = this.state.assign_to;
		curtask_jsonValue['object_data'].rvw_rqrd_rvwdate = moment(this.state.review_date.toString()).format(
			'YYYY/MM/DD'
		);

		const from_api = curtask_jsonValue.object_data.from_api || 0;
		const postData = {
			command: 'update_task',
			task_json: curtask_jsonValue,
			bin_files: this.state.cur_files_json == null ? [] : this.state.cur_files_json,
			from_api,
			submit: 1,
			taskid: this.state.taskid,
			discloser_id: this.state.contact_id
		};

		await this.api.command(postData, this.followUpReviewStatusUpdate);
	};

	closeFollowUpcreateModalOk = async (val, evt) => {
		await this.setState({ followUpTaskShowPrompt: false });
	}


	followUpReviewStatusUpdate = async () => {
		//debugger;
		await this.setState({ ...this.state, followUpTaskShowPrompt: true, isLoadedIcon: false });
	};

	toggleCommentsRadio = (e) => {
		const field = e.target.name.split('_').pop();
		const el = document.getElementById(`field_question_${field}`);
		//el.style.display = 'block';
	};

	generateField = (data, data_container) => {
		//debugger;
		return (
			<div key={data.name} style={{ zIndex: '10000 !important' }}>
				<MRModalLabel>
					{
						(() => {
							if (data.required) {
								return (<div>{data.nickname}
									<span style={{ color: "red", paddingLeft: "5px", fontSize: "8px" }}>
										<FaStarOfLife title={data.nickname + " required"} />
									</span>
								</div>);
							} else {
								return data.nickname;
							}
						})()
					}
				</MRModalLabel>
				{
					(() => {
						let fieldhtml = <div></div>;
						switch (data.type) {
							case "Alpha":
								fieldhtml = this.createAlphaField(data, data_container);
								break;
							case "Numeric":
								fieldhtml = this.createNumericField(data, data_container);
								break;
							case "Dropdown":
								fieldhtml = this.createDropdownField(data, data_container);
								break;
							default:
								fieldhtml = fieldhtml;
						}
						if (data_container === "additional_fields")
							return fieldhtml;
					})()
				}
			</div>
		);
	}

	createAlphaField = (data, data_container) => {
		return (<CSLTextInput id={"text-" + data.name} disabled={this.state.readOnly} handleTextChange={this.handleTextChange} name={data.name} value={data.value} entity="additional_fields" field="value" />);
	}

	handleTextChange = (obj) => {
		//console.log("obj:", obj);
		let assign_data = JSON.parse(JSON.stringify(this.state.assign_data));
		if (obj.entity === null) {
			assign_data[obj.name] = obj.value;
		} else {
			assign_data[obj.entity].forEach(item => {
				if (item.name === obj.name) item[obj.field] = obj.value;
			})
		}
		this.modifyAssignData(assign_data);
	}

	createNumericField = (data, data_container) => {
		return (<MRModalInput id={"number-" + data.name} readOnly={this.state.readOnly} type="number" name={data.name} value={data.value} onChange={this.handleAddlField(data.type, data_container)} />);
	}

	createDropdownField = (data, data_container) => {

		return (
			<div>
				<MRModalSelect id={"select-" + data.name} disabled={this.state.readOnly}
					onChange={this.handleAddlField(data.type, data_container)}
					defaultValue={"-1"}
					name={data.name} value={data.value}>

					<option key="sel" value="">Select</option>
					{
						data.dropdown_options.map((opt, index) => {
							return (
								<option key={index} value={opt}>{opt}</option>
							);
						})
					}
					{
						(() => {
							if (data_container === "additional_fields") {
								if ("other" in data) {
									if (data.other === true) {
										return (<option value="other">Other</option>);
									}
								}
							}
						})()
					}
				</MRModalSelect>
				{
					(() => {
						if (data_container === "additional_fields") {
							if ("other" in data) {
								if (data.other === true && data.value === "other") {
									return (
										<div style={{ marginTop: "15px" }}>
											<div style={{ float: "left", width: "20%", boxSizing: "border-box", paddingTop: "12px" }}>
												<MRModalLabel style={{ margin: "0px" }}>Please give details</MRModalLabel>
											</div>
											<div style={{ float: "left", width: "80%", boxSizing: "border-box" }}>
												<CSLTextInput id={"text-" + data.name} disabled={this.state.readOnly} entity="additional_fields" name={data.name} value={data.other_value} handleTextChange={this.handleTextChange} field="other_value" />
											</div>
											<div style={{ clear: "both" }}></div>
										</div>
									);
								}
							}
						}
					})()
				}
			</div>
		);
	}



	handleAddlField = (field_type, data_container) => (event) => {
		event.preventDefault();
		let assign_data = JSON.parse(JSON.stringify(this.state.assign_data));

		if (event.target.name === "smcr_role") {
			assign_data[event.target.name] = event.target.value;
			assign_data[data_container].forEach((item) => {
				if (item.name === event.target.name) {
					item.value = event.target.value;
				}
			})
		} else {
			assign_data[data_container].forEach((item) => {
				if (item.name.toString() === event.target.name.toString()) {
					item.value = event.target.value;
				}
			})
		}
		//console.log("ModifyAssigndata+++==>", assign_data)
		this.modifyAssignData(assign_data);
	}

	modifyAssignData = (obj) => {
		this.setState({ assign_data: obj });
	}

	render() {
		//console.log('gc_company===>', this.state.gc_company);
		if (!this.state.ready) return <div>Loading...</div>;
		if (this.state.isSubmitClicked) {
			return (
				<div>
					<CSLLoader />
				</div>
			);
		}
		return (
			<div>
				{
					(() => {
						if (this.state.isLoadedIcon) {
							return <div><CSLLoader style={{ position: 'absolute' }} /></div>
						}

					})()
				}
				<MRModalContainer>
					{(() => {
						if (this.state.isRevieweeDropDownChanged == true) {
							return (
								<AlertBox
									alertParam={{
										title: 'Confirm',
										ok_text: 'Yes',
										cancel_text: 'No',
										confirm: true,
										alertHandler: this.alertHandler,
										message: `Are you sure you want to change the reviewer?
									<br/><br/>This will create a new follow-up task for the new reviewer.`
									}}
								/>
							);
						}
					})()}
					{(() => {
						if (this.state.followUpTaskShowPrompt == true) {
							return (
								<AlertBox
									alertParam={{
										title: 'Success',
										ok_text: 'Ok',
										confirm: false,
										alertHandler: this.closeFollowUpcreateModalOk,
										message: 'A new follow-up task has been created for the new reviewer'
									}}
								/>
							);
						}
					})()}

					<MRModalHeader>
						{this.state.Ref_ID === 'Not Yet Generated' ? (
							<MRModalHeaderText>Add Financial Promotion</MRModalHeaderText>
						) : (
							<MRModalHeaderText>{this.state.Ref_ID}</MRModalHeaderText>
						)}
						<MRModalHeaderCloseBtn onClick={this.closeView}>
							<FaTimes />
						</MRModalHeaderCloseBtn>
						<ClearBoth />
						{this.state.Ref_ID === 'Not Yet Generated' ? (
							<MRModalSubHeaderText>
								Complete the required fields to create a new Financial Promotion entry
							</MRModalSubHeaderText>
						) : (
							<MRModalSubHeaderText>
								Edit required fields to update this Financial Promotion entry
							</MRModalSubHeaderText>
						)}
						<ClearBoth />
					</MRModalHeader>

					{/* description pane     */}
					<MRModalBody style={{ display: this.state.toggleView ? 'none' : 'block' }}>
						<div>
							<MRModalLabel>Select Company </MRModalLabel>
							<MRModalSelect
								id="company"
								name="field_company"
								value={this.state.company}
								// onChange={(e) => this.setState({company: e.target.value})}
								onChange={this.handleChangeCurGc}
								disabled={this.state.readOnly || this.props.isEditedFromReviewRequired}
							>
								{this.state.gc_company.length > 0 && (
									<option key={0} value={0}>
										Select
									</option>
								)}
								{this.state.gc_company.map((gc) => (
									<option key={gc.id} value={gc.id}>
										{gc.company_name}
									</option>
								))}
							</MRModalSelect>
						</div>

						<MRModalLabel style={{ marginTop: '30px', width: '100%' }}>Promotion Name</MRModalLabel>
						<MRModalInput
							id="promo_name"
							type="text"
							name="field_name"
							readOnly={this.state.readOnly}
							onChange={(e) => this.setState({ name: e.target.value })}
							value={this.state.name}
						/>

						<MRModalLabel style={{ marginTop: '30px' }}>Description</MRModalLabel>

						<HtmlEditorDescription
							content={this.state.description}
							readOnly={this.state.readOnly}
							id="field_description"
						/>

						<MRModalLabel style={{ marginTop: '30px' }}>Promotion Type</MRModalLabel>
						<MRModalSelect
							id="promo_type"
							name="field_promotion"
							disabled={this.state.readOnly}
							value={this.state.promotion || '1'}
							onChange={(e) => this.setState({ promotion: e.target.value })}
						>
							{this.state.promotions.map((promotion) => (
								<option key={promotion.id} value={promotion.id}>
									{promotion.title}
								</option>
							))}
						</MRModalSelect>

						<ModalNameLabelDiv>Attachments</ModalNameLabelDiv>
						<ModalNameInputDiv>
							<Dropzone
								id="attachments"
								onFilesAdded={this.filesLoaded}
								disabled={this.state.readOnly}
								initFiles={this.state.cur_files_json}
								onUploadComplete={this.onUploadComplete}
								//onUploadStatus = {this.onUploadStatus}
								ref="dropZone"
							/>
						</ModalNameInputDiv>

						<MRModalLabel style={{}}>Promotion URL</MRModalLabel>
						<MRModalInput
							id="url"
							type="text"
							name="field_urlLink"
							readOnly={this.state.readOnly}
							onChange={(e) => this.setState({ urlLink: e.target.value })}
							value={this.state.urlLink}
						/>

						{
							(() => {
								if (this.state.assign_data.additional_fields.length !== 0) {
									let custom_html = [];
									this.state.assign_data.additional_fields.forEach((item) => {
										const field = this.generateField(item, "additional_fields");
										custom_html.push(field);
									})
									return custom_html;
								}
								else {
									return null;
								}
							})()
						}

						{(() => {
							if (this.props.isEditedFromReviewRequired && this.state.curlane === 'COMP_FINPROMO_REVIEW_REQUIRED') {
								return (
									<React.Fragment>
										<MRModalLabel>Review Date</MRModalLabel>
										<CSLDateCover>
											<DatePicker
												onChange={this.onDateChangeDueBy}
												clearIcon={null}
												calendarIcon={<FaCalendarAlt style={{ color: '#243E57' }} />}
												locale={'en-GB'}
												value={this.state.review_date == null ? new Date() : this.state.review_date}
											/>
										</CSLDateCover>
										<MRModalLabel>Reviewer</MRModalLabel>
										<MRModalSelect
											onChange={this.handleChangeDropDown}
											name="assigned_to"
											value={this.state.reviewee_name}
										>
											{this.state.admin_fp_managers != null && (
												<option key={0} value={0}>
													Select
												</option>
											)}
											{this.state.admin_fp_managers.map((reviewer) => (
												<option key={reviewer.ID} value={reviewer.ID}>
													{reviewer.ContactName}
												</option>
											))}
										</MRModalSelect>

										{(() => {
											if (this.state.isEnabledExpiryDate) {
												return (
													<React.Fragment>
														<MRModalLabel>Expiry Date</MRModalLabel>
														<CSLDateCover>
															<DatePicker
																onChange={this.onExpiryDateChanged}
																clearIcon={null}
																calendarIcon={<FaCalendarAlt style={{ color: '#243E57' }} />}
																locale={'en-GB'}
																minDate={moment().add(1, 'days').toDate()}
																value={this.state.expiry_date}
															/>
														</CSLDateCover>
													</React.Fragment>
												)
											}

											return null;
										})()}

									</React.Fragment>
								);
							}

							return null;
						})()}
					</MRModalBody>

					{/* checklist pane     */}
					<MRModalBody style={{ display: this.state.toggleView ? 'block' : 'none', paddingTop: '10px' }}>
						<MRModalLabel style={{ marginTop: '30px' }}>Checklist</MRModalLabel>
						<MRModalSelect
							name="field_checklist"
							defaultValue={this.state.checklist}
							disabled={this.state.readOnly}
							onChange={this.resetChecklist}
							id="checklist_dropdown"
						>
							<option key={0} value="">
								Select
							</option>

							{this.state.checklists.sets.map((checklist) => {
								let checklist_id = checklist.id; //new_list_checklist
								if (this.state.checklists.questions[checklist_id].length > 0 && checklist.isActive === true) {
									return (
										<option key={checklist.id} value={checklist.id}>
											{checklist.title}
										</option>
									);
								}
							})}
						</MRModalSelect>

						<MRModalListContainer>
							<MRModalLabel style={{ marginTop: '30px', flexGrow: '1' }}>Questions</MRModalLabel>
							<div style={{ width: '120px', display: 'flex', flexDirection: 'row' }}>
								<MRModalLabel id="yes" style={{ marginTop: '30px', flex: '1 1 auto' }}>Yes</MRModalLabel>
								<MRModalLabel id="no" style={{ marginTop: '30px', flex: '1 1 auto' }}>No</MRModalLabel>
								<MRModalLabel id="na" style={{ marginTop: '30px', flex: '1 1 auto' }}>N/A</MRModalLabel>
							</div>
						</MRModalListContainer>


						<div style={{ maxHeight: '600px', overflow: 'auto' }}>
							{(() => {
								if (Object.keys(this.state.questions).length !== 0) {
									//debugger;
									if (Object.values(this.state.questions).length > 0 && Object.values(this.state.questions)[0].hasOwnProperty('question_id')) {
										{
											return this.state.checklist && this.state.checklists.questions[this.state.checklist].map((question) => {
												//debugger;
												//console.log('question.question_id==>', question.question_id);
												let question_id = question.question_id;
												const result = {};
												const answerFound = Object.values(this.state.questions).find(x => question.question_id === x.question_id);
												if (answerFound) {
													result[question.question_id] = {
														question_id: question.question_id,
														title: question.title,
														answer: answerFound.answer,
														comments: answerFound.comments
													}
												}

												return (
													<div key={`keys_${question.question_id}`} >
														<MRModalListContainer key={question.question_id}>
															{(() => {
																if (typeof result[question_id] !== 'undefined' && this.state.curlane !== 'COMP_FINPROMO_TEAMSAVE' && result[question_id].answer != null) {
																	return (
																		<React.Fragment>

																			<MRModalLabel
																				style={{ flexGrow: '1' }}>{result[question_id].title}</MRModalLabel>
																		</React.Fragment>

																	)
																}
																else if (typeof result[question_id] !== 'undefined' && this.state.curlane === 'COMP_FINPROMO_TEAMSAVE') {
																	return (
																		<React.Fragment>
																			<MRModalLabel
																				style={{ flexGrow: '1' }}>{result[question_id].title}</MRModalLabel>
																		</React.Fragment>

																	)
																}

																else {
																	return null;
																}
															})()}

															<div key={question.question_id}
																style={{ width: '120px', flex: '0 0 120px' }}>

																{(() => {
																	if (typeof result[question_id] !== 'undefined' && this.state.curlane !== 'COMP_FINPROMO_TEAMSAVE' && result[question_id].answer != null) {
																		//debugger;
																		return (
																			<RadioGroup
																				name={`field_question_${question.id}`}
																				disabled={this.state.readOnly}
																				value={typeof result[question_id].answer !== 'undefined' ? result[question_id].answer : Object.keys(this.state.questions).reduce((arr, key) => arr.concat(this.state.questions[key]), []).find((q) => {
																					return q.question_id == question_id
																				}).answer}
																				onChange={this.onRadioChange}
																			>
																				<RadioOption id="yes" value="yes" />
																				<RadioOption id="no" value="no" />
																				<RadioOption id="na" value="na" />
																			</RadioGroup>
																		)
																	}
																	else if (typeof result[question_id] !== 'undefined' && this.state.curlane == 'COMP_FINPROMO_TEAMSAVE') {
																		return (
																			<RadioGroup
																				name={`field_question_${question.id}`}
																				disabled={this.state.readOnly}
																				value={result[question_id].answer}
																			//onChange={this.toggleComments}
																			>
																				<RadioOption id="yes" value="yes" />
																				<RadioOption id="no" value="no" />
																				<RadioOption id="na" value="na" />
																			</RadioGroup>
																		)
																	}
																	else {
																		return null;
																	}

																})()}

															</div>
														</MRModalListContainer>

														{
															(() => {
																if (typeof result[question_id] !== 'undefined' && (this.state.curlane !== 'COMP_FINPROMO_TEAMSAVE' && this.state.curlane !== 'COMP_FINPROMO_REVISION') && result[question_id].answer != null) {

																	return (


																		<div
																			id={`group_question_comments_${question.id}`}
																			style={{
																				margin: '20px 0',
																				display: result[question_id].comments ? 'block' : 'none'
																			}}
																		>
																			<MRModalLabel>Comments</MRModalLabel>
																			<MRModalTextarea
																				rows="5"
																				readOnly={this.state.readOnly}
																				name={`field_question_comments_${question.id}`}
																				defaultValue={result[question_id].comments}
																				onChange={this.toggleComments}
																			/>
																		</div>

																	)
																}
																else if (typeof result[question_id] !== 'undefined' && (this.state.curlane === 'COMP_FINPROMO_REVISION') && result[question_id].answer != null) {

																	if (this.state.checklist !== this.state.headerinfo.checklist) {
																		return (

																			<div
																				id={`group_question_comments_${question.id}`}
																				style={{
																					margin: '20px 0',
																					display: result[question_id].comments ? 'block' : 'none'
																				}}
																			>
																				<MRModalLabel>Comments</MRModalLabel>
																				<MRModalTextarea
																					rows="5"
																					readOnly={this.state.readOnly}
																					name={`field_question_comments_${question.id}`}
																					defaultValue={result[question_id].comments}
																					onChange={this.toggleComments}
																				/>
																			</div>

																		)
																	} else {

																		return (

																			<div
																				id={`group_question_comments_${question.id}`}
																				style={{
																					margin: '20px 0',
																					display: this.state.current_question_ids.includes(question_id) === true ? 'block' : 'none'
																				}}
																			>
																				<MRModalLabel>Comments</MRModalLabel>
																				<MRModalTextarea
																					rows="5"
																					readOnly={this.state.readOnly}
																					name={`field_question_comments_${question.id}`}
																					defaultValue={result[question_id].comments}
																					onChange={this.toggleComments}
																				/>
																			</div>
																		)
																	}

																}
																else if (typeof result[question_id] !== 'undefined' && this.state.curlane === 'COMP_FINPROMO_TEAMSAVE') {
																	return (
																		<div
																			id={`group_question_comments_${question.id}`}
																			style={{
																				margin: '20px 0',
																				display: (result[question_id].comments) ? 'block' : 'none'
																			}}
																		>
																			<MRModalLabel>Comments</MRModalLabel>
																			<MRModalTextarea
																				rows="5"
																				readOnly={this.state.readOnly}
																				name={`field_question_comments_${question.id}`}
																				defaultValue={result[question_id].comments}
																			//onChange={this.toggleComments}
																			/>
																		</div>
																	)
																}
																else {
																	return null;
																}
															})()
														}
													</div>
												)
											})
										}
									} else {
										return (
											this.state.checklist && this.state.checklists.questions[this.state.checklist].map((question) => {
												// while in first entry, register , revision
												if (typeof this.state.questions[question.id] !== 'undefined') {

													return (
														<div key={question.id}>
															<MRModalListContainer>

																<MRModalLabel style={{ flexGrow: "1" }}>{question.title}</MRModalLabel>
																<div style={{ width: "120px", flex: "0 0 120px" }}>
																	<RadioGroup
																		name={`field_question_${question.id}`}
																		disabled={this.state.readOnly}
																		value={this.state.questions[question.id].hasOwnProperty('answer') ? this.state.questions[question.id].answer : ''}
																		onChange={this.onRadioChange}>
																		<RadioOption id="yes" value="yes" />
																		<RadioOption id="no" value="no" />
																		<RadioOption id="na" value="na" />
																	</RadioGroup>
																</div>
															</MRModalListContainer>
															<div id={`group_question_comments_${question.id}`} style={{ margin: "20px 0", display: this.state.questions[question.id].comments ? "block" : "none" }}>
																<MRModalLabel>Comments</MRModalLabel>
																<MRModalTextarea
																	rows="5"
																	readOnly={this.state.readOnly}
																	name={`field_question_comments_${question.id}`}
																	defaultValue={this.state.questions[question.id].comments}
																	onChange={this.toggleComments}
																/>
															</div>
														</div>
													)
												}
												// while in revision , when user changes the checklist
												else {
													return (
														<div key={question.id}>
															<MRModalListContainer>

																<MRModalLabel style={{ flexGrow: "1" }}>{question.title}</MRModalLabel>
																<div style={{ width: "120px", flex: "0 0 120px" }}>
																	<RadioGroup
																		name={`field_question_${question.id}`}
																		disabled={this.state.readOnly}
																		value={''}
																		onChange={this.onRadioChange}>
																		<RadioOption id="yes" value="yes" />
																		<RadioOption id="no" value="no" />
																		<RadioOption id="na" value="na" />
																	</RadioGroup>
																</div>
															</MRModalListContainer>
															{/* <div id={`group_question_comments_${question.id}`} style={{ margin: "20px 0", display: this.state.questions[question.id].comments ? "block" : "none" }}>
																<MRModalLabel>Comments</MRModalLabel>
																<MRModalTextarea
																	rows="5"
																	readOnly={this.state.readOnly}
																	name={`field_question_comments_${question.id}`}
																	defaultValue={this.state.questions[question.id].comments}
																	onChange={this.toggleComments}
																/>
															</div> */}
														</div>
													)
												}

											})
										)
									}
								} else {
									return null;
								}

								return null;
							})()}
						</div>
						<hr style={{ width: '100%' }} />
						<MRModalLabel style={{ marginTop: '50px' }}>Comments</MRModalLabel>
						<HtmlEditor
							content={typeof this.state.comments === 'undefined' ? '' : this.state.comments}
							readOnly={this.state.curlane === 'COMP_FINPROMO_WITHDRAWN' ? true : this.state.readOnly}
							id="field_comments"
						/>
					</MRModalBody>

					<MRModalFooter>
						{!this.state.toggleView ? (
							<MRModalNextBtn id="checklist" onClick={() => this.toggleView()}>
								CHECKLIST <FaArrowAltCircleRight />
							</MRModalNextBtn>
						) : (
							<MRModalNextBtn onClick={() => this.toggleView()}>
								<FaArrowAltCircleLeft /> DETAILS
							</MRModalNextBtn>
						)}
						<MRModalNextBtn
							style={{ display: this.state.ctaSubmit ? 'inherit' : 'none' }}
							onClick={() => this.submitTask(1)}
							disabled={this.state.disabled}
							id="submit"
						>
							{this.state.disabled ? 'Submitting...' : 'Submit'}
						</MRModalNextBtn>
						{(() => {
							if (this.props.isEditedFromReviewRequired) {
								return null;
							} else {
								return (
									<MRModalSaveCloseBtn
										id="save_close"
										style={{ display: this.state.ctaSaveClose ? 'inherit' : 'none' }}
										onClick={() => this.submitTask(0)}
										disabled={this.state.isWithdrawClicked || this.state.disabled}
									>
										Save and Close
									</MRModalSaveCloseBtn>
								);
							}
						})()}


						<MRModalWithdrawBtn
							style={{ display: this.state.ctaWithdraw ? 'inherit' : 'none' }}
							onClick={() => this.submitWithdraw()}
						>
							WITHDRAW
						</MRModalWithdrawBtn>

						<MRModalCancelBtn onClick={() => this.closeView()}>Cancel</MRModalCancelBtn>
						<ClearBoth />
					</MRModalFooter>
					{(() => {
						if (this.state.isWithdrawClicked) {
							return (
								<React.Fragment>
									<MRModalLabelReasonCode style={{ marginTop: '20px' }}>
										Withdraw Reason (*)
									</MRModalLabelReasonCode>
									<MRModalSelectReasonCodes
										name="select_reason_code"
										value={this.state.selectedReasonCode}
										onChange={(e) => this.setState({ selectedReasonCode: e.target.value })}
									>
										{this.state.reasonCodes != null && (
											<option key={0} value={0}>
												Select
											</option>
										)}
										{Object.keys(this.state.reasonCodes).map((rc) => {
											if (this.state.reasonCodes[rc].isActive == 1 && this.state.reasonCodes[rc].isDelete == 0) {
												return (
													<option key={rc} value={rc}>
														{this.state.reasonCodes[rc].title}
													</option>
												);
											}
										})}
									</MRModalSelectReasonCodes>

									<div style={{ marginRight: '17px', marginBottom: '17px' }}>
										<MRModalNextBtn onClick={() => this.submitTask(2)}>Confirm</MRModalNextBtn>

										<MRModalCancelBtn onClick={() => this.setState({ isWithdrawClicked: false })}>
											Cancel
										</MRModalCancelBtn>
									</div>
								</React.Fragment>
							);
						}
						return null;
					})()}
				</MRModalContainer>
			</div >
		);
	}
}

export default TaskModal;
