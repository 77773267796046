import React from 'react';
import PropTypes from "prop-types";
import styled, { css } from 'styled-components';
import APICall from '../../Common/APICall';

const BodyContainer = styled.div`
  width: 90%;
`;
const BodyInnerContainer = styled.div`
  background-color: #ffffff;
  padding: 15px 30px;
`;
const BoldLabel = styled.div`
  font-weight: 600;
  color: #595959;
  margin-top: 5px;
`;

const CancelBtn = styled.button`
  padding: 10px 20px;
  background-color: #ffffff;
  border: 1px solid #ffffff;
  border-radius: 2px;
  color: #333333;
  cursor: pointer;
`;

const SaveBtn = styled.button`
  padding: 10px 30px;
  background-color: #37ada7;
  border: 1px solid #37ada7;
  border-radius: 2px;
  color: #ffffff;
  cursor: pointer;
  margin-left: 15px;
`;

const FooterButtonsContainer = styled.div`
  text-align: right;
  padding-top: 25px;
  width: 90%;
`;

const SelectedRadio = styled.div`
  background-color: #37ADA7;
  border: 1px solid #1f615e;
  padding: 5px 10px;
  color: #ffffff;
  display: inline-block;
  cursor: pointer;
  ${(props) =>
        props.disabled
            ? css`
                    pointer-events: none !important;
                    opacity: 0.4;
                  `
            : css`
                    cursor: pointer !important;
                  `};
` ;
const UnselectedRadio = styled.div`
  background-color: #F4F4F4;
  border: 1px solid #8c8c8c;
  padding: 5px 10px;
  color: #262626;
  display: inline-block;
  cursor: pointer;
  ${(props) =>
        props.disabled
            ? css`
                    pointer-events: none !important;
                    opacity: 0.4;
                  `
            : css`
                    cursor: pointer !important;
                  `};
`;
const RadioBlock = styled.div`
  margin-top: 10px;
`;

class GeneralConfigs extends React.Component {
    static propTypes = {
        /** */
        general: PropTypes.object,
        updateGeneralSave: PropTypes.func,
    };

    state = {
        general: true,
        has_gc: true
    };

    componentDidMount() {
        this.setState({
            general: this.props.general,
            has_gc: this.props.has_gc
        });
    }

    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            this.setState({
                general: this.props.general
            });
        }
    }

    switchRadio = (key) => (event) => {
        //debugger;
        event.preventDefault();
        let { general } = this.state;
        general.value[key] = !general.value[key];
        this.setState({ general })
        // this.props.updateGeneralSave(general);
    }

    updateModuleConfig = () => {
        console.log('this.state', this.state)
    }

    updateGeneral = () => {
        let general = this.state.general.value
        let api = new APICall()
        let postData = { command: 'save_general', general: general }
        api.command(postData, this.processUpdateGeneral)
    }

    processUpdateGeneral = (result) => {
        console.log('result', result)
        if (result.error_code > 0) {
            alert('ERROR: The server encountered an unexpected error. Please try after some time')
            return
        }
        let general = this.state.general
        general.value = result.result.general
        alert('Update successful.')
        this.setState({ general })
    }

    render() {
        console.log(`this.state.has_gc=>${this.state.has_gc}`);
        console.log('show parent company fp', this.state.general);
        if (this.state.general === null)
            return (<div>Loading...</div>);

        return (
            <div>
                <BodyContainer>
                    <BodyInnerContainer>
                        <BoldLabel style={{ display: "inline-block" }}>Show Parent Company Financial
                            Promotions</BoldLabel>
                        {
                            (() => {
                                if (this.props.general.value.show_parent_company_financial_promotions) {
                                    return (
                                        <RadioBlock>
                                            <SelectedRadio
                                            >Yes</SelectedRadio>
                                            <UnselectedRadio disabled={!this.state.has_gc}
                                                onClick={this.switchRadio('show_parent_company_financial_promotions')}>No</UnselectedRadio>
                                        </RadioBlock>
                                    );
                                } else {
                                    return (
                                        <RadioBlock>
                                            <UnselectedRadio
                                                onClick={this.switchRadio('show_parent_company_financial_promotions')}>Yes</UnselectedRadio>
                                            <SelectedRadio disabled={!this.state.has_gc}>No</SelectedRadio>
                                        </RadioBlock>
                                    );
                                }
                            })()
                        }
                    </BodyInnerContainer>
                    <BodyInnerContainer>
                        <BoldLabel style={{ display: "inline-block" }}>Enable Approval Process for Financial Promotions?</BoldLabel>
                        {
                            (() => {
                                if (this.props.general.value.add_review_date_for_finpromo) {
                                    return (
                                        <RadioBlock>
                                            <SelectedRadio>Yes</SelectedRadio>
                                            <UnselectedRadio
                                                onClick={this.switchRadio('add_review_date_for_finpromo')}>No</UnselectedRadio>
                                        </RadioBlock>
                                    );
                                } else {
                                    return (
                                        <RadioBlock>
                                            <UnselectedRadio
                                                onClick={this.switchRadio('add_review_date_for_finpromo')}>Yes</UnselectedRadio>
                                            <SelectedRadio>No</SelectedRadio>
                                        </RadioBlock>
                                    );
                                }
                            })()
                        }
                    </BodyInnerContainer>
                    <BodyInnerContainer>
                        <BoldLabel style={{ display: "inline-block" }}>Enable Expiry Dates for Financial
                            Promotions?</BoldLabel>
                        {
                            (() => {
                                if (this.props.general.value.add_expiry_date_for_financial_promotions) {
                                    return (
                                        <RadioBlock>
                                            <SelectedRadio>Yes</SelectedRadio>
                                            <UnselectedRadio
                                                onClick={this.switchRadio('add_expiry_date_for_financial_promotions')}>No</UnselectedRadio>
                                        </RadioBlock>
                                    );
                                } else {
                                    return (
                                        <RadioBlock>
                                            <UnselectedRadio
                                                onClick={this.switchRadio('add_expiry_date_for_financial_promotions')}>Yes</UnselectedRadio>
                                            <SelectedRadio>No</SelectedRadio>
                                        </RadioBlock>
                                    );
                                }
                            })()
                        }
                    </BodyInnerContainer>
                    <BodyInnerContainer>
                        <BoldLabel style={{ display: "inline-block" }}>Confirm Financial Promotions with Outstanding
                            Follow-up Tasks</BoldLabel>
                        {
                            (() => {
                                if (this.props.general.value.complete_financial_promotions_with_outstanding_follow_up_tasks) {
                                    return (
                                        <RadioBlock>
                                            <SelectedRadio>Yes</SelectedRadio>
                                            <UnselectedRadio
                                                onClick={this.switchRadio('complete_financial_promotions_with_outstanding_follow_up_tasks')}>No</UnselectedRadio>
                                        </RadioBlock>
                                    );
                                } else {
                                    return (
                                        <RadioBlock>
                                            <UnselectedRadio
                                                onClick={this.switchRadio('complete_financial_promotions_with_outstanding_follow_up_tasks')}>Yes</UnselectedRadio>
                                            <SelectedRadio>No</SelectedRadio>
                                        </RadioBlock>
                                    );
                                }
                            })()
                        }
                    </BodyInnerContainer>
                    <FooterButtonsContainer>
                        <CancelBtn onClick={this.closePage}>Cancel</CancelBtn>
                        <SaveBtn onClick={this.updateGeneral}>Save</SaveBtn>
                    </FooterButtonsContainer>
                </BodyContainer>

            </div>
        );
    }
}

export default GeneralConfigs;
