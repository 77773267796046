import React from 'react';
import PropTypes from "prop-types";
import styled from 'styled-components';
import { Icon } from 'react-icons-kit';
import { plusCircle } from 'react-icons-kit/fa/plusCircle';
import SingleCustomField from './/SingleCustomField';
import CSLSimpleDropDown from '../Common/CSLSimpleDropDown';
// import Utils from '../../Common/Utils';
import {basic_trashcan} from 'react-icons-kit/linea/basic_trashcan';
import {software_pencil} from 'react-icons-kit/linea/software_pencil';
import {basic_floppydisk} from 'react-icons-kit/linea/basic_floppydisk';
import {checkboxChecked} from 'react-icons-kit/icomoon/checkboxChecked';
import {checkboxUnchecked} from 'react-icons-kit/icomoon/checkboxUnchecked';
import APICall from '../../Common/APICall';

const FooterButtonsContainer = styled.div`
  text-align: right;
  padding-top: 25px;
  width: 90%;
`;
const SaveBtn = styled.button`
  padding: 10px 30px;
  background-color: #37ada7;
  border: 1px solid #37ada7;
  border-radius: 2px;
  color: #ffffff;
  cursor: pointer;
  margin-left: 15px;
`;


const Section80 = styled.div`
	width: 80%;
	float: left;
	box-sizing: border-box;
`;
const Section20 = styled.div`
	width: 20%;
	float: left;
	box-sizing: border-box;
	text-align: right;
`;
const TextBox = styled.input`
	height: 20px;
    width: 98%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: ${process.env.DEFAULT_TEXT_COLOR};
    font-size: 15px;
    font-family: 'Montserrat',sans-serif;
    &:focus {
		outline-width: 0;
	}
`;

const MainContainer = styled.div`
	width: 90%;
`;
const InnerContainer = styled.div`
	background-color: #ffffff;
	padding: 30px;
`;
const TitleBoldLabel = styled.div`
	font-weight: 600;
	color: #595959;
	margin-top: 5px;
`;
const SubHeading = styled.div`
	font-style: italic;
	font-size: 12px;
	color: #adacac;
	margin-top: 5px;
`;
const SelectedRadio = styled.div`
	background-color: #37ADA7;
	border: 1px solid #1f615e;
	padding: 5px 10px;
	color: #ffffff;
	display: inline-block;
	cursor: pointer;
`;
const DropdownOptions = styled.textarea`
	border: 1px solid #ffffff;
    border-radius: 3px;
    background-color: #ffffff;
    box-shadow: 0 0 4px #c4c4c4;
    resize: none;
    padding: 5px;
    width: 98%;
    font-family: 'Montserrat',sans-serif;
    margin-top: 7px;
`;

const UnselectedRadio = styled.div`
	background-color: #F4F4F4;
	border: 1px solid #8c8c8c;
	padding: 5px 10px;
	color: #262626;
	display: inline-block;
	cursor: pointer;
`;
const RadioBlock = styled.div`
	margin-top: 10px;
`;
const Clearfix = styled.div`
	clear: both;
`;
const FloatingSection = styled.div`
	float: left;
	box-sizing: border-box;
`;
const BoldLabel = styled.div`
	font-weight: 600;
`;

class CustomFieldEditor extends React.Component
{
	state = {field: null, index: null, type_options: null, editable: false}

	setComponentState = () => {
		this.setState({field: this.props.field, index: this.props.index, type_options: this.props.type_options})
	}

	componentDidMount = () => {
		this.setComponentState()
	}

	componentDidUpdate = (prevprops) => {
		if(this.props === prevprops)return
		this.setComponentState()
	}

	changeFieldNickname = (e) => {
		e.preventDefault()
		let field = this.state.field;
		field.nickname = e.target.value 
		this.setState({field})
	}

	modifyDropdownOptions = (e) => {
		e.preventDefault()
		let field = this.state.field;
		let dropdown_options = e.target.value.split("\n")
		field.dropdown_options = dropdown_options

		this.setState({field})
	}

	changeFieldType = (type) => {
		// console.log('s', s)
		let field = this.state.field 
		field.type = type 
		this.setState({field})
	}

	toggleOther = (e) => {
		e.preventDefault()
		let field = this.state.field
		field.other = !field.other
		this.setState({field})
	}
	toggleRequired = (e) => {
		e.preventDefault()
		let field = this.state.field
		field.required = !field.required
		this.setState({field})
	}

	removeField = () => {
		this.props.removeField(this.state.index)
	}

	render() {
		if(this.state.field === null) return (<div />)
		console.log('CustomFieldEditor this.state', this.state)
		let dropdown_options_str = 'dropdown_options' in this.state.field && this.state.field.dropdown_options.length > 0 ? this.state.field.dropdown_options.join("\n") : ''
		return (<>
			<div style={{width: "100%", boxSizing: "border-box", marginTop: 15}}>
				<Section80>
					<FloatingSection>
						{
							(() => {
								if (this.state.editable) {
									return (<TextBox defaultValue={this.state.field.nickname} name={this.state.field.name} onChange={this.changeFieldNickname} />)
								} else {
									return (<BoldLabel>{this.state.field.nickname}</BoldLabel>);
								}
							})()
						}
					</FloatingSection>
					<FloatingSection style={{paddingLeft: "7px"}}>
						{
							(() => {
								if (this.state.editable) {
									return (<Icon style={{cursor: "pointer", marginLeft: "20px", paddingTop: "5px"}} size={18} icon={basic_floppydisk} onClick={()=>this.setState({editable: false})} />);
								} else {
									return (<Icon style={{cursor: "pointer"}} icon={software_pencil} onClick={()=>this.setState({editable: true})} />);
								}
							})()
						}
					</FloatingSection>
					<Clearfix></Clearfix>
				</Section80>
				<Section20><Icon style={{cursor: "pointer"}} icon={basic_trashcan} onClick={this.removeField} /></Section20>
				<Clearfix></Clearfix>
				<BoldLabel style={{padding: "10px 0px", color: "#595959"}}>Field Type</BoldLabel>
				<div style={{width: "40%"}}>
					<CSLSimpleDropDown options={this.state.type_options} selected={this.state.field.type} changeFieldType={this.changeFieldType} />
				</div>
				
			</div>
			{
				(() => {
					if (this.state.field.type === "Dropdown") {
						return (<>
							<div style={{padding: "10px 0px"}}>
								<SubHeading>Configure the dropdown options. New line separates options.</SubHeading>
								<DropdownOptions rows="7" value={dropdown_options_str} onChange={this.modifyDropdownOptions} />
							</div>
							<div style={{marginTop: "10px"}}>
								<div style={{float: "left", width: "50%", boxSizing: "border-box"}}>
									<FloatingSection>
										{
											(() => {
												if (this.state.field.required) {
													return (<Icon style={{cursor: "pointer", color: "#37ADA7"}} icon={checkboxChecked} onClick={this.toggleRequired} />)
												} else {
													return (<Icon style={{cursor: "pointer", color: "#37ADA7"}} icon={checkboxUnchecked} onClick={this.toggleRequired} />)
												}
											})()
										}
									</FloatingSection>
									<FloatingSection style={{paddingLeft: "7px"}}><BoldLabel>Required field</BoldLabel></FloatingSection>
									<Clearfix></Clearfix>
								</div>
								<div style={{float: "right", width: "50%", boxSizing: "border-box"}}>
									<FloatingSection>
										{
											(() => {
												if (this.state.field.other) {
													return (<Icon style={{cursor: "pointer", color: "#37ADA7"}} icon={checkboxChecked} onClick={this.toggleOther} />)
												} else {
													return (<Icon style={{cursor: "pointer", color: "#37ADA7"}} icon={checkboxUnchecked} onClick={this.toggleOther} />)
												}
											})()
										}
									</FloatingSection>
									<FloatingSection style={{paddingLeft: "7px"}}><BoldLabel>Include 'Other'</BoldLabel></FloatingSection>
									<Clearfix></Clearfix>
								</div>
								<div style={{clear: "both"}}></div>
							</div>
						</>);
					}
				})()
			}
			{
				(() => {
					if (this.state.field.type !== "Dropdown") {
						return (
							<div style={{marginTop: "10px"}}>
								<FloatingSection>
									{
										(() => {
											if (this.state.field.required) {
												return (<Icon style={{cursor: "pointer", color: "#37ADA7"}} icon={checkboxChecked} onClick={this.toggleRequired} />)
											} else {
												return (<Icon style={{cursor: "pointer", color: "#37ADA7"}} icon={checkboxUnchecked} onClick={this.toggleRequired} />)
											}
										})()
									}
								</FloatingSection>
								<FloatingSection style={{paddingLeft: "7px"}}><BoldLabel>Required field</BoldLabel></FloatingSection>
								<Clearfix></Clearfix>
							</div>
						);
					}
				})()
			}
		</>)
	}

}

class CustomFields extends React.Component
{
	static propTypes = {
        /** Initial Data */
        custom_fields: PropTypes.object,
        /** callback: custom field */
		updateCustomFields: PropTypes.func,
    };

	state = {custom_fields: null};

	componentDidMount(){
		this.setState( {custom_fields: this.props.custom_fields} );
	}

	componentDidUpdate(prevProps){
		if (this.props !== prevProps)
			this.setState({custom_fields: this.props.custom_fields});
	}

	switchRadio = (key) => (event) => {
		event.preventDefault();
		let custom_fields = JSON.parse(JSON.stringify(this.state.custom_fields));
		custom_fields.value[key] = custom_fields.value[key] ? false : true;
		this.setState({custom_fields});
		// this.props.updateCustomFields(custom_fields);
	}

	removeField = (index) => {
		let custom_fields = this.state.custom_fields
		custom_fields.value.additional_fields.splice(index, 1)
		this.setState({custom_fields})
	}

	addAdditionalField = (event) => {
		event.preventDefault();
		let custom_fields = this.state.custom_fields
		let custom_fields_temp = JSON.parse(JSON.stringify(custom_fields.value));
		// const randtxt = Utils.genKey(10);
		const randtxt = Math.floor(Math.random() * 9000000000) + 1000000000; // random 10 digits
		custom_fields_temp.additional_fields.push({
			name: randtxt,
			nickname: "Untitled Field",
			type: "Alpha",
			dropdown_options: [],
			required: false,
			other: false
		});
		custom_fields.value = custom_fields_temp
		this.setState({custom_fields})
		// this.props.updateCustomFields(custom_fields);
	}

	updateCustomField = (field, index) => {
		console.log('field, index', field, index)
		let custom_fields = this.state.custom_fields
		custom_fields.value[index] = field 
		this.setState({custom_fields})
	}

	saveCustomFields = () => {
		console.log('saveCustomFields custom_fields', this.state.custom_fields)
		let custom_fields = this.state.custom_fields.value 
		let api = new APICall()
		let postData = {command: 'save_custom_fields', custom_fields: custom_fields}
		api.command(postData, this.processSaveCustomFields)
	}

	processSaveCustomFields = (result) => {
		if(result.error_code > 0) {
			alert('ERROR: The server encountered an unexpected error. Please try after some time')
            return
		}
		alert('Custom fields saved successfully.')
		let custom_fields = this.state.custom_fields
		custom_fields.value = result.result.custom_fields
		this.setState({custom_fields})
	}

	render()
	{
		if (this.state.custom_fields === null)
			return (<div>Loading...</div>);

		console.log('this.state custom_fields', this.state)

		return (
			<div>
				<MainContainer>
					<InnerContainer>
						<TitleBoldLabel>Enable Custom Fields</TitleBoldLabel>
						<SubHeading>Configure customisable fields to be utilised within this Module</SubHeading>
						{
							(() => {
								if (this.state.custom_fields.value.enabled) {
									return (
										<RadioBlock>
											<SelectedRadio>Yes</SelectedRadio>
											<UnselectedRadio onClick={this.switchRadio('enabled')}>No</UnselectedRadio>
										</RadioBlock>
									);
								} else {
									return (
										<RadioBlock>
											<UnselectedRadio onClick={this.switchRadio('enabled')}>Yes</UnselectedRadio>
											<SelectedRadio>No</SelectedRadio>
										</RadioBlock>
									);
								}
							})()
						}
						{
							(() => {
								if (this.state.custom_fields.value.enabled) {
									return (
										<div>
											{ this.state.custom_fields.value.additional_fields.map((field, index) =>
												<CustomFieldEditor
													key={index}
													field={field}
													index={index}
													removeField={this.removeField}
													type_options={this.state.custom_fields.available_field_types}
													updateCustomField={this.updateCustomField} /> )
											}

											
											<div style={{marginTop: "20px"}}>
												<FloatingSection><Icon style={{cursor: "pointer", color: "#37ADA7"}} icon={plusCircle} size={20} onClick={this.addAdditionalField} /></FloatingSection>
												<FloatingSection style={{paddingLeft: "7px"}}><BoldLabel style={{paddingTop: "1px"}}>Additional Custom Field</BoldLabel></FloatingSection>
												<Clearfix></Clearfix>
											</div>
										</div>
									);
								}
							})()
						}
					</InnerContainer>
					<FooterButtonsContainer>
                        <SaveBtn onClick={this.saveCustomFields}>Save</SaveBtn>
                    </FooterButtonsContainer>
				</MainContainer>
			</div>
		);
	}
}

export default CustomFields;


// { this.state.custom_fields.value.additional_fields.map((item, index) =>
// 												<SingleCustomField
// 													key={index}
// 													data={this.state.custom_fields.value}
// 													outer_data_key="additional_fields"
// 													inner_data={item}
// 													removable={true}
// 													updateCustomFields={this.updateCustomFields} /> )
// 											}




// name: 4176477865
// nickname: "Untitled Field"
// other: false
// required: false
// type: "Alpha"