import React from 'react';
import styled from 'styled-components';
import { FaTimes, FaEdit, FaPlusCircle, FaPlus } from 'react-icons/fa';
import { IoIosArrowForward } from 'react-icons/io';
import APICall from '../../Common/APICall';
import CSLLoader from '../../Components/Common/CSLLoader';
import Store from '../../Common/Store.js';
import Utils from '../../Common/Utils.js';
import ConfigTypes from './ConfigTypes'
import Email from './Email'
import ReasonCodes from './ReasonCodes'
import GeneralConfigs from './GeneralConfigs'
import CustomFields from './CustomFields'
import CSLToggle from '../Common/CSLToggle'
import EditSectionNameModal from './EditSectionNameModal'
import ConfigEditModal from './ConfigEditModal'
import Reload from '../../Common/Reload.js';
import AddCheckListModal from "../FPR/AddCheckListModal";
import { Container, Draggable } from 'react-smooth-dnd';

const ReviewBodyContainer = styled.div`
  padding-top: 25px;
  width: 100%;
`;
const ReviewLeftContainer = styled.div`
  display: inline-table;
  overflow: hidden;
  text-overflow: ellipsis;

  position: sticky;
  top: 2px;
  float: left;
  padding-right: 20px;
  width: 20%;
  box-sizing: border-box;
`;
const ReviewRightContainer = styled.div`
  float: right;
  padding-left: 20px;
  width: 80%;
  box-sizing: border-box;
`;
const ReviewTab = styled.div`
  background-color: #ffffff;
  color: ${process.env.DEFAULT_TEXT_COLOR};
  padding: 10px 20px;
  cursor: pointer;
`;
const CloseBtn = styled.div`
  float: right;
  font-size: 20px;
  color: #9c9c9c;
  cursor: pointer;
  padding-top: 10px;
  padding-right: 5px;
`;
const FooterButtonsContainer = styled.div`
  text-align: right;
  padding-top: 25px;
  width: 90%;
`;
const CancelBtn = styled.button`
  padding: 10px 20px;
  background-color: #ffffff;
  border: 1px solid #ffffff;
  border-radius: 2px;
  color: #333333;
  cursor: pointer;
`;
const SaveBtn = styled.button`
  padding: 10px 30px;
  background-color: #37ada7;
  border: 1px solid #37ada7;
  border-radius: 2px;
  color: #ffffff;
  cursor: pointer;
  margin-left: 15px;
`;
const ErrorBar = styled.div`
  background-color: #f6dbdb;
  color: #de8d8e;
  font-weight: 600;
  padding: 10px 20px;
`;
const ExportButton = styled.button`
  box-sizing: border-box;
  background-color: #213e61;
  color: #f2f2f2;
  border: 1px solid #213e61;
  width: 125px;
  font-size: 14px;
  height: 32px;
  border-radius: 5px;
  margin-right: 3px;
  float: left;
`;
const LeftLabel = styled.div`float: left;`;
const RightArrow = styled.div`
  float: right;
  color: #39b3ad;
  margin-top: 2px;
  padding-left: 3px;
`;
const QuestionLine = styled.div`
  padding: 5px;
  height: 30px;
  cursor: grabbing;
`;
const QuestionTitle = styled.div`
  font-size: 13px;
  font-weight: 500;
  float: left;
  width: calc(100% - 150px);
`;
const QSearchInput = styled.input`
  height: 25px;
  width: 829px;
  box-shadow: rgb(196, 196, 196) 0px 0px 4px;
  color: #545454;
  font-family: montserrat;
  font-size: 15px;
  font-weight: 400;
  border-width: 1px;
  border-style: solid;
  border-color: rgb(255, 255, 255);
  border-image: initial;
  border-radius: 3px;
  padding: 2px;
`;

const AddButton = styled.div`
  width: 30px;
  height: 30px;
  margin: 0px;
  float: right;
  padding-left: 10px;
  font-size: 16px;
  cursor: pointer;
`;

const SerialHeader = styled.div`
  text-align: center;
  flex: 30 0 auto;
  width: 30px;
  max-width: 30px;
  cursor: pointer;
  padding: 5px 5px;
  line-height: normal;
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.05);
  transition: box-shadow .3s cubic-bezier(.175, .885, .32, 1.275);
  box-shadow: inset 0 0 0 0 transparent;
  white-space: nowrap;
  text-overflow: ellipsis;
  -webkit-box-flex: 1;
  height: 35px;
`;

const QuestionTitleHeader = styled.div`
  text-align: left;
  flex: 100 0 auto;
  width: 100px;
  cursor: pointer;
  overflow: visible;
  padding: 5px 5px;
  line-height: normal;
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.05);
  transition: box-shadow .3s cubic-bezier(.175, .885, .32, 1.275);
  box-shadow: inset 0 0 0 0 transparent;
  white-space: nowrap;
  text-overflow: ellipsis;
  -webkit-box-flex: 1;
  height: 35px;
`;

const ToggleHeader = styled.div`
  text-align: center;
  flex: 80 0 auto;
  width: 80px;
  max-width: 80px;
  overflow: visible;
  padding: 5px 5px;
  line-height: normal;
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.05);
  transition: box-shadow .3s cubic-bezier(.175, .885, .32, 1.275);
  box-shadow: inset 0 0 0 0 transparent;
  white-space: nowrap;
  text-overflow: ellipsis;
  -webkit-box-flex: 1;
  height: 35px;
`;
const ActionHeader = styled.div`
  text-align: center;
  flex: 55 0 auto;
  width: 55px;
  max-width: 55px;
  overflow: hidden;
  cursor: pointer;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 5px 5px;
  line-height: normal;
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.05);
  transition: box-shadow .3s cubic-bezier(.175, .885, .32, 1.275);
  box-shadow: inset 0 0 0 0 transparent;
  height: 35px;
`;

const TypesBoldLabel = styled.div`
  font-weight: 600;
  color: #595959;
  margin-top: 5px;
`;

const ClearBoth = styled.div`clear: both;`;

class Checklist extends React.Component {
    state = {checklist: null, 
              questions: null, 
              cur_q_id:'0', 
              cur_q_index: 0, 
              search_string: '', 
              keyCount: 0, 
              q_order_save: '', 
              q_order: null,
              edit_q_id: null,
              show_edit_question_modal: false,
              show_edit_checklist_modal: false,
              show_order_save_button: false
            }

    object_store = {}

    setComponentState = () => {
        let checklist = this.props.parentstate.checklists.value[this.props.parentstate.active_checklist_index]
        let q_order = checklist.q_order
        let q_order_save = JSON.stringify(q_order)
        let questions = checklist.questions
        let isActive = false;
        if(!('isActive' in checklist)) {
          Object.entries(questions.value).forEach(([k, v]) => {if(v.isActive === 1 && v.isDelete === 0) isActive=true});
          checklist.isActive = isActive
        }
        this.setState({checklist, questions, q_order, q_order_save})
    }

    componentDidMount = () => {
        this.setComponentState()
    }

    componentDidUpdate = (prevprops) => {
        if(this.props === prevprops)return
        this.setComponentState()
    }

    getKey() {
        return this.keyCount++;
    }

    addQuestion = () => {

    }

    searchQuestions = () => {

    }
     
    showChecklistModal = () => {
        this.setState({show_edit_checklist_modal: true})
    }
    
    closeEditChecklistModal = () => {
        this.setState({show_edit_checklist_modal: false})
    }

    arrayMove(arr, fromIndex, toIndex) {
        var element = arr[fromIndex];
        arr.splice(fromIndex, 1);
        arr.splice(toIndex, 0, element);
    }
    applyDrag = (e) => {
        // console.log('e',e)
        // e.preventDefault()
        let checklist = this.state.checklist
        
        this.arrayMove(checklist.q_order, e.removedIndex, e.addedIndex)
        let q_order = this.state.checklist.q_order
        let show_order_save_button = JSON.stringify(q_order) !== this.state.q_order_save ? true : false
        this.setState({checklist, q_order, show_order_save_button})
    }
    questionToggle = (q) => {

    }

    notDeleteType = () =>
        alert(`This ${this.state.section_name} is in use.`);

    openModal = (q_id) => {
        let show_edit_question_modal = true 
        let edit_q_id = q_id
        this.setState({show_edit_question_modal, edit_q_id})
    }

    deleteType = () => {

    }

    processSaveQOrder = (result) => {
        console.log('result', result)
        if(result.error_code > 0) {
            alert(result.error_msg)
            return
        }

        let checklist = result.result.checklist
        let q_order = checklist.q_order 
        let q_order_save = JSON.stringify(q_order)
        let show_order_save_button = false;
        this.setState({checklist, q_order, q_order_save, show_order_save_button})
    }

    saveQuestionOrder = () => {
        let checklist = JSON.parse(JSON.stringify(this.state.checklist))
        checklist.q_order = this.state.q_order;

        let api = new APICall()
        let postData = {command: 'save_checklist', checklist: checklist}
        console.log('postData', postData)
        api.command(postData, this.processSaveQOrder)
    }

    deleteQuestion = (q_id) => {
        let confirmation = window.confirm("WARNING: You are trying to delete a question. This action cannot be reversed. Do you want to proceed?")
        if(!confirmation)return
        let questions = this.state.questions
        let question_temp = questions.value[q_id]
        let cmd_id = Utils.genKey(15)
        this.object_store[cmd_id] = {q_id: q_id}
        question_temp.isDelete = 1
        this.setState({questions})
        let question = {record_id: question_temp.record_id, isDelete: 1, q_id: q_id}
        let postData = {command: 'delete_question', question: question, cmd_id: cmd_id}
        console.log('postData', postData)
        let api = new APICall()
        api.command(postData, this.processDeleteQuestion)
    }

    processDeleteQuestion = (result, command_id) => {
        let questions = this.state.questions;
        if(result.error_code > 0) {
            alert('ERROR: Could not get a response from the server. Your update failed. Please retry after some time.')
            questions.value[this.object_store[command_id].q_id].isDelete = 0
            delete this.object_store[command_id]
            this.setState({questions})
            return
        }
        console.log(result.result)
        
        let q_id = result.result.question.q_id
        questions.value[q_id].isDelete = 1;
        this.setState({questions})
    }

    toggleQuestionStatus = (s, q_id) => {
        if(!this.state.checklist.isActive) {
            alert("To change question status, you must first activate the checklist.")
            return
        }
        console.log('s, q_id', s, q_id)
        let isActive = s ? 1 : 0
        let question_temp = this.state.questions.value[q_id]
        let question = {record_id: question_temp.record_id, isActive: isActive, q_id: q_id}
        let command_id = Utils.genKey(13)
        let postData = {command: 'change_question_status', question: question, cmd_id: command_id}
        console.log('postData', postData)
        let api = new APICall()
        let questions = this.state.questions 
        questions.value[q_id].isActive = isActive
        this.setState({questions})
        
        this.object_store[command_id] = {q_id: q_id, isActive: s}
        api.command(postData, this.processChangeQuestion)
        
    }

    processChangeQuestion = (result, command_id) => {
        console.log('result, command_id, this.object_store[command_id]',result, command_id, this.object_store[command_id])
        let questions = this.state.questions;
        let q_id = result.error_code === 0 ? result.result.question.q_id : null
        if(result.error_code > 0) {
            alert('ERROR: Could not get a response from the server. Your update failed. Please retry after some time.')
            questions.value[this.object_store[command_id].q_id].isActive = this.object_store[command_id].isActive
            delete this.object_store[command_id]
            this.setState({questions})
            return
        }
        
        questions.value[q_id].isActive = result.result.question.isActive;
        this.setState({questions})
    }

    toggleChecklistStatus = (s) => {
        let checklist_temp = this.state.checklist 
        let checklist = {record_id: checklist_temp.record_id, isActive: s}
        let cmd_id = Utils.genKey(13)
        this.object_store[cmd_id] = {isActive: checklist_temp.isActive}
        let postData = {command: 'change_checklist', checklist: checklist, cmd_id: cmd_id}
        checklist_temp.isActive = s 
        this.setState({checklist: checklist_temp})
        console.log('checklist', checklist)
        let api = new APICall()
        api.command(postData, this.processChangeChecklistStatus)
    }

    processChangeChecklistStatus = (result, command_id) => {
        let checklist = this.state.checklist;
        if(result.error_code > 0) {
            alert('ERROR: Could not get a response from the server. Your update failed. Please retry after some time.')
            checklist.isActive = this.object_store[command_id].isActive
            delete this.object_store[command_id]
            this.setState({checklist})
            return
        }
        // console.log('processChangeChecklistStatus')
        checklist.isActive = result.result.checklist.isActive;
        this.setState({checklist})
        this.props.onChangeChecklist(checklist.isActive, checklist.title)
    }

    updateChecklistName = (title) => {
        let checklist_temp = this.state.checklist
        let checklist = {record_id: checklist_temp.record_id, title: title}
        let postData = {command: 'change_checklist', checklist: checklist}
        console.log('checklist', checklist)
        let api = new APICall()
        api.command(postData, this.processChangeChecklistName)
    }

    processChangeChecklistName = (result) => {
        let checklist = this.state.checklist;
        checklist.title = result.result.checklist.title;
        this.setState({checklist, show_edit_checklist_modal: false})
        this.props.onChangeChecklist(checklist.isActive, checklist.title)
    }

    saveQuestion = (q, id) => {
        
        let api = new APICall()
        let postData = {}
        postData.command = id === '0' ? 'add_question' : 'save_question'
        postData.question = id === '0' ? {__type: 'obj', value: q} : q
        let checklist = this.state.checklist
        if(id === '0')checklist.q_order.push(q.q_id)
        postData.checklist = checklist
        console.log('postData',postData)
        api.command(postData, this.processSaveQuestion)
    }

    processSaveQuestion = (result) => {
        console.log(result)
        let questions = this.state.questions 

        questions.value[result.result.question.q_id] = result.result.question
        let checklist = this.state.checklist
        if('checklist' in result.result) {
            checklist.q_order = result.result.checklist.q_order
            let q_order = checklist.q_order  
            let q_order_save = JSON.stringify(q_order)
            this.setState({checklist, questions, q_order, q_order_save})
        } else {
            this.setState({questions})
        }
        // this.forceUpdate()
        this.closeModal()
    }

    closeModal = () => {
        this.setState({show_edit_question_modal: false})
    }

    render() {
        if(this.state.checklist === null)return <div />

        console.log('checklist state', this.state)
        let checklist = this.state.checklist
        let active_checklist = checklist.isActive
        console.log('active_checklist', active_checklist)
        let count = 0;
        return (
            <div style={{ backgroundColor: "white", width: '90%' }}>
                <QuestionLine>
                    <QuestionTitle style={{ width: 'calc(100% - 220px' }}>
                        <QSearchInput placeholder="Search..."
                            onChange={this.searchQuestions}
                        />
                    </QuestionTitle>
                </QuestionLine>
                <div style={{ display: "flex", justifyContent: 'space-between' }}>
                    <div>
                        <TypesBoldLabel
                            style={{
                                display: "inline-block",
                                marginTop: '4px',
                                marginLeft: '12px'
                            }}>{this.state.checklist.title}</TypesBoldLabel>
                        <FaEdit onClick={this.showChecklistModal} style={{
                            cursor: 'pointer',
                            fontSize: '18px',
                            color: '#b4b5b4',
                            display: 'inline-block',
                            marginLeft: "12px"
                        }} />
                    </div>

                    <div style={{
                        cursor: 'pointer',
                        fontSize: '17px',
                        marginRight: '15px',
                        color: "#04ADA8"
                    }}
                        onClick={() => this.openModal('0')}>
                        <FaPlusCircle title={this.state.checklist.title} />
                  </div>
                </div>
                {
                  (() => {
                    if(this.state.show_order_save_button) {
                      return (<div style={{display: 'flex', justifyContent: 'flex-end', marginTop: 5, marginBottom: 5, cursor: 'pointer', marginRight: 15}}>
                          <SaveBtn onClick={this.saveQuestionOrder}>Save Ordering</SaveBtn>
                        </div>)
                    }
                  })()
                }
                <div style={{ display: "flex", backgroundColor: '#F5F5FD' }}>
                    <SerialHeader>#</SerialHeader>
                    <QuestionTitleHeader>Question</QuestionTitleHeader>
                    <ToggleHeader>{
                        <CSLToggle
                            checked={this.state.checklist.isActive}
                            onClick={this.toggleChecklistStatus}
                            change_state={false}
                        />
                    }</ToggleHeader>
                    <ActionHeader>Action</ActionHeader>
                </div>
                <Container
                    onDrop={e => this.applyDrag(e)}>
                    {

                      this.state.q_order.map((q_id, i) => {
                        //debugger;
                        let q = this.state.questions.value[q_id]
                        // console.log('q', q)
                        let display = q.isDelete === 0 ? 'block' : 'none'
                        // console.log('display', display)
                        if(q.isDelete === 0)count++

                        return (
                            <Draggable key={i} style={{display: display}}>
                                <div key={this.getKey()}
                                    style={{ display: 'flex' }}>
                                    <SerialHeader
                                        style={{
                                            fontWeight: 400,
                                            color: '#282828'
                                        }}
                                    >
                                        {count}
                                    </SerialHeader>
                                    <QuestionTitleHeader style={{
                                        backgroundColor: 'white',
                                        height: '100%',
                                        borderBottom: '0',
                                        marginRight: '35px',
                                        whiteSpace: 'pre-wrap',
                                        wordBreak: 'break-all',
                                    }}>{q.title}</QuestionTitleHeader>
                                    <AddButton
                                        style={{
                                            marginRight: '30px',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            width: '14px'
                                        }}>
                                        <CSLToggle
                                            checked={(q.isActive === 1 && active_checklist) ? true : false}
                                            id={q_id}
                                            change_state={false}
                                            onClick={this.toggleQuestionStatus}
                                        />
                                        
                                    </AddButton>
                                    <AddButton
                                        style={{
                                            marginRight: '12px',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            borderLeft: '1px solid rgba(0, 0, 0, 0.05)',
                                            borderBottom: '1px solid rgba(0, 0, 0, 0.05)',
                                            width: '45px'
                                        }}>
                                        {
                                            (() => {
                                                // if(active_checklist)
                                                  if (q.isDelete === 0) {
                                                      return (<div key={this.getKey()}>
                                                          <FaEdit onClick={() => this.openModal(q.q_id)}
                                                              style={{
                                                                  cursor: 'pointer',
                                                                  fontSize: '18px',
                                                                  color: '#b4b5b4',
                                                                  display: 'inline-block',
                                                                  marginRight: "5px"
                                                              }} />
                                                          <FaTimes onClick={() => this.deleteQuestion(q.q_id)} style={{
                                                              cursor: 'pointer',
                                                              fontSize: '18px',
                                                              color: '#b4b5b4',
                                                              display: 'inline-block'
                                                          }} />
                                                      </div>)
                                                  } 
                                            })()
                                        }
                                    </AddButton>
                                </div>
                            </Draggable>
                        );
                    })}
                </Container>
                {
                    (() => {
                        if(this.state.show_edit_checklist_modal){
                            return (
                              <EditSectionNameModal
                                sectionName={this.state.checklist.title}
                                updateConfigName={this.updateChecklistName}
                                closeModal={this.closeEditChecklistModal} />
                              )
                        }
                    })()
                }
                {
                    (() => {
                        if(this.state.show_edit_question_modal){
                            return (
                              <ConfigEditModal
                                  questions={this.state.questions.value}
                                  parent_id={this.state.questions.parent_id}
                                  saveQuestion={this.saveQuestion}
                                  q_id={this.state.edit_q_id}
                                  closeModal={this.closeModal} />
                              )
                        }
                    })()
                }
            </div>
            )
        }
    }

export default Checklist;