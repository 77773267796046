/* eslint-disable */
import React, {Component} from "react";
import PropTypes from "prop-types";
import styled from 'styled-components';
import { FiDownload } from 'react-icons/fi'
import { MdClear } from 'react-icons/md'
import { saveAs } from 'file-saver';
import UploadFile from './UploadFile';
import "./Dropzone.css";
import CSLProgressbar from './CSLProgressbar';
import {APICall, Store} from "./index";

const AttachmentButton = styled.button`
    width: 100%;
    padding: 10px;
    background-color: #37ADA7;
    border: 1px solid #37ADA7;
    border-radius: 4px;
    color: #ffffff;
    cursor: pointer;
    font-family: 'Montserrat', sans-serif;
    &:hover {
      background-color: #2e948f;
      border-color: #2e948f;
    }
`;

class Dropzone extends Component {

  static propTypes = {
    /** */
    disabled: PropTypes.bool,
    /** */
    initFiles: PropTypes.array,
    /** enables the 'Add an attachment' button */
    addnew: PropTypes.any,
    /** callback: returns files */
    onFilesAdded: PropTypes.func,
  };

  state = { files: [], bin_files: [], files_to_show: [], addnew: true };
  binFiles = [];
  numFiles = 0;
  prevNumFiles = 0;
  numFilesLoading = 0;

  constructor (props) {
    super(props);
    this.state = { hightlight: false, got_file: 0, files: [], files_to_show: [], isShowProgressBar: false, percentLoaded: 0 };
    this.fileInputRef = React.createRef();

    this.openFileDialog = this.openFileDialog.bind(this);
    this.onFilesAdded = this.onFilesAdded.bind(this);
    this.onDragOver = this.onDragOver.bind(this);
    this.onDragLeave = this.onDragLeave.bind(this);
    this.onDrop = this.onDrop.bind(this);

    this.uploadApi = new UploadFile();
  }

  uploadFilesFromChild = async (files) => {
    let result = null;
    console.log('called from Parent');
    console.log('files array==>', files);
    // upload to temp storage
    await this.uploadApi.command(files, this.returnTempFilesInfo, this.updateStatus);
  }


  returnTempFilesInfo = (result) => {
    this.props.onUploadComplete(result);
  };


  updateStatus = (status) => {

    const { loaded, total } = status;
    let percentLoaded = Math.round((loaded / total) * 100);
    //console.log('percentLoaded ===>',percentLoaded);
    if (percentLoaded > 0 && percentLoaded <= 99) {
      //show progress bar
      this.setState({ ...this.state, isShowProgressBar: true, percentLoaded: percentLoaded });
    } else {
      //hide progress bar
      this.setState({ ...this.state, isShowProgressBar: false, percentLoaded: percentLoaded });
    }
  }



  openFileDialog() {
    if (this.props.disabled) return;
    this.fileInputRef.current.click();
  }

  updateProgress = (evt) => {
    if (evt.lengthComputable) {
      var percentLoaded = Math.round((evt.loaded / evt.total) * 100);
      //console.log('percentLoaded',percentLoaded);
    }
  }

  readBinaryFile = (file) => {
    let reader = new FileReader();
    let that = this;
    that.binFiles.forEach((tfile, index) => {
      if (typeof tfile.uid === 'undefined') {
        that.binFiles.splice(index, 1);
      }
    })
    reader.onprogress = this.updateProgress;
    reader.onload = (function (theFile) {
      return function (e) {
        // let processed_bin = e.target.result.replace(/\n/g,'');
        let fElement = { uid: that.genKey(10), name: theFile.name, bin_file: window.btoa(e.target.result).replace(/\n/g, '') };
        that.binFiles.push(fElement);
        that.numFiles++;
      };
    })(file);

    reader.readAsBinaryString(file);
  }

  checkFilesUploading = () => {
    if (this.numFiles < this.prevNumFiles + this.numFilesLoading) {
      setTimeout(this.checkFilesUploading, 500);
    } else {
      let files_to_show = JSON.parse(JSON.stringify(this.state.bin_files));
      this.binFiles.forEach((tfile) => {
        files_to_show.push(tfile);
      })
      this.props.onFilesAdded(this.binFiles);
      this.setState({ files_to_show });
    }
  }

  onFilesAdded(evt) {
    if (this.props.disabled) return;
    const files = evt.target.files;
    let tempFiles = this.state.files;
    this.numFilesLoading = files.length;
    this.prevNumFiles = this.numFiles;
    setTimeout(this.checkFilesUploading, 500);

    for (var i = 0; i < files.length; i++) {
      tempFiles.push(files.item(i));
      this.readBinaryFile(files.item(i));
    }
  }

  onDragOver(evt) {
    evt.preventDefault();

    if (this.props.disabled) return;

    this.setState({ hightlight: true });
  }

  onDragLeave() {
    this.setState({ hightlight: false });
  }

  onDrop(event) {
    event.preventDefault();

    if (this.props.disabled) return;

    const files = event.dataTransfer.files;
    if (this.props.onFilesAdded) {
      const array = this.fileListToArray(files);
      this.props.onFilesAdded(array);
    }
    this.setState({ hightlight: false });
  }

  fileListToArray(list) {
    const array = [];
    for (var i = 0; i < list.length; i++) {
      array.push(list.item(i));
    }
    return array;
  }

  handleRemove = (uid) => {
    let tempFiles = JSON.parse(JSON.stringify(this.state.files));
    let newtempFiles = [];
    this.binFiles.forEach((tfile) => {
      if (tfile.uid !== uid) {
        newtempFiles.push(tfile);
      }
    });
    this.binFiles = newtempFiles;
    // tempFiles.splice(index,1);
    let files_to_show = JSON.parse(JSON.stringify(this.state.bin_files));
    newtempFiles.forEach((tfile) => {
      files_to_show.push(tfile);
    });
    this.setState({ files: newtempFiles, files_to_show });
  }

  handleDownload = (uid) => {
      let dbinfile = this.state.files_to_show.find(item => item.uid === uid);
      if (dbinfile && dbinfile.bin_file && dbinfile.bin_file.length > 0) {
          this.downloadFile(dbinfile.bin_file, dbinfile.name);
      } else {
          this.downloadFileFromDb(dbinfile.ref_id, dbinfile.name);
      }
  }

  downloadFileFromDb = (refId, fileName) => {
      this.api = new APICall();
      let companyId = Store.getStoreData('curCompanyID')
      let postData = {command: 'get_binfile_content', ref_id: refId, companyId: companyId};
      this.api.command(postData, (response) => {
          this.afterFileDownload(response, fileName);
      });
  }

  afterFileDownload = (response, fileName) => {
      if (response.error_code === 0) {
          let fileContent = response.result[0]["file_content"];
          this.downloadFile(fileContent, fileName);
      }
  }

  downloadFile = (data, fileName) => {
      data = data.replace(/ /g, '+');
      const binary = window.atob(data);
      const binaryLength = binary.length;
      const decodedBytes = new Uint8Array(binaryLength);
      for (let index = 0; index < binaryLength; index++) {
          decodedBytes[index] = binary.charCodeAt(index);
      }
      const fileBlob = new Blob([decodedBytes]);
      saveAs(fileBlob, fileName);
  }

  componentDidMount() {
    let existing_files = this.props.initFiles === null ? [] : JSON.parse(JSON.stringify(this.props.initFiles));
    existing_files.forEach((efile) => {
      efile.removable = false;
    })
    let addnew = true;
    if ("addnew" in this.props) {
      addnew = this.props.addnew;
    }
    this.setState({ bin_files: existing_files, files_to_show: existing_files, files: [], addnew });
  }

  genKey = (length) => {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  render() {
    //console.log('isShowProgressBar===>',this.state.isShowProgressBar);
    //console.log('this.state.percentLoaded===>',this.state.percentLoaded);
    return (
      <div>
        {
          (() => {
            if (this.state.files_to_show.length > 0) {
              return (
                <div style={{ width: '100%' }}>
                  {
                    this.state.files_to_show.map((f, index) => {
                      const file_parts = f.name.split(".");
                      let ext = "PDF";
                      switch (file_parts[file_parts.length - 1]) {
                        case 'jpeg':
                        case 'jpg': ext = 'JPG'; break;
                        case 'png': ext = 'PNG'; break;
                        case 'docx': ext = 'DOCX'; break;
                        case 'doc': ext = 'DOC'; break;
                        case 'msg': ext = 'MSG'; break;
                        case 'txt': ext = 'TXT'; break;
                        case 'ppt': ext = 'PPT'; break;
                        case 'pptx': ext = 'PPTX'; break;
                        case 'xls': ext = 'XLS'; break;
                        case 'xlsx': ext = 'XLS'; break;
                        default: ext = 'PDF'; break;
                      }
                      let file_icon_var = ext === 'DOCX' ? 'DOC' : ext;
                      file_icon_var = ext === 'XLSX' ? 'XLS' : ext;
                      const file_icon = `/dropdownImages/${ext}.png`;
                      return (
                        <div key={index} style={{ width: "50%", float: "left", boxSizing: "border-box", padding: index === 0 || index % 2 === 0 ? "10px 10px 10px 0px" : "10px 0px 10px 10px" }}>
                          <div style={{ boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)" }}>
                            <div style={{ float: "left", padding: "10px", boxSizing: "border-box" }}>
                              <img src={file_icon} height="100px" width="auto" />
                              {/*<FileTypeContainer>{ext}</FileTypeContainer>*/}
                            </div>
                            <div style={{ float: "left", padding: "10px", boxSizing: "border-box" }}>
                              <div><strong title={f.name} style={{ color: '#000000' }}>{f.name.length > 20 ? f.name.substring(0, 20) : f.name}</strong><br /><br /></div>
                              <div style={{ cursor: "pointer" }} onClick={() => this.handleDownload(f.uid)}><FiDownload /> Download</div>
                              {
                                (() => {
                                  if (!("removable" in f)) {
                                    return (<div style={{ cursor: "pointer", marginTop: "10px" }} onClick={() => this.handleRemove(f.uid)}><MdClear /> Remove</div>);
                                  }
                                })()
                              }
                            </div>
                            <div style={{ clear: "both" }}></div>
                          </div>
                        </div>
                      );
                    })
                  }
                  <div style={{ clear: "both" }}></div>
                </div>
              )
            }

          })()
        }

        {
          (() => {
            if (this.state.isShowProgressBar === true) {
              return (
                <div>
                  <CSLProgressbar style={{ position: 'absolute' }} value={this.state.percentLoaded} />
                </div>
              );
            }
          })()
        }


        <input
          ref={this.fileInputRef}
          className="FileInput"
          type="file"
          multiple
          accept=".pdf,.jpg,.jpeg,.png,.docx,.doc,.msg,.txt,.ppt,.pptx,.xls,.xlsx"
          onChange={this.onFilesAdded}
        />
        {
          (() => {
            if (this.state.addnew) {
              return (
                <div style={{ paddingTop: "20px" }}>
                  <AttachmentButton onClick={this.openFileDialog}>Add an attachment</AttachmentButton>
                </div>
              );
            }
          })()
        }
      </div>
    );
  }
}

export default Dropzone;
