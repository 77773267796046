/* eslint-disable */
import React from 'react';
import PropTypes from "prop-types";
import styled from 'styled-components';
import { Icon } from 'react-icons-kit';
import {basic_trashcan} from 'react-icons-kit/linea/basic_trashcan';
import {software_pencil} from 'react-icons-kit/linea/software_pencil';
import {basic_floppydisk} from 'react-icons-kit/linea/basic_floppydisk';
import CSLSimpleDropDown from '../Common/CSLSimpleDropDown';
import {checkboxChecked} from 'react-icons-kit/icomoon/checkboxChecked';
import {checkboxUnchecked} from 'react-icons-kit/icomoon/checkboxUnchecked';

const FieldContainer = styled.div`
	padding: 20px 0px;
	border-bottom: 2px solid #d2d1d1;
`;
const Section80 = styled.div`
	width: 80%;
	float: left;
	box-sizing: border-box;
`;
const Section20 = styled.div`
	width: 20%;
	float: left;
	box-sizing: border-box;
	text-align: right;
`;
const Clearfix = styled.div`
	clear: both;
`;
const FloatingSection = styled.div`
	float: left;
	box-sizing: border-box;
`;
const BoldLabel = styled.div`
	font-weight: 600;
`;
const TextBox = styled.input`
	height: 20px;
    width: 98%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: ${process.env.DEFAULT_TEXT_COLOR};
    font-size: 15px;
    font-family: 'Montserrat',sans-serif;
    &:focus {
		outline-width: 0;
	}
`;
const SubHeading = styled.div`
	font-style: italic;
	font-size: 12px;
	color: #adacac;
	margin-top: 5px;
`;
const DropdownOptions = styled.textarea`
	border: 1px solid #ffffff;
    border-radius: 3px;
    background-color: #ffffff;
    box-shadow: 0 0 4px #c4c4c4;
    resize: none;
    padding: 5px;
    width: 98%;
    font-family: 'Montserrat',sans-serif;
    margin-top: 7px;
`;

class SingleCustomField extends React.Component
{

	static propTypes = {
		/** Initial Data Structure */
		data: PropTypes.object,
        /** Initial Data Structure */
        inner_data: PropTypes.object,
        /**  */
        outer_data_key: PropTypes.string,
        /** callback: with customfield */
		updateCustomFields: PropTypes.func,
        /**  */
        removable: PropTypes.bool,
    };

	state = {
		custom_fields: null,
		outer_data_key: null,
		inner_data: null,
		dropdown_options: "",
		removable: false,
		editable: false
	};

	componentDidMount(){
		let dropdown_options = "";
		if (this.props.inner_data.dropdown_options.length !== 0) {
			this.props.inner_data.dropdown_options.forEach((item, index) => {
				if (index !== (this.props.inner_data.dropdown_options.length - 1)) {
					dropdown_options = dropdown_options + item + "\n";
				} else {
					dropdown_options = dropdown_options + item;
				}
			})
		}
		this.setState({
			custom_fields: this.props.data,
			outer_data_key: this.props.outer_data_key,
			inner_data: this.props.inner_data,
			removable: this.props.removable,
			dropdown_options
		});
	}

	componentDidUpdate(prevProps){
		if (prevProps !== this.props) {
			// let dropdown_options = "";
			// if (this.props.inner_data.dropdown_options.length !== 0) {
			// 	this.props.inner_data.dropdown_options.forEach((item, index) => {
			// 		if (index !== (this.props.inner_data.dropdown_options.length - 1)) {
			// 			dropdown_options = dropdown_options + item + "\n";
			// 		} else {
			// 			dropdown_options = dropdown_options + item;
			// 		}
			// 	})
			// }
			this.setState({
				custom_fields: this.props.data,
				outer_data_key: this.props.outer_data_key,
				inner_data: this.props.inner_data,
				removable: this.props.removable,
				// dropdown_options
			});
		}
	}

	changeFieldNickname = (event) => {
		event.preventDefault();
		let custom_fields = JSON.parse(JSON.stringify(this.state.custom_fields));
		custom_fields[this.state.outer_data_key].forEach((item) => {
			if (item.name === this.state.inner_data.name) {
				item.nickname = event.target.value;
			}
		})
		let inner_data = this.state.inner_data;
		inner_data.nickname = event.target.value;
		this.setState({inner_data, custom_fields});
		this.props.updateCustomFields(custom_fields);
	}

	changeFieldType = (selected_type) => {
		let custom_fields = JSON.parse(JSON.stringify(this.state.custom_fields));
		custom_fields[this.state.outer_data_key].forEach((item) => {
			if (item.name === this.state.inner_data.name) {
				item.type = selected_type;
				item.dropdown_options = item.type !== "Dropdown" ? [] : item.dropdown_options;
			}
		})
		this.props.updateCustomFields(custom_fields);
	}

	modifyDropdownOptions = (event) => {
		event.preventDefault();
		let custom_fields = JSON.parse(JSON.stringify(this.state.custom_fields));
		custom_fields[this.state.outer_data_key].forEach((item) => {
			if (item.name === this.state.inner_data.name) {
				// const trimedvalue = event.target.value.replace(/^\s+|\s+$/g, '');
				const trimedvalue = event.target.value;
				let optarr = trimedvalue.split("\n");
				if (optarr.length === 1 && optarr[0] === "") {
					optarr = [];
				}
				item.dropdown_options = optarr;
			}
		})
		let dropdown_options = this.state.dropdown_options;
		dropdown_options = event.target.value;
		this.setState({dropdown_options, custom_fields});
		this.props.updateCustomFields(custom_fields);
	}

	toggleRequired = (event) => {
		event.preventDefault();
		let custom_fields = JSON.parse(JSON.stringify(this.state.custom_fields));
		custom_fields[this.state.outer_data_key].forEach((item) => {
			if (item.name === this.state.inner_data.name) {
				item.required = item.required ? false : true;
			}
		})
		this.props.updateCustomFields(custom_fields);
	}

	toggleOther = (event) => {
		event.preventDefault();
		let custom_fields = JSON.parse(JSON.stringify(this.state.custom_fields));
		custom_fields[this.state.outer_data_key].forEach((item) => {
			if (item.name === this.state.inner_data.name) {
				item.other = item.other ? false : true;
			}
		})
		this.props.updateCustomFields(custom_fields);
	}

	removeField = (event) => {
		event.preventDefault();
		let custom_fields = JSON.parse(JSON.stringify(this.state.custom_fields));
		let new_addl_fields = [];
		custom_fields[this.state.outer_data_key].forEach((item) => {
			if (item.name !== this.state.inner_data.name) {
				new_addl_fields.push(item);
			}
		})
		custom_fields[this.state.outer_data_key] = new_addl_fields;
		this.props.updateCustomFields(custom_fields);
	}

	render()
	{
		if (this.state.custom_fields === null) {
			return (<div>Loading...</div>);
		}
		return (
			<FieldContainer>
				{
					(() => {
						if (this.state.removable) {
							return (
								<div style={{width: "100%", boxSizing: "border-box"}}>
									<Section80>
										<FloatingSection>
											{
												(() => {
													if (this.state.editable) {
														return (<TextBox defaultValue={this.state.inner_data.nickname} name={this.state.inner_data.name} onChange={this.changeFieldNickname} />)
													} else {
														return (<BoldLabel>{this.state.inner_data.nickname}</BoldLabel>);
													}
												})()
											}
										</FloatingSection>
										<FloatingSection style={{paddingLeft: "7px"}}>
											{
												(() => {
													if (this.state.editable) {
														return (<Icon style={{cursor: "pointer", marginLeft: "20px", paddingTop: "5px"}} size={18} icon={basic_floppydisk} onClick={()=>this.setState({editable: false})} />);
													} else {
														return (<Icon style={{cursor: "pointer"}} icon={software_pencil} onClick={()=>this.setState({editable: true})} />);
													}
												})()
											}
										</FloatingSection>
										<Clearfix></Clearfix>
									</Section80>
									<Section20><Icon style={{cursor: "pointer"}} icon={basic_trashcan} onClick={this.removeField} /></Section20>
									<Clearfix></Clearfix>
								</div>
							);
						} else {
							return (
								<div>
									<FloatingSection>
										{
											(() => {
												if (this.state.editable) {
													return (<TextBox defaultValue={this.state.inner_data.nickname} name={this.state.inner_data.name} onChange={this.changeFieldNickname} />)
												} else {
													return (<BoldLabel>{this.state.inner_data.nickname}</BoldLabel>);
												}
											})()
										}
									</FloatingSection>
									<FloatingSection style={{paddingLeft: "7px"}}>
										{
											(() => {
												if (this.state.editable) {
													return (<Icon style={{cursor: "pointer", marginLeft: "20px", paddingTop: "5px"}} size={18} icon={basic_floppydisk} onClick={()=>this.setState({editable: false})} />);
												} else {
													return (<Icon style={{cursor: "pointer"}} icon={software_pencil} onClick={()=>this.setState({editable: true})} />);
												}
											})()
										}
									</FloatingSection>
									<Clearfix></Clearfix>
								</div>
							);
						}
					})()
				}
				<BoldLabel style={{padding: "10px 0px", color: "#595959"}}>Field Type</BoldLabel>
				<div style={{width: "40%"}}>
					<CSLSimpleDropDown options={this.state.custom_fields.available_field_types} selected={this.state.inner_data.type} changeFieldType={this.changeFieldType} />
				</div>
				{
					(() => {
						if (this.state.inner_data.type === "Dropdown") {
							return (
								<div style={{padding: "10px 0px"}}>
									<SubHeading>Configure the dropdown options. New line separates options.</SubHeading>
									<DropdownOptions rows="7" value={this.state.dropdown_options} onChange={this.modifyDropdownOptions} />
								</div>
							);
						}
					})()
				}
				{
					(() => {
						if (this.state.inner_data.type === "Dropdown") {
							return (
								<div style={{marginTop: "10px"}}>
									<div style={{float: "left", width: "50%", boxSizing: "border-box"}}>
										<FloatingSection>
											{
												(() => {
													if (this.state.inner_data.required) {
														return (<Icon style={{cursor: "pointer", color: "#37ADA7"}} icon={checkboxChecked} onClick={this.toggleRequired} />)
													} else {
														return (<Icon style={{cursor: "pointer", color: "#37ADA7"}} icon={checkboxUnchecked} onClick={this.toggleRequired} />)
													}
												})()
											}
										</FloatingSection>
										<FloatingSection style={{paddingLeft: "7px"}}><BoldLabel>Required field</BoldLabel></FloatingSection>
										<Clearfix></Clearfix>
									</div>
									<div style={{float: "right", width: "50%", boxSizing: "border-box"}}>
										<FloatingSection>
											{
												(() => {
													if (this.state.inner_data.other) {
														return (<Icon style={{cursor: "pointer", color: "#37ADA7"}} icon={checkboxChecked} onClick={this.toggleOther} />)
													} else {
														return (<Icon style={{cursor: "pointer", color: "#37ADA7"}} icon={checkboxUnchecked} onClick={this.toggleOther} />)
													}
												})()
											}
										</FloatingSection>
										<FloatingSection style={{paddingLeft: "7px"}}><BoldLabel>Include 'Other'</BoldLabel></FloatingSection>
										<Clearfix></Clearfix>
									</div>
									<div style={{clear: "both"}}></div>
								</div>
							);
						}
					})()
				}
				{
					(() => {
						if (this.state.inner_data.type !== "Dropdown") {
							return (
								<div style={{marginTop: "10px"}}>
									<FloatingSection>
										{
											(() => {
												if (this.state.inner_data.required) {
													return (<Icon style={{cursor: "pointer", color: "#37ADA7"}} icon={checkboxChecked} onClick={this.toggleRequired} />)
												} else {
													return (<Icon style={{cursor: "pointer", color: "#37ADA7"}} icon={checkboxUnchecked} onClick={this.toggleRequired} />)
												}
											})()
										}
									</FloatingSection>
									<FloatingSection style={{paddingLeft: "7px"}}><BoldLabel>Required field</BoldLabel></FloatingSection>
									<Clearfix></Clearfix>
								</div>
							);
						}
					})()
				}
			</FieldContainer>
		);
	}
}

export default SingleCustomField;
